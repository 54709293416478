import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@app/interfaces/address.modal';
import { cities } from '@app/interfaces/cities.response.modal';
import { normalizedAddress } from '@app/interfaces/trillium.address.response.modal';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class TrilliumService {
    constructor(private httpClient: HttpClient) {}

    getCities(zip) {
        return this.httpClient.get<cities>(environment.nodeserver + 'address?zipCode=' + zip);
    }

    normalizeAddress(addressInput: Address) {
        return this.httpClient.post<normalizedAddress>(environment.nodeserver + 'address', addressInput);
    }
}