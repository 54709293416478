<div class="main-container">
  <div class="login-main-content">
    <div class="form">
      <div class="login">
        <div class="feedback-box" *ngIf="hasErrors">
          <img class="error-icon" src="./assets/img/icon-error.png" alt="Error">
          <div class="error-text">Invalid user name/password combination.</div>
        </div>
        <div class="header-title">Pilot BOP Quote</div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="content">
            <div class="content-details">
              <div class="field-box-w336">
                <label class="field-label-w336">User name</label>
                <input class="field-input-w336 form-control" type="text" formControlName="username" [ngClass]="{ 'is-invalid': f.username.touched && f.username.errors }" />
                <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)" class="alert">
                  <div *ngIf="f.username.errors.required">Required</div>
                </div>
              </div>
              <div class="field-box-w336">
                <label class="field-label-w336">Password</label>
                <input class="field-input-w336" type="password" formControlName="password" [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }" />
                <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)" class="alert">
                  <div *ngIf="f.password.errors.required">Required</div>
                </div>
              </div>
            </div>
            <div class="button-group">
              <button class="login-submit">Log in</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
