import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { login } from "../../interfaces/login.modal";
import { SecurityService } from "./security.service";
import { authResp } from "../../interfaces/security.auth.response.modal";
import { Router } from "@angular/router";
import { map } from "rxjs";
import { of } from "rxjs";
import { Observable } from "rxjs";
import { catchError } from "rxjs";
import { DataService } from "./data.service";

@Injectable()
export class AuthService {
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private authResp: authResp;

  constructor(private security: SecurityService, private router: Router, private dataService: DataService) {}

  checkLogin() {
    // Check session storage for login data
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      // Update isLoggedIn$ BehaviorSubject to true
      this.isLoggedIn$.next(true);
    } else {
      // Update isLoggedIn$ BehaviorSubject to false
      this.isLoggedIn$.next(false);
    }
    return isLoggedIn;
  }

  loginData$: BehaviorSubject<login>;

  initiateLogin(loginInput: login): Observable<boolean> {
    return this.security.authenticateUser(loginInput).pipe(
      map(resp => {
        let isLoggedIn: boolean = false;
        if(resp && resp.apiOutput && resp.apiOutput.result && resp.apiOutput.result.ACORD && resp.apiOutput.result.ACORD.SignonRs 
          && resp.apiOutput.result.ACORD.SignonRs.Status && resp.apiOutput.result.ACORD.SignonRs.Status.StatusCd) {
          isLoggedIn = (resp.apiOutput.result.ACORD.SignonRs.Status.StatusCd._text === "206");
          if (isLoggedIn) {
            // Store login data in session storage
            sessionStorage.setItem("isLoggedIn", "true");
            sessionStorage.setItem("username", loginInput.username);
            sessionStorage.setItem("token", loginInput.username);

            this.dataService.current_business_request = this.dataService.instantiateRequest();
          }
  
          this.isLoggedIn$.next(isLoggedIn);
        }
        return isLoggedIn;
      }),
      catchError(error => {
        console.log(error);
        return of(false);
      })
    );
  }

  logout(): void {
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("current_business_request");

    this.isLoggedIn$.next(false);

    location.reload();
  }
}