import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@app/core/services/auth.service";
import { ComponentService } from '@app/core/services/component.service';

@Component({
  selector: "app-stepper",
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"]
})
export class StepperComponent implements OnInit {
  currentComponent: string;
  constructor(public router: Router, public authService: AuthService, private componentService: ComponentService) {

  }

  ngOnInit(): void {
    this.componentService.currentComponentChanged.subscribe((component: string) => {
      this.currentComponent = component;
      console.log("compo", this.currentComponent);
    });
  }

  goToInsuredPage() {
    this.router.navigate(['/agent']);
  }

  goToPropertyPage() {
    if (this.currentComponent !== 'insured') {
      this.router.navigate(['/property-details']);
    }
  }

  goToLiabilityPage() {
    if (this.currentComponent !== 'insured' && this.currentComponent !== 'property') {
      this.router.navigate(['/liability-details']);
    }
  }

  goToOptionalPage() {
    if (this.currentComponent !== 'insured' && this.currentComponent !== 'property' && this.currentComponent !== 'liability') {
      this.router.navigate(['/optional-coverages']);
    }
  }

  goToQuestionsPage() {
    if (this.currentComponent !== 'insured' && this.currentComponent !== 'property' && this.currentComponent !== 'liability' && this.currentComponent !== 'optionalCoverage') {
      this.router.navigate(['/uw-questions']);
    }
  }
}
