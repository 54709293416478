<div class="upper-header">
    <p class="upper-text">Choose day</p>
    <p class="upper-year">{{upperYear}}</p>
    <span class="upper-label">{{upperLabel}}</span>
</div>

<div class="header">
    <button class="button-arrows" (click)="previous('month')">
        <img src="../../../assets/img/calendar_left.png">
    </button>
    <span class="header-label">{{periodLabel}}</span>
    <button class="button-arrows" (click)="next('month')">
        <img src="../../../assets/img/calendar_right.png">
    </button>
</div>