import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { UiService } from "./ui.service";
import {
    AddlossDate,
    AddlossDetail,
    AddlossLiabReserv,
    AddlossPropReserv,
    AddressConst,
    BPBuildingConst,
    BPLocationsConst,
    BusinessDesc,
    BusinessName,
    BusinessOwnersConst,
    CExtendednamedinsured1,
  City,
  ClassCode,
  CompanyCode,
  CompanyName,
  ContactName,
  EMail,
  InsuredType,
  Line1,
  LosseHistory,
  OwnerPartner,
  Phone1,
  ProducerCode,
  PropertyType,
  StateCode,
  StateDesc,
  YrsExpClass,
  ZipCode,
  auditFrequency,
  cSmallContractor,
  classificationDesc,
  effectiveDate,
  expirationDate,
  hiredAutoLiabLimit,
  isMailingLocationAddressSame,
  liabMedicalExpensLmt,
  lossDate,
  lossDetail,
  lossLiabReserv,
  lossPropReserv,
  producerOfRecord,
  underwriterCode,
  yearsOfOperation
} from "../../contractor.constant";
import { DropdownService } from "./dropdown.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { DataMappingService } from "./data-mapping.service";


@Injectable({
  providedIn: "root",
})
export class AgentDataMappingService {
  constructor(
    public dataService: DataService,
    public dropDownService: DropdownService,
    public dataMappingService: DataMappingService,
    public uiService: UiService
  ) {
   
  }

  functionsMap = {
    
    [CompanyCode]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.CompanyCode =
            dataMappingService.currentForm.get(CompanyCode).value;
        }
      },
    ],
    [CompanyName]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.CompanyName =
            dataMappingService.currentForm.get(CompanyName).value;
        }
      },
    ],
    [effectiveDate]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.effectiveDate = dataMappingService.currentForm.get(effectiveDate).value;
        }
      },
    ],
    [expirationDate]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.expirationDate = dataMappingService.currentForm.get(expirationDate).value;
        }
      },
    ],
    [auditFrequency]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.auditFrequency =
            dataMappingService.currentForm.get(auditFrequency).value;
        }
      },
    ],
    [underwriterCode]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.quote_policy.underwriterCode =
            dataMappingService.currentForm.get(underwriterCode).value;
        }
      },
    ],
    [ProducerCode]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.producer.ProducerCode = dataMappingService.get10DigitString(dataMappingService.currentForm.get("Producer." + ProducerCode).value);
        }
      }
    ],
    [producerOfRecord]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.producer.producerOfRecord = dataMappingService.currentForm.get("Producer." + producerOfRecord).value;
        }
      }
    ],
    producerContactName: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.producer.Contact.ContactName = dataMappingService.currentForm.get("Producer.Contact." + ContactName).value;
        }
      }
    ],
    producerEMail: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.producer.Contact.EMail = dataMappingService.currentForm.get("Producer.Contact." + EMail).value;
        }
      }
    ],
    [Phone1]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.producer.Contact.Phone1 = dataMappingService.currentForm.get("Producer.Contact." + Phone1).value;
        }
      }
    ],
    InsuredPhone1: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          const formattedInsuredPhoneNumber = dataMappingService.formatPhoneNumber(dataMappingService.currentForm.get("Insured.Contact." + Phone1).value);
          dataService.insured.Contact.Phone1 = formattedInsuredPhoneNumber;
        }
      }
    ],
    [BusinessName]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.insured.BusinessName = dataMappingService.currentForm.get("Insured." + BusinessName).value;
        }
      }
    ],
    [CExtendednamedinsured1]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.insured.Extendednamedinsured1.CExtendednamedinsured1 = dataMappingService.currentForm.get("Insured." + CExtendednamedinsured1).value;
        }
      }
    ],
    [InsuredType]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.insured.InsuredType = dataMappingService.currentForm.get("Insured." + InsuredType).value;
        }
      }
    ],
    [yearsOfOperation]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.insured.yearsOfOperation = dataMappingService.currentForm.get("Insured." + yearsOfOperation).value;
        }
      }
    ],
    [YrsExpClass]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(YrsExpClass in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.YrsExpClass = dataService.insured.yearsOfOperation;
          }
        }
      }
    ],
    [EMail]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(EMail in dataService.insured.Contact){
            dataService.insured.Contact.EMail = dataMappingService.currentForm.get("Insured.Contact." + EMail).value;
          }
        }
      }
    ],
    [ContactName]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataMappingService.firstName = dataMappingService.currentForm.get("Insured.Contact.BoFirstName").value;
          dataMappingService.lastName = dataMappingService.currentForm.get("Insured.Contact.BoLastName").value;
          if(dataMappingService.currentForm.get("Insured.Contact.BoFirstName").value==''&& dataMappingService.currentForm.get("Insured.Contact.BoLastName").value=='')
          {
            dataService.insured.Contact.ContactName = "Not Entered";
          }
          else
          {dataService.insured.Contact.ContactName = `${dataMappingService.firstName} ${dataMappingService.lastName}`;
          }
        }
      }
    ],
    [ClassCode]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          //dataService.BPClassifications.ClassCode = dataMappingService.originalClassCodeObj.classcode;
        }
      }
    ],
    [classificationDesc]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if (classificationDesc in dataService.BPClassifications) {
           // dataService.BPClassifications.classificationDesc = dataMappingService.originalClassCodeObj.description;
          }
        }
      }
    ],
    [PropertyType]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          //dataService.BPBuilding.PropertyType = dataMappingService.originalClassCodeObj.propertytype
        }
      }
    ],
    [BusinessDesc]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          const BusinessDescControl = `${BusinessOwnersConst}.${BPBuildingConst}.${BusinessDesc}`;
          if (BusinessDesc in dataService.BPGeneralQuestionsMU) {
            dataService.BPGeneralQuestionsMU.BusinessDesc = dataMappingService.currentForm.get(BusinessDescControl).value;
          }
        }
      }
    ],
    [LosseHistory]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPGeneralQuestionsMU.LosseHistory = dataMappingService.currentForm.get(LosseHistory).value;
        }
      }
    ],
    loss: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          const lossDateValue = dataMappingService.currentForm.get(lossDate).value;
          if(lossDateValue && lossDateValue != null && lossDateValue != ''){
            if(dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 0){
              dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.push({
                lossDate: "",
                lossDetail: "",
                LossClosed: "N",
                lossPropReserv: "",
                lossLiabReserv: ""
              })
            }
            dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossDate = lossDateValue;
            dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossDetail = dataMappingService.currentForm.get(lossDetail).value;
            dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(lossPropReserv).value);
            dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(lossLiabReserv).value);
          }else{
            while (dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length > 0) {
              dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.pop();
            }      
          }
        }
      }
    ],
    addLoss: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length >= 2){
            while (dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length > 1) {
              dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.pop();
            }
          }
      
          const addLossDateValue = dataMappingService.currentForm.get(AddlossDate).value;
          if(addLossDateValue && addLossDateValue != null && addLossDateValue != ''){
            dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.push({
              lossDate: addLossDateValue,
              lossDetail: dataMappingService.currentForm.get(AddlossDetail).value,
              LossClosed: "N",
              lossPropReserv: dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(AddlossPropReserv).value),
              lossLiabReserv: dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(AddlossLiabReserv).value)
            })
          }
        }
      }
    ],
    insuredAddress: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          const insuredStateCode = dataMappingService.currentForm.get("Insured.Address." + StateCode).value;
          const insuredStateDesc = dataMappingService.getStateDescByCode(insuredStateCode);
            dataService.insured.Address.City = dataMappingService.currentForm.get("Insured.Address." + City).value;
            dataService.insured.Address.Line1 = dataMappingService.currentForm.get("Insured.Address." + Line1).value;
            dataService.insured.Address.ZipCode = dataMappingService.currentForm.get("Insured.Address." + ZipCode).value;
            dataService.insured.Address.StateCode = insuredStateCode;
            dataService.insured.Address.StateDesc = insuredStateDesc;
            dataMappingService.currentForm.get(
              "Insured.Address." + StateDesc
            ).setValue(insuredStateDesc);
         
        }
      }
    ],
    [OwnerPartner]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(OwnerPartner in dataService.BPGeneralQuestionsMU) {
            dataService.BPGeneralQuestionsMU.OwnerPartner = dataMappingService.isEffectiveDateAfterMargin?"35700":"28600";
          }
        }
      }
    ],
    [BPLocationsConst]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          let CopyData:any = dataService.quote_policy.BusinessOwners.BPLocations;
          if(CopyData.length > 1){
            CopyData.splice(1);
          }
          if("restEndorsement" in dataService.BPInlineOBJ){
            if(dataMappingService.propertyL1FirstVisit){
              CopyData[0].BPInlineOBJ.restEndorsement="";
            }
            else{
              CopyData[0].BPInlineOBJ.restEndorsement=dataMappingService.restaurantLocationOneEndorsementSelectedVal;
            }
          }
          if("apartmentBldEnd" in dataService.BPInlineOBJ){
            if(dataMappingService.propertyL1FirstVisit){
              CopyData[0].BPInlineOBJ.apartmentBldEnd="";
            }else{
              CopyData[0].BPInlineOBJ.apartmentBldEnd=dataMappingService.apartmentLocationOneEndorsementSelectedVal;
            }
          }
          CopyData[0].Address.StateCode = "MA"
          CopyData[0].Address.City = dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("City").value;
          CopyData[0].Address.Line1 = dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("Line1").value;
          CopyData[0].Address.StateDesc = dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("StateDesc").value;
          CopyData[0].Address.ZipCode = dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("ZipCode").value;
          CopyData[0].Address.CCoastalZone = "";
          CopyData[0].Address.cSmallContractor = dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("cSmallContractor").value;
          dataMappingService.originalClassCodeObj.businessDesc = dataMappingService.currentForm.get(BusinessOwnersConst).get("BPBuilding").get("BusinessDesc").value;
          dataService.quote_policy.BusinessOwners.BPLocations = CopyData;
          if(dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("ZipCode2")){
            dataMappingService.originalClassCodeObj.businessDesc2 = dataMappingService.currentForm.get(BusinessOwnersConst).get("BPBuilding").get("BusinessDesc2").value;
            let obj:any = {
              referenceObjectId: "2", 
              copyMailingAddress: "Y",
              name: "", 
              description: "", 
              Address: {
                City: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("City2").value, 
                Line1: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("Line2").value, 
                StateCode: "MA",
                StateDesc: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("StateDesc2").value, 
                ZipCode: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("ZipCode2").value,
                CCoastalZone: "",
                cSmallContractor: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("cSmallContractor2").value
              },
              BPInlineOBJ: {
                incLmtFireDeptSc: "N",
                outdoorSigns: "N",
                moneyAndSecurity: "N",
                empDishonestyApp: "N",
                moldFungWetDryRtAp: "N",
                wtrBckupSmpOvrfl: "N",
                apartBuildCov: "N",
                restaurantsCov: "N",
              }
            }
            if("restEndorsement" in dataService.BPInlineOBJ){
              if(dataMappingService.propertyL2FirstVisit){
                obj.BPInlineOBJ.restEndorsement="";
              }else{
                obj.BPInlineOBJ.restEndorsement=dataMappingService.restaurantLocationTwoEndorsementSelectedVal;
              }
            }
            if("apartmentBldEnd" in dataService.BPInlineOBJ){
              if(dataMappingService.propertyL2FirstVisit){
                obj.BPInlineOBJ.apartmentBldEnd="";
              }else{
                obj.BPInlineOBJ.apartmentBldEnd=dataMappingService.apartmentLocationTwoEndorsementSelectedVal;
              }
            }
            CopyData.push(obj)
            dataService.quote_policy.BusinessOwners.BPLocations = CopyData;
            
          }
          if(dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("ZipCode3")){
            dataMappingService.originalClassCodeObj.businessDesc3 = dataMappingService.currentForm.get(BusinessOwnersConst).get("BPBuilding").get("BusinessDesc3").value;
            let obj:any = {
              referenceObjectId: "3", 
              copyMailingAddress: "Y",
              name: "", 
              description: "", 
              Address: {
                City: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("City3").value, 
                Line1: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("Line3").value, 
                StateCode: "MA",
                StateDesc: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("StateDesc3").value, 
                ZipCode: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("ZipCode3").value,
                CCoastalZone: "",
                cSmallContractor: dataMappingService.currentForm.get(BusinessOwnersConst).get(BPLocationsConst).get(AddressConst).get("cSmallContractor3").value
              },
              BPInlineOBJ: {
                incLmtFireDeptSc: "N",
                outdoorSigns: "N",
                moneyAndSecurity: "N",
                empDishonestyApp: "N",
                moldFungWetDryRtAp: "N",
                wtrBckupSmpOvrfl: "N",
                apartBuildCov: "N",
                restaurantsCov: "N",
              }
            }
            if("restEndorsement" in dataService.BPInlineOBJ){
              if(dataMappingService.propertyL3FirstVisit){
                obj.BPInlineOBJ.restEndorsement="";
              }else{
                obj.BPInlineOBJ.restEndorsement=dataMappingService.restaurantLocationThreeEndorsementSelectedVal;
              }
            }
            if("apartmentBldEnd" in dataService.BPInlineOBJ){
              if(dataMappingService.propertyL3FirstVisit){
                obj.BPInlineOBJ.apartmentBldEnd="";
              }else{
                obj.BPInlineOBJ.apartmentBldEnd=dataMappingService.apartmentLocationThreeEndorsementSelectedVal;
              }
            }
            CopyData.push(obj)
            dataService.quote_policy.BusinessOwners.BPLocations = CopyData;
          }
        }
      }
    ],
    blanketRating: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.blanketRating = dataMappingService.currentForm.get(BusinessOwnersConst).get("blanketRating").value;
          if(dataMappingService.currentForm.get(BusinessOwnersConst).get("blanketRating").value=='Y'){
            let x= {
              "BlanketId": "1",
              "BlanketType": dataMappingService.blanketType,
              "BuildingBppLimit": "0",
              "EarthquakeSublimit": "N"
            }
            dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"]=x; 
          }else{
            delete dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"];
          }
        }
      }
    ],
    // []: [
    //   (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
    //     if (flag) {
          
    //     }
    //   }
    // ],
    // add more mappings as necessary


  };


  businessFlagFunctionsMap = {
    [BusinessAttribute.CONTRACTORS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.OwnerPartner,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
      // add more functions as necessary
    ],
    [BusinessAttribute.APARTMENTS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
      // add more functions as necessary
    ],
    [BusinessAttribute.WHOLESALE_RISKS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.OFFICE]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.MERCANTILE_RISKS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.CONVENIENCE]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.RESTAURANTS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ],
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: [
      this.functionsMap.CompanyCode,
      this.functionsMap.CompanyName,
      this.functionsMap.effectiveDate,
      this.functionsMap.expirationDate,
      this.functionsMap.auditFrequency,
      this.functionsMap.underwriterCode,
      this.functionsMap.ProducerCode,
      this.functionsMap.producerOfRecord,
      this.functionsMap.producerContactName,
      this.functionsMap.producerEMail,
      this.functionsMap.Phone1,
      this.functionsMap.BusinessName,
      this.functionsMap.CExtendednamedinsured1,
      this.functionsMap.InsuredType,
      this.functionsMap.yearsOfOperation,
      this.functionsMap.YrsExpClass,
      this.functionsMap.EMail,
      this.functionsMap.ContactName,
      this.functionsMap.ClassCode,
      this.functionsMap.classificationDesc,
      this.functionsMap.PropertyType,
      this.functionsMap.BusinessDesc,
      this.functionsMap.LosseHistory,
      this.functionsMap.loss,
      this.functionsMap.addLoss,
      this.functionsMap.insuredAddress,
      this.functionsMap.InsuredPhone1,
      this.functionsMap.BPLocations,
      this.functionsMap.blanketRating
    ]

    // add more mappings as necessary
  };

  // call this function from data mapping service for mapping data
  mapDataBasedOnFlagName (dataMappingService: DataMappingService, dataService: DataService) {
    const functionsToExecute = this.businessFlagFunctionsMap[dataMappingService.getBusinessFlag()];
    console.log(functionsToExecute, "functionsToExecute")
    if (functionsToExecute) {
      functionsToExecute.forEach((functions) => {
        functions.forEach((fn) => {
          fn(true, dataMappingService, dataService);
        });
      });
    }
  }
}
