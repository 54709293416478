import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() {
    const savedValue = sessionStorage.getItem(this.LOADING_KEY);
    if (savedValue !== null) {
      this.isLoading = JSON.parse(savedValue);
    }
    this.isLoadingFromStorage = false; 
  }

  private readonly LOADING_KEY = 'isLoading';
  private readonly LOADING_TEXT = 'We are calculating your quote';
  loaderText: string = this.LOADING_TEXT;
  isLoading: boolean = false;

  setLoaderText(value?: string) {
    this.loaderText = value && value.trim().length > 0 ? value: this.LOADING_TEXT;
  }

  get isLoadingFromStorage(): boolean {
    const savedValue = sessionStorage.getItem(this.LOADING_KEY);
    return savedValue !== null ? JSON.  parse(savedValue) : this.isLoading;
  }

  set isLoadingFromStorage(value: boolean) {
    this.isLoading = value;
    sessionStorage.setItem(this.LOADING_KEY, JSON.stringify(value));
  }

}
