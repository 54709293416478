<div class="bottom-nav-clear"></div>

<div class="bottom-fixed-nav" *ngIf="router.url !== '/login' && router.url !== '/quote-summary'">

    <div class="bottom-nav-content" *ngIf="router.url === '/agent'">
        <div>
            <button class="back-button" *ngIf="false">
                <img src="./assets/img/navArrowLeft.png">
                Back
            </button>
        </div>

        <div>
            <button class="continue-button" (click)="goToNextPage()">
                Continue
                <img src="./assets/img/navArrowRight.png">
            </button>
        </div>
    </div>

    <div class="bottom-nav-content" *ngIf="router.url === '/property-details'">
        <div>
            <button class="back-button" (click)="goToPreviousPage()">
                <img src="./assets/img/navArrowLeft.png">
                Back
            </button>
        </div>

        <div>
            <button class="continue-button" (click)="goToNextPage()">
                Continue
                <img src="./assets/img/navArrowRight.png">
            </button>
        </div>
    </div>

    <div class="bottom-nav-content" *ngIf="router.url === '/liability-details'">
        <div>
            <button class="back-button" (click)="goToPreviousPage()">
                <img src="./assets/img/navArrowLeft.png">
                Back
            </button>
        </div>

        <div>
            <button class="continue-button" (click)="goToNextPage()">
                Continue
                <img src="./assets/img/navArrowRight.png">
            </button>
        </div>
    </div>

    <div class="bottom-nav-content" *ngIf="router.url === '/optional-coverages'">
        <div>
            <button class="back-button" (click)="goToPreviousPage()">
                <img src="./assets/img/navArrowLeft.png">
                Back
            </button>
        </div>

        <div>
            <button class="continue-button" (click)="goToNextPage()">
                Continue
                <img src="./assets/img/navArrowRight.png">
            </button>
        </div>
    </div>

    <div class="bottom-nav-content" *ngIf="router.url === '/uw-questions'">
        <div>
            <button class="back-button" (click)="goToPreviousPage()">
                <img src="./assets/img/navArrowLeft.png">
                Back
            </button>
        </div>

        <div>
            <button class="continue-button" (click)="goToNextPage()">
                Continue
                <img src="./assets/img/navArrowRight.png">
            </button>
        </div>
    </div>
</div>