<form [formGroup]="uwQuestionsform">
  <div class="uw-main-contractor">

    <div class="uw-container-contractor">
      <app-developer-json-view [myFormGroup]="uwQuestionsform" [mode]="'uwQuestionsform'"></app-developer-json-view>
      <!-- <div class="uw-block-contractor"> -->
      <div [ngClass]="uiService.natureOfBusines =='Apartments' ? 'uw-block-apartment':'uw-block-contractor'">
        <div class="uw-section-contractor">
          <div class="uw-inner-title">
            <p class="iner-title">Underwriting Questions</p>
          </div>

          <div class="uw-row1">
            <div class="uw-check1">
              <label class="required-field">
                Are any listed building(s) under construction or reconstruction?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                      check1selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check1ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                      check1selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check1ValChange(false)">
                  No
                </div>
              </div>
              <div
                *ngIf="uiService.uwBuildUnderConstructionReConstructionRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>

          <div class="uw-row2">
            <div class="uw-check2">
              <div class="uw-check2-label1">
                <label class="uw-check2-label required-field">
                  Do any of the premises include a restaurant, auto repair, manufacturing or processing occupant?
                </label>
              </div>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    check2selectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check2ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    check2selectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check2ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwBusinessPremisesRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>

          <div class="uw-row3">
            <div class="uw-check3">
              <div class="uw-check3-label1">
                <label class="uw-check3-label required-field">
                  Has the most recent policy been cancelled or non-renewed?
                </label>
              </div>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    check3selectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check3ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    check3selectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check3ValChange(false)">
                  No
                </div>
              </div>
              <div
                *ngIf="uiService.uwRecentPolicyCancelledOrRenewedRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="uw-alert">
                Required
              </div>
            </div>

            <div class="uw-row3-label" *ngIf="check3selectedVal == 'Y'">
              <label class="required-field">Reason</label>
              <select name="type" class="uw-row3-dropdown" id="uw-questions-reasons"
                [formControlName]="contractorConstants.cReasons">
                <option class="dropdown-text" style="display:none">Select</option>
                <option class="dropdown-text" [value]="reason.id" *ngFor="let reason of dropDownService.reasonOptions">
                  {{
                  reason.value }}</option>
              </select>
            </div>
          </div>
          <div class="uw-row4">
            <div class="uw-check4">
              <label class="required-field">
                Does the applicant have other businesses or locations for which
                coverage is not requested?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    check4selectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check4ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    check4selectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check4ValChange(false)">
                  No
                </div>
              </div>
              <div
                *ngIf="uiService.uwApplicantOthersCoverageNotRequestRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-row5">
            <div class="uw-check5">
              <label class="required-field" style="width: 367px">
                MAPFRE Companion Policy
              </label>
              <div class="tooltip">
                <img src="../../../assets/img/icon-info.png">
                <span class="tooltiptext">
                  A 5% Companion Policy Credit for the BOP applies when another eligible C/L Policy is written with
                  MAPFRE
                </span>
              </div>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    check5selectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check5ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    check5selectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check5ValChange(false)">
                  No
                </div>
              </div>

            </div>

            <div class="uw-row5-label" *ngIf="check5selectedVal == 'Y'">
              <label style="width: 90px" class="required-field">Policy Type</label>
              <select name="type" class="uw-row5-dropdown" [formControlName]="contractorConstants.CCompPolicyType"
                id="uw-questions-policy-type">
                <option class="dropdown-text" style="display:none">Select</option>
                <option class="dropdown-text" [value]="policy" *ngFor="let policy of dropDownService.policyTypeOptions">
                  {{ policy }}</option>
              </select>
            </div>
            <div class="uw-row5-label" *ngIf="check5selectedVal == 'Y'">
              <label class="required-field" style="width: 115px">Policy Number</label>
              <input class="uw-row5-dropdown" style="width: 196px;" min="1" max="20"
                [formControlName]="contractorConstants.CCompPolicyNumberRef" id="uw-questions-policy-number" />
            </div>
          </div>
          <div class="uw-row6" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
            <div class="uw-check6">
              <div class="uw-label6">
                <label class="uw-label6_1 required-field">
                  Has the applicant been aware of any incident, circumstance,
                  defect or alleged defects including but not limited to faulty
                  or defective workmanship, product failure, construction
                  dispute, property damage, or subcontractor or construction
                  worker injury that has or may give rise to a claim or lawsuit,
                  whether valid or not, which might involve the applicant?
                </label>
              </div>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    check6selectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check6ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    check6selectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="check6ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwApplicantLawSuitRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>

          <div class="uw-apartment-row7"
            *ngIf="((dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]) && (uiService.condo69145Flag) && (uiService.condoDOSelectedFlag=='Y'))|| ((dataMappingService.businessFlags[BusinessAttribute.OFFICE])&&(uiService.condoOffice60999Flag)&& (uiService.condoDOSelectedFlag=='Y'))">
            <div class="uw-apartment-check7">
              <label class="required-field">
                Does any Board Member have knowledge of any act which has given or may give rise to a claim?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  check7selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check7ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                  check7selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check7ValChange(false)">
                  No
                </div>
              </div>
              <div
                *ngIf="uiService.uwBoardMemberKnowledgeAboutClaimRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-apartment-row7" *ngIf="dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]">
            <div class="uw-apartment-check7">
              <label class="required-field">
                Does insured comply w/NFPA-96?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  checkInsuredComplyNFPA96selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="checkInsuredComplyNFPA96ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                  checkInsuredComplyNFPA96selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="checkInsuredComplyNFPA96ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwInsuredComplyNFPA96RequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-apartment-row7"
            *ngIf="dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] || dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS] ">
            <div class="uw-apartment-check7">
              <label class="required-field">
                Any foreign products imported to the United States by the Applicant?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                  checkForeignProductImportselectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="checkForeignProductImportValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                  checkForeignProductImportselectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="checkForeignProductImportValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwForeignProductImportRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-apartment-row8"
            *ngIf="checkForeignProductImportselectedVal == 'Y' && dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS] || checkForeignProductImportselectedVal == 'Y' && dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]">
            <div class="uw-apartment-check8">
              <label class="required-field">Describe the Products</label>
              <textarea class="field-input-w576 required-field" style="height: 95px;" maxlength="4000"
                [formControlName]="contractorConstants.CDescProducts"></textarea>
              <div *ngIf="
              uwQuestionsform.get(contractorConstants.CDescProducts).invalid &&
                    (uwQuestionsform.get(contractorConstants.CDescProducts).dirty ||
                    uwQuestionsform.get(contractorConstants.CDescProducts).touched)
                  " class="alert">
                Required</div>

            </div>
          </div>
          <div class="uw-apartment-inner-title" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
            <p class="iner-title">
              <!-- Is there any of the following on the location premises? -->
              Do any of the locations have the following on the premises?
            </p>
          </div>
          <div class="uw-apartment-row8" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
            <div class="uw-apartment-check8">
              <label class="required-field">
                Swimming Pools?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                      check8selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check8ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                  check8selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check8ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwPremiseSwimmingPoolRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-apartment-row9" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
            <div class="uw-apartment-check9">
              <label class="required-field">
                Recreational Facilities?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                      check9selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check9ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                      check9selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check9ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwPremiseRecreationFacilityRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
          <div class="uw-apartment-row10" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]">
            <div class="uw-apartment-check10">
              <label class="required-field">
                Playground?
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                      check10selectedVal == 'Y'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check10ValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                      check10selectedVal == 'N'
                        ? 'selected-button'
                        : 'unselected-button'
                    " (click)="check10ValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="uiService.uwPremisePlaygroundRequiredFlag && (!uiService.uwReenteredRequiredFlag)"
                class="alert">
                Required
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="uw-rectangle-contractor"></div>

  </div>
</form>