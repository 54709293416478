import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DataService } from "@app/core/services/data.service";
import { DataMappingService } from "@app/core/services/data-mapping.service";
import { ComponentService } from '@app/core/services/component.service';

import { liabMedicalExpensLmt, prodCompleteOperAggLmt, liabMedicalExpAggLmt, medicalExpensPerPerson, totalPayroll, liabilityDeductible, applicantSubcontract, Endorsement, snowIceRemoval, roadHighways, parkingLots, privateDriveways, cNoOfPlows, interest, condDirctLiabEndt, condLiabEndtLmt, condOffLiabEndtDeduc, BPAdditionalInsured, BPOptionalRTCCoveragesConst, brbBeutPrsEnhPrfLb, professionalClass, noOfPartTimeBarbers, noOfFullTimeBarbers, noOfFlTmBuPrEnPf, noOfPtTmBuPrEnPf, photography, veterinarProfLiab, numberOfVeterinarian, AnualGrossSales, LiquorSales, snowPlowing } from "@app/contractor.constant";

import * as contractorConstants from '@app/contractor.constant';
import { DropdownService } from "@app/core/services/dropdown.service";
import { UiService } from '@app/core/services/ui.service';
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';

@Component({
  selector: "app-liability-details",
  templateUrl: "./liability-details.component.html",
  styleUrls: ["./liability-details.component.scss"]
})

export class LiabilityDetailsComponent implements OnInit {

  prodCompleteOperAggLmt: string;
  liabMedicalExpAggLmt: string;
  liquiorLiabilityLimitLabelValue: string;

  contractorConstants = contractorConstants;
  BusinessAttribute = BusinessAttribute;
  liabilityForm: FormGroup;

  liabilityDeductibleLabelValue: string;

  policyStartPayroll: string;

  public addressBpLocation: string='10 E Main St, Dorchester, 02124';
  blanketTypes=["Building","Building and Business Personal Property Combined","Business Personal Property"]

  constructor(private router: Router, private fb: FormBuilder, private componentService: ComponentService, public dataService: DataService, public dataMappingService: DataMappingService, public dropDownService: DropdownService, public uiService: UiService) {
    this.componentService.currentComponent = 'liability';
    this.componentService.currentComponentChanged.emit('liability');
  }

  goToPage(pageName: string, next?: boolean): void {
    if (next) {
      this.dataMappingService.mapLiabilityFormDatatoFinalJson(this.liabilityForm);
    }
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit(): void {
    this.initializeLiabilityForm();
    this.initializeFormDefaultValues();
    this.initializeCondoLiabilityFormChanges();
    this.initializeCondo64181OfficeFormChanges();
    // this.initializeProcessServiceFormChanges();
    this.initiliazeLiquorFormChanges();
    this.conditionalContractFieldRequired();
    this.dataMappingService.currentForm = this.liabilityForm;
    this.initializeRadioValues();
    this.initializeLiabilityDeductibleForContractors();
    this.initializeEndorsementOffice();
    this.payrollHelpTextAdjust();
    this.setBlanketRatingFields()
    this.claculateBlanketLimit()
    this.mapPhotographyEndorsement()

  }

  liabilityPayrollValidadation() {
    if (this.uiService.isDateAfter && this.liabilityForm.get([totalPayroll]).value) {
      if (this.liabilityForm.get([totalPayroll]).value < 35700) {
        this.uiService.isLiabilityPayrollNotValid = true;
        this.liabilityForm.get([totalPayroll]).setErrors({ 'totalPayroll': true });
      } else {
        this.uiService.isLiabilityPayrollNotValid = false;
      }
    } else if (!this.uiService.isDateAfter && this.liabilityForm.get([totalPayroll]).value) {
      if (this.liabilityForm.get([totalPayroll]).value < 28600) {
        this.uiService.isLiabilityPayrollNotValid = true;
        this.liabilityForm.get([totalPayroll]).setErrors({ 'totalPayroll': true });
      } else {
        this.uiService.isLiabilityPayrollNotValid = false;
      }
    } else {
      this.uiService.isLiabilityPayrollNotValid = false;
    }
  }

  payrollHelpTextAdjust() {
    if (this.uiService.isDateAfter) {
      this.policyStartPayroll = "$35,700";
    } else {
      this.policyStartPayroll = "$28,600"
    }
  }

  conditionalContractFieldRequired() {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get([applicantSubcontract]), this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get([snowPlowing]), this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]);
  }
  initializeLiabilityForm() {
    const businessOwners = this.dataService.businessOwners;
    this.liabilityForm = new FormGroup({
      [liabMedicalExpensLmt]: new FormControl(businessOwners.liabMedicalExpensLmt),
      [prodCompleteOperAggLmt]: new FormControl(businessOwners.prodCompleteOperAggLmt), // calculated value to be mapped
      [liabMedicalExpAggLmt]: new FormControl(businessOwners.liabMedicalExpAggLmt), // calculated value to be mapped
      [medicalExpensPerPerson]: new FormControl(businessOwners.medicalExpensPerPerson),
      [liabilityDeductible]: new FormControl(liabilityDeductible in businessOwners ? businessOwners.liabilityDeductible : ""), // calculated value to be mapped
      [applicantSubcontract]: new FormControl(applicantSubcontract in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.applicantSubcontract : ''),
      [Endorsement]: new FormControl(''),
      [snowIceRemoval]: new FormControl(snowIceRemoval in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.snowIceRemoval : ''),
      [roadHighways]: new FormControl(roadHighways in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.roadHighways : ''),
      [parkingLots]: new FormControl(parkingLots in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.parkingLots : ''),
      [privateDriveways]: new FormControl(privateDriveways in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.privateDriveways : ''),
      [cNoOfPlows]: new FormControl(''),
      [interest]: new FormControl(''),
      [snowPlowing]: new FormControl(''),
      blanketType:new FormControl('Building'),
      blanketLimit:new FormControl('')
    });
  }

  initializeCondoLiabilityFormChanges() {
    if ((this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS] && this.uiService.condo69145Flag) || (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE] && this.uiService.condoOffice60999Flag)) {
      this.liabilityForm.addControl(condDirctLiabEndt, new FormControl(''));
      this.liabilityForm.addControl(condLiabEndtLmt, new FormControl(''));
      this.liabilityForm.addControl(condOffLiabEndtDeduc, new FormControl(''));
    } else {
      this.liabilityForm.removeControl(condDirctLiabEndt);
      this.liabilityForm.removeControl(condLiabEndtLmt);
      this.liabilityForm.removeControl(condOffLiabEndtDeduc);
    }
  }

  initiliazeLiquorFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && this.dataService.BPClassifications.ClassCode == '09331') || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {
      this.liabilityForm.addControl(LiquorSales, new FormControl(''));
    } else {
      this.liabilityForm.removeControl(LiquorSales);

    }
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && this.uiService.convenience09331Flag)) {
      this.liabilityForm.addControl(AnualGrossSales, new FormControl(''));
    } else {
      this.liabilityForm.removeControl(AnualGrossSales);

    }
    this.initialValueForliquorLiabilityCoverage();
  }

  initializeProcessServiceFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) {
      this.liabilityForm.addControl(brbBeutPrsEnhPrfLb, new FormControl(''));
      this.liabilityForm.addControl(professionalClass, new FormControl(''));
      this.liabilityForm.addControl(noOfFullTimeBarbers, new FormControl(''));
      this.liabilityForm.addControl(noOfPartTimeBarbers, new FormControl(''));
      this.liabilityForm.addControl(noOfFlTmBuPrEnPf, new FormControl(''));
      this.liabilityForm.addControl(noOfPtTmBuPrEnPf, new FormControl(''));
      this.liabilityForm.addControl(photography, new FormControl(''));
      this.initialValueForProcessServiceBarberBeauticianPhoto();
    } else {
      this.liabilityForm.removeControl(brbBeutPrsEnhPrfLb);
      this.liabilityForm.removeControl(professionalClass);
      this.liabilityForm.removeControl(noOfFullTimeBarbers);
      this.liabilityForm.removeControl(noOfPartTimeBarbers);
      this.liabilityForm.removeControl(noOfFlTmBuPrEnPf);
      this.liabilityForm.removeControl(noOfPtTmBuPrEnPf);
      this.liabilityForm.removeControl(photography);

    }
  }

  initializeCondo64181OfficeFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE] && this.uiService.classcodeOffice64181Flag) {
      //Need to add for Veterinarian Endorsement
      this.liabilityForm.addControl(veterinarProfLiab, new FormControl(''));
      this.liabilityForm.addControl(numberOfVeterinarian, new FormControl(''));
    }
    else {
      this.liabilityForm.removeControl(veterinarProfLiab);
      this.liabilityForm.removeControl(numberOfVeterinarian);
    }
  }

  initialValueForCondoFields() {

    let condLiabEndtLmtValue = condLiabEndtLmt in this.dataService.BPClassifications ? this.dataService.BPClassifications.condLiabEndtLmt : '';
    let GetcondLiabEndtLmtValue= this.liabilityForm.get(liabMedicalExpensLmt).value === "$2,000,000" ? "$1,000,000" : this.liabilityForm.get(liabMedicalExpensLmt).value;
    condLiabEndtLmtValue = condLiabEndtLmtValue == '' && this.condoDOSelectedVal == 'Y' ? this.dataMappingService.removeCommasAndDollar(GetcondLiabEndtLmtValue) : condLiabEndtLmtValue; // init and back button  logic for data
    let condOffLiabEndtDeducValue = condOffLiabEndtDeduc in this.dataService.BPClassifications ? this.dataService.BPClassifications.condOffLiabEndtDeduc : '';
    condOffLiabEndtDeducValue = condOffLiabEndtDeducValue =='' && this.condoDOSelectedVal == 'Y' ? (this.dataService.BPBuilding.OptionalPropertyDedu == '7500' ? '5000' : this.dataService.BPBuilding.OptionalPropertyDedu) : condOffLiabEndtDeducValue; // init and back button  logic for data prefill
    this.liabilityForm.get(condLiabEndtLmt).setValue(this.dataMappingService.convertToDollarFormat(Number(condLiabEndtLmtValue)));
    this.liabilityForm.get(condOffLiabEndtDeduc).setValue(this.dataMappingService.convertToDollarFormat(Number(condOffLiabEndtDeducValue)));
  }

  public medicalExpenseSelectedVal: string;
  public applicantSubContSelectedVal: string;
  public snowPlowingSelectedVal: string;
  public blanketOngoingSelectedVal: string;
  public blanketCompletedSelectedVal: string;
  public waiverOfSubSelectedVal: string;
  public primNonContributorySelectedVal: string;

  public condoDOSelectedVal: string;


  public condoOffice60999Flag: boolean = false;
  public classcodeOffice64181Flag: boolean = false;

  public defaultContractorLiabilityDeductibleFlag: boolean = false;

  public processingBarberService7195271332Flag: boolean = false;
  public psBarberBeauticianSelectedVal: string;

  public psFuneralServiceSelectedVal: string;

  public processingPhotoService71899Flag: boolean = false;
  public psPhotoServiceSelectedVal: string;

  public liquorLiabilityCoverageSelectedVal: string;
  public liquorLiabilityMerchantileSelectedVal: string;

  public veterinarianEndorsementSelectedVal: string;
  
  // formControlName="liabMedicalExpensLmt"

  public processedLiabilityLimitOptions: any = [];

  public noPlowsSnow: boolean = true;

  toggleForm() {
    this.noPlowsSnow = !this.noPlowsSnow
  }
  showDiv = {
    noPlowsSnow: true,
  }

  initializeFormDefaultValues() {
    let max1000000LiabilityLimitClassCodes: number[] = [74161, 74171, 74221, 74231, 76021, 76031];

    if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && max1000000LiabilityLimitClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
      this.processedLiabilityLimitOptions = this.dropDownService.liabilityLimitOptions.filter(options => options.value <= 1000000);
    }
    else {
      this.processedLiabilityLimitOptions = this.dropDownService.liabilityLimitOptions;
    }
    const selectedValue = this.processedLiabilityLimitOptions.filter(options => options.value == Number(this.dataService.businessOwners.liabMedicalExpensLmt))[0].name
    this.liabilityForm.get(liabMedicalExpensLmt).setValue(selectedValue);
    this.setLabelValues(this.dataMappingService.convertToDollarFormat(Number(this.dataService.businessOwners.prodCompleteOperAggLmt)));

    //Flag for Merchantile Bewarage Store
    let bewareClassCodes: number[] = [59215, 54136, 54321, 54341, 54221, 54241];
    if (this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] && bewareClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
      this.uiService.mercantileRiskBewargeGorceryStoreFlag = true;
      console.log("this.uiService.mercantileRiskBewargeGorceryStoreFlag", this.uiService.mercantileRiskBewargeGorceryStoreFlag);
    }

    //Flag for condo 69145
    this.uiService.condo69145Flag = this.condoCheck('69145');


    //Flag for condo Office 60999
    this.uiService.condoOffice60999Flag = this.condoCheck('60999');

    //Veterinarian Endorsement Flag for class code 64181
    this.uiService.classcodeOffice64181Flag = (this.dataService.BPClassifications.ClassCode == '64181');

    //Flag for processing service class code  71952 or 71332
    this.uiService.processingProcessService7195271332Flag = (this.dataService.BPClassifications.ClassCode == '71952' || this.dataService.BPClassifications.ClassCode == '71332');
    this.uiService.processingBarberService71332Flag = (this.dataService.BPClassifications.ClassCode == '71332');
    this.uiService.processingBeauticianService71952Flag = (this.dataService.BPClassifications.ClassCode == '71952');

    //Flag for processing service class code  71865
    this.uiService.processingFuneralService71865Flag = (this.dataService.BPClassifications.ClassCode == '71865');

    //Flag for processing service class code  71899
    this.uiService.processingPhotoService71899Flag = (this.dataService.BPClassifications.ClassCode == '71899');

    //Flag for Convenience class code  09331
    this.uiService.convenience09331Flag = (this.dataService.BPClassifications.ClassCode == '09331');
 
  }

  initializeLiabilityDeductibleForContractors() {
    this.liabilityDeductibleLabelValue = '';
    this.defaultContractorLiabilityDeductibleFlag = false;

    if (this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
      //class codes for default values
      let default500LiabilityDeductible: number[] = [74161, 74171, 74221, 74231, 75781, 75791, 75811, 75822];

      let default250LiabilityDeductible: number[] = [75601, 75611, 75631, 75641];

      if (default500LiabilityDeductible.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
        this.defaultContractorLiabilityDeductibleFlag = true;
        // this.liabilityDeductibleLabelValue = this.dataMappingService.convertToDollarFormat(Number("this.dataService.BPBuilding.OptionalPropertyDedu"));
        this.liabilityDeductibleLabelValue = this.dataMappingService.convertToDollarFormat(Number("500"));
      }
      if (default250LiabilityDeductible.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
        this.defaultContractorLiabilityDeductibleFlag = true;
        this.liabilityDeductibleLabelValue = this.dataMappingService.convertToDollarFormat(Number("250"));
      }
      this.liabilityForm.get(liabilityDeductible).setValue(this.liabilityDeductibleLabelValue != '' ? this.dataMappingService.removeCommasAndDollar(this.liabilityDeductibleLabelValue) : '');

    }
  }

  initializeEndorsementOffice() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE]) {
      let officeEndorsementClassCodes: number[] = [63751, 63741, 63771, 63761, 63891, 63911, 64031, 64051, 64081, 64091, 63691, 63711, 63721, 63731];

      if (officeEndorsementClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
        if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Exclusion - Personal And Advertising Injury').length == 0) {
          this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Exclusion - Personal And Advertising Injury' });
        }
      } else {
        this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Exclusion - Personal And Advertising Injury');
      }


      let officeEndorsementLawyersClassCodes: number[] = [63921, 63931];

      if (officeEndorsementLawyersClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
        if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Exclusion - Personal And Advertising Injury - Lawyers').length == 0) {
          this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Exclusion - Personal And Advertising Injury - Lawyers' });
        }
      } else {
        this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Exclusion - Personal And Advertising Injury - Lawyers');
      }
    }
  }


  initializeRadioValues() {
    if (BPOptionalRTCCoveragesConst in this.dataService.businessOwners) {
      let snowPlowingFlag: boolean = false;
      snowPlowingFlag = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Snow Plow Coverage (Mass only)').length != 0;

      let waiverOfSubFlag: boolean = false;
      waiverOfSubFlag = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Transfer of Rights of Recovery (Waiver of Subrogation)').length != 0;

      let onPrimNonContributoryFlag = false;
      onPrimNonContributoryFlag = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Primary and Noncontributory').length != 0;

      if (this.uiService.snowPlowingSelected) {
        this.onSnowPlowingClick(snowPlowingFlag);
      }
      if (snowPlowingFlag) {
        this.liabilityForm.get(cNoOfPlows).setValue(this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Snow Plow Coverage (Mass only)')[0].cNoOfPlows)
      }

      if (waiverOfSubFlag || this.uiService.waiverOfSubFlag != '') {
        this.onWaiverOfSubClick(waiverOfSubFlag); // dirty check
      }

      if (onPrimNonContributoryFlag || this.uiService.onPrimNonContributoryFlag != '') {
        this.onPrimNonContributoryClick(onPrimNonContributoryFlag); // dirty check
      }
    }

    let onMedicalExpenseFlag = true;
    onMedicalExpenseFlag = this.dataService.businessOwners.medicalExpensPerPerson == '5000';

    if (applicantSubcontract in this.dataService.BPGeneralQuestionsMU) {
      let applicantSubcontractFlag = false;
      if (this.dataService.BPGeneralQuestionsMU.applicantSubcontract) {
        applicantSubcontractFlag = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.applicantSubcontract);
        this.onApplicantSubContClick(applicantSubcontractFlag);
      }
    }

    if (BPAdditionalInsured in this.dataService.businessOwners) {
      let onBlanketOngoingFlag = false;
      onBlanketOngoingFlag = this.dataService.BPAdditionalInsured.filter(option => option.interestName == 'Owners, Lessees Or Contractors - With AI Requirement in Const Contract (Blanket)').length != 0;

      let onBlanketCompletedFlag = true;
      onBlanketCompletedFlag = this.dataService.BPAdditionalInsured.filter(option => option.interestName == 'Owners, Lessees Or Contractors - With AI Requirement In Const Contract - Prod \u0026 Compl Ops (Blanket)').length != 0;

      if (onBlanketOngoingFlag || this.uiService.onBlanketOngoingFlag != '') {
        this.onBlanketOngoingClick(onBlanketOngoingFlag); // dirty check
      }

      if (onBlanketCompletedFlag || this.uiService.onBlanketCompletedFlag != '') {
        this.onBlanketCompletedClick(onBlanketCompletedFlag); // dirty check
      }
    }

    this.onMedicalExpenseClick(onMedicalExpenseFlag);


    if (this.uiService.condo69145Flag && this.uiService.condoDOSelectedFlag != '') {
      this.onCondoDOClick(this.dataMappingService.YNToBool(this.uiService.condoDOSelectedFlag));
    }

    if (this.uiService.condoOffice60999Flag && this.uiService.condoDOSelectedFlag != '') {
      this.onCondoDOClick(this.dataMappingService.YNToBool(this.uiService.condoDOSelectedFlag));
    }

    // if (this.uiService.classcodeOffice64181Flag && this.uiService.veterinarianEndorsementSelectedFlag != '') {
    //   this.onveterinarianEndorsementClick(this.dataMappingService.YNToBool(this.uiService.veterinarianEndorsementSelectedFlag));
    // }

    if (this.uiService.convenience09331Flag && this.uiService.liquorLiabilityCoverageSelectedFlag != '') {
      this.onliquorLiabilityCoverageClick(this.dataMappingService.YNToBool(this.uiService.liquorLiabilityCoverageSelectedFlag));
    }

    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] && this.uiService.liquorLiabilityCoverageSelectedFlag != '') {
      this.onliquorLiabilityCoverageClick(this.dataMappingService.YNToBool(this.uiService.liquorLiabilityCoverageSelectedFlag));
    }

    if (this.uiService.mercantileRiskBewargeGorceryStoreFlag && this.uiService.liquorLiabilityCoverageSelectedFlag != '') {
      this.onliquorLiabilityCoverageClick(this.dataMappingService.YNToBool(this.uiService.liquorLiabilityCoverageSelectedFlag));
    }

    // if ((this.uiService.processingBarberService71332Flag || this.uiService.processingBeauticianService71952Flag) && this.uiService.processingServiceBarBeautSelectedFlag != '') {
    //   this.onPsBarberBeauticianClick(this.dataMappingService.YNToBool(this.uiService.processingServiceBarBeautSelectedFlag));
    // }

    // if (this.uiService.processingPhotoService71899Flag && this.uiService.processingServicePhotoSelectedFlag != '') {
    //   this.onPsPhotoServiceClick(this.dataMappingService.YNToBool(this.uiService.processingServicePhotoSelectedFlag));
    // }

    if (this.uiService.processingFuneralService71865Flag && this.uiService.processingServiceFuneralSelectedFlag != '') {
      this.onPsFuneralServiceClick(this.dataMappingService.YNToBool(this.uiService.processingServiceFuneralSelectedFlag));
    }
  }

  onLiabilityChange(liabilityName: string) {
    // Retrieve the selected liability object
    const selectedLiability = this.dropDownService.liabilityLimitOptions.find(liability => liability.name === liabilityName);

    // Perform the calculation
    const calculatedLimit = selectedLiability.value * 2;
    this.setLabelValues(this.dataMappingService.convertToDollarFormat(Number(calculatedLimit)));

     //Asssign condo limit from Policy Level Liability Limit
     if(this.uiService.condo69145Flag || this.uiService.condoOffice60999Flag)
     {
      let condLiabEndtLmtValue = condLiabEndtLmt in this.dataService.BPClassifications ? this.dataService.BPClassifications.condLiabEndtLmt : '';
      if(condLiabEndtLmtValue == '' && this.condoDOSelectedVal == 'Y'){
       if(selectedLiability.value==2000000)
        {
         this.liabilityForm.get([contractorConstants.condLiabEndtLmt]).setValue(this.dataMappingService.convertToDollarFormat(Number(1000000)));
        }
        else
        {
      
            this.liabilityForm.get([contractorConstants.condLiabEndtLmt]).setValue(this.dataMappingService.convertToDollarFormat(Number(selectedLiability.value)));

          }
        }
        
     }

    //Assign Convenience Liquior Limit from Policy Level Liability Limit
    if (this.uiService.convenience09331Flag) {
      this.liquiorLiabilityLimitLabelValue = this.dataMappingService.convertToDollarFormat(Number(selectedLiability.value));
    }
    //Assign Restaurant Liquior Limit from Policy Level Liability Limit
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {
      this.liquiorLiabilityLimitLabelValue = this.dataMappingService.convertToDollarFormat(Number(selectedLiability.value));
      console.log("this.liquiorLiabilityLimitLabelValue onLiabilityChange", this.liquiorLiabilityLimitLabelValue)
    }

  }

  setLabelValues(calculatedLimit) {
    // Set the values of prodCompleteOperAggLmt and liabMedicalExpAggLmt
    this.prodCompleteOperAggLmt = calculatedLimit;
    this.liabMedicalExpAggLmt = calculatedLimit;
  }


  onSnowPlowingClick(flag) {
    if (flag) {
      this.snowPlowingSelectedVal = 'Y';
    } else {
      this.snowPlowingSelectedVal = 'N';
    }
    this.liabilityForm.get([snowPlowing]).setValue(this.snowPlowingSelectedVal);
    this.liabilityForm.get([snowIceRemoval]).setValue(this.snowPlowingSelectedVal);
    this.liabilityForm.get([privateDriveways]).setValue(this.snowPlowingSelectedVal);
    this.liabilityForm.get([parkingLots]).setValue(this.snowPlowingSelectedVal);
    this.liabilityForm.get([roadHighways]).setValue(this.snowPlowingSelectedVal);

    this.uiService.snowPlowingSelected = true;
    this.uiService.snowPlowingRequiredFlag = false;

    if (this.snowPlowingSelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Snow Plow Coverage (Mass only)').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Snow Plow Coverage (Mass only)', cNoOfPlows: this.liabilityForm.get(cNoOfPlows).value });
      }
    } else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Snow Plow Coverage (Mass only)');
    }
    this.updateNumberofPlowsRequired(this.snowPlowingSelectedVal == 'Y');
  }

  updateNumberofPlowsRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(cNoOfPlows), condition);
  }
  onWaiverOfSubClick(flag) {
    if (flag) {
      this.waiverOfSubSelectedVal = 'Y';
    } else {
      this.waiverOfSubSelectedVal = 'N';
    }
    this.uiService.waiverOfSubFlag = this.waiverOfSubSelectedVal;
    // this.liabilityForm.get([Endorsement]).setValue(this.waiverOfSubSelectedVal);
    if (this.waiverOfSubSelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Transfer of Rights of Recovery (Waiver of Subrogation)').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Transfer of Rights of Recovery (Waiver of Subrogation)' });
      }
    } else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Transfer of Rights of Recovery (Waiver of Subrogation)');
    }
  }

  onPrimNonContributoryClick(flag) {
    if (flag) {
      this.primNonContributorySelectedVal = 'Y';
    } else {
      this.primNonContributorySelectedVal = 'N';
    }
    this.uiService.onPrimNonContributoryFlag = this.primNonContributorySelectedVal;
    // this.liabilityForm.get([Endorsement]).setValue(this.waiverOfSubSelectedVal);
    if (this.primNonContributorySelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Primary and Noncontributory').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Primary and Noncontributory' });
      }
    } else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Primary and Noncontributory');
    }
  }

  onMedicalExpenseClick(flag) {
    if (flag) {
      this.medicalExpenseSelectedVal = 'Y';
    } else {
      this.medicalExpenseSelectedVal = 'N';
    }
    this.liabilityForm.get([medicalExpensPerPerson]).setValue(this.medicalExpenseSelectedVal == 'Y' ? '5000' : '10000');
  }

  onCondoDOClick(flag) {
    if (flag) {
      this.condoDOSelectedVal = 'Y';
    } else {
      this.condoDOSelectedVal = 'N';
    }

    this.initialValueForCondoFields(); // Need intialize value for office - gokul
    this.uiService.condoDOSelectedFlag = this.condoDOSelectedVal;
    this.liabilityForm.get([condDirctLiabEndt]).setValue(this.condoDOSelectedVal);
    this.updateCondoDOClickRequired(this.condoDOSelectedVal == 'Y')
  }
  updateCondoDOClickRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(condLiabEndtLmt), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(condOffLiabEndtDeduc), condition);
  }

  onveterinarianEndorsementClick(flag) {
    if (flag) {
      this.veterinarianEndorsementSelectedVal = 'Y';
    } else {
      this.veterinarianEndorsementSelectedVal = 'N';
    }
    this.initialValueForVeterinarianEndorsementFields();
    this.uiService.veterinarianEndorsementSelectedFlag = this.veterinarianEndorsementSelectedVal;
    this.liabilityForm.get([veterinarProfLiab]).setValue(this.veterinarianEndorsementSelectedVal);
  }

  initialValueForVeterinarianEndorsementFields() {
    let veterinarprofLiab = veterinarProfLiab in this.dataService.BPClassifications ? this.dataService.BPClassifications.veterinarProfLiab : '';
    this.liabilityForm.get(veterinarProfLiab).setValue(veterinarprofLiab);

    let numberofVeterinarian = numberOfVeterinarian in this.dataService.BPClassifications ? this.dataService.BPClassifications.numberOfVeterinarian : '';
    this.liabilityForm.get(numberOfVeterinarian).setValue(numberofVeterinarian);
  }

  initialValueForProcessServiceBarberBeauticianPhoto() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && this.uiService.processingProcessService7195271332Flag) {
      let brbbeutPrsEnhPrfLb = brbBeutPrsEnhPrfLb in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.brbBeutPrsEnhPrfLb : '';
      this.liabilityForm.get(brbBeutPrsEnhPrfLb).setValue(brbbeutPrsEnhPrfLb);

      let professionalclass = professionalClass in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.professionalClass : '';
      this.liabilityForm.get(professionalClass).setValue(professionalclass);

      let noOfFullTimeBarber = noOfFullTimeBarbers in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.noOfFullTimeBarbers : '';
      this.liabilityForm.get(noOfFullTimeBarbers).setValue(noOfFullTimeBarber);

      let noOfPartTimeBarber = noOfPartTimeBarbers in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.noOfPartTimeBarbers : '';
      this.liabilityForm.get(noOfPartTimeBarbers).setValue(noOfPartTimeBarber);

      let noOfflTmBuPrEnPf = noOfFlTmBuPrEnPf in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.noOfFlTmBuPrEnPf : '';
      this.liabilityForm.get(noOfFlTmBuPrEnPf).setValue(noOfflTmBuPrEnPf);

      let noOfptTmBuPrEnPf = noOfPtTmBuPrEnPf in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.noOfPtTmBuPrEnPf : '';
      this.liabilityForm.get(noOfPtTmBuPrEnPf).setValue(noOfptTmBuPrEnPf);
    }

    if (this.dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] && this.uiService.processingPhotoService71899Flag) {
      let photoGraphy = photography in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.photography : '';
      this.liabilityForm.get(photography).setValue(photoGraphy);
    }
  }

  onPsBarberBeauticianClick(flag) {
    if (flag) {
      this.psBarberBeauticianSelectedVal = 'Y';
    } else {
      this.psBarberBeauticianSelectedVal = 'N';
    }
    this.uiService.processingServiceBarBeautSelectedFlag = this.psBarberBeauticianSelectedVal;
    this.liabilityForm.get([brbBeutPrsEnhPrfLb]).setValue(this.psBarberBeauticianSelectedVal);
    let professionalClassCategory = this.uiService.processingBarberService71332Flag ? "Barbers" : "Beauticians";
    this.liabilityForm.get([professionalClass]).setValue(professionalClassCategory);

    this.updatePsBarberBeauticianRequired(professionalClassCategory, this.psBarberBeauticianSelectedVal == 'Y')
  }

  updatePsBarberBeauticianRequired(professionalClass: string, condition: boolean) {
    if (professionalClass == "Barbers") {
      this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(noOfFullTimeBarbers), condition);
      this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(noOfPartTimeBarbers), condition);
    }
    if (professionalClass == "Beauticians") {
      this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(noOfFlTmBuPrEnPf), condition);
      this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(noOfPtTmBuPrEnPf), condition);
    }
  }

  onPsFuneralServiceClick(flag) {
    if (flag) {
      this.psFuneralServiceSelectedVal = 'Y';
    } else {
      this.psFuneralServiceSelectedVal = 'N';
    }
    this.uiService.processingServiceFuneralSelectedFlag = this.psFuneralServiceSelectedVal;
    //population of FuneralService in BPOptionalRTCCoverages
    if (this.psFuneralServiceSelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Funeral Directors Professional Liability').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Funeral Directors Professional Liability' });
      }
    }
    else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Funeral Directors Professional Liability');
    }
  }

  onPsPhotoServiceClick(flag) {
    if (flag) {
      this.psPhotoServiceSelectedVal = 'Y';
    } else {
      this.psPhotoServiceSelectedVal = 'N';
    }
    this.uiService.processingServicePhotoSelectedFlag = this.psPhotoServiceSelectedVal;
    this.liabilityForm.get([photography]).setValue(this.psPhotoServiceSelectedVal);
    //population of PhotoService in BPOptionalRTCCoverages
    if (this.psPhotoServiceSelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Abuse Or Molestation Exclusion').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Abuse Or Molestation Exclusion' });
      }
    }
    else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Abuse Or Molestation Exclusion');
    }
  }

  onliquorLiabilityCoverageClick(flag) {
    if (flag) {
      this.liquorLiabilityCoverageSelectedVal = 'Y';
    } else {
      this.liquorLiabilityCoverageSelectedVal = 'N';
      this.liabilityForm.get(LiquorSales).setValue("0");
    }

    this.uiService.liquorLiabilityCoverageSelectedFlag = this.liquorLiabilityCoverageSelectedVal;
    this.liquiorLiabilityLimitLabelValue = this.liabilityForm.get(liabMedicalExpensLmt).value;
    this.updateliquorLiabilityCoverageClickRequired(this.liquorLiabilityCoverageSelectedVal == 'Y');
    if (this.liquorLiabilityCoverageSelectedVal == 'Y') {
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Liquor Liability Coverage (for restaurants and liquor stores)').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Liquor Liability Coverage (for restaurants and liquor stores)' });
      }
    }
    else {
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Liquor Liability Coverage (for restaurants and liquor stores)');
    }
  }
  updateliquorLiabilityCoverageClickRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.liabilityForm.get(LiquorSales), condition);
  }

  initialValueForliquorLiabilityCoverage() {

    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && this.uiService.convenience09331Flag)) {
      let anualGrossSalesValue = AnualGrossSales in this.dataService.BPBuilding.BPClassifications ? this.dataService.BPBuilding.BPClassifications.AnualGrossSales : '';
      this.liabilityForm.get(AnualGrossSales).setValue(anualGrossSalesValue);
    }
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && this.dataService.BPClassifications.ClassCode == '09331') || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {
      let liquorSales = LiquorSales in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.LiquorSales : '0';
      this.liabilityForm.get(LiquorSales).setValue(liquorSales);
    }
  }

  onApplicantSubContClick(flag) {
    if (flag) {
      this.applicantSubContSelectedVal = 'Y';
    } else {
      this.applicantSubContSelectedVal = 'N';
    }
    this.uiService.applicantSubcontractRequiredFlag = false;
    this.liabilityForm.get([applicantSubcontract]).setValue(this.applicantSubContSelectedVal);
  }

  onBlanketOngoingClick(flag) {
    if (flag) {
      // Check if the other value is already 'Y', then set it to 'N'
      if (this.blanketCompletedSelectedVal === 'Y') {
        this.onBlanketCompletedClick(false);
      }
      this.blanketOngoingSelectedVal = 'Y';
    } else {
      this.blanketOngoingSelectedVal = 'N';
    }
    this.uiService.onBlanketOngoingFlag = this.blanketOngoingSelectedVal;

    // Additional logic for Y to N and N to Y changes
    if (this.blanketOngoingSelectedVal === 'Y') {
      if (this.dataService.BPAdditionalInsured.filter(option => option.interestName === 'Owners, Lessees Or Contractors - With AI Requirement in Const Contract (Blanket)').length === 0) {
        this.dataService.BPAdditionalInsured.push({ interest: 'OLCAIR', interestName: 'Owners, Lessees Or Contractors - With AI Requirement in Const Contract (Blanket)' });
      }
    } else {
      this.dataService.BPAdditionalInsured = this.dataService.BPAdditionalInsured.filter(option => option.interestName !== 'Owners, Lessees Or Contractors - With AI Requirement in Const Contract (Blanket)');
    }
  }

  onBlanketCompletedClick(flag) {
    if (flag) {
      // Check if the other value is already 'Y', then set it to 'N'
      if (this.blanketOngoingSelectedVal === 'Y') {
        this.onBlanketOngoingClick(false);
      }
      this.blanketCompletedSelectedVal = 'Y';
    } else {
      this.blanketCompletedSelectedVal = 'N';
    }
    this.uiService.onBlanketCompletedFlag = this.blanketCompletedSelectedVal;

    // Additional logic for Y to N and N to Y changes
    if (this.blanketCompletedSelectedVal === 'Y') {
      if (this.dataService.BPAdditionalInsured.filter(option => option.interestName === 'Owners, Lessees Or Contractors - With AI Requirement In Const Contract - Prod \u0026 Compl Ops (Blanket) 166').length === 0) {
        this.dataService.BPAdditionalInsured.push({ interest: 'OLC', interestName: 'Owners, Lessees Or Contractors - With AI Requirement In Const Contract - Prod \u0026 Compl Ops (Blanket) 166' });
      }
    } else {
      this.dataService.BPAdditionalInsured = this.dataService.BPAdditionalInsured.filter(option => option.interestName !== 'Owners, Lessees Or Contractors - With AI Requirement In Const Contract - Prod \u0026 Compl Ops (Blanket) 166');
    }
  }

  onVeterinarianChange() {
    this.uiService.numberOfVeterinarianFailFlag = false;
    let total: number = parseInt(this.liabilityForm.get([numberOfVeterinarian]).value);
    if (total <= 0) {
      //alert("Total No of employee should not be great than 100");
      this.liabilityForm.get([numberOfVeterinarian]).setValue("");
      this.uiService.numberOfVeterinarianFailFlag = true;
    }
  }

  toggleBlanketType(){
    this.claculateBlanketLimit();
  }
  setBlanketRatingFields(){
    if(this.dataService.current_business_request.quotePolicy.BusinessOwners.blanketRating=='Y'){
      this.liabilityForm.get('blanketType').setValue(this.dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"].BlanketType);
      this.liabilityForm.get('blanketLimit').setValue(this.dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"].BuildingBppLimit)
    }
    console.log(this.liabilityForm,"liability form");
  }
  claculateBlanketLimit(){
    console.log(this.dataService.current_business_request,"liability current businees request")
    this.liabilityForm.get('blanketLimit').disable()
    const bOwners=this.dataService.businessOwners;
    let x=this.liabilityForm.get('blanketType').value;
    if(x=='Building'){
      let sum=0;
      bOwners.BPBuilding.forEach((d)=>{
        if(d.BPBuildingCoverage){
          sum=sum+Number(d.BPBuildingCoverage.BuildingLimitInsurance);
        }
        
      })
      this.liabilityForm.get('blanketLimit').setValue(sum);
    }else if(x=='Business Personal Property'){
      let sum=0;
      bOwners.BPBuilding.forEach((d)=>{
        sum=sum+Number(d.BPClassifications.BusinssPersonalPropLmt);
      })
      this.liabilityForm.get('blanketLimit').setValue(sum);
    }else if(x=='Building and Business Personal Property Combined'){
      let sum=0;
      bOwners.BPBuilding.forEach((d)=>{
        if(d.BPBuildingCoverage){
          sum=sum+Number(d.BPBuildingCoverage.BuildingLimitInsurance);
        }
        sum=sum+Number(d.BPClassifications.BusinssPersonalPropLmt);
      })
      this.liabilityForm.get('blanketLimit').setValue(sum);
    }
  }
  condoCheck(value){
    let x=false;
    this.dataService.businessOwners.BPBuilding.forEach((d)=>{
      if(d.BPClassifications.ClassCode == value){
        x=true;
      }
    })
    return x;
  }
  mapPhotographyEndorsement(){
    if(this.dataService.BPOptionalRTCCoverages){
      this.dataService.BPOptionalRTCCoverages = this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement !== 'Abuse Or Molestation Exclusion');
    }
    let p=false;
    this.dataService.businessOwners.BPBuilding.forEach((d)=>{
      if(d.BPClassifications.photography=='Y'){
        p=true;
      }
    })
    if(p){
      if (this.dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement == 'Abuse Or Molestation Exclusion').length == 0) {
        this.dataService.BPOptionalRTCCoverages.push({ Endorsement: 'Abuse Or Molestation Exclusion' });
      }
    }
  }
}
