import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { UiService } from "./ui.service";
import {BPBuildingConst,
  apartmentBldEnd,
  restEndorsement
} from "../../contractor.constant";
import { DropdownService } from "./dropdown.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { DataMappingService } from "./data-mapping.service";

@Injectable({
  providedIn: "root",
})
export class PropertyDataMappingService {
  constructor(
    public dataService: DataService,
    public dropDownService: DropdownService,
    public dataMappingService: DataMappingService,
    public uiService: UiService
  ) { }

  functionsMap = {
    [apartmentBldEnd]: [
      (flag: boolean, dataMappingService: DataMappingService, dataService: DataService) => {
          if (flag && apartmentBldEnd in dataService.BPInlineOBJ) {
            const Locations: any = dataService.quote_policy.BusinessOwners.BPLocations;
            if (Locations.length === 1) {
              Locations[0].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
            }
            if (Locations.length === 2) {
              Locations[0].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationTwoEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationTwoEndorsementSelectedVal;
            }
            if (Locations.length === 3) {
              Locations[0].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationOneEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationTwoEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationTwoEndorsementSelectedVal;
              Locations[2].BPInlineOBJ.apartmentBldEnd = dataMappingService.apartmentLocationThreeEndorsementSelectedVal;
              Locations[2].BPInlineOBJ.apartBuildCov = dataMappingService.apartmentLocationThreeEndorsementSelectedVal;
            }
            dataService.quote_policy.BusinessOwners.BPLocations = Locations;
          }
      }
    ],
    [restEndorsement]: [
      (flag: boolean, dataMappingService: DataMappingService, dataService: DataService) => {
      
          if (flag && restEndorsement in dataService.BPInlineOBJ) {
            const Locations: any = dataService.quote_policy.BusinessOwners.BPLocations;
            if (Locations.length === 1) {
              Locations[0].BPInlineOBJ.restEndorsement = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
            }
            if (Locations.length === 2) {
              Locations[0].BPInlineOBJ.restEndorsement = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.restEndorsement = dataMappingService.restaurantLocationTwoEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationTwoEndorsementSelectedVal;
            }
            if (Locations.length === 3) {
              Locations[0].BPInlineOBJ.restEndorsement = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
              Locations[0].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationOneEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.restEndorsement = dataMappingService.restaurantLocationTwoEndorsementSelectedVal;
              Locations[1].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationTwoEndorsementSelectedVal;
              Locations[2].BPInlineOBJ.restEndorsement = dataMappingService.apartmentLocationThreeEndorsementSelectedVal;
              Locations[2].BPInlineOBJ.restaurantsCov = dataMappingService.restaurantLocationThreeEndorsementSelectedVal;
            }
            dataService.quote_policy.BusinessOwners.BPLocations = Locations;
          }
       
      }
    ],
    [BPBuildingConst]: [
      (flag: boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          let data: any = {
            locationId: "1",
            buildingId: 1,
            building: "Y", // default value may change ?
            businessPersonalProp: "Y", // default value may change ?
            cMortgageInfo: "N", // hardcoded based on gopal comments 
            BuildingDescription: "", // hardcoded based on gopal comments 
            PropertyType: "", // hardcoded based on gopal comments 
            BuildingSquareFootage: "", // hardcoded based on gopal comments 
            ConstructionType: this.dropDownService.constructionOptions[1], // field default - updated based on tester comments
            BldOwnrAndSoleOccupant: "N", // hardcoded based on gopal comments 
            NumberOfEmployees: "0",
            DistanceFromFireStation: "", // hardcoded based on gopal comments 
            DistToNearestHydrant: "",
            PublicProtFireClas: "",
            autoSprinkleredSystem: "",
            Interest: "Tenant",
            RentalProperty: '',
            OwnerOccupiedSqFtge: "1500", // hardcoded based on gopal comments 
            cRatingBasis: "Replacement Cost",
            YearBuilt: "",
            cEffectiveAgeOfBldg: "", // hard coded based on Gopal Comments
            cRoof: "",
            cElectrical: "",
            cWaterHeater: "",
            cHeating: "",
            cPlumbing: "",
            NumberOfUnits: "",
            NumberOccupiedByOwners: "",
            NumberLeasedToOthers: "",
            NumberVacant: "",
            IsThereABasement: "N",
            PercentFinished: "0", // hard coded based on Gopal Comments
            BldReplacementCostEst: "", // hard coded based on Gopal Comments
            cReconstrConstr: "",
            resAuRepMnufcProcOcup: "",
            fireAlarmALocalAlarm: "",
            fireAlarmACenStnAlarm: "",
            fireAlarmAHrdwrSmkDet: "",
            fireAlarmABatopSmkDet: "",
            CrmProtAlmALocalAlm: "",
            CrmProtAlmACenStAlm: "",
            BldDeadboltDoorLocks: "Y", // hard coded based on Gopal Comments
            bldAnyOtherProtection: "", // hard coded based on Gopal Comments
            leadPoisoningAppl: "N",
            NoOfUnitsLetterCntrl: "0", // new - HARCODED CHECK WITH GOPAL
            supplLeadPoisonAppl: "N",
            fuelTankApplies: "N",
            dmgPremisesRentYou: "Y",
            dmgPremRentYouSelCov: "Basic",
            dmgPremRentYouLimit: "50000", // field default
            swimmingPool: "N",
            cBusinessIncExcl: "N",
            offPrmBsnsInter: "N",
            windstormOrHailExcl: "N",
            optionalBldgDedu: "Y",
            OptionalPropertyDedu: "500",
            windstrmHailPrcntDed: "", // hard coded based on Gopal Comments
            autoIncInInsurance: "", // hard coded based on Gopal Comments
            spoilage: "N",
            comCondUntOwner: "N",
            condominComUntOwnr: "N",
            foodContamination: "N",
            timeElement: "N", // hard coded based on Gopal Comments
            directDamage: "N", // hard coded based on Gopal Comments
            vacancyPermitEndors: "N",
            lossPayableClauses: "N",
            earthquakeCoverage: "N",
            yearOfOldestUpdate: "",
            selectedInterestDropdownValue: "", // need to remove the property dynamically before calling to CoverAll
            BPBuildingCoverage: {
              BuildingLimitInsurance: "",
              includedBlanketRating: "N", // hard coded based on Gopal Comments
              incRebudExpFollDis: "N",
              greenUpgrades: "N",
              ordinanceLaw: "N",
              whRoofSurfaceLoss: "N", // hard coded based on Gopal Comments
              ltCovAcvBldRc: "N",
              debrisRemAddInsEndt: "N",
            },
            BPClassifications: {
              ClassCode: "",
              classificationDesc: "",
              BusinessDesc: "",
              BusinssPersonalPropLmt: "",
              liabilityExposure: "150000", // hard coded based on Gopal Comments
              AreaOccupied: "Percentage",
              ClassificationPercen: "100",
              cTenanatRelExp: "N",
              includedBlanketRating: "N",
              greenUpgrades: "N",
              utlSrvcDirectDmgApp: "N", // hard coded based on Gopal Comments
              electronicDataCovApp: "N", // hard coded based on Gopal Comments
              intOfCompOprCovApp: "N", // hard coded based on Gopal Comments
              incLmtAccountsRecv: "N",
              incLmtDpndntProp: "N", // hard coded based on Gopal Comments
              incLmtPaperRecord: "N", // hard coded based on Gopal Comments
              incLmtOutdoorProp: "N", // hard coded based on Gopal Comments
              specBppTmpAwayFrmPrm: "N", // hard coded based on Gopal Comments
              theftLimitations: "N", // hard coded based on Gopal Comments
              brandsAndLabels: "N",
              permYardsMaintainStr: "N", // hard coded based on Gopal Comments
              seasonalIncPercentage: "25", // hard coded based on Gopal Comments
              spoilageCovApp: "N",
              earthquakeCovApplies: "N",
              eqSprinklerLeakage: "N", // hard coded based on Gopal Comments
              medExpensExclusion: "N", // hard coded based on Gopal Comments
              amdAggLmtInsrnPrOb: "Y", // default value - may change ?
              isBuildingRoofValueSelected: false,// need to remove the property dynamically before calling to CoverAll,
              totalPayroll: "",
            },
          }
          if (dataMappingService.originalClassCodeObj.propertytype !== "Contractors") {
            data.bldHaveAFireAlarm = "Y";
            data.LeadPoisonYrConst="After 1978";
          }

          if (dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) {
            data.BPClassifications.photography = ""
          }

          if (dataMappingService.businessFlags[BusinessAttribute.OFFICE] && (dataMappingService.originalClassCodeObj.classcode == '64181' || dataMappingService.originalClassCodeObj.classcode2 == '64181' || dataMappingService.originalClassCodeObj.classcode3 == '64181')) {
            data.BPClassifications.veterinarProfLiab = "";
            data.BPClassifications.numberOfVeterinarian = "";
          }
          if (dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]) {
            data.BPClassifications.brbBeutPrsEnhPrfLb = "";
            data.BPClassifications.professionalClass = "";
            data.BPClassifications.noOfFullTimeBarbers = "";
            data.BPClassifications.noOfPartTimeBarbers = "";
            data.BPClassifications.noOfFlTmBuPrEnPf = "";
            data.BPClassifications.noOfPtTmBuPrEnPf = "";
          }

          if (dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] || dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && (dataMappingService.originalClassCodeObj.classcode == '09331' || dataMappingService.originalClassCodeObj.classcode2 == '09331' || dataMappingService.originalClassCodeObj.classcode3 == '09331'))) {
            data.BPClassifications.liabilityExposure = "";
          }

          if (dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && (dataMappingService.originalClassCodeObj.classcode == '09331' || dataMappingService.originalClassCodeObj.classcode2 == '09331' || dataMappingService.originalClassCodeObj.classcode3 == '09331'))) {
            data.BPClassifications.AnualGrossSales = "";
          }
          if(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS] || dataMappingService.businessFlags[BusinessAttribute.OFFICE]){
            data.BPClassifications.condDirctLiabEndt="";
            data.BPClassifications.condLiabEndtLmt="";
            data.BPClassifications.condOffLiabEndtDeduc="";
          }
          if(dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]){
            data.NumberOfSwimmingPools="";
          }

          let Building = [];
          if (dataMappingService.currentForm.get("LocationOne")) {
            console.log(dataMappingService.currentForm.value.LocationOne,"L1 form")
            Building = dataMappingService.currentForm.value.LocationOne;
            const interestOptions = dataMappingService.bppLimitChoosedFlag == true ? this.dropDownService.interestOptions : this.dropDownService.interestBPPOptions;
            dataMappingService.LocationOneBuildings = Building.map((element, index) => (
              dataMappingService.selectedInterestDropdownValue = element.Interest,
              {
             ...data,
             locationId:'1',
             buildingId:index+1,
             yearOfOldestUpdate:element.yearOfOldestUpdate,
             building : element.BuildingLimitInsurance ? 'Y' : 'N',
             selectedInterestDropdownValue:element.Interest,
             cReconstrConstr: element.cReconstrConstr,
             resAuRepMnufcProcOcup: element.resAuRepMnufcProcOcup,
             BldReplacementCostEst : element.BuildingLimitInsurance ? dataMappingService.removeCommasAndDollar(element.BuildingLimitInsurance) : "",
             BPClassifications :{
              ...data.BPClassifications,
              BusinssPersonalPropLmt : dataMappingService.removeCommasAndDollar(element.BusinssPersonalPropLmt),
              ClassCode : dataMappingService.originalClassCodeObj.classcode,
              classificationDesc : dataMappingService.originalClassCodeObj.description,
              BusinessDesc:dataMappingService.originalClassCodeObj.businessDesc,
              isBuildingRoofValueSelected:element.yearOfOldestUpdate ? true : false,
              photography:element.photography,
              veterinarProfLiab:element.veterinarProfLiab,
              numberOfVeterinarian:element.numberOfVeterinarian,
              brbBeutPrsEnhPrfLb:element.brbBeutPrsEnhPrfLb,
              professionalClass:element.professionalClass,
              noOfFullTimeBarbers:element.noOfFullTimeBarbers,
              noOfPartTimeBarbers:element.noOfPartTimeBarbers,
              noOfFlTmBuPrEnPf:element.noOfFlTmBuPrEnPf,
              noOfPtTmBuPrEnPf:element.noOfPtTmBuPrEnPf,
              AnualGrossSales:element.AnualGrossSales,
              totalPayroll:element.totalPayroll,
              liabilityExposure:dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] ? dataMappingService.removeCommasAndDollar(element.totalPayroll):dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && (dataMappingService.originalClassCodeObj.classcode=='09331' || dataMappingService.originalClassCodeObj.classcode2=='09331' || dataMappingService.originalClassCodeObj.classcode3=='09331')) ? element.AnualGrossSales: ""
             },
             BPBuildingCoverage:{
              ...data.BPBuildingCoverage,
              BuildingLimitInsurance:element.BuildingLimitInsurance
             },
             PropertyType:dataMappingService.originalClassCodeObj.propertytype,
             businessPersonalProp: element.BusinssPersonalPropLmt != '' ? 'Y' : 'N',
             OptionalPropertyDedu : dataMappingService.removeCommasAndDollar(element.OptionalPropertyDedu),
             dmgPremRentYouLimit:dataMappingService.removeCommasAndDollar(element.dmgPremRentYouLimit),
             YearBuilt : element.YearBuilt,
             ConstructionType : element.ConstructionType,
             DistanceFromFireStation : element.DistanceFromFireStation,
             DistToNearestHydrant : element.DistToNearestHydrant,
             cRoof:element.yearOfOldestUpdate !== "" &&  element.yearOfOldestUpdate !== undefined  ?element.yearOfOldestUpdate :element.YearBuilt,
             cElectrical:element.yearOfOldestUpdate !== "" && element.yearOfOldestUpdate !== undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cWaterHeater:element.yearOfOldestUpdate !== "" &&  element.yearOfOldestUpdate !== undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cHeating:element.yearOfOldestUpdate !== "" &&  element.yearOfOldestUpdate !== undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cPlumbing:element.yearOfOldestUpdate !== "" &&  element.yearOfOldestUpdate !== undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             fireAlarmALocalAlarm : dataMappingService.boolToYN(element.fireAlarmALocalAlarm),
             CrmProtAlmALocalAlm : dataMappingService.boolToYN(element.CrmProtAlmALocalAlm),
             fireAlarmACenStnAlarm : dataMappingService.boolToYN(element.fireAlarmACenStnAlarm),
             CrmProtAlmACenStAlm : dataMappingService.boolToYN(element.CrmProtAlmACenStAlm),
             fireAlarmABatopSmkDet : dataMappingService.boolToYN(element.fireAlarmABatopSmkDet),
             fireAlarmAHrdwrSmkDet : dataMappingService.boolToYN(element.fireAlarmAHrdwrSmkDet),
             autoSprinkleredSystem : element.autoSprinkleredSystem,
             BuildingSquareFootage : element.BuildingLimitInsurance ? element.BuildingSquareFootage : '',
             NumberOfUnits : element.BuildingLimitInsurance ? element.NumberOfUnits : "",
             NumberOccupiedByOwners : element.BuildingLimitInsurance ? element.NumberOccupiedByOwners : "",
             NumberVacant : element.BuildingLimitInsurance ? element.NumberVacant : "",
             NumberLeasedToOthers : element.BuildingLimitInsurance ? element.NumberLeasedToOthers : "",
             PublicProtFireClas : dataMappingService.ppc,
             Interest : element.Interest== interestOptions[3] || this.dropDownService.interestOptions[3] ==  element.Interest ||  element.Interest == this.dropDownService.interestBPPOptions[3] ? "Tenant" : "Owner",
             BldOwnrAndSoleOccupant:dataMappingService.selectedInterestDropdownValue == interestOptions[0]? "Y" : dataMappingService.selectedInterestDropdownValue == interestOptions[3] ? "" :  "N",
             OwnerOccupiedSqFtge :dataMappingService.selectedInterestDropdownValue == interestOptions[3] || dataMappingService.selectedInterestDropdownValue == interestOptions[1] || dataMappingService.selectedInterestDropdownValue == interestOptions[5] || dataMappingService.selectedInterestDropdownValue == interestOptions[6] || this.dropDownService.interestOptions[3] == dataMappingService.selectedInterestDropdownValue || dataMappingService.selectedInterestDropdownValue == this.dropDownService.interestBPPOptions[3] ? ((this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && element.BusinssPersonalPropLmt || (this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && !element.BusinssPersonalPropLmt)) ? "1" : "0") : (element.BuildingLimitInsurance ? element.BuildingSquareFootage : '1'),
             RentalProperty:dataMappingService.selectedInterestDropdownValue == interestOptions[0] || dataMappingService.selectedInterestDropdownValue == interestOptions[4] ? "N" : "Y",
             comCondUntOwner:element.Interest==interestOptions[4] || element.Interest==interestOptions[5] || element.Interest==interestOptions[6]?"Y":"N"
            }));
            dataMappingService.propertyL1FirstVisit=false;
            
          }
          if (dataMappingService.currentForm.get("LocationTwo")) {
            Building = dataMappingService.currentForm.value.LocationTwo;
            const interestOptions = dataMappingService.bppLimitChoosedFlag == true ? this.dropDownService.interestOptions : this.dropDownService.interestBPPOptions;
            dataMappingService.LocationTwoBuildings = [];
            dataMappingService.LocationTwoBuildings = Building.map((element, index) => (
              dataMappingService.selectedInterestDropdownValue = element.Interest,
              {
             ...data,
             locationId:'2',
             buildingId:index+1,
             yearOfOldestUpdate:element.yearOfOldestUpdate,
             building : element.BuildingLimitInsurance ? 'Y' : 'N',
             selectedInterestDropdownValue:element.Interest,
             cReconstrConstr: element.cReconstrConstr,
             resAuRepMnufcProcOcup: element.resAuRepMnufcProcOcup,
             BldReplacementCostEst : element.BuildingLimitInsurance ? dataMappingService.removeCommasAndDollar(element.BuildingLimitInsurance) : "",
             BPClassifications :{
              ...data.BPClassifications,
              BusinssPersonalPropLmt : dataMappingService.removeCommasAndDollar(element.BusinssPersonalPropLmt),
              ClassCode : dataMappingService.originalClassCodeObj.classcode2,
              classificationDesc : dataMappingService.originalClassCodeObj.description2,
              BusinessDesc:dataMappingService.originalClassCodeObj.businessDesc2,
              isBuildingRoofValueSelected:element.yearOfOldestUpdate ? true : false,
              photography:element.photography,
              veterinarProfLiab:element.veterinarProfLiab,
              numberOfVeterinarian:element.numberOfVeterinarian,
              brbBeutPrsEnhPrfLb:element.brbBeutPrsEnhPrfLb,
              professionalClass:element.professionalClass,
              noOfFullTimeBarbers:element.noOfFullTimeBarbers,
              noOfPartTimeBarbers:element.noOfPartTimeBarbers,
              noOfFlTmBuPrEnPf:element.noOfFlTmBuPrEnPf,
              noOfPtTmBuPrEnPf:element.noOfPtTmBuPrEnPf,
              AnualGrossSales:element.AnualGrossSales,
              totalPayroll:element.totalPayroll,
              liabilityExposure:dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] ? dataMappingService.removeCommasAndDollar(element.totalPayroll):dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && (dataMappingService.originalClassCodeObj.classcode=='09331' || dataMappingService.originalClassCodeObj.classcode2=='09331' || dataMappingService.originalClassCodeObj.classcode3=='09331')) ? element.AnualGrossSales: ""
             },
             BPBuildingCoverage:{
              ...data.BPBuildingCoverage,
              BuildingLimitInsurance:element.BuildingLimitInsurance
             },
             PropertyType:dataMappingService.originalClassCodeObj.propertytype2,
             businessPersonalProp: element.BusinssPersonalPropLmt != '' ? 'Y' : 'N',
             OptionalPropertyDedu : dataMappingService.removeCommasAndDollar(element.OptionalPropertyDedu),
             dmgPremRentYouLimit:dataMappingService.removeCommasAndDollar(element.dmgPremRentYouLimit),
             YearBuilt : element.YearBuilt,
             ConstructionType : element.ConstructionType,
             DistanceFromFireStation : element.DistanceFromFireStation,
             DistToNearestHydrant : element.DistToNearestHydrant,
             cRoof:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cElectrical:element.yearOfOldestUpdate  != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cWaterHeater:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cHeating:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cPlumbing:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             fireAlarmALocalAlarm : dataMappingService.boolToYN(element.fireAlarmALocalAlarm),
             CrmProtAlmALocalAlm : dataMappingService.boolToYN(element.CrmProtAlmALocalAlm),
             fireAlarmACenStnAlarm : dataMappingService.boolToYN(element.fireAlarmACenStnAlarm),
             CrmProtAlmACenStAlm : dataMappingService.boolToYN(element.CrmProtAlmACenStAlm),
             fireAlarmABatopSmkDet : dataMappingService.boolToYN(element.fireAlarmABatopSmkDet),
             fireAlarmAHrdwrSmkDet : dataMappingService.boolToYN(element.fireAlarmAHrdwrSmkDet),
             autoSprinkleredSystem : element.autoSprinkleredSystem,
             BuildingSquareFootage : element.BuildingLimitInsurance ? element.BuildingSquareFootage : '',
             NumberOfUnits : element.BuildingLimitInsurance ? element.NumberOfUnits : "",
             NumberOccupiedByOwners : element.BuildingLimitInsurance ? element.NumberOccupiedByOwners : "",
             NumberVacant : element.BuildingLimitInsurance ? element.NumberVacant : "",
             NumberLeasedToOthers : element.BuildingLimitInsurance ? element.NumberLeasedToOthers : "",
             PublicProtFireClas : dataMappingService.ppc1,
             Interest : element.Interest== interestOptions[3] || this.dropDownService.interestOptions[3] ==  element.Interest ||  element.Interest == this.dropDownService.interestBPPOptions[3] ? "Tenant" : "Owner",
             BldOwnrAndSoleOccupant:dataMappingService.selectedInterestDropdownValue == interestOptions[0]? "Y" : dataMappingService.selectedInterestDropdownValue == interestOptions[3] ? "" :  "N",
             OwnerOccupiedSqFtge :dataMappingService.selectedInterestDropdownValue == interestOptions[3] || dataMappingService.selectedInterestDropdownValue == interestOptions[1] || dataMappingService.selectedInterestDropdownValue == interestOptions[5] || dataMappingService.selectedInterestDropdownValue == interestOptions[6] || this.dropDownService.interestOptions[3] == dataMappingService.selectedInterestDropdownValue || dataMappingService.selectedInterestDropdownValue == this.dropDownService.interestBPPOptions[3] ? ((this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && element.BusinssPersonalPropLmt || (this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && !element.BusinssPersonalPropLmt)) ? "1" : "0") : (element.BuildingLimitInsurance ? element.BuildingSquareFootage : '1'),
             RentalProperty:dataMappingService.selectedInterestDropdownValue == interestOptions[0] || dataMappingService.selectedInterestDropdownValue == interestOptions[4] ? "N" : "Y",
             comCondUntOwner:element.Interest==interestOptions[4] || element.Interest==interestOptions[5] || element.Interest==interestOptions[6]?"Y":"N"
            }));
            dataMappingService.propertyL2FirstVisit=false;
          }
          if (dataMappingService.currentForm.get("LocationThree")) {
            Building = dataMappingService.currentForm.value.LocationThree;
            const interestOptions = dataMappingService.bppLimitChoosedFlag == true ? this.dropDownService.interestOptions : this.dropDownService.interestBPPOptions;
            dataMappingService.LocationThreeBuildings = [];
            dataMappingService.LocationThreeBuildings = Building.map((element, index) => (
              dataMappingService.selectedInterestDropdownValue = element.Interest,
              {
             ...data,
             locationId:'3',
             buildingId:index+1,
             yearOfOldestUpdate:element.yearOfOldestUpdate,
             building : element.BuildingLimitInsurance ? 'Y' : 'N',
             selectedInterestDropdownValue:element.Interest,
             cReconstrConstr: element.cReconstrConstr,
             resAuRepMnufcProcOcup: element.resAuRepMnufcProcOcup,
             BldReplacementCostEst : element.BuildingLimitInsurance ? dataMappingService.removeCommasAndDollar(element.BuildingLimitInsurance) : "",
             BPClassifications :{
              ...data.BPClassifications,
              BusinssPersonalPropLmt : dataMappingService.removeCommasAndDollar(element.BusinssPersonalPropLmt),
              ClassCode : dataMappingService.originalClassCodeObj.classcode3,
              classificationDesc : dataMappingService.originalClassCodeObj.description3,
              BusinessDesc:dataMappingService.originalClassCodeObj.businessDesc3,
              isBuildingRoofValueSelected:element.yearOfOldestUpdate ? true : false,
              photography:element.photography,
              veterinarProfLiab:element.veterinarProfLiab,
              numberOfVeterinarian:element.numberOfVeterinarian,
              brbBeutPrsEnhPrfLb:element.brbBeutPrsEnhPrfLb,
              professionalClass:element.professionalClass,
              noOfFullTimeBarbers:element.noOfFullTimeBarbers,
              noOfPartTimeBarbers:element.noOfPartTimeBarbers,
              noOfFlTmBuPrEnPf:element.noOfFlTmBuPrEnPf,
              noOfPtTmBuPrEnPf:element.noOfPtTmBuPrEnPf,
              AnualGrossSales:element.AnualGrossSales,
              totalPayroll:element.totalPayroll,
              liabilityExposure:dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] ? dataMappingService.removeCommasAndDollar(element.totalPayroll):dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && (dataMappingService.originalClassCodeObj.classcode=='09331' || dataMappingService.originalClassCodeObj.classcode2=='09331' || dataMappingService.originalClassCodeObj.classcode3=='09331')) ? element.AnualGrossSales: ""
             },
             BPBuildingCoverage:{
              ...data.BPBuildingCoverage,
              BuildingLimitInsurance:element.BuildingLimitInsurance
             },
             PropertyType:dataMappingService.originalClassCodeObj.propertytype3,
             businessPersonalProp: element.BusinssPersonalPropLmt != '' ? 'Y' : 'N',
             OptionalPropertyDedu : dataMappingService.removeCommasAndDollar(element.OptionalPropertyDedu),
             dmgPremRentYouLimit:dataMappingService.removeCommasAndDollar(element.dmgPremRentYouLimit),
             YearBuilt : element.YearBuilt,
             ConstructionType : element.ConstructionType,
             DistanceFromFireStation : element.DistanceFromFireStation,
             DistToNearestHydrant : element.DistToNearestHydrant,
             cRoof:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cElectrical:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cWaterHeater:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cHeating:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             cPlumbing:element.yearOfOldestUpdate != "" &&  element.yearOfOldestUpdate != undefined ?element.yearOfOldestUpdate :element.YearBuilt,
             fireAlarmALocalAlarm : dataMappingService.boolToYN(element.fireAlarmALocalAlarm),
             CrmProtAlmALocalAlm : dataMappingService.boolToYN(element.CrmProtAlmALocalAlm),
             fireAlarmACenStnAlarm : dataMappingService.boolToYN(element.fireAlarmACenStnAlarm),
             CrmProtAlmACenStAlm : dataMappingService.boolToYN(element.CrmProtAlmACenStAlm),
             fireAlarmABatopSmkDet : dataMappingService.boolToYN(element.fireAlarmABatopSmkDet),
             fireAlarmAHrdwrSmkDet : dataMappingService.boolToYN(element.fireAlarmAHrdwrSmkDet),
             autoSprinkleredSystem : element.autoSprinkleredSystem,
             BuildingSquareFootage : element.BuildingLimitInsurance ? element.BuildingSquareFootage : '',
             NumberOfUnits : element.BuildingLimitInsurance ? element.NumberOfUnits : "",
             NumberOccupiedByOwners : element.BuildingLimitInsurance ? element.NumberOccupiedByOwners : "",
             NumberVacant : element.BuildingLimitInsurance ? element.NumberVacant : "",
             NumberLeasedToOthers : element.BuildingLimitInsurance ? element.NumberLeasedToOthers : "",
             PublicProtFireClas : dataMappingService.ppc2,
             Interest : element.Interest== interestOptions[3] || this.dropDownService.interestOptions[3] ==  element.Interest ||  element.Interest == this.dropDownService.interestBPPOptions[3] ? "Tenant" : "Owner",
             BldOwnrAndSoleOccupant:dataMappingService.selectedInterestDropdownValue == interestOptions[0]? "Y" : dataMappingService.selectedInterestDropdownValue == interestOptions[3] ? "" :  "N",
             OwnerOccupiedSqFtge :dataMappingService.selectedInterestDropdownValue == interestOptions[3] || dataMappingService.selectedInterestDropdownValue == interestOptions[1] || dataMappingService.selectedInterestDropdownValue == interestOptions[5] || dataMappingService.selectedInterestDropdownValue == interestOptions[6] || this.dropDownService.interestOptions[3] == dataMappingService.selectedInterestDropdownValue || dataMappingService.selectedInterestDropdownValue == this.dropDownService.interestBPPOptions[3] ? ((this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && element.BusinssPersonalPropLmt || (this.dropDownService.interestOptions[3] != dataMappingService.selectedInterestDropdownValue && dataMappingService.selectedInterestDropdownValue != this.dropDownService.interestBPPOptions[3] && !element.BuildingLimitInsurance && !element.BusinssPersonalPropLmt)) ? "1" : "0") : (element.BuildingLimitInsurance ? element.BuildingSquareFootage : '1'),
             RentalProperty:dataMappingService.selectedInterestDropdownValue == interestOptions[0] || dataMappingService.selectedInterestDropdownValue == interestOptions[4] ? "N" : "Y",
             comCondUntOwner:element.Interest==interestOptions[4] || element.Interest==interestOptions[5] || element.Interest==interestOptions[6]?"Y":"N"
            }));
            dataMappingService.propertyL3FirstVisit=false;
          }
          let LocationOne = dataMappingService.LocationOneBuildings;
          let LocationTwo = dataMappingService.LocationTwoBuildings ? dataMappingService.LocationTwoBuildings : [];
          let LocationThree = dataMappingService.LocationThreeBuildings ? dataMappingService.LocationThreeBuildings : [];
          console.log(LocationOne,"one");
          console.log(LocationTwo,"two");
          console.log(LocationThree,"three")
          dataService.quote_policy.BusinessOwners.BPBuilding = LocationOne.concat(LocationTwo, LocationThree);
        }
      }
    ]
    // add more mappings as necessary

  };


  businessFlagFunctionsMap = {
    [BusinessAttribute.CONTRACTORS]: [
      this.functionsMap.BPBuilding

      // add more functions as necessary
    ],
    [BusinessAttribute.APARTMENTS]: [
      this.functionsMap.BPBuilding,
      this.functionsMap.apartmentBldEnd
      // add functions for this flag
    ],
    [BusinessAttribute.WHOLESALE_RISKS]: [
      // add functions for this flag
      this.functionsMap.BPBuilding
    ],
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: [
      this.functionsMap.BPBuilding
      // add functions for this flag
    ],
    [BusinessAttribute.OFFICE]: [
      this.functionsMap.BPBuilding
      // add functions for this flag
    ],
    [BusinessAttribute.MERCANTILE_RISKS]: [
      this.functionsMap.BPBuilding
      // add functions for this flag
    ],
    [BusinessAttribute.CONVENIENCE]: [
      this.functionsMap.BPBuilding
      // add functions for this flag
    ],
    [BusinessAttribute.RESTAURANTS]: [
      this.functionsMap.BPBuilding,
      this.functionsMap.restEndorsement
      // add functions for this flag
    ],
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: [
      this.functionsMap.BPBuilding
      // add functions for this flag
    ],
    // add more mappings as necessary
  };


  // call this function from data mapping service for mapping data
  mapDataBasedOnFlagName = (dataMappingService: DataMappingService, dataService: DataService) => {
    const functionsToExecute = this.businessFlagFunctionsMap[dataMappingService.getBusinessFlag()];
    if (functionsToExecute) {
      functionsToExecute.forEach((functions) => {
        functions.forEach((fn) => {
          fn(true, dataMappingService, dataService);
        });
      });
    }
  }
}
