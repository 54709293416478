import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { agencyCodesResp } from "../../interfaces/producer.agencycode.modal";
import { agencyDetailsResp } from "../../interfaces/producer.agencydetail.modal";
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class ProducerOneService {
    constructor(private httpClient: HttpClient) {}

    getAgencyCodes(username) {
        return this.httpClient.get<agencyCodesResp>(environment.nodeserver + 'producer/agencycodes/' + username);
    }

    getAgencyDetails(agencyCode) {
        return this.httpClient.get<agencyDetailsResp>(environment.nodeserver + 'producer/agencydetails/' + agencyCode);
    }
}