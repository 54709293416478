import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { DropdownService } from "./dropdown.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { dmgPremRentYouLimit, hireNonownAutoLiab, cEmpPracLiab, cEpliLimDed, CNoEmpMore, CNoEmpLess, cIsCyberCov, cCyberOption, cCyberCovLimit, CBlgExtRepCc, CExtRpCcOptions, cContraEnhaEndorsement, contrToolsEqpmntCov, blanketCov, sublimit, sublimitOption, contrInstalLimit, blanketLimit, cEnhancerPlusEndt, enhancerEndorsement, apartmentBldEnd, apartBuildCov, restEndorsement } from "@app/contractor.constant";
import { DataMappingService } from "./data-mapping.service";

@Injectable({
  providedIn: "root",
})
export class OptionalDataMappingService {
  constructor(
    public dataService: DataService,
    public dropDownService: DropdownService,
    public dataMappingService: DataMappingService
  ) {}

  functionsMap = {
    [dmgPremRentYouLimit]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.BPBuilding.dmgPremRentYouLimit  = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(dmgPremRentYouLimit).value);
        }
      }
    ],
    [hireNonownAutoLiab]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.hireNonownAutoLiab = dataMappingService.currentForm.get(hireNonownAutoLiab).value;
          if(dataService.businessOwners.hireNonownAutoLiab == 'Y'){
            dataService.businessOwners.hireNonownAutoLiabCov = 'Increased Limit';
          }else{
            dataService.businessOwners.hireNonownAutoLiabCov = '';
          }
        }
      }
    ],
    [cEmpPracLiab]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.cEmpPracLiab = dataMappingService.currentForm.get(cEmpPracLiab).value;
        }
      }
    ],
    [cEpliLimDed]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.cEpliLimDed = dataMappingService.currentForm.get(cEpliLimDed).value;
        }
      }
    ],
    [CNoEmpMore]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.CNoEmpMore = dataMappingService.currentForm.get(CNoEmpMore).value;
        }
      }
    ],
    [CNoEmpLess]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.CNoEmpLess = dataMappingService.currentForm.get(CNoEmpLess).value;
        }
      }
    ],
    [cIsCyberCov]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.cIsCyberCov = dataMappingService.currentForm.get(cIsCyberCov).value;
        }
      }
    ],
    [cCyberOption]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.cCyberOption = dataMappingService.currentForm.get(cCyberOption).value;
        }
      }
    ],
    [cCyberCovLimit]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.cCyberCovLimit = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(cCyberCovLimit).value);
        }
      }
    ],
    [CBlgExtRepCc]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(CBlgExtRepCc in dataService.businessOwners){
            dataService.businessOwners.CBlgExtRepCc = dataMappingService.currentForm.get(CBlgExtRepCc).value;
          }
        }
      }
    ],
    [CExtRpCcOptions]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(CExtRpCcOptions in dataService.businessOwners){
            dataService.businessOwners.CExtRpCcOptions = dataMappingService.currentForm.get(CExtRpCcOptions).value;
          }
        }
      }
    ],
    [cContraEnhaEndorsement]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(cContraEnhaEndorsement in dataService.businessOwners){
            dataService.businessOwners.cContraEnhaEndorsement = dataMappingService.currentForm.get([cContraEnhaEndorsement]).value;
          }
        }
      }
    ],
    [contrToolsEqpmntCov]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.contractorsEndorsement=dataMappingService.currentForm.get(contrToolsEqpmntCov).value;
          
          if(dataMappingService.currentForm.get(contrToolsEqpmntCov).value=='Y')
         {
          console.log("inside dataMappingService.currentForm.get(contrToolsEqpmntCov).value=='Y'" , dataMappingService.currentForm.get(contrToolsEqpmntCov).value)
          dataService.BPContractorsEndorsement.contrToolsEqpmntCov = dataMappingService.currentForm.get(contrToolsEqpmntCov).value;
          dataService.BPContractorsEndorsement.sublimit = dataMappingService.currentForm.get(contrToolsEqpmntCov).value=="Y"?dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(sublimit).value):"3000";
          dataService.BPContractorsEndorsement.sublimitOption = `$${dataService.BPContractorsEndorsement.sublimit} Sub-limit Option`;
          dataService.BPContractorsEndorsement.contrInstalLimit =dataMappingService.currentForm.get(contrToolsEqpmntCov).value=="Y"?dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(contrInstalLimit).value):"3000";
          dataService.BPContractorsEndorsement.contrInstalCoverage=dataMappingService.currentForm.get(contrToolsEqpmntCov).value=="Y"?"Y":"N";
          dataService.BPContractorsEndorsement.blanketLimit = dataMappingService.currentForm.get(contrToolsEqpmntCov).value=="Y"?dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(blanketLimit).value):"3000";
        }
      }
      }
    ],
    [enhancerEndorsement]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(enhancerEndorsement in dataService.businessOwners){
            dataService.businessOwners.enhancerEndorsement = dataMappingService.currentForm.get(enhancerEndorsement).value;
          }
        }
      }
    ],
    [cEnhancerPlusEndt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(cEnhancerPlusEndt in dataService.businessOwners){
            dataService.businessOwners.cEnhancerPlusEndt =dataMappingService.currentForm.get(cEnhancerPlusEndt).value;
          }
        }
      }
    ],
    [apartmentBldEnd]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(apartmentBldEnd in dataService.BPInlineOBJ){
            dataService.BPInlineOBJ.apartmentBldEnd = dataMappingService.currentForm.get(apartmentBldEnd).value;
          }
          if(apartBuildCov in dataService.BPInlineOBJ){
             dataService.BPInlineOBJ.apartBuildCov = dataMappingService.currentForm.get(apartmentBldEnd).value;
          }
        }
      }
    ],
    [restEndorsement]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(restEndorsement in dataService.BPInlineOBJ){
            dataService.BPInlineOBJ.restEndorsement = dataMappingService.currentForm.get(restEndorsement).value;
            dataService.BPInlineOBJ.restaurantsCov= (dataMappingService.currentForm.get(restEndorsement).value=='Y')?'Y':'N';
          }
         
        }
      }
    ],
    // add more mappings as necessary
  };

   businessFlagFunctionsMap = {
    [BusinessAttribute.CONTRACTORS]: [
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.cContraEnhaEndorsement,
      this.functionsMap.contrToolsEqpmntCov,
      // add more functions as necessary
    ],
    [BusinessAttribute.APARTMENTS]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.WHOLESALE_RISKS]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.OFFICE]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.CONVENIENCE]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.MERCANTILE_RISKS]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.RESTAURANTS]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: [
      // add functions for this flag
      this.functionsMap.hireNonownAutoLiab,
      this.functionsMap.cEmpPracLiab,
      this.functionsMap.cEpliLimDed,
      this.functionsMap.CNoEmpMore,
      this.functionsMap.CNoEmpLess,
      this.functionsMap.cIsCyberCov,
      this.functionsMap.cCyberOption,
      this.functionsMap.cCyberCovLimit,
      this.functionsMap.CBlgExtRepCc,
      this.functionsMap.CExtRpCcOptions,
      this.functionsMap.enhancerEndorsement,
      this.functionsMap.cEnhancerPlusEndt,
    ],
    // add more mappings as necessary
  };


  // call this function from data mapping service for mapping data
  mapDataBasedOnFlagName = (dataMappingService: DataMappingService, dataService: DataService) => {
    const functionsToExecute = this.businessFlagFunctionsMap[dataMappingService.getBusinessFlag()];
    if (functionsToExecute) {
      functionsToExecute.forEach((functions) => {
        functions.forEach((fn) => {
          fn(true, dataMappingService, dataService);
        });
      });
    }
  }
}
