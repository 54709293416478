<div class="main">
  <div class="top">
    <div class="left">

      <img src="./assets/img/mapfreHeader.png" alt="Mapfre Logo">

    </div>
    <div class="right">
      <div class="actionlink pointer" (click)="exitQuote();" *ngIf='authService.isLoggedIn$ | async'>
        <div class="exittext">Exit quote</div>
        <img src="./assets/img/icon-log-in.png" alt="LogOut">
      </div>
    </div>
  </div>
</div>