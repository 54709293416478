import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
    url:string = environment.nodeserver;

    constructor(private httpClient: HttpClient) { }

    prefill(payload: any, interaction: string): Observable<any> {
        return this.httpClient.post(`${this.url}marketplace/prefill/${interaction}`, payload);
    }
}