<form [formGroup]="OptionalCoveragesForm">
  <div class="main-property">

    <div class="container">
      <div class="sub-container">
        <app-developer-json-view [myFormGroup]="OptionalCoveragesForm"
          [mode]="'OptionalCoveragesForm'"></app-developer-json-view>
        <div class="disabled-fixed-height" [ngClass]="uiService.builidingCoverageFlag?'sub-container-sec1':'sub-container-sec1-no-buildingCoverage'">
          <div class="section-title">
            <p class="title-text">
              Other Optional Coverages
            </p>
          </div>
          <div class="row-frame-h62">
            <div class="field-box-w276">
              <label class="field-label-w276">Non-owned and Hired Auto Coverage</label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    noHaselectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="noHaValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    noHaselectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="noHaValChange(false)">
                  No
                </div>
              </div>
            </div>

            <div class="field-main-box" *ngIf="noHaselectedVal == 'Y'">
              <label class="field-label-readonly">Coverage</label>
              <label class="field-value">Without Delivery Service</label>
            </div>
            <div class="field-main-box" *ngIf="noHaselectedVal == 'Y'">
              <label class="field-label-readonly" style="width: 267px">Non-owned and Hired Auto Liability Limit</label>
              <label class="field-value">{{liabilityLimitValue}}</label>
            </div>
          </div>
          <div class="row-frame-h62">
            <div class="field-box-w276">
              <label class="field-label-w276">Employment Practices Liability</label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    employmentselectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="employmentValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    employmentselectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="employmentValChange(false)">
                  No
                </div>
              </div>
            </div>

            <div class="field-box-w276" *ngIf="employmentselectedVal == 'Y'">
              <label class="field-label-w276 required-field">Employment Practices Limit</label>
              <select class="field-input-w276" id="optional-coverages-emp-prac-limit" name="type"
                [formControlName]="contractorConstants.cEpliLimDed">
                <option class="dropdown-text" style="display:none"></option>
                <option class="dropdown-text" [value]="empLimit.value"
                  *ngFor="let empLimit of dropDownService.empPracticesLimitOptions">
                  {{ empLimit.name }}
                </option>
              </select>
            </div>

            <div class="field-box-w276" *ngIf="employmentselectedVal == 'Y'">
              <label class="field-label-w276 required-field"># of Full Time Employees</label>
              <input class="field-input-w276" [formControlName]="contractorConstants.CNoEmpMore" minlength="1"
                maxlength="20" onlyNumber id="optional-coverages-no-full-time-employees" required (input)="onFullTimeEmployeeChange($event.target.value)"/>
              <div *ngIf="
              OptionalCoveragesForm.get(contractorConstants.CNoEmpMore).invalid &&
                        (OptionalCoveragesForm.get(contractorConstants.CNoEmpMore).dirty ||
                        OptionalCoveragesForm.get(contractorConstants.CNoEmpMore).touched)
                      " class="alert">
                Required
              </div>
              <div *ngIf="uiService.totalNumberOfEmployeeFailFlag" class="alert">
                Employee Total Should Not Exceed 100
              </div>
            </div>

            <div class="field-box-w276" *ngIf="employmentselectedVal == 'Y'">
              <label class="field-label-w276 required-field"># of Part Time Employees</label>
              <input class="field-input-w276" [formControlName]="contractorConstants.CNoEmpLess" minlength="1"
                maxlength="20" onlyNumber id="optional-coverages-no-part-time" required (input)="onPartTimeEmployeeChange($event.target.value)"/>
              <div *ngIf="
              OptionalCoveragesForm.get(contractorConstants.CNoEmpLess).invalid &&
                        (OptionalCoveragesForm.get(contractorConstants.CNoEmpLess).dirty ||
                        OptionalCoveragesForm.get(contractorConstants.CNoEmpLess).touched)
                      " class="alert">
                Required
              </div>
            </div>
            
          </div>
          <div class="row-frame-h62">
            <div class="field-box-w276">
              <label class="field-label-w276">Cyber Suite Coverage
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="link-form-tooltiptext">
                    <a class="link-form-link" href='../../../assets/docs/HSB_CyberSuite_FAQ_R2.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Cyber Suite FAQs
                    </a>
                  </span>
                </div>
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    cyberSuiteselectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="cyberSuiteValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    cyberSuiteselectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="cyberSuiteValChange(false)">
                  No
                </div>
              </div>
            </div>
            <div class="field-box-w276" *ngIf="cyberSuiteselectedVal == 'Y'">
              <label class="field-label-w276">Cyber Suite Coverage Type</label>
              <select class="field-input-w276" id="optional-coverages-coverage-type" name="type"
                [formControlName]="contractorConstants.cCyberOption">
                <option class="dropdown-text" style="display:none"></option>
                <option class="dropdown-text" [ngValue]="coverageType"
                  *ngFor="let coverageType of dropDownService.cyberCoverageTypeOptions">
                  {{ coverageType }}
                </option>
              </select>
            </div>

            <div class="field-box-w276" *ngIf="cyberSuiteselectedVal == 'Y'">
              <label class="field-label-w276">Cyber Suite Coverage Limit</label>
              <select id="optional-coverages-coverage-limit" class="field-input-w276" name="type"
                [formControlName]="contractorConstants.cCyberCovLimit">
                <option class="dropdown-text" style="display:none"></option>
                <option class="dropdown-text" [ngValue]="coverageLimit"
                  *ngFor="let coverageLimit of dropDownService.cyberCoverageLimitOptions">
                  {{ coverageLimit }}
                </option>
              </select>
            </div>
          </div>
          <div class="row-frame-h62" *ngIf="uiService.builidingCoverageFlag">
            <div class="field-box-w276">
              <label class="field-label-w276">Building Extended Replacement Cost</label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    costCoverageselectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="costCoverageValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    costCoverageselectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="costCoverageValChange(false)">
                  No
                </div>
              </div>
            </div>

            <div class="field-box-w276" *ngIf="costCoverageselectedVal == 'Y'">
              <label class="field-label-w276 required-field" style="width: 300px;">Building Extended Replacement Cost
                Value</label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    costValueselectedVal == '10'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="costValueValChange(true)">
                  10%
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                    costValueselectedVal == '25'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="costValueValChange(false)">
                  25%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-container-sec3 disabled-fixed-height" *ngIf="dataMappingService.businessFlags[BusinessAttribute.APARTMENTS] || dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] || dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS] || dataMappingService.businessFlags[BusinessAttribute.SELF_STORAGE_FACILITIES] || dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] || dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] || dataMappingService.businessFlags[BusinessAttribute.OFFICE]">
          <div class="row-frame-h62">
            <div class="field-box-w276">
              <label class="field-label-w276">Enhancer Endorsement
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">

                  <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                  <span class="link-form-tooltiptext" *ngIf="!uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/BOP Enhancer End Summ 12-28-21.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>
                  <span class="link-form-tooltiptext" *ngIf="uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/Revised BOP Enhancer End Summ 12-28-23.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>

                </div>
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    enhancerEndorsementSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="enhancerEndorsementValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                enhancerEndorsementSelectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="enhancerEndorsementValChange(false)">
                  No
                </div>
              </div>
            </div>
          </div>
          <div class="row-frame-h62">
            <div class="field-box-w276">
              <label class="field-label-w276">Enhancer Plus Endorsement
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">

                  <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                  <span class="link-form-tooltiptext" *ngIf="!uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/BOP Enhancer End Summ 12-28-21.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>
                  <span class="link-form-tooltiptext" *ngIf="uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/Revised BOP Enhancer End Summ 12-28-23.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>
                  
                </div>
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                enhancerPlusEndorsementSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="enhancerPlusEndorsementValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                enhancerPlusEndorsementSelectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="enhancerPlusEndorsementValChange(false)">
                  No
                </div>
              </div>
            </div>
          </div>
         </div> 

        <div class="sub-container-sec2 disabled-fixed-height" *ngIf="dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]">
          <div class="row-frame-h62">
            <div class="field-box-w276" style="gap:0px;">
              <label class="field-label-w276">Contractor Enhancer Endorsement
                <div class="link-form-tooltip">
                  <img src="../../../assets/img/icon-info.png">

                  <!-- Show appropriate info based upon if selected date is before or after the margin date -->
                  <span class="link-form-tooltiptext" *ngIf="!uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/BOP Enhancer End Summ 12-28-21.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>
                  <span class="link-form-tooltiptext" *ngIf="uiService.isDateAfter">
                    <a class="link-form-link" href='../../../assets/docs/Revised BOP Enhancer End Summ 12-28-23.pdf' target="_blank"
                      rel="noopener noreferrer">
                      Enhancer Summary
                    </a>
                  </span>

                </div>
              </label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    ContractorEnhancerselectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="contractorEnhancerValChange(true)">
                  Yes
                </div>
                <!-- Leave the comments below line for defect 68907-->
                <!-- <div value="N" class="toggle-button-right-w138" [ngClass]="
                    ContractorEnhancerselectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="contractorEnhancerValChange(false)">
                  No
                </div> -->
                <div class="toggle-button-freeze-w138">
                  No
                </div>
              </div>
              <div class="message-contractenhancer top-space-10">
                If the Contractor Enhancer is not desired, please remove selection within MAPFREConnect
              </div>
            </div>
          </div>
          <div class="row-frame-h62 top-space-15" >
            <div class="field-box-w276">
              <label class="field-label-w276">Contractors Tools & Equipment</label>
              <div class="toggle-group-w276">
                <div value="Y" class="toggle-button-left-w138" [ngClass]="
                    contractorToolsSelectedVal == 'Y'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="contractorToolsValChange(true)">
                  Yes
                </div>
                <div value="N" class="toggle-button-right-w138" [ngClass]="
                   contractorToolsSelectedVal == 'N'
                      ? 'selected-button'
                      : 'unselected-button'
                  " (click)="contractorToolsValChange(false)">
                  No
                </div>
              </div>
              <div *ngIf="contractorToolsEquipementErrorFlag" class="alert-contractequip" style="padding: 10px;">
                Contractors Tools & Equipment is required when BPP is less than $5,000
              </div>
            </div>

            <div class="field-box-w276" *ngIf="contractorToolsSelectedVal == 'Y'">
              <label class="field-label-w276">Contractors Installation</label>
              <input class="field-input-w276" minlength="1" maxlength="20" currencyInput
                [formControlName]="contractorConstants.contrInstalLimit" (change)="validateContractorInstallationLimit($event.target.value)"/>
                    <div *ngIf="contractorInstallationLimitFailedFlag" class="alert"> Contractors Equipment cannot exceed $50,000</div>
            </div>

            <div class="field-box-w276" *ngIf="contractorToolsSelectedVal == 'Y'">
              <label class="field-label-w276">Sublimit</label>
              <select id="optional-coverages-sublimit" class="field-input-w276" name="type"
                [formControlName]="contractorConstants.sublimit">
                <option class="dropdown-text" style="display:none"></option>
                <option class="dropdown-text" [ngValue]="sublimit"
                  *ngFor="let sublimit of dropDownService.sublimitTypeOptions">
                  {{ sublimit }}
                </option>
              </select>
            </div>

            <div class="field-box-w276" *ngIf="contractorToolsSelectedVal == 'Y'">
              <label class="field-label-w276">Blanket Tools</label>
              <input class="field-input-w276" minlength="1" maxlength="20" currencyInput
                [formControlName]="contractorConstants.blanketLimit" (change)="validateBlanketTools($event.target.value)"/>
             </div>
           </div>
           <div *ngIf="contractorInstallationLimitBlanketToolsRequiredFailedFlag || contractorInstallationLimitRequiredBlanketToolsFailedFlag" class="alert-contractequip"> Contractors Tools and Equipment must include both Contractors Installation and Blanket Tools Coverage</div>
        </div>
      </div>
    </div>

    <div class="rectangle"></div>

  </div>
</form>