import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataMappingService } from "@app/core/services/data-mapping.service";
import { DataService } from "@app/core/services/data.service";
import { cReconstrConstr, resAuRepMnufcProcOcup, recentPolCancld, otherLocations, cCompPolicy, applicantLawsuit, cReasons, CCompPolicyType, CCompPolicyNumberRef, SwimmingPoolExists, PlayGroundExists, RecFacilities, RiseToClaim, foreignProducts, CRecQues, Nfpa96, CDescProducts } from "@app/contractor.constant";
import { ComponentService } from '@app/core/services/component.service';

import * as contractorConstants from '@app/contractor.constant';
import { ApiService } from "@app/core/services/api.service";
import { DropdownService } from "@app/core/services/dropdown.service";
import { UiService } from "@app/core/services/ui.service";
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';

@Component({
  selector: "app-uw-questions",
  templateUrl: "./uw-questions.component.html",
  styleUrls: ["./uw-questions.component.scss"]
})
export class UwQuestionsComponent implements OnInit {
  public check1selectedVal: string;
  public check2selectedVal: string;
  public check3selectedVal: string;
  public check4selectedVal: string;
  public check5selectedVal: string;
  public check6selectedVal: string;

  public check7selectedVal: string;
  public check8selectedVal: string;
  public check9selectedVal: string;
  public check10selectedVal: string;

  public checkForeignProductImportselectedVal: string;
  public checkInsuredComplyNFPA96selectedVal: string;

  public condo69145Flag: boolean = false;

  public reasonOption: string;
  public policyTypeOption: string;

  uwQuestionsform: FormGroup;
  contractorConstants = contractorConstants;
  BusinessAttribute = BusinessAttribute;
  constructor(private router: Router, public dataMappingService: DataMappingService, private componentService: ComponentService, public dataService: DataService, private apiService: ApiService, public dropDownService: DropdownService, public uiService: UiService) {
    this.componentService.currentComponent = 'uwQuestions';
    this.componentService.currentComponentChanged.emit('uwQuestions');
  }

  ngOnInit(): void {
    this.initializeUWQuestionsForm();
    this.initializeHabitationalUWQuestionsFormChanges();
    this.initializeForiegnUWQuestionsFormChanges();
    this.initializeOfficialUWQuestionsFormChanges();
    this.initializeNfpa96UWQuestionsFormChanges();
    this.dataMappingService.currentForm = this.uwQuestionsform;
    this.initializeRadioValues();
  }

  initializeRadioValues() {
    const cReconstrConstrSelected = this.dataMappingService.YNToBool(this.dataService.BPBuilding.cReconstrConstr);
    if (cReconstrConstrSelected || this.dataService.BPBuilding.cReconstrConstr != '') {
      this.check1ValChange(cReconstrConstrSelected);
    }

    if (resAuRepMnufcProcOcup in this.dataService.BPBuilding) {
      const resAuRepMnufcProcOcupSelected = this.dataMappingService.YNToBool(this.dataService.BPBuilding.resAuRepMnufcProcOcup);
      if (resAuRepMnufcProcOcupSelected || this.dataService.BPBuilding.resAuRepMnufcProcOcup != '') {
        this.check2ValChange(resAuRepMnufcProcOcupSelected);
      }
    }

    const recentPolCancldSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.recentPolCancld);
    if (recentPolCancldSelected || this.dataService.BPGeneralQuestionsMU.recentPolCancld != '') {
      this.check3ValChange(recentPolCancldSelected);
    }

    const otherLocationsSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.otherLocations);
    if (otherLocationsSelected || this.dataService.BPGeneralQuestionsMU.otherLocations != '') {
      this.check4ValChange(otherLocationsSelected);
    }

    const cCompPolicySelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.cCompPolicy);
    if (cCompPolicySelected || this.dataService.BPGeneralQuestionsMU.cCompPolicy != '') {
      this.check5ValChange(cCompPolicySelected);
    }

    if (applicantLawsuit in this.dataService.BPGeneralQuestionsMU) {
      const applicantLawsuitSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.applicantLawsuit);
      if (applicantLawsuitSelected || this.dataService.BPGeneralQuestionsMU.applicantLawsuit != '') {
        this.check6ValChange(applicantLawsuitSelected);
      }
    }

    // Habitational
    if (PlayGroundExists in this.dataService.BPGeneralQuestionsMU) {
      const PlayGroundExistsSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.PlayGroundExists);
      if (PlayGroundExistsSelected || this.dataService.BPGeneralQuestionsMU.PlayGroundExists != '') {
        this.check10ValChange(PlayGroundExistsSelected);
      }
    }
    if (CRecQues in this.dataService.BPGeneralQuestionsMU) {
      const CRecQuesSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.CRecQues);
      if (CRecQuesSelected || this.dataService.BPGeneralQuestionsMU.CRecQues != '') {
        this.check9ValChange(CRecQuesSelected);
      }
    }
    if (RiseToClaim in this.dataService.BPGeneralQuestionsMU) {
      const RiseToClaimSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.RiseToClaim);
      if (RiseToClaimSelected || this.dataService.BPGeneralQuestionsMU.RiseToClaim != '') {
        this.check7ValChange(RiseToClaimSelected);
      }
    }
    if (SwimmingPoolExists in this.dataService.BPGeneralQuestionsMU) {
      const SwimmingPoolExistsSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.SwimmingPoolExists);
      if (SwimmingPoolExistsSelected || this.dataService.BPGeneralQuestionsMU.SwimmingPoolExists != '') {
        this.check8ValChange(SwimmingPoolExistsSelected);
      }
    }

    if (foreignProducts in this.dataService.BPGeneralQuestionsMU) {
      const ForeignProductsSelected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.foreignProducts);
      if (ForeignProductsSelected || this.dataService.BPGeneralQuestionsMU.foreignProducts != '') {
        this.checkForeignProductImportValChange(ForeignProductsSelected);
      }
    }

    if (Nfpa96 in this.dataService.BPGeneralQuestionsMU) {
      const Nfpa96Selected = this.dataMappingService.YNToBool(this.dataService.BPGeneralQuestionsMU.Nfpa96);
      if (Nfpa96Selected || this.dataService.BPGeneralQuestionsMU.Nfpa96 != '') {
        this.checkInsuredComplyNFPA96ValChange(Nfpa96Selected);
      }
    }

    if (this.uiService.natureOfBusines == this.BusinessAttribute.CONTRACTORS) {
      this.uiService.updateFormControlValidatorBasedOnCondition(this.dataMappingService.currentForm.get(applicantLawsuit), true);
    }
    if ((this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS] && this.uiService.condo69145Flag && this.uiService.condoDOSelectedFlag == 'Y') || (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE] && this.uiService.condoOffice60999Flag && this.uiService.condoDOSelectedFlag == 'Y')) {
      this.uiService.updateFormControlValidatorBasedOnCondition(this.dataMappingService.currentForm.get(RiseToClaim), true);
    }
  }

  initializeUWQuestionsForm() {
    if (!this.dataService.BPGeneralQuestionsMU[contractorConstants.BPCompanionPolicy]) {
      this.dataService.BPGeneralQuestionsMU[contractorConstants.BPCompanionPolicy] = {
        [CCompPolicyType]: "",
        [CCompPolicyNumberRef]: ""
      }
    }
    this.uwQuestionsform = new FormGroup({
      [cReconstrConstr]: new FormControl(this.dataService.BPBuilding.cReconstrConstr, Validators.required),
      [resAuRepMnufcProcOcup]: new FormControl(resAuRepMnufcProcOcup in this.dataService.BPBuilding ? this.dataService.BPBuilding.resAuRepMnufcProcOcup : ''),
      [recentPolCancld]: new FormControl(this.dataService.BPGeneralQuestionsMU.recentPolCancld, Validators.required),
      [otherLocations]: new FormControl(this.dataService.BPGeneralQuestionsMU.otherLocations, Validators.required),
      [cCompPolicy]: new FormControl(this.dataService.BPGeneralQuestionsMU.cCompPolicy, Validators.required),
      [applicantLawsuit]: new FormControl(applicantLawsuit in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.applicantLawsuit : ''),
      [cReasons]: new FormControl(cReasons in this.dataService.BPGeneralQuestionsMU ? this.dataService.BPGeneralQuestionsMU.cReasons : ''),
      [CCompPolicyType]: new FormControl(CCompPolicyType in this.dataService.BPGeneralQuestionsMU.BPCompanionPolicy ? this.dataService.BPGeneralQuestionsMU.BPCompanionPolicy.CCompPolicyType : ''),
      [CCompPolicyNumberRef]: new FormControl(CCompPolicyNumberRef in this.dataService.BPGeneralQuestionsMU.BPCompanionPolicy ? this.dataService.BPGeneralQuestionsMU.BPCompanionPolicy.CCompPolicyNumberRef : '')
    });
    this.updateValidationForResAuRepMnufcProcOcup();
  }

  updateValidationForResAuRepMnufcProcOcup() {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.uwQuestionsform.get(resAuRepMnufcProcOcup), resAuRepMnufcProcOcup in this.dataService.BPBuilding);
  }

  initializeHabitationalUWQuestionsFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]) {
      if (SwimmingPoolExists in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(SwimmingPoolExists, new FormControl(this.dataService.BPGeneralQuestionsMU.SwimmingPoolExists,Validators.required));
      }
      if (PlayGroundExists in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(PlayGroundExists, new FormControl(this.dataService.BPGeneralQuestionsMU.PlayGroundExists,Validators.required));
      }
      if (CRecQues in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(CRecQues, new FormControl(this.dataService.BPGeneralQuestionsMU.CRecQues,Validators.required));
      }
      if (RiseToClaim in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(RiseToClaim, new FormControl(this.dataService.BPGeneralQuestionsMU.RiseToClaim));
      }
    }
  }

  initializeOfficialUWQuestionsFormChanges() {
    console.log("initializeOfficialUWQuestionsFormChanges", this.dataMappingService.businessFlags[BusinessAttribute.OFFICE])
    if (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE]) {
      if (RiseToClaim in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(RiseToClaim, new FormControl(this.dataService.BPGeneralQuestionsMU.RiseToClaim));
      }
    }
  }

  initializeForiegnUWQuestionsFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS] || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {
      if (foreignProducts in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(foreignProducts, new FormControl(this.dataService.BPGeneralQuestionsMU.foreignProducts));
        this.uwQuestionsform.addControl(CDescProducts, new FormControl(this.dataService.BPGeneralQuestionsMU.CDescProducts));

      }
    }
  }

  initializeNfpa96UWQuestionsFormChanges() {
    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]) {
      if (Nfpa96 in this.dataService.BPGeneralQuestionsMU) {
        this.uwQuestionsform.addControl(Nfpa96, new FormControl(this.dataService.BPGeneralQuestionsMU.Nfpa96));
      }
    }
  }

  check1ValChange(check1) {
    if (check1 == true) {
      this.check1selectedVal = 'Y'
    } else {
      this.check1selectedVal = 'N'
    }
    this.uwQuestionsform.get([cReconstrConstr]).setValue(this.check1selectedVal);
    this.uiService.uwBuildUnderConstructionReConstructionRequiredFlag = false;
  }

  check2ValChange(check2) {
    if (check2 == true) {
      this.check2selectedVal = 'Y'
    } else {
      this.check2selectedVal = 'N'
    }
    this.uwQuestionsform.get([resAuRepMnufcProcOcup]).setValue(this.check2selectedVal);
    this.uiService.uwBusinessPremisesRequiredFlag = false;
  }

  check3ValChange(check3) {
    if (check3 == true) {
      this.check3selectedVal = 'Y'
    } else {
      this.check3selectedVal = 'N'
      this.uwQuestionsform.get(cReasons).setValue('');
    }
    this.uwQuestionsform.get([recentPolCancld]).setValue(this.check3selectedVal);
    this.uiService.uwRecentPolicyCancelledOrRenewedRequiredFlag = false;
    this.updateReasonsRequired(this.check3selectedVal == 'Y');
  }

  updateReasonsRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.uwQuestionsform.get(cReasons), condition)
  }

  check4ValChange(check4) {
    if (check4 == true) {
      this.check4selectedVal = 'Y'
    } else {
      this.check4selectedVal = 'N'
    }
    this.uwQuestionsform.get([otherLocations]).setValue(this.check4selectedVal);
    this.uiService.uwApplicantOthersCoverageNotRequestRequiredFlag = false;
  }

  check5ValChange(check5) {
    if (check5 == true) {
      this.check5selectedVal = 'Y'
    } else {
      this.check5selectedVal = 'N'
      this.uwQuestionsform.get(CCompPolicyType).setValue('');
      this.uwQuestionsform.get(CCompPolicyNumberRef).setValue('');
    }
    this.uwQuestionsform.get([cCompPolicy]).setValue(this.check5selectedVal);
    this.uiService.uwMapfreCompanionPolicyRequiredFlag = false;
    this.updatePolicyTypeNumberRequired(this.check5selectedVal == 'Y');
  }

  updatePolicyTypeNumberRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.uwQuestionsform.get(CCompPolicyType), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.uwQuestionsform.get(CCompPolicyNumberRef), condition)
  }

  check6ValChange(check6) {
    if (check6 == true) {
      this.check6selectedVal = 'Y'
    } else {
      this.check6selectedVal = 'N'
    }
    this.uwQuestionsform.get([applicantLawsuit]).setValue(this.check6selectedVal);
    this.uiService.uwApplicantLawSuitRequiredFlag = false;
  }

  check7ValChange(check7) {
    if (check7 == true) {
      this.check7selectedVal = 'Y'
    } else {
      this.check7selectedVal = 'N'
    }
    //need mapping
    // need required field validation
    this.uiService.uwBoardMemberKnowledgeAboutClaimRequiredFlag = false;
    this.uwQuestionsform.get([RiseToClaim]).setValue(this.check7selectedVal);
  }

  check8ValChange(check8) {
    if (check8 == true) {
      this.check8selectedVal = 'Y'
    } else {
      this.check8selectedVal = 'N'
    }
    //need mapping
    // need required field validation
    this.uiService.uwPremiseSwimmingPoolRequiredFlag = false;
    this.uwQuestionsform.get([SwimmingPoolExists]).setValue(this.check8selectedVal);
  }

  check9ValChange(check9) {
    if (check9 == true) {
      this.check9selectedVal = 'Y'
    } else {
      this.check9selectedVal = 'N'
    }
    //need mapping
    // need required field validation
    this.uiService.uwPremiseRecreationFacilityRequiredFlag = false;
    this.uwQuestionsform.get([CRecQues]).setValue(this.check9selectedVal);
  }

  check10ValChange(check10) {
    if (check10 == true) {
      this.check10selectedVal = 'Y'
    } else {
      this.check10selectedVal = 'N'
    }
    //need mapping
    // need required field validation
    this.uiService.uwPremisePlaygroundRequiredFlag = false;
    this.uwQuestionsform.get([PlayGroundExists]).setValue(this.check10selectedVal);
  }

  checkForeignProductImportValChange(check) {
    if (check == true) {
      this.checkForeignProductImportselectedVal = 'Y'
    } else {
      this.checkForeignProductImportselectedVal = 'N'
      this.uwQuestionsform.get(CDescProducts).setValue('');

    }
    this.uiService.uwForeignProductImportRequiredFlag = false;
    this.uwQuestionsform.get([foreignProducts]).setValue(this.checkForeignProductImportselectedVal);
    this.updateForeignProductRequired(this.checkForeignProductImportselectedVal == 'Y');
  }

  updateForeignProductRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.uwQuestionsform.get(CDescProducts), condition);
  }

  checkInsuredComplyNFPA96ValChange(check) {
    if (check == true) {
      this.checkInsuredComplyNFPA96selectedVal = 'Y'
    } else {
      this.checkInsuredComplyNFPA96selectedVal = 'N'
    }
    this.uiService.uwInsuredComplyNFPA96RequiredFlag = false;
    this.uwQuestionsform.get([Nfpa96]).setValue(this.checkInsuredComplyNFPA96selectedVal);
  }


}
