<span *ngIf="developerService.isDeveloperMode">
  <button (click)="showData = !showData">
    {{ showData ? "Hide Json" : "Show Json" }}
  </button>
  <div *ngIf="showData">
    <h1>Flags : </h1>
    IsLoading : {{ LoaderService.isLoadingFromStorage }}
    <h1>{{ mode }}</h1>
    {{ myFormGroup.value | json }}
    <h1>Final JSON</h1>
    {{ dataService.current_business_request | json }}
    <h1>### Form Errors ###</h1>
    {{ myFormGroup.invalid | json }} 
    <br/>
    Is Form Invalid : {{ invalidFormControls | json }}
    <h1>Empty Attributes</h1>
    {{ developerService.findEmptyAttributes(dataService.current_business_request) | json }} 
  </div>
</span>
