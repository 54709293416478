import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { DataService } from "@app/core/services/data.service";
import { DeveloperService } from "@app/core/services/developer.service";
import { UiService } from "@app/core/services/ui.service";
import { LoaderService } from "@app/loader.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-developer-json-view",
  templateUrl: "./developer-json-view.component.html",
  styleUrls: ["./developer-json-view.component.scss"],
})
export class DeveloperJsonViewComponent implements OnInit, OnChanges, OnDestroy{
  @Input() myFormGroup: FormGroup;
  @Input() mode: string;

  public validFormControls: any[] = [];
  public invalidFormControls: any[] = [];
  public isFormValid: Boolean;
  public formValueChangesSubscription: Subscription;

  constructor(
    public developerService: DeveloperService,
    public dataService: DataService,
    public uiService: UiService,
    public LoaderService:LoaderService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
  }
  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.myFormGroup) {
  //     if (this.formValueChangesSubscription) {
  //       this.formValueChangesSubscription.unsubscribe();
  //     }
  //     this.formValueChangesSubscription = this.myFormGroup.valueChanges.subscribe(() => {
  //       this.generateFormsValidation(this.myFormGroup);
  //     });
  //     this.generateFormsValidation(this.myFormGroup);
  //   }
  // }

  ngOnInit(): void {
    //this.generateFormsValidation(this.myFormGroup);
  }

  ngOnDestroy() {
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
  }

  generateFormsValidation(myForm: FormGroup) {
    myForm.markAllAsTouched();
  
    this.validFormControls = [];
    this.invalidFormControls = [];
  
    let isFormValid = true;
  
    const processControl = (key: string, control: AbstractControl) => {
      if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach((nestedKey) => {
          const nestedControl = control.get(nestedKey);
          processControl(`${key}.${nestedKey}`, nestedControl);
        });
      } else {
        if (control.invalid) {
          this.invalidFormControls.push({ formControlName: key, valid: false });
          isFormValid = false;
        } else {
          this.validFormControls.push({ formControlName: key, valid: true });
        }
      }
    };
  
    Object.keys(myForm.controls).forEach((key) => {
      const control = myForm.get(key);
      processControl(key, control);
    });
  
    this.isFormValid = isFormValid;
  }  
  
}
