import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor() { }

  // agent screen

  entityTypes: string[] = [
    "Association",
    "Common Ownership",
    "Corporation",
    "Executor or Trustee",
    "Governmental Entity",
    "Individual",
    "Joint Employers",
    "Joint Venture",
    "Labor Union",
    "Limited Liability Company (LLC)",
    "Limited Liability Partnership",
    "Limited Partnership",
    "Multiple Status",
    "Other",
    "Partnership",
    "Religious Organization",
    "Trust",
    "Trust or Estate"
  ];

  mailingState: any[] = [
    {
    "stateDesc": "Alabama",
    "stateCode": "AL"
    },
    {
    "stateDesc": "Alaska",
    "stateCode": "AK"
    },
    {
    "stateDesc": "Arizona",
    "stateCode": "AZ"
    },
    {
    "stateDesc": "Arkansas",
    "stateCode": "AR"
    },
    {
    "stateDesc": "California",
    "stateCode": "CA"
    },
    {
    "stateDesc": "Colorado",
    "stateCode": "CO"
    },
    {
    "stateDesc": "Connecticut",
    "stateCode": "CT"
    },
    {
    "stateDesc": "Delaware",
    "stateCode": "DE"
    },
    {
    "stateDesc": "District of Columbia",
    "stateCode": "DC"
    },
    {
    "stateDesc": "Florida",
    "stateCode": "FL"
    },
    {
    "stateDesc": "Georgia",
    "stateCode": "GA"
    },
    {
    "stateDesc": "Hawaii",
    "stateCode": "HA"
    },
    {
    "stateDesc": "Idaho",
    "stateCode": "ID"
    },
    {
    "stateDesc": "Illinois",
    "stateCode": "IL"
    },
    {
    "stateDesc": "Indiana",
    "stateCode": "IN"
    },
    {
    "stateDesc": "Iowa",
    "stateCode": "IA"
    },
    {
    "stateDesc": "Kansas",
    "stateCode": "KS"
    },
    {
    "stateDesc": "Kentucky",
    "stateCode": "KY"
    },
    {
    "stateDesc": "Louisiana",
    "stateCode": "LA"
    },
    {
    "stateDesc": "Maine",
    "stateCode": "ME"
    },
    {
    "stateDesc": "Maryland",
    "stateCode": "MD"
    },
    {
    "stateDesc": "Massachusetts",
    "stateCode": "MA"
    },
    {
    "stateDesc": "Michigan",
    "stateCode": "MI"
    },
    {
    "stateDesc": "Minnesota",
    "stateCode": "MN"
    },
    {
    "stateDesc": "Mississippi",
    "stateCode": "MS"
    },
    {
    "stateDesc": "Missouri",
    "stateCode": "MO"
    },
    {
    "stateDesc": "Montana",
    "stateCode": "MT"
    },
    {
    "stateDesc": "Nebraska",
    "stateCode": "NE"
    },
    {
    "stateDesc": "Nevada",
    "stateCode": "NV"
    },
    {
    "stateDesc": "New Hampshire",
    "stateCode": "NH"
    },
    {
    "stateDesc": "New Jersey",
    "stateCode": "NJ"
    },
    {
    "stateDesc": "New Mexico",
    "stateCode": "NM"
    },
    {
    "stateDesc": "New York",
    "stateCode": "NY"
    },
    {
    "stateDesc": "North Carolina",
    "stateCode": "NC"
    },
    {
    "stateDesc": "North Dakota",
    "stateCode": "ND"
    },
    {
    "stateDesc": "Ohio",
    "stateCode": "OH"
    },
    {
      "stateDesc": "Oklahoma",
      "stateCode": "OK"
    },
    {
      "stateDesc": "Oregon",
      "stateCode": "OR"
    },
    {
      "stateDesc": "Pennsylvania",
      "stateCode": "PA"
    },
    {
      "stateDesc": "Puerto Rico",
      "stateCode": "PR"
    },
    {
      "stateDesc": "Rhode Island",
      "stateCode": "RI"
    },
    {
      "stateDesc": "South Carolina",
      "stateCode": "SC"
    },
    {
      "stateDesc": "South Dakota",
      "stateCode": "SD"
    },
    {
      "stateDesc": "Tennessee",
      "stateCode": "TN"
    },
    {
      "stateDesc": "Texas",
      "stateCode": "TX"
    },
    {
      "stateDesc": "Utah",
      "stateCode": "UT"
    },
    {
      "stateDesc": "Vermont",
      "stateCode": "VT"
    },
    {
      "stateDesc": "Virgin Islands",
      "stateCode": "VI"
    },
    {
      "stateDesc": "Virgina",
      "stateCode": "VA"
    },
    {
      "stateDesc": "Washington",
      "stateCode": "WA"
    },
    {
      "stateDesc": "West Virginia",
      "stateCode": "WV"
    },
    {
      "stateDesc": "Wisconsin",
      "stateCode": "WI"
    },
    {
      "stateDesc": "Wyoming",
      "stateCode": "WY"
    }
  ];

  lossOptions:any = [
    { name: "No losses", value: "There Have Been No Losses (Insured Or Uninsured) For The Insurance Coverage Sought." },
    { name: "3 or more losses", value: "3 or more losses" },
    { name: "$250,000 or more in total paid and reserved losses", value: "$250,000 or more in total paid and reserved losses" },
    { name: "Other - please describe", value: "Losses- Describe All Claims" }
  ];

  blanketTypeOptions:any=[
    {name:"Building", value:"Building"},
    {name:"Building and Business Personal Property Combined", value:"Building and Business Personal Property Combined"},
    {name:"Business Personal Property", value:"Business Personal Property"}
  ]

  // liabilities

  liabilityLimitOptions = [
    { name: "$300,000", value: 300000 },
    { name: "$500,000", value: 500000 },
    { name: "$1,000,000", value: 1000000 },
    { name: "$2,000,000", value: 2000000 }
  ];

  // Optional coverages

  empPracticesLimitOptions = [
    { name: "$10,000/$2,500", value: 10000 },
    { name: "$25,000/$5,000", value: 25000 },
    { name: "$50,000/$5,000", value: 50000 },
    { name: "$75,000/$5,000", value: 75000 },
    { name: "$100,000/$5,000", value: 100000 },
    { name: "$250,000/$5,000", value: 250000 }
  ];

  damageRentedLimitOptions = [
    "$50,000",
    "$100,000",
    "$300,000",
    "$500,000",
  ];

  cyberCoverageTypeOptions = [
    "Lite",
    "Full"
  ];

  cyberCoverageLimitOptions= [
    "$25,000",
    "$50,000",
    "$100,000",
    "$250,000"
  ];

  sublimitTypeOptions= [
    "$500",
    "$1,000",
    "$2,000",
  ];

  // property details

  deductibleOptions = [
    "$500",
    "$1,000",
    "$2,500",
    "$5,000",
    "$7,500",
    "$10,000"
  ];

  deductibleafterDec282023Options = [
    "$500",
    "$1,000",
    "$2,500",
    "$5,000",
    "$7,500",
    "$10,000",
    "$25,000"
  ];

  condoDeductibleOptions = [
    "$500",
    "$1,000",
    "$2,500",
    "$5,000",
    "$10,000",
    "$25,000"
  ];

  condoLimitOptions = [
    "$10,000",
    "$25,000",
    "$50,000",
    "$75,000",
    "$100,000",
    "$300,000",
    "$500,000",
    "$1,000,000"
  ];

  constructionOptions = [
    "Frame Construction",
    "Joisted Masonry",
    "Masonry Non-combustible",
    "Non-combustible",
    "Modified Fire-resistive",
    "Fire-resistive",
    ];

  interestOptions = [
    "I am the owner and sole occupant of the building", // N
    "I own the building and occupy 10% or less of the building area", // Y
    "I own the building and occupy more than 10% of the building area", // Y
    "I am a tenant", // Y
    "I own and occupy a commercial condominium unit", // N
    "I own a commercial condminium unit and rent it to others", // Y
    "I own a residential condominium unit and rent it to others" // Y
  ]

  interestBPPOptions = [
    "I am the owner and sole occupant of the building", // N
    "I own the building and occupy 10% or less of the building area", // Y
    "I own the building and occupy more than 10% of the building area", // Y
    "I am a tenant – use when BPP only, no building", // Y
    "I own and occupy a commercial condominium unit", // N
    "I own a commercial condminium unit and rent it to others", // Y
    "I own a residential condominium unit and rent it to others" // Y
  ]

  // uw questions

  reasonOptions = [
    { id: 1, value: "Agent No longer represents the insurer" },
    { id: 2, value: "Non-payment of premium" },
    { id: 3, value: "Inspection condition" },
    { id: 4, value: "Claim" }
  ];  

  policyTypeOptions = [
    "Businessowners Policy",
    "Commercial Auto/Garage",
    "Commercial Package Policy",
    "Commercial Property",
    "Commercial Umbrella",
    "Crime",
    "General Liability",
    "Inland Marine"
  ];
}
