export const rate = "rate";
export const draft = "draft";
export const fullModel = "fullModel";
export const quickQuote = "quickQuote";
export const quotePolicy = "quotePolicy";
export const CompanyCode = "CompanyCode";
export const CompanyName = "CompanyName";
export const effectiveDate = "effectiveDate";
export const expirationDate = "expirationDate";
export const auditFrequency = "auditFrequency";
export const underwriterCode = "underwriterCode";
export const InsuredConst = "Insured";
export const BusinessName = "BusinessName";
export const InsuredType = "InsuredType";
export const yearsOfOperation = "yearsOfOperation";
export const AddressConst = "Address";
export const City = "City";
export const Line1 = "Line1";
export const StateCode = "StateCode";
export const StateDesc = "StateDesc";
export const ZipCode = "ZipCode";
export const ContactConst = "Contact";
export const ContactName = "ContactName";
export const Phone1 = "Phone1";
export const ProducerConst = "Producer";
export const ProducerCode = "ProducerCode";
export const producerOfRecord = "producerOfRecord";
export const EMail = "EMail";
export const BusinessOwnersConst = "BusinessOwners";
export const BPLocationsConst = "BPLocations";
export const BPInlineOBJConst = "BPInlineOBJ";
export const Extendednamedinsured1 = "Extendednamedinsured1";
export const CExtendednamedinsured1 = "CExtendednamedinsured1";
export const primaryState = "primaryState";
export const liabMedicalExpensLmt = "liabMedicalExpensLmt";
export const prodCompleteOperAggLmt = "prodCompleteOperAggLmt";
export const liabMedicalExpAggLmt = "liabMedicalExpAggLmt";
export const medicalExpensPerPerson = "medicalExpensPerPerson";
export const liabilityDeduApp = "liabilityDeduApp";
export const liabilityDeductibleType = "liabilityDeductibleType";
export const liabilityDeductible = "liabilityDeductible";
export const blanketRating = "blanketRating";
export const equipmentBreakdown = "equipmentBreakdown";
export const cEmpPracLiab = "cEmpPracLiab";
export const cEpliLimDed = "cEpliLimDed";
export const cDoesLocMtf = "cDoesLocMtf";
export const CNoEmpMore = "CNoEmpMore";
export const CNoEmpLess = "CNoEmpLess";
export const cIsCyberCov = "cIsCyberCov";
export const cCyberOption = "cCyberOption";
export const cCyberCovLimit = "cCyberCovLimit";
export const CBlgExtRepCc = "CBlgExtRepCc";
export const CExtRpCcOptions = "CExtRpCcOptions";
export const fuelTank = "fuelTank";
export const cContraEnhaEndorsement = "cContraEnhaEndorsement";
export const selfStorgFacilEnd = "selfStorgFacilEnd";
export const residCleanServCovAp = "residCleanServCovAp";
export const employeeDishonesty = "employeeDishonesty";
export const electronicData = "electronicData";
export const forgeryOrAlteration = "forgeryOrAlteration";
export const interruptionCompOper = "interruptionCompOper";
export const businessIncome = "businessIncome";
export const hireNonownAutoLiab = "hireNonownAutoLiab";
export const hireNonownAutoLiabCov = "hireNonownAutoLiabCov";
export const hireAutoNonownLiabType = "hireAutoNonownLiabType";
export const hiredAutoLiabLimit = "hiredAutoLiabLimit";
export const nonownedAutoLiabLmt = "nonownedAutoLiabLmt";
export const nonOwnedAutoLiabCov = "nonOwnedAutoLiabCov";
export const compAndFundTranFrd = "compAndFundTranFrd";
export const moldFungiWetDryRot = "moldFungiWetDryRot";
export const apartLosDamCusAuto = "apartLosDamCusAuto";
export const resLossDamCustAuto = "resLossDamCustAuto";
export const contractorsEndorsement = "contractorsEndorsement";
export const terrorismCoverage = "terrorismCoverage";
export const leadPoisoning = "leadPoisoning";
export const wantGenQstn = "wantGenQstn";
export const referenceObjectId = "referenceObjectId";
export const copyMailingAddress = "copyMailingAddress";
export const name = "name";
export const description = "description";
export const incLmtFireDeptSc = "incLmtFireDeptSc";
export const outdoorSigns = "outdoorSigns";
export const moneyAndSecurity = "moneyAndSecurity";
export const empDishonestyApp = "empDishonestyApp";
export const moldFungWetDryRtAp = "moldFungWetDryRtAp";
export const wtrBckupSmpOvrfl = "wtrBckupSmpOvrfl";
export const apartBuildCov = "apartBuildCov";
export const restaurantsCov = "restaurantsCov";
export const locationId = "locationId";
export const building = "building";
export const businessPersonalProp = "businessPersonalProp";
export const cMortgageInfo = "cMortgageInfo";
export const BuildingDescription = "BuildingDescription";
export const PropertyType = "PropertyType";
export const BuildingSquareFootage = "BuildingSquareFootage";
export const ConstructionType = "ConstructionType";
export const ResAuRepMnufcProcOcup = "ResAuRepMnufcProcOcup";
export const BldOwnrAndSoleOccupant = "BldOwnrAndSoleOccupant";
export const NumberOfEmployees = "NumberOfEmployees";
export const DistanceFromFireStation = "DistanceFromFireStation";
export const DistToNearestHydrant = "DistToNearestHydrant";
export const PublicProtFireClas = "PublicProtFireClas";
export const autoSprinkleredSystem = "autoSprinkleredSystem";
export const Interest = "Interest";
export const RentalProperty = "RentalProperty";
export const OwnerOccupiedSqFtge = "OwnerOccupiedSqFtge";
export const cRatingBasis = "cRatingBasis";
export const YearBuilt = "YearBuilt";
export const cEffectiveAgeOfBldg = "cEffectiveAgeOfBldg";
export const cRoof = "cRoof";
export const cElectrical = "cElectrical";
export const cWaterHeater = "cWaterHeater";
export const cHeating = "cHeating";
export const cPlumbing = "cPlumbing";
export const NumberOfUnits = "NumberOfUnits";
export const NumberOccupiedByOwners = "NumberOccupiedByOwners";
export const NumberLeasedToOthers = "NumberLeasedToOthers";
export const NumberVacant = "NumberVacant";
export const IsThereABasement = "IsThereABasement";
export const PercentFinished = "PercentFinished";
export const BldReplacementCostEst = "BldReplacementCostEst";
export const cReconstrConstr = "cReconstrConstr";
export const resAuRepMnufcProcOcup = "resAuRepMnufcProcOcup";
export const fireAlarmALocalAlarm = "fireAlarmALocalAlarm";
export const fireAlarmACenStnAlarm = "fireAlarmACenStnAlarm";
export const fireAlarmAHrdwrSmkDet = "fireAlarmAHrdwrSmkDet";
export const fireAlarmABatopSmkDet = "fireAlarmABatopSmkDet";
export const cBurglarLocal = "cBurglarLocal";
export const cBurgCentral = "cBurgCentral";
export const CrmProtAlmACenStAlm= "CrmProtAlmACenStAlm";
export const CrmProtAlmALocalAlm= "CrmProtAlmALocalAlm";
export const BldDeadboltDoorLocks = "BldDeadboltDoorLocks";
export const bldAnyOtherProtection = "bldAnyOtherProtection";
export const leadPoisoningAppl = "leadPoisoningAppl";
export const supplLeadPoisonAppl = "supplLeadPoisonAppl";
export const fuelTankApplies = "fuelTankApplies";
export const dmgPremisesRentYou = "dmgPremisesRentYou";
export const dmgPremRentYouSelCov = "dmgPremRentYouSelCov";
export const dmgPremRentYouLimit = "dmgPremRentYouLimit";
export const swimmingPool = "swimmingPool";
export const cBusinessIncExcl = "cBusinessIncExcl";
export const offPrmBsnsInter = "offPrmBsnsInter";
export const windstormOrHailExcl = "windstormOrHailExcl";
export const optionalBldgDedu = "optionalBldgDedu";
export const OptionalPropertyDedu = "OptionalPropertyDedu";
export const windstrmHailPrcntDed = "windstrmHailPrcntDed";
export const autoIncInInsurance = "autoIncInInsurance";
export const spoilage = "spoilage";
export const comCondUntOwner = "comCondUntOwner";
export const condominComUntOwnr = "condominComUntOwnr";
export const foodContamination = "foodContamination";
export const timeElement = "timeElement";
export const directDamage = "directDamage";
export const vacancyPermitEndors = "vacancyPermitEndors";
export const lossPayableClauses = "lossPayableClauses";
export const earthquakeCoverage = "earthquakeCoverage";
export const BPBuildingCoverageConst = "BPBuildingCoverage";
export const BuildingLimitInsurance = "BuildingLimitInsurance";
export const includedBlanketRating = "includedBlanketRating";
export const incRebudExpFollDis = "incRebudExpFollDis";
export const greenUpgrades = "greenUpgrades";
export const ordinanceLaw = "ordinanceLaw";
export const whRoofSurfaceLoss = "whRoofSurfaceLoss";
export const ltCovAcvBldRc = "ltCovAcvBldRc";
export const debrisRemAddInsEndt = "debrisRemAddInsEndt";
export const BPClassificationsConst = "BPClassifications";
export const ClassCode = "ClassCode";
export const classificationDesc = "classificationDesc";
export const BusinssPersonalPropLmt = "BusinssPersonalPropLmt";
export const liabilityExposure = "liabilityExposure";
export const AreaOccupied = "AreaOccupied";
export const ClassificationPercen = "ClassificationPercen";
export const cTenanatRelExp = "cTenanatRelExp";
export const utlSrvcDirectDmgApp = "utlSrvcDirectDmgApp";
export const electronicDataCovApp = "electronicDataCovApp";
export const intOfCompOprCovApp = "intOfCompOprCovApp";
export const incLmtAccountsRecv = "incLmtAccountsRecv";
export const incLmtDpndntProp = "incLmtDpndntProp";
export const incLmtPaperRecord = "incLmtPaperRecord";
export const incLmtOutdoorProp = "incLmtOutdoorProp";
export const specBppTmpAwayFrmPrm = "specBppTmpAwayFrmPrm";
export const theftLimitations = "theftLimitations";
export const brandsAndLabels = "brandsAndLabels";
export const permYardsMaintainStr = "permYardsMaintainStr";
export const seasonalIncPercentage = "seasonalIncPercentage";
export const spoilageCovApp = "spoilageCovApp";
export const earthquakeCovApplies = "earthquakeCovApplies";
export const eqSprinklerLeakage = "eqSprinklerLeakage";
export const medExpensExclusion = "medExpensExclusion";
export const amdAggLmtInsrnPrOb = "amdAggLmtInsrnPrOb";
export const BPBuildingConst = "BPBuilding";
export const BPOptionalRTCCoveragesConst = "BPOptionalRTCCoverages";
export const Endorsement = "Endorsement";
export const cNoOfPlows = "cNoOfPlows";
export const BPAdditionalInsured = "BPAdditionalInsured";
export const interestName = "interestName";
export const interest = "interest";
export const snowPlowing = "snowPlowing";
export const AdditionlInterestAddress = "AdditionlInterestAddress";
export const line1 = "line1";
export const line2 = "line2";
export const city = "city";
export const stateCode = "stateCode";
export const zipCode = "zipCode";
export const BPAdditionalInsuredLocations = "BPAdditionalInsuredLocations";
export const BPContractorsEndorsementConst = "BPContractorsEndorsement";
export const contrInstalCoverage = "contrInstalCoverage";
export const contrInstalLimit = "contrInstalLimit";
export const contrToolsEqpmntCov = "contrToolsEqpmntCov";
export const sublimitOption = "sublimitOption";
export const sublimit = "sublimit";
export const blanketCov = "blanketCov";
export const blanketLimit = "blanketLimit";
export const acvOption = "acvOption";
export const scheduledCov = "scheduledCov";
export const nonownedToolsOptCov = "nonownedToolsOptCov";
export const empToolsOptionalCov = "empToolsOptionalCov";
export const BPGeneralQuestionsMUConst = "BPGeneralQuestionsMU";
export const BusinessDesc = "BusinessDesc";
export const otherLocations = "otherLocations";
export const CSelectSubmission = "CSelectSubmission";
export const cCompPolicy = "cCompPolicy";
export const namePriorInsurer = "namePriorInsurer";
export const PriorInsPremium = "PriorInsPremium";
export const ListOfPolicies = "ListOfPolicies";
export const recentPolCancld = "recentPolCancld";
export const YrsExpClass = "YrsExpClass";
export const LosseHistory = "LosseHistory";
export const BPLossHistoryGeneralQuestionsConst = "BPLossHistoryGeneralQuestions";
export const LossClosed = "LossClosed";
export const lossDate = "lossDate";
export const lossDetail = "lossDetail";
export const lossPropReserv = "lossPropReserv";
export const lossLiabReserv = "lossLiabReserv";
export const AddlossDate = "AddlossDate";
export const AddlossDetail = "AddlossDetail";
export const AddlossPropReserv = "AddlossPropReserv";
export const AddlossLiabReserv = "AddlossLiabReserv";
export const ContractorResg = "ContractorResg";
export const snowIceRemoval = "snowIceRemoval";
export const privateDriveways = "privateDriveways";
export const parkingLots = "parkingLots";
export const roadHighways = "roadHighways";
export const OwnerPartner = "OwnerPartner";
export const EmployeePayroll = "EmployeePayroll";
export const TemporaryWorker = "TemporaryWorker";
export const totalPayroll = "totalPayroll";
export const UiSubcontractorPay = "UiSubcontractorPay";
export const GlCertSubcntrsPay = "GlCertSubcntrsPay";
export const applicantSubcontract = "applicantSubcontract";
export const applicantLawsuit = "applicantLawsuit";
export const remarkTradeContractors = "remarkTradeContractors";
export const cReasons = "cReasons";
export const BPCompanionPolicy = "BPCompanionPolicy";
export const CCompPolicyType = "CCompPolicyType";
export const CCompPolicyNumberRef = "CCompPolicyNumberRef";
export const cSmallContractor = "cSmallContractor";
export const CCoastalZone = "CCoastalZone";

export const isMailingLocationAddressSame ="isMailingLocationAddressSame";
export const isInsuredSmallContractor="isInsuredSmallContractor";

// Habitational
export const enhancerEndorsement = "enhancerEndorsement";
export const cEnhancerPlusEndt = "cEnhancerPlusEndt";
export const apartBldFineArtsCov = "apartBldFineArtsCov";
export const apartBldFineArtsLmt = "apartBldFineArtsLmt";
export const apartLosDamCusApp = "apartLosDamCusApp";
export const apartBldLossDamLmt = "apartBldLossDamLmt";
export const apartmentBldEnd = "apartmentBldEnd";
export const resLosDamCusAutoAp = "resLosDamCusAutoAp";
export const restEndorsement = "restEndorsement";
export const BldgPropertyType = "BldgPropertyType";
export const bldHaveAFireAlarm = "bldHaveAFireAlarm";
export const cBurgAlarm = "cBurgAlarm";
export const NoOfUnitsLetterCntrl = "NoOfUnitsLetterCntrl";
export const LeadPoisonYrConst = "LeadPoisonYrConst";
export const lossAssessmentLmt = "lossAssessmentLmt";
export const MiscRealPropertyLmt = "MiscRealPropertyLmt";
export const lossAssessmentDed = "lossAssessmentDed";
export const LossAssmntSubltForDed = "LossAssmntSubltForDed";
export const condDirctLiabEndt = "condDirctLiabEndt";
export const condLiabEndtLmt = "condLiabEndtLmt";
export const condOffLiabEndtDeduc = "condOffLiabEndtDeduc";
export const cancelExplanation = "cancelExplanation";
export const SwimmingPoolExists = "SwimmingPoolExists";
export const PlayGroundExists = "PlayGroundExists";
export const RecFacilities = "RecFacilities";
export const RiseToClaim = "RiseToClaim";
export const CRecQues="CRecQues";
export const NumberOfSwimmingPools="NumberOfSwimmingPools";

// Whole Sale Request
export const IndRiskPremiumMod = "IndRiskPremiumMod";
export const cusGoodsLegalLiabLmt = "cusGoodsLegalLiabLmt";
export const maintenanceFees = "maintenanceFees";
export const brbBeutPrsEnhPrfLb = "brbBeutPrsEnhPrfLb";
export const professionalClass = "professionalClass";
export const noOfFullTimeBarbers = "noOfFullTimeBarbers";
export const noOfPartTimeBarbers = "noOfPartTimeBarbers";
export const noOfFlTmBuPrEnPf = "noOfFlTmBuPrEnPf";
export const noOfPtTmBuPrEnPf = "noOfPtTmBuPrEnPf";
export const photography = "photography";
export const BPIRPMRating = "BPIRPMRating";
export const ProvideBreak = "ProvideBreak";
export const StateName = "StateName";
export const BPIRPMRatingLevel = "BPIRPMRatingLevel";
export const FactorType = "FactorType";
export const IrpmCovUk = "IrpmCovUk";
export const OverrideModifier = "OverrideModifier";
export const TotalModifier = "TotalModifier";
export const BPIRPMOverridefactors = "BPIRPMOverridefactors";
export const UnderwriterRiskConcern = "UnderwriterRiskConcern";
export const RiskFactor = "RiskFactor";
export const OverrideReason = "OverrideReason";
export const OverrideValue = "OverrideValue";
export const foreignProducts = "foreignProducts";
export const CDescProducts="CDescProducts";

// Convenience
export const AnualGrossSales = "AnualGrossSales";
export const HrsOperation = "HrsOperation";
export const LiquorSales = "LiquorSales";

// Processing
export const DefaultValue = "DefaultValue";
export const MaxValue = "MaxValue";
export const MinValue = "MinValue";

// Self - no new variables added

// Restaurant
export const WineCellar = "WineCellar";
export const TotalArea = "TotalArea";
export const Sommelier = "Sommelier";
export const LiqServerHire = "LiqServerHire";
export const Nfpa96 = "Nfpa96";
export const RemarksRest = "RemarksRest";

// Office
export const numberOfVeterinarian = "numberOfVeterinarian";
export const vetProLiabPerOccLmt = "vetProLiabPerOccLmt";
export const vetProfLiabAggLmt = "vetProfLiabAggLmt";
export const veterinarIncLmtApp = "veterinarIncLmtApp";
export const veterinarProfLiab = "veterinarProfLiab";

// Mercantile - no new variables added

export const BPBlanketRating="BPBlanketRating";
export const BlanketId="BlanketId";
export const BlanketType="BlanketType";
export const BuildingBppLimit="BuildingBppLimit";
export const EarthquakeSublimit="EarthquakeSublimit";