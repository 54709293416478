<div class="main-content">
  <div class="sub-content">
    <app-developer-json-view [myFormGroup]="agentForm" [mode]="'agentForm'"></app-developer-json-view>
    <form class="form-styling" [formGroup]="agentForm">
      <div class="agent-info no-default-height">
        <div class="box no-default-height">
          <div class="section-title">
            <p class="title-text">Agent/Insured Details</p>
          </div>
        
          <div class="row-frame-h62 h45">
            <div class="h45-p10">
              <mat-label class="required-field">Does the Insured have more than 3 locations and/or 3 buildings per
                location?
                <div class="tooltip">
                  <img src="../../../assets/img/icon-info.png">
                  <span class="tooltiptext">
                    Please navigate to MAPFRE Connect for Risks that exceed 3 Locations and/or 3 Buildings Per Location
                  </span>
                </div>
              </mat-label>
            </div>

            <div class="toggle-group-w288">
              <div value="Y" class="toggle-button-left-w144"
                [ngClass]="secondLocationselectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                (click)="secondLocationValChange(true);uiService.hasMultipleLocations=true;">Yes</div>
              <div value="N" class="toggle-button-right-w144"
                [ngClass]="secondLocationselectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                (click)="secondLocationValChange(false);uiService.hasMultipleLocations=false;">No</div>
            </div>
            <div *ngIf="uiService.moreThan3LocationsRequiredFlag" class="alert">
              Required
            </div>




          </div>
          <div class="agent-details" formGroupName="Producer">
            <div class="agent-details-row">
              <div class="field-box-w176">
                <label class="field-label-w176 required-field">Agent Code</label>
                <select class="field-input-w176" id="producer-agent-code" (change)="fetchAgencyDetails($event);"
                  [formControlName]="contractorConstants.ProducerCode">
                  <option class="dropdown-text" value="">Select</option>
                  <option class="dropdown-text" *ngFor="let code of agencyCodes" [ngValue]="code">{{ code }}</option>
                </select>
                <div *ngIf="
              agentForm.get(contractorConstants.ProducerConst).get(contractorConstants.ProducerCode).invalid &&
                  (agentForm.get(contractorConstants.ProducerConst).get(contractorConstants.ProducerCode).dirty ||
                  agentForm.get(contractorConstants.ProducerConst).get(contractorConstants.ProducerCode).touched)
                " class="alert">
                  Required
                </div>
              </div>
              <div class="field-main" style="width: 476px">
                <label class="field-label-readonly" style="width: 476px">Agent Name</label>
                <label class="field-value" style="width: 476px">{{ agencyName }}</label>
              </div>
              <div class="field-main" style="width: 317px">
                <label class="field-label-readonly" style="width: 317px">Agent Address</label>
                <label class="field-value" style="width: 400px">{{ agencyAddress }}</label>
              </div>
            </div>

            <div class="agent-details-row" style="gap: 24px" formGroupName="Contact">
              <div class="field-box-w376">
                <label class="field-label-w376 required-field">Agent Contact</label>

                <input required="required" autocomplete="off" type="text"
                  [formControlName]="contractorConstants.ContactName" id="agent-contact" class="field-input-w376"
                  maxlength="250" value="" />

                <div *ngIf="
                  contact.get(contractorConstants.ContactName).invalid &&
                  (contact.get(contractorConstants.ContactName).dirty ||
                    contact.get(contractorConstants.ContactName).touched)
                " class="alert">
                  Required
                </div>
              </div>

              <div class="field-box-w376">
                <label class="field-label-w376 required-field">Agent Email</label>

                <input class="field-input-w376" type="email" maxlength="256" autocomplete="off" required="required"
                  id="agent-email" [formControlName]="contractorConstants.EMail" placeholder="Ex. pat@example.com" />
                <div *ngIf="
                  contact.get(contractorConstants.EMail).errors?.email &&
                  contact.get(contractorConstants.EMail).touched
                " class="alert">
                  Please enter a valid value
                </div>
                <div *ngIf="
                  contact.get(contractorConstants.EMail).errors?.required &&
                  contact.get(contractorConstants.EMail).touched
                " class="alert">
                  Required
                </div>
              </div>

              <div class="field-box-w176">
                <label class="field-label-w176 required-field">Agent Phone</label>

                <input class="field-input-w176" appPhoneMask="appPhoneMask"
                  [formControlName]="contractorConstants.Phone1" id="agent-phone-number" required minlength="12"
                  maxlength="16" value="" />
                <div *ngIf="
                  contact.get(contractorConstants.Phone1).invalid &&
                  (contact.get(contractorConstants.Phone1).dirty ||
                    contact.get(contractorConstants.Phone1).touched)
                " class="alert">
                  Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="insuredbox no-default-height">
          <div class="section-title">
            <p class="title-text">
              Businessowners Massachusetts Quote
            </p>
          </div>

          <div class="datesdiv">

            <!-- <input type="date" id=""date> -->

            <mat-form-field appearance="outline">

              <mat-label>Policy Effective Date</mat-label>

              <input matInput="matInput" required="required" [matDatepicker]="picker" id="agent-policy-effective-date"
                (dateChange)="calculateEndDate($event)" [formControlName]="contractorConstants.effectiveDate">

              <img class="calendar-icon" matSuffix src="../../../assets/img/calendar_icon.png" (click)="picker.open()">

              <mat-hint>MM/DD/YYYY</mat-hint>

              <!-- <mat-datepicker-toggle matSuffix [for]="picker" (dateChange)="calculateEndDate($event)">
            </mat-datepicker-toggle> -->

              <mat-datepicker [startAt]="todayDate" #picker panelClass="datepickerstyle"
                [calendarHeaderComponent]="datepicker"></mat-datepicker>
              <!-- startView="multi-year" startView="year" -->

              <div *ngIf="
            agentForm.get(contractorConstants.effectiveDate).invalid &&
            (agentForm.get(contractorConstants.effectiveDate).dirty ||
            agentForm.get(contractorConstants.effectiveDate).touched)
              " class="alert">
                Required
              </div>
            </mat-form-field>

            <mat-form-field appearance="none">

              <mat-label>Policy Expiration Date</mat-label>

              <input matInput="matInput" value="{{getFormattedDate()}}" readonly="readonly" />
            </mat-form-field>

          </div>

          <hr class="line" style="order: 2;" />

          <div class="section-title" style="order: 3;">
            <p class="title-text">Insured Information</p>
          </div>

          <div class="insured-details" formGroupName="Insured">
            <div class="field-box-w376">
              <label class="field-label-w376 required-field">Insured Name</label>

              <input class="field-input-w376" required maxlength="80"
                [formControlName]="contractorConstants.BusinessName" id="agent-insured-name" />
              <div *ngIf="
                insured.get(contractorConstants.BusinessName).invalid &&
                (insured.get(contractorConstants.BusinessName).dirty ||
                  insured.get(contractorConstants.BusinessName).touched)
              " class="alert">
                Required
              </div>
            </div>

            <div class="field-box-w376">
              <label class="field-label-w376">Extended Insured Name</label>

              <input class="field-input-w376" maxlength="80" id="agent-extended-insured-name"
                [formControlName]="contractorConstants.CExtendednamedinsured1" />
            </div>
          </div>

          <hr class="line" style="order: 6;" />

          <div class="insured-address no-default-height">

            <div class="insured-locations" formGroupName="BusinessOwners">
              <div class="insured-locations-container" formGroupName="BPLocations">
                <div class="insured-locations-wrapper" formGroupName="Address">
                  <div class="insured-locations-row" *ngFor="let location of InsuredLocations; let i = index">
                    <h3 class="insured-locations-title">Location {{i+1}}</h3>
                    <div class="insured-locations-form-group-inline">
                      <div class="insured-locations-form-group medium-width">
                        <label class="insured-locations-form-lbl required-field">Insured Location Address</label>
                        <input class="insured-locations-form-input" type="text" maxlength="80" required
                          [formControlName]="location.LocationAddress" id="agent-insured-location-address" />
                        <div *ngIf="
                      boBpLocationAddress.get(location.LocationAddress).invalid &&
                      (boBpLocationAddress.get(location.LocationAddress).dirty ||
                        boBpLocationAddress.get(location.LocationAddress).touched)
                    " class="alert">
                          Required
                        </div>
                      </div>
                      <div class="insured-locations-form-group">
                        <label class="insured-locations-form-lbl required-field">Insured Location City</label>
                        <input type="text" class="insured-locations-form-input" maxlength="30" required value=""
                          [formControlName]="location.LocationCity" id="agent-insured-location-city" />
                        <div *ngIf="
                      boBpLocationAddress.get(location.LocationCity).invalid &&
                      (boBpLocationAddress.get(location.LocationCity).dirty ||
                        boBpLocationAddress.get(location.LocationCity).touched)
                    " class="alert">
                          Required
                        </div>
                      </div>
                      <div class="insured-locations-form-group">
                        <label class="insured-locations-form-lbl required-field" disabled>Insured Location State</label>
                        <input type="text" class="insured-locations-form-input" maxlength="30" required value=""
                          [formControlName]="location.LocationState" id="agent-insured-location-state" disabled />
                      </div>
                      <div class="insured-locations-form-group ">
                       
                        <label class="insured-locations-form-lbl required-field">Insured Location Zip Code</label>

                        <input class="insured-locations-form-input" required onlyNumber="onlyNumber"
                          [formControlName]="location.LocationZipCode" minlength="0" maxlength="5" value=""
                          id="agent-insured-location-zipcode" (change)="fetchByZipCode(location);" />
                        <div
                          *ngIf="boBpLocationAddress.get(location.LocationZipCode).invalid && (boBpLocationAddress.get(location.LocationZipCode).dirty || boBpLocationAddress.get(location.LocationZipCode).touched)"
                          class="alert no-default-width">
                          Required
                        </div>
                      </div>
                      <div class="delete-icon-container">
                        <i aria-label="Delete" class="material-icons" title="Remove location"
                        [ngClass]="InsuredLocations.length == 1 ? 'disable-delete-icon' : ''"
                        (click)="_doDeleteLocation(i)">delete</i>
                      </div>
                    </div>
                    <!-- <div class="insured-locations-btn-right" *ngIf="i > 0">
                    <button type="button" class="insured-locations-delete" (click)="_doDeleteLocation(i)">Delete Location</button>
                  </div> -->
                  </div>
                  <div class="insured-locations-form-action" *ngIf="InsuredLocations.length < 3">
                    <button type="button" mat-stroked-button="mat-stroked-button" class="aditional-loss-button"
                      (click)="_doAddMultiLocation()" color="warn">Add Location</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-frame-h62" *ngIf="uiService.coastalZip">
              <div class="small-radio-group">
                <label class="small-contractor-label required-field">Insured is a small contractor (maximum of $5,000
                  BPP,
                  $50,000 contractors equipment and no building coverage)</label>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="smallContractorselectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onSmallContractorValChange(true)">Yes</div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="smallContractorselectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onSmallContractorValChange(false)">No</div>
                </div>
                <div *ngIf="uiService.isInsuredSmallContractorRequiredFlag" class="alert">
                  Required
                </div>
              </div>
            </div>
            <div class="row-frame-h62">
              <div class="mailing-address-group">
                <label class="small-contractor-label required-field">Is Mailing Address same as one of the Location
                  Addresses?</label>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="mailingAddressselectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onMailingAddressValChange(true)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="mailingAddressselectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onMailingAddressValChange(false)">
                    No
                  </div>
                </div>
                <div *ngIf="uiService.agentMailingAddressSameRequiredFlag" class="alert">
                  Required
                </div>
              </div>
            </div>
            <div formGroupName="Insured">
              <div class="row-frame-h62" *ngIf="mailingAddressselectedVal == 'N' || uiService.confirmedMailingLocation"
                formGroupName="Address">
                <div class="field-box-w376">
                  <label class="field-label-w376 required-field">Insured Mailing Address</label>

                  <input class="field-input-w376" maxlength="80" required [formControlName]="contractorConstants.Line1"
                    id="agent-insured-mailing-address" />
                  <div *ngIf="
                    insuredAddress.get(contractorConstants.Line1).invalid &&
                    (insuredAddress.get(contractorConstants.Line1).dirty ||
                      insuredAddress.get(contractorConstants.Line1).touched)
                  " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w376">
                  <label class="field-label-w376 required-field">Insured Mailing City</label>

                  <input class="field-input-w376" maxlength="128" required [formControlName]="contractorConstants.City"
                    id="agent-insured-mailing-city" />
                  <div *ngIf="
                    insuredAddress.get(contractorConstants.City).invalid &&
                    (insuredAddress.get(contractorConstants.City).dirty ||
                      insuredAddress.get(contractorConstants.City).touched)
                  " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w176">
                  <label class="field-label-w176 required-field">Insured Mailing State</label>

                  <select class="field-input-w176" maxlength="120" required
                    [formControlName]="contractorConstants.StateCode" id="agent-insured-mailing-state">
                    <option class="dropdown-text">Select State</option>
                    <option class="dropdown-text" *ngFor="let mstate of dropDownService.mailingState"
                      [ngValue]="mstate.stateCode">{{
                      mstate.stateDesc }}</option>
                  </select>
                  <div
                    *ngIf="insuredAddress.get(contractorConstants.StateCode).invalid && (insuredAddress.get(contractorConstants.StateCode).dirty || insuredAddress.get(contractorConstants.StateCode).touched)"
                    class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w176">
                  <label class="field-label-w176 required-field" style="width: 180px">Insured Mailing Zip Code</label>
                  <input onlyNumber="onlyNumber" [formControlName]="contractorConstants.ZipCode" id="insured-zip-code"
                    class="field-input-w176" minlength="0" maxlength="5" id="agent-insured-mailing-zipcode"
                    (change)="fetchMailByZipCode();" />
                  <div
                    *ngIf="insuredAddress.get(contractorConstants.ZipCode).invalid && (insuredAddress.get(contractorConstants.ZipCode).dirty || insuredAddress.get(contractorConstants.ZipCode).touched)"
                    class="alert">
                    Required
                  </div>
                </div>
              </div>
            </div>

            <div class="insured-details2" [formGroupName]="contractorConstants.InsuredConst">
              <div class="field-box-w376" [formGroupName]="contractorConstants.ContactConst">
                <label class="field-label-w376">Insured Email</label>
                <input class="field-input-w376" type="email" maxlength="256"
                  [formControlName]="contractorConstants.EMail" placeholder="Ex. pat@example.com"
                  id="agent-insured-email" />
                <div *ngIf="insuredContact.get(contractorConstants.EMail).errors?.email" class="alert">
                  Please enter a valid value
                </div>
              </div>
              <div class="field-box-w376" formGroupName="Contact">
                <label class="field-label-w376 required-field" >Insured Phone</label>
                <input class="field-input-w176" appPhoneMask="appPhoneMask"
                  [formControlName]="contractorConstants.Phone1" id="agent-insured-phone" required minlength="12" maxlength="16" value="" />
                  <div *ngIf="
                  insuredContact.get(contractorConstants.Phone1).invalid &&
                  (insuredContact.get(contractorConstants.Phone1).dirty ||
                  insuredContact.get(contractorConstants.Phone1).touched)
                " class="alert">
                  Required
                </div>
                </div>
            </div>
            <div class="row-frame-h62">
              <div class="mailing-address-group">
                <label class="small-contractor-label">Blanket Rating?</label>
                <div class="toggle-group-w288">
                  <div value="Y" class="toggle-button-left-w144"
                    [ngClass]="blanketRatingselectedVal == 'Y' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketRatingValChange(true);onBlanketAlert(true)">
                    Yes
                  </div>
                  <div value="N" class="toggle-button-right-w144"
                    [ngClass]="blanketRatingselectedVal == 'N' ? 'selected-button' : 'unselected-button'"
                    (click)="onBlanketRatingValChange(false);onBlanketAlert(false)">
                    No
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>

        <div class="buisness-box">
          <div class="section-title">
            <p class="title-text">Business Information</p>
          </div>
          <div formGroupName="Insured">
            <div class="row-frame-h62" formGroupName="Contact">
              <div class="field-box-w376">
                <label class="field-label-w376">Business Owner First Name</label>

                <input class="field-input-w376" maxlength="80" formControlName="BoFirstName"
                  id="agent-business-owner-first-name" />
              </div>

              <div class="field-box-w376">
                <label class="field-label-w376">Business Owner Last Name</label>
                <input class="field-input-w376" maxlength="80" formControlName="BoLastName"
                  id="agent-business-owner-last-name" />
              </div>
            </div>
          </div>

          <div class="row-frame-h62" formGroupName="Insured">
            <div class="field-box-w976">
              <label class="field-label-w85 required-field">Entity Type</label>

              <select class="field-dropdown-w976" [formControlName]="contractorConstants.InsuredType"
                id="agent-entity-type">
                <option class="dropdown-text" value="">Select</option>
                <option class="dropdown-text" *ngFor="let entity of dropDownService.entityTypes" [ngValue]="entity">{{
                  entity
                  }}</option>
              </select>
              <div *ngIf="
                insured.get(contractorConstants.InsuredType).invalid &&
                (insured.get(contractorConstants.InsuredType).dirty ||
                  insured.get(contractorConstants.InsuredType).touched)
              " class="alert">
                Required
              </div>
            </div>

            <div class="field-box-w210">
              <label class="field-label-w210 required-field">Number of Years in Operation</label>
              <input class="field-input-w210" onlyNumber="onlyNumber" required
                [formControlName]="contractorConstants.yearsOfOperation" value="" maxlength="3"
                id="agent-details-years-of-opertion" />
              <div *ngIf="
                insured.get(contractorConstants.yearsOfOperation).invalid &&
                (insured.get(contractorConstants.yearsOfOperation).dirty ||
                  insured.get(contractorConstants.yearsOfOperation).touched)
              " class="alert">
                Required
              </div>
            </div>
          </div>

          <div class="classcode-box" *ngFor="let location of InsuredLocations; let i = index">


            <div [formGroupName]="contractorConstants.BusinessOwnersConst">
              <div>
                <p class="loc1">Location {{i+1}}
                  <span *ngIf="boBpLocationAddress.get(location.LocationZipCode).value !=''">- {{boBpLocationAddress.get(location.LocationAddress).value}} , </span>
                 <span *ngIf="boBpLocationAddress.get(location.LocationZipCode).value !=''">{{boBpLocationAddress.get(location.LocationCity).value}} , </span> 
                 <span *ngIf="boBpLocationAddress.get(location.LocationZipCode).value !=''">{{boBpLocationAddress.get(location.LocationState).value}} , </span> 
                 <span *ngIf="boBpLocationAddress.get(location.LocationZipCode).value !=''"> {{boBpLocationAddress.get(location.LocationZipCode).value}}</span></p>
              </div>


              <div class="row-frame-h62 no-default-height" [formGroupName]="contractorConstants.BPBuildingConst">

                <div class="field-box-w576-h130 no-default-height">
                  <label class="field-label-w576 required-field">Class of Business Description</label>
                  <div class="ng-autocomplete2">

                    <ng-autocomplete [data]="classcodes" [searchKeyword]="descriptionKeyword"
                      [customFilter]="customFilterDescription" placeholder="Class Code Description"
                      (selected)='selectDescriptionEvent($event, location, i)' historyIdentifier="classcodes"
                      [itemTemplate]="itemTemplateDescription2" [notFoundTemplate]="notFoundTemplate2"
                      [formControlName]="location.classificationDesc"
                      (inputCleared)="clearClassCode($event, location, i)">
                    </ng-autocomplete>

                    <ng-template #itemTemplateDescription2 let-item>
                      <a *ngIf="item.classcode!=null"
                        [innerHTML]="'['+item.propertytype+']'+ '-' + item.description+'  '+item.classcode ">
                      </a>
                    </ng-template>
                    <ng-template #notFoundTemplate2 let-notFound>
                      <div>No match: please check spelling or business could be out-of-appetite.</div>
                    </ng-template>
                  </div>
                  <div *ngIf="
                  boBPClassifications.get(location.classificationDesc).invalid &&
                    (boBPClassifications.get(location.classificationDesc).dirty ||
                    boBPClassifications.get(location.classificationDesc).touched)
                  " class="alert class-code-alert">
                    Required
                  </div>
                </div>
                <div class="bui-desc-field-box no-default-height">
                  <label class="field-label-w198">Additional Business Details</label>
                  <textarea class="field-input-w576" style="height: 130px;" maxlength="4000"
                    id="agent-business-additional-details" [formControlName]="location.BusinessDesc"></textarea>
                </div>


              </div>
            </div>
            <div [formGroupName]="contractorConstants.BusinessOwnersConst">
              <div class="row-frame-h62" [formGroupName]="contractorConstants.BPBuildingConst">

                <div class="field-box-w576">
                  <label class="field-label-w576 required-field">Class of Business Code</label>
                  <div class="ng-autocomplete">
                    <ng-autocomplete [data]="classcodes" [searchKeyword]="keyword" [customFilter]="customFilter"
                      placeholder="Class Code" (selected)='selectEvent($event, location, i)'
                      historyIdentifier="classcodes" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                      [formControlName]="location.ClassCode" (inputCleared)="clearDescription($event,location, i)"
                      (inputChanged)="clearDescription($event,location, i)">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a *ngIf="item.classcode!=null"
                        [innerHTML]="'['+item.propertytype+']'+ '-' + item.description+'  '+item.classcode ">
                      </a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <div>No match: please check spelling or business could be out-of-appetite.</div>
                    </ng-template>
                  </div>

                  <div *ngIf="
                  boBPClassifications.get(location.ClassCode).invalid &&
                  (boBPClassifications.get(location.ClassCode).dirty ||
                    boBPClassifications.get(location.ClassCode).touched)
                " class="alert">
                    Required
                  </div>
                </div>

              </div>
            </div>
          </div>


        </div>

        <div class="losses-box">
          <div class="section-title">
            <p class="title-text">Losses</p>
          </div>

          <div class="row-frame-h62">
            <div class="field-box-w400">
              <label class="field-label-w400 required-field">
                Losses - insured or uninsured in the last 3 years?
              </label>

              <select class="field-input-w400" [formControlName]="contractorConstants.LosseHistory" name="type" required
                id="property-details-loss-history" (change)="toggleLossForm()">
                <option class="dropdown-text" [value]="loss.value" *ngFor="let loss of dropDownService.lossOptions">
                  {{ loss.name }}
                </option>
              </select>
              <div *ngIf="
            agentForm.get(contractorConstants.LosseHistory).invalid &&
            (agentForm.get(contractorConstants.LosseHistory).dirty ||
            agentForm.get(contractorConstants.LosseHistory).touched)
              " class="alert">
                Required
              </div>
            </div>
          </div>

          <hr class="line" style="order: 2;" />

          <div class="loss-details" *ngIf="showLoss">
            <div class="row-frame-h62">
              <mat-form-field appearance="outline" style="width:376px">
                <mat-label class="required-field">Loss Date</mat-label>

                <input matInput="matInput" [matDatepicker]="picker2" [formControlName]="contractorConstants.lossDate"
                  [max]="todayDate" (dateChange)="formatLossDate($event)" />

                <img class="calendar-icon" matSuffix src="../../../assets/img/calendar_icon.png"
                  (click)="picker2.open()">

                <mat-hint>MM/DD/YYYY</mat-hint>

                <!-- <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle> -->

                <mat-datepicker #picker2 panelClass="datepickerstyle" [calendarHeaderComponent]="datepicker"
                  startView="multi-year"></mat-datepicker>
                <div *ngIf="
              agentForm.get(contractorConstants.lossDate).invalid &&
              (agentForm.get(contractorConstants.lossDate).dirty ||
              agentForm.get(contractorConstants.lossDate).touched)
                " class="alert">
                  Required
                </div>
              </mat-form-field>

              <div class="field-box-w200">
                <label class="field-label-w198 required-field">Property Reserves / Paid</label>

                <input class="field-input-w200" [formControlName]="contractorConstants.lossPropReserv"
                  id="property-details-loss-prop-reserve" placeholder="Amount in dollars" autocomplete="off"
                  currencyInput="currencyInput" maxDigits="9" min="1" max="32" />
                <div *ngIf="
                agentForm.get(contractorConstants.lossPropReserv).invalid &&
                (agentForm.get(contractorConstants.lossPropReserv).dirty ||
                agentForm.get(contractorConstants.lossPropReserv).touched)
                  " class="alert">
                  Required
                </div>
              </div>

              <div class="field-box-w200">
                <label class="field-label-w198 required-field">Liability Reserves / Paid</label>

                <input class="field-input-w200" [formControlName]="contractorConstants.lossLiabReserv"
                  id="property-details-loss-liab-reserv" placeholder="Amount in dollars" autocomplete="off"
                  currencyInput="currencyInput" maxDigits="9" min="1" max="32" />
                <div *ngIf="
                agentForm.get(contractorConstants.lossLiabReserv).invalid &&
                (agentForm.get(contractorConstants.lossLiabReserv).dirty ||
                agentForm.get(contractorConstants.lossLiabReserv).touched)
                  " class="alert">
                  Required
                </div>
              </div>
            </div>

            <div class="loss-details-desc">
              <div class="loss-details-desc-box">
                <label class="field-label-w198 required-field">Loss Description</label>

                <textarea class="loss-details-desc-input" minlength="1" maxlength="128"
                  [formControlName]="contractorConstants.lossDetail" id="property-details-loss-detail"></textarea>
                <div *ngIf="
                agentForm.get(contractorConstants.lossDetail).invalid &&
                (agentForm.get(contractorConstants.lossDetail).dirty ||
                agentForm.get(contractorConstants.lossDetail).touched)
                  " class="alert">
                  Required
                </div>
              </div>
            </div>

            <div class="row-frame-h40" style="padding-top: 20px; order: 7;">
              <button mat-stroked-button="mat-stroked-button" class="aditional-loss-button" *ngIf="!addLoss"
                (click)="toggleAddLossForm()" color="warn">
                Add an additional loss
              </button>
            </div>
          </div>

          <hr class="line" style="order: 6;" *ngIf="addLoss" />

          <div class="loss-details" style="padding-top: 20px;" *ngIf="addLoss">
            <div class="row-frame-h62">
              <div class="row-frame-h62" style="width: 824px">
                <mat-form-field appearance="outline" style="width:376px">
                  <mat-label class="required-field">Additional Loss Date</mat-label>

                  <input matInput="matInput" [matDatepicker]="picker3"
                    [formControlName]="contractorConstants.AddlossDate" [max]="todayDate"
                    (dateChange)="formatAddLossDate($event)" />

                  <img class="calendar-icon" matSuffix src="../../../assets/img/calendar_icon.png"
                    (click)="picker3.open()">

                  <mat-hint>MM/DD/YYYY</mat-hint>

                  <!-- <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle> -->

                  <mat-datepicker #picker3 panelClass="datepickerstyle" [calendarHeaderComponent]="datepicker"
                    startView="multi-year"></mat-datepicker>
                  <div *ngIf="
              agentForm.get(contractorConstants.AddlossDate).invalid &&
              (agentForm.get(contractorConstants.AddlossDate).dirty ||
              agentForm.get(contractorConstants.AddlossDate).touched)
                " class="alert">
                    Required
                  </div>
                </mat-form-field>

                <div class="field-box-w200">
                  <label class="field-label-w198 required-field">Property Reserves / Paid</label>

                  <input class="field-input-w200" [formControlName]="contractorConstants.AddlossPropReserv"
                    id="property-details-add-loss-prop-reserve" placeholder="Amount in dollars" autocomplete="off"
                    currencyInput="currencyInput" maxDigits="9" id="agent-additional-losses-property-reserves-paid"
                    min="1" max="32" />
                  <div *ngIf="
              agentForm.get(contractorConstants.AddlossPropReserv).invalid &&
              (agentForm.get(contractorConstants.AddlossPropReserv).dirty ||
              agentForm.get(contractorConstants.AddlossPropReserv).touched)
                " class="alert">
                    Required
                  </div>
                </div>

                <div class="field-box-w200">
                  <label class="field-label-w198 required-field">Liability Reserves / Paid</label>

                  <input class="field-input-w200" placeholder="Amount in dollars"
                    [formControlName]="contractorConstants.AddlossLiabReserv" id="property-details-add-loss-liab-reserv"
                    autocomplete="off" currencyInput="currencyInput" maxDigits="9"
                    id="agent-liability-losses-property-reserves-paid" min="1" max="32" />
                  <div *ngIf="
              agentForm.get(contractorConstants.AddlossLiabReserv).invalid &&
              (agentForm.get(contractorConstants.AddlossLiabReserv).dirty ||
              agentForm.get(contractorConstants.AddlossLiabReserv).touched)
                " class="alert">
                    Required
                  </div>
                </div>
              </div>

              <div class="removebutton">
                <button mat-stroked-button="mat-stroked-button" class="remove-loss-button" (click)="toggleAddLossForm()"
                  color="warn">
                  Remove Loss

                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>

            <div class="loss-details-desc">
              <div class="loss-details-desc-box">
                <label class="field-label-w198 required-field">Loss Description</label>

                <textarea class="loss-details-desc-input" minlength="1" maxlength="128"
                  [formControlName]="contractorConstants.AddlossDetail"
                  id="property-add-details-loss-detail"></textarea>
                <div *ngIf="
              agentForm.get(contractorConstants.AddlossDetail).invalid &&
              (agentForm.get(contractorConstants.AddlossDetail).dirty ||
              agentForm.get(contractorConstants.AddlossDetail).touched)
                " class="alert">
                  Required
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </form>
  </div>
  <div class="rectangle"></div>
</div>