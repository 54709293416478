import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DataService } from "./data.service";
import { CompanyCode, CompanyName, effectiveDate, expirationDate, auditFrequency, underwriterCode, BuildingLimitInsurance, BusinssPersonalPropLmt, OptionalPropertyDedu, YearBuilt, ConstructionType, DistanceFromFireStation, DistToNearestHydrant, cRoof, cPlumbing, cHeating, cWaterHeater, cElectrical, fireAlarmALocalAlarm, cBurglarLocal, fireAlarmACenStnAlarm, cBurgCentral, fireAlarmABatopSmkDet, fireAlarmAHrdwrSmkDet, autoSprinkleredSystem, Interest, BuildingSquareFootage, NumberOfUnits, NumberOccupiedByOwners, NumberVacant, NumberLeasedToOthers, liabMedicalExpensLmt, prodCompleteOperAggLmt, liabMedicalExpAggLmt, medicalExpensPerPerson, totalPayroll, liabilityDeductible, applicantSubcontract, Endorsement, cNoOfPlows, interest, dmgPremRentYouLimit, hireNonownAutoLiab, cEmpPracLiab, cEpliLimDed, cIsCyberCov, cCyberOption, cCyberCovLimit, CBlgExtRepCc, CExtRpCcOptions, cContraEnhaEndorsement, contrToolsEqpmntCov, contrInstalCoverage, sublimit, blanketCov, cReconstrConstr, resAuRepMnufcProcOcup, recentPolCancld, otherLocations, cCompPolicy, applicantLawsuit, CNoEmpMore, CNoEmpLess, BusinessName, InsuredType, ProducerCode, producerOfRecord, yearsOfOperation, City, ContactName, EMail, Line1, Phone1, StateCode, StateDesc, ZipCode } from "@app/contractor.constant";
import { Router } from '@angular/router';

@Injectable({
  providedIn: "root",
})
export class DeveloperService {
  constructor(public dataService: DataService, private router: Router) {}

  public isDeveloperMode: boolean = false;
  

  localCoverAllDataForTesting = (pageName: string, dataService: DataService) => {
    dataService.current_business_response = {
      "apiOutput":{
        "result":{
          "quotePolicy": {
            "DisplayPolicyNumber": "80-BP-000117314-0",
            "AnnualPremium": "15118"
          }
        }
      }
    }
    this.router.navigate([`${pageName}`]);
  }

  findEmptyAttributes(obj) {
    let emptyAttributes = [];
  
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        let value = obj[prop];
  
        if (typeof value === 'object' && value !== null) {
          emptyAttributes = emptyAttributes.concat(this.findEmptyAttributes(value));
        } else if (value === '') {
          emptyAttributes.push(prop);
        }
      }
    }
  
    return emptyAttributes;
  }
}
