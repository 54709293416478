<div class="bop-stepper" *ngIf='authService.isLoggedIn$ | async'>
  <div class="progree-bg">
    <div class="stepper">
      <div class="progress-bar-step1">
        <div [ngClass]="currentComponent === 'insured' ? 'stepper-selected-state' : 'stepper-completed-state'"
          (click)="goToInsuredPage()">
          <div [ngClass]="currentComponent === 'insured' ? 'stepper-selected-text' : 'stepper-completed-text'">
            1
          </div>
        </div>
        <label [ngClass]="currentComponent === 'insured' ? 'stepper-selected-label' : 'stepper-completed-label'">
          Insured
        </label>
      </div>
      <div class="progress-bar-step2">
        <div
          [ngClass]="currentComponent === 'property' ? 'stepper-selected-state' : (currentComponent == 'liability' || currentComponent == 'optionalCoverage' || currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-state' : 'stepper-unselected-state')"
          (click)="goToPropertyPage()">
          <div
            [ngClass]="currentComponent === 'property' ? 'stepper-selected-text' : (currentComponent == 'liability' || currentComponent == 'optionalCoverage' || currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-text' : 'stepper-unselected-text')">
            2
          </div>
        </div>
        <label
          [ngClass]="currentComponent === 'property' ? 'stepper-selected-label' : (currentComponent == 'liability' || currentComponent == 'optionalCoverage' || currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary'? 'stepper-completed-label' : 'stepper-unselected-label')">
          Property
        </label>
      </div>
      <div class="progress-bar-step3">
        <div
          [ngClass]="currentComponent === 'liability' ? 'stepper-selected-state' : (currentComponent == 'optionalCoverage' || currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-state' : 'stepper-unselected-state')"
          (click)="goToLiabilityPage()">
          <div
            [ngClass]="currentComponent === 'liability' ? 'stepper-selected-text' : (currentComponent == 'optionalCoverage' || currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary'? 'stepper-completed-text' : 'stepper-unselected-text')">
            3
          </div>
        </div>
        <label
          [ngClass]="currentComponent === 'liability' ? 'stepper-selected-label' : (currentComponent == 'optionalCoverage'|| currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-label' : 'stepper-unselected-label')">
          Liability
        </label>
      </div>
      <div class="progress-bar-step4">
        <div
          [ngClass]="currentComponent === 'optionalCoverage' ? 'stepper-selected-state' : ( currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-state' : 'stepper-unselected-state')"
          (click)="goToOptionalPage()">
          <div
            [ngClass]="currentComponent === 'optionalCoverage' ? 'stepper-selected-text':( currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary'? 'stepper-completed-text' : 'stepper-unselected-text')">
            4
          </div>
        </div>
        <label
          [ngClass]="currentComponent === 'optionalCoverage' ? 'stepper-selected-label':(currentComponent == 'uwQuestions' || currentComponent == 'quoteSummary' ? 'stepper-completed-label' : 'stepper-unselected-label')">Optional
          Coverages
        </label>

      </div>
      <div class="progress-bar-step5">
        <div
          [ngClass]="currentComponent === 'uwQuestions' ? 'stepper-selected-state' : (currentComponent == 'quoteSummary' ? 'stepper-completed-state' : 'stepper-unselected-state')"
          (click)="goToQuestionsPage()">
          <div
            [ngClass]="currentComponent === 'uwQuestions' ? 'stepper-selected-text':(currentComponent == 'quoteSummary'? 'stepper-completed-text' : 'stepper-unselected-text')">
            5
          </div>
        </div>
        <label
          [ngClass]="currentComponent === 'uwQuestions' ? 'stepper-selected-label':(currentComponent == 'quoteSummary' ? 'stepper-completed-label' : 'stepper-unselected-label')">UW
          Questions
        </label>

      </div>
      <div class="progress-bar-step6">
        <div
          [ngClass]="currentComponent === 'quoteSummary' ? 'stepper-selected-state' : (currentComponent == '' ? 'stepper-completed-state' : 'stepper-unselected-state')">
          <div
            [ngClass]="currentComponent === 'quoteSummary' ? 'stepper-selected-text':(currentComponent == ''? 'stepper-completed-text' : 'stepper-unselected-text')">
            6
          </div>
        </div>
        <label
          [ngClass]="currentComponent === 'quoteSummary' ? 'stepper-selected-label':(currentComponent == '' ? 'stepper-completed-label' : 'stepper-unselected-label')">Quote
          Summary
        </label>
      </div>

    </div>
  </div>
</div>