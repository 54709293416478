import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AgentInsurerDetailsComponent } from "./lead/agent-insurer-details/agent-insurer-details.component";
import { PropertyDetailsComponent } from "./lead/property-details/property-details.component";
import { LiabilityDetailsComponent } from "./lead/liability-details/liability-details.component";
import { OptionalCoveragesComponent } from "./lead/optional-coverages/optional-coverages.component";
import { UwQuestionsComponent } from "./lead/uw-questions/uw-questions.component";
import { QuoteSummaryComponent } from "./lead/quote-summary/quote-summary.component";
import { LoginComponent } from './lead/login/login.component';
import { AuthGuardService } from "./core/services/authguard.service";
import { SSOLoginComponent } from "./lead/sso-login/sso-login.component";

const routes: Routes = [
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "SSOLogin", component: SSOLoginComponent },
  { path: "login", component: LoginComponent },
  { path: "agent", component: AgentInsurerDetailsComponent, canActivate: [AuthGuardService] },
  { path: "property-details", component: PropertyDetailsComponent, canActivate: [AuthGuardService] },
  { path: "liability-details", component: LiabilityDetailsComponent, canActivate: [AuthGuardService] },
  { path: "optional-coverages", component: OptionalCoveragesComponent, canActivate: [AuthGuardService] },
  { path: "uw-questions", component: UwQuestionsComponent, canActivate: [AuthGuardService] },
  { path: "quote-summary", component: QuoteSummaryComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
