import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { ComponentService } from '@app/core/services/component.service';
import { DataMappingService } from '@app/core/services/data-mapping.service';
import { DataService } from '@app/core/services/data.service';
import { dmgPremRentYouLimit, hireNonownAutoLiab, hiredAutoLiabLimit, cEmpPracLiab, cDoesLocMtf, cEpliLimDed, cIsCyberCov, cCyberOption, cCyberCovLimit, CBlgExtRepCc, CExtRpCcOptions, cContraEnhaEndorsement, contrToolsEqpmntCov, contrInstalLimit, blanketLimit, CNoEmpLess, CNoEmpMore, sublimit, BPContractorsEndorsementConst, enhancerEndorsement, cEnhancerPlusEndt, apartmentBldEnd, restEndorsement, businessPersonalProp } from '@app/contractor.constant';

import * as contractorConstants from '@app/contractor.constant';
import { DropdownService } from '@app/core/services/dropdown.service';
import { UiService } from '@app/core/services/ui.service';
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';

@Component({
  selector: 'app-optional-coverages',
  templateUrl: './optional-coverages.component.html',
  styleUrls: ['./optional-coverages.component.scss']
})
export class OptionalCoveragesComponent implements OnInit {

  liabilityLimitValue: string;
  
  public noHaselectedVal : string;
  public employmentselectedVal : string;
  public cyberSuiteselectedVal : string;
  public costCoverageselectedVal: string;
  public costValueselectedVal:string;
  public empPracticesLimitOption:string;
  public ContractorEnhancerselectedVal:string;
  public contractorToolsSelectedVal:string;

  public apartmentBuildingEndorsementSelectedVal:string;
  public enhancerEndorsementSelectedVal:string;
  public enhancerPlusEndorsementSelectedVal:string;

  public restaurantEndorsementSelectedVal:string;

  public builidingCoverageFlag: boolean=false;
  public contractorInstallationLimitFailedFlag: boolean=false;
  public contractorToolsEquipementErrorFlag: boolean=false;

  public contractorInstallationLimitRequiredBlanketToolsFailedFlag: boolean=false;
  public contractorInstallationLimitBlanketToolsRequiredFailedFlag: boolean=false;
  OptionalCoveragesForm: FormGroup;

  contractorConstants = contractorConstants;
  BusinessAttribute = BusinessAttribute;


  constructor(private router: Router, private componentService: ComponentService, public dataMappingService: DataMappingService, public dataService: DataService, public dropDownService: DropdownService,public uiService: UiService) {
  this.componentService.currentComponent = 'optionalCoverage';
    this.componentService.currentComponentChanged.emit('optionalCoverage');
  }
  goToPage(pageName:string, next?: boolean):void{
    if(next){
      this.dataMappingService.mapOptionalCoveragesFormDatatoFinalJson(this.OptionalCoveragesForm);
    }
    this.router.navigate([`${pageName}`]);
  }

  initializeOptionalCoveragesForm(){
    const businessOwners = this.dataService.businessOwners;
    const selectedValue = (this.dataService.businessOwners.cEmpPracLiab=='Y')?(this.dropDownService.empPracticesLimitOptions.filter(options => options.value == Number(businessOwners.cEpliLimDed))[0].value):'';
    const selectedDmgPremRentYouLimitValue = this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPBuilding.dmgPremRentYouLimit));
    this.OptionalCoveragesForm = new FormGroup({
      [hireNonownAutoLiab]: new FormControl(businessOwners.hireNonownAutoLiab),
      [hiredAutoLiabLimit]: new FormControl(businessOwners.hiredAutoLiabLimit),
      [cEmpPracLiab]: new FormControl(businessOwners.cEmpPracLiab,Validators.required),
      [cDoesLocMtf]: new FormControl(businessOwners.cDoesLocMtf),
      [cEpliLimDed]: new FormControl(selectedValue),
      [CNoEmpMore]: new FormControl(businessOwners.CNoEmpMore),
      [CNoEmpLess]: new FormControl(businessOwners.CNoEmpLess),
      [cIsCyberCov]: new FormControl(businessOwners.cIsCyberCov),
      [cCyberOption]: new FormControl(businessOwners.cCyberOption),
      [cCyberCovLimit]: new FormControl(this.dataService.businessOwners.cCyberCovLimit?this.dataMappingService.convertToDollarFormat(Number(this.dataService.businessOwners.cCyberCovLimit)):''),
      [CBlgExtRepCc]: new FormControl(CBlgExtRepCc in businessOwners ? businessOwners.CBlgExtRepCc: ""),
      [CExtRpCcOptions]: new FormControl(CExtRpCcOptions in businessOwners ? businessOwners.CExtRpCcOptions: ""),
      [cContraEnhaEndorsement]: new FormControl(cContraEnhaEndorsement in businessOwners ? businessOwners.cContraEnhaEndorsement: ""),
      // [contrToolsEqpmntCov]: new FormControl(this.dataService.BPContractorsEndorsement.contrToolsEqpmntCov),
      // [contrInstalLimit]: new FormControl(this.dataService.BPContractorsEndorsement.contrInstalLimit),
      // [sublimit]: new FormControl(this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPContractorsEndorsement.sublimit))),
      // [blanketLimit]: new FormControl(this.dataService.BPContractorsEndorsement.blanketLimit),
    });

    const isBPContractorsEndorsement = BPContractorsEndorsementConst in this.dataService.businessOwners;
    if(isBPContractorsEndorsement){
      this.OptionalCoveragesForm.addControl(contrToolsEqpmntCov, new FormControl(this.dataService.BPContractorsEndorsement.contrToolsEqpmntCov));
      this.OptionalCoveragesForm.addControl(contrInstalLimit, new FormControl(this.dataService.BPContractorsEndorsement.contrInstalLimit));
      this.OptionalCoveragesForm.addControl(sublimit, new FormControl(this.dataMappingService.convertToDollarFormat(Number(this.dataService.BPContractorsEndorsement.sublimit))));
      this.OptionalCoveragesForm.addControl(blanketLimit, new FormControl(this.dataService.BPContractorsEndorsement.blanketLimit));
    }
  }

  addBPContractorsEndorsement() {
    const isBPContractorsEndorsement = BPContractorsEndorsementConst in this.dataService.businessOwners;
    if(!isBPContractorsEndorsement && this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS]) {
      this.dataService.businessOwners[BPContractorsEndorsementConst] = {
        contrInstalCoverage: "Y", 
        contrInstalLimit: "3000", 
        contrToolsEqpmntCov: "Y",
        sublimitOption: "", 
        sublimit: "500", 
        blanketCov: "Y", 
        blanketLimit: "3000",
        acvOption: "N",
        scheduledCov: "N",
        nonownedToolsOptCov: "N",
        empToolsOptionalCov: "N",
      }
    }
  }

  ngOnInit(): void {
    this.addBPContractorsEndorsement();
    this.initializeOptionalCoveragesForm();
    this.initializeHabitationalOptionalFormChanges();
    this.initializeEnchancerOptionalFormChanges();
    this.dataMappingService.currentForm = this.OptionalCoveragesForm;
    this.initializeRadioValues();
    this.initializeFormValues();
    this.initializeBildingCoverage();
  }

  initializeHabitationalOptionalFormChanges(){
    if(this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS]){
      if(enhancerEndorsement in this.dataService.businessOwners){
        this.OptionalCoveragesForm.addControl(enhancerEndorsement, new FormControl(this.dataService.businessOwners.enhancerEndorsement));
      }
      if(cEnhancerPlusEndt in this.dataService.businessOwners){
        this.OptionalCoveragesForm.addControl(cEnhancerPlusEndt, new FormControl(this.dataService.businessOwners.cEnhancerPlusEndt));
      }
    
    }
  }
  initializeBildingCoverage(){
    let buildingLimitChecked=false;
    this.dataService.businessOwners.BPBuilding.forEach((d)=>{
      if(d.BPBuildingCoverage && d.BPBuildingCoverage.BuildingLimitInsurance!=""){
        buildingLimitChecked=true;
      }
    })
    if(buildingLimitChecked){
      this.uiService.builidingCoverageFlag=true;
    }else{
      this.uiService.builidingCoverageFlag=false;
    }
  }

  initializeEnchancerOptionalFormChanges(){
    if(this.dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS]|| this.dataMappingService.businessFlags[BusinessAttribute.SELF_STORAGE_FACILITIES] || this.dataMappingService.businessFlags[BusinessAttribute.OFFICE] || this.dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS] || this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || this.dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]){
      if(enhancerEndorsement in this.dataService.businessOwners){
        this.OptionalCoveragesForm.addControl(enhancerEndorsement, new FormControl(this.dataService.businessOwners.enhancerEndorsement));
      }
      if(cEnhancerPlusEndt in this.dataService.businessOwners){
        this.OptionalCoveragesForm.addControl(cEnhancerPlusEndt, new FormControl(this.dataService.businessOwners.cEnhancerPlusEndt));
      }
     }
 
  }

  initializeFormValues(){
    this.liabilityLimitValue = this.dataMappingService.convertToDollarFormat(Number(this.dataService.businessOwners.liabMedicalExpensLmt));
    // this.OptionalCoveragesForm.get(dmgPremRentYouLimit).setValue("$50,000");
   }


  initializeRadioValues(){
    this.noHaValChange(this.dataMappingService.YNToBool(this.dataService.businessOwners.hireNonownAutoLiab));
    this.employmentValChange(this.dataMappingService.YNToBool(this.dataService.businessOwners.cEmpPracLiab));
    this.costValueValChange(CExtRpCcOptions in this.dataService.businessOwners ? this.dataService.businessOwners.CExtRpCcOptions == '10': false);
    this.contractorEnhancerValChange(this.dataMappingService.YNToBool(cContraEnhaEndorsement in this.dataService.businessOwners ? this.dataService.businessOwners.cContraEnhaEndorsement: 'N'));
    const isBPContractorsEndorsement = BPContractorsEndorsementConst in this.dataService.businessOwners;
    if(this.dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS] && isBPContractorsEndorsement)
   {
    this.contractorToolsValChange(this.dataMappingService.YNToBool(this.dataService.businessOwners.contractorsEndorsement));
  }
    const cIsCyberCovSelected = this.dataMappingService.YNToBool(this.dataService.businessOwners.cIsCyberCov)
    if(cIsCyberCovSelected || this.dataService.businessOwners.cIsCyberCov != ''){
      this.cyberSuiteValChange(cIsCyberCovSelected);
    }
    const CBlgExtRepCcSelected = this.dataMappingService.YNToBool(CBlgExtRepCc in this.dataService.businessOwners ? this.dataService.businessOwners.CBlgExtRepCc: 'N')
    if(CBlgExtRepCcSelected || (CBlgExtRepCc in this.dataService.businessOwners ? this.dataService.businessOwners.CBlgExtRepCc != '': false)){
      this.costCoverageValChange(CBlgExtRepCcSelected);
    }
    const enhancerEndorsementSelected = this.dataMappingService.YNToBool(enhancerEndorsement in this.dataService.businessOwners ? this.dataService.businessOwners.enhancerEndorsement: 'N')
    if(enhancerEndorsementSelected || (enhancerEndorsement in this.dataService.businessOwners ? this.dataService.businessOwners.enhancerEndorsement != '': false)){
      this.enhancerEndorsementValChange(enhancerEndorsementSelected);
    }
    const cEnhancerPlusEndtSelected = this.dataMappingService.YNToBool(cEnhancerPlusEndt in this.dataService.businessOwners ? this.dataService.businessOwners.cEnhancerPlusEndt: 'N')
    if(cEnhancerPlusEndtSelected || (cEnhancerPlusEndt in this.dataService.businessOwners ? this.dataService.businessOwners.cEnhancerPlusEndt != '': false)){
      this.enhancerPlusEndorsementValChange(cEnhancerPlusEndtSelected);
    }

    const apartmentBldEndSelected = this.dataMappingService.YNToBool(apartmentBldEnd in this.dataService.BPInlineOBJ ? this.dataService.BPInlineOBJ.apartmentBldEnd: 'N')
    if(apartmentBldEndSelected || (apartmentBldEnd in this.dataService.BPInlineOBJ ? this.dataService.BPInlineOBJ.apartmentBldEnd != '': false)){
      this.apartmentBuildingEndorsementValChange(apartmentBldEndSelected);
    }
    
    const restaurantEndSelected = this.dataMappingService.YNToBool(restEndorsement in this.dataService.BPInlineOBJ ? this.dataService.BPInlineOBJ.restEndorsement: 'N')
    if(restaurantEndSelected){
      this.restaurantEndorsementValChange(restaurantEndSelected);
    }

    
  }

  public noHaValChange(noHaCheck){
    if(noHaCheck == true){
      this.noHaselectedVal = 'Y'
    }
    else{
      this.noHaselectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([hireNonownAutoLiab]).setValue(this.noHaselectedVal);
  }

  employmentValChange(employmentValCheck) {
    if(employmentValCheck == true){
      this.employmentselectedVal = 'Y'
      if(this.OptionalCoveragesForm.get([cEpliLimDed]).value=='')
      {const selectedValue = this.dropDownService.empPracticesLimitOptions[1].value;
      this.OptionalCoveragesForm.get([cEpliLimDed]).setValue(selectedValue);
      }
    }
    else{
      this.employmentselectedVal = 'N'
      this.OptionalCoveragesForm.get([cEpliLimDed]).setValue('');
      this.OptionalCoveragesForm.get([CNoEmpMore]).setValue('');
      this.OptionalCoveragesForm.get([CNoEmpLess]).setValue('');
    }
    this.OptionalCoveragesForm.get([cEmpPracLiab]).setValue(this.employmentselectedVal);
    this.updateEmployeeLimitFullParttimeRequired(this.employmentselectedVal == 'Y');
       
  }
  updateEmployeeLimitFullParttimeRequired(condition:boolean)
  {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.OptionalCoveragesForm.get(CNoEmpMore),condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.OptionalCoveragesForm.get(CNoEmpLess),condition);
  }

  cyberSuiteValChange(cyberSuiteCheck) {
    if(cyberSuiteCheck == true){
      this.cyberSuiteselectedVal = 'Y'
    }
    else{
      this.cyberSuiteselectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([cIsCyberCov]).setValue(this.cyberSuiteselectedVal);
    const defaultcCyberOption = this.dropDownService.cyberCoverageTypeOptions[0];
    const defaultcCyberCovLimit = this.dropDownService.cyberCoverageLimitOptions[0];
    if(this.cyberSuiteselectedVal == 'Y'){
      if(this.dataService.businessOwners.cCyberCovLimit == '' && this.dataService.businessOwners.cCyberOption == ''){
        this.dataService.businessOwners.cCyberCovLimit = defaultcCyberCovLimit;
        this.dataService.businessOwners.cCyberOption = defaultcCyberOption;
        this.OptionalCoveragesForm.get(cCyberOption).setValue(defaultcCyberOption);
        this.OptionalCoveragesForm.get(cCyberCovLimit).setValue(defaultcCyberCovLimit);
      }
    }else{
      this.dataService.businessOwners.cCyberCovLimit = '';
      this.dataService.businessOwners.cCyberOption = '';
      this.OptionalCoveragesForm.get(cCyberOption).setValue('');
      this.OptionalCoveragesForm.get(cCyberCovLimit).setValue('');    
    }
    this.updateCSCoverageLimit(this.cyberSuiteselectedVal == 'Y');
  }


  updateCSCoverageLimit(condition:boolean)
  {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.OptionalCoveragesForm.get(cCyberCovLimit),condition);
  }

  costCoverageValChange(costCoverageCheck) {
    if(costCoverageCheck == true){
      this.costCoverageselectedVal = 'Y'
    }
    else{
      this.costCoverageselectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([CBlgExtRepCc]).setValue(this.costCoverageselectedVal);
    this.updateBuildingExtendedReplacementCostValueRequired(this.costCoverageselectedVal == 'Y');
  }

  updateBuildingExtendedReplacementCostValueRequired(condition:boolean)
  {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.OptionalCoveragesForm.get(CExtRpCcOptions),condition);
  
  }
  costValueValChange(costValueCheck) {
    if(costValueCheck == true){
      this.costValueselectedVal = '10'
    }
    else{
      this.costValueselectedVal = '25'
    }
    this.OptionalCoveragesForm.get([CExtRpCcOptions]).setValue(this.costValueselectedVal);
  }

  contractorEnhancerValChange(ContractorEnhancerCheck) {
    if(ContractorEnhancerCheck == true){
      this.ContractorEnhancerselectedVal = 'Y'
    }
    else{
      this.ContractorEnhancerselectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([cContraEnhaEndorsement]).setValue(this.ContractorEnhancerselectedVal);
  }

  apartmentBuildingEndorsementValChange(flag) {
    if(flag == true){
      this.apartmentBuildingEndorsementSelectedVal = 'Y'
    }
    else{
     this.apartmentBuildingEndorsementSelectedVal = 'N'
    }
  }
  
  restaurantEndorsementValChange(flag) {
    if(flag == true){
      this.restaurantEndorsementSelectedVal = 'Y'
    }
    else{
      this.restaurantEndorsementSelectedVal = 'N'
    }
    //need mapping
  }

  
  enhancerEndorsementValChange(flag)
  {
    if(flag == true){
      if( this.enhancerPlusEndorsementSelectedVal == 'Y')
      {
       this.enhancerPlusEndorsementValChange(false);
      }
     this.enhancerEndorsementSelectedVal = 'Y'
    }
    else{
      this.enhancerEndorsementSelectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([enhancerEndorsement]).setValue(this.enhancerEndorsementSelectedVal);
  }

  enhancerPlusEndorsementValChange(flag)
  {
    if(flag == true){
      if( this.enhancerEndorsementSelectedVal == 'Y')
      {
       this.enhancerEndorsementValChange(false);
      }
      this.enhancerPlusEndorsementSelectedVal = 'Y'
    }
    else{
      this.enhancerPlusEndorsementSelectedVal = 'N'
    }
    this.OptionalCoveragesForm.get([cEnhancerPlusEndt]).setValue(this.enhancerPlusEndorsementSelectedVal);
  }

  contractorToolsValChange(contractorToolsCheck) {

    this.contractorToolsEquipementErrorFlag=false;
    if(contractorToolsCheck == true){
      this.contractorToolsSelectedVal = 'Y'
    }
    else{
      this.contractorToolsSelectedVal = 'N'
    }

    let bppValue=Number(this.dataService.BPClassifications.BusinssPersonalPropLmt);

    if(this.contractorToolsSelectedVal=='N'  && bppValue<5000)
    {
      this.contractorToolsEquipementErrorFlag=true;
      this.dataService.BPClassifications.BusinssPersonalPropLmt="5000";
     if(businessPersonalProp in this.dataService.BPBuilding)
      {this.dataService.BPBuilding.businessPersonalProp='Y'
      }
    }
    this.OptionalCoveragesForm.get([contrToolsEqpmntCov]).setValue(this.contractorToolsSelectedVal);
  }

  onFullTimeEmployeeChange(fullEmployeeCountvalue:string)
  {
    this.uiService.totalNumberOfEmployeeFailFlag=false;
    let total:number =parseInt(fullEmployeeCountvalue)+ parseInt(this.OptionalCoveragesForm.get([CNoEmpLess]).value);
    if(total>100)
    {
      //alert("Total No of employee should not be great than 100");
      this.OptionalCoveragesForm.get([CNoEmpMore]).setValue("");
      this.uiService.totalNumberOfEmployeeFailFlag=true;
   }
  }

  onPartTimeEmployeeChange(partEmployeeCountvalue:string)
  {
    this.uiService.totalNumberOfEmployeeFailFlag=false;
    let total:number=parseInt(partEmployeeCountvalue)+ parseInt(this.OptionalCoveragesForm.get([CNoEmpMore]).value);
    if(total>100)
    {
      //alert("Total No of employee should not be great than 100");
    partEmployeeCountvalue="";
    this.OptionalCoveragesForm.get([CNoEmpLess]).setValue("");
    this.uiService.totalNumberOfEmployeeFailFlag=true;
     }
  }

  validateContractorInstallationLimit(contractorInstallationLimit:number)
  {
    this.contractorInstallationLimitFailedFlag=false;
    this.contractorInstallationLimitRequiredBlanketToolsFailedFlag=false;
    if(contractorInstallationLimit)
    {
      if(this.uiService.isInsuredSmallContractorSelectedVal=="Y")
        {
       if(contractorInstallationLimit>50000)
      {
        this.contractorInstallationLimitFailedFlag=true;
        this.OptionalCoveragesForm.get(contractorConstants.contrInstalLimit).setErrors({ 'contrInstalLimit': true });
       
      }
    }   
    }
    else
    {
      if(this.OptionalCoveragesForm.get([contrToolsEqpmntCov]).value == 'Y')
      {
       this.OptionalCoveragesForm.get(contractorConstants.contrInstalLimit).setErrors({ 'contrInstalLimit': true });
       this.contractorInstallationLimitRequiredBlanketToolsFailedFlag=true;
      }
      }
  }

  validateBlanketTools(blanketToolsValue:number)
  {
    this.contractorInstallationLimitBlanketToolsRequiredFailedFlag=false;
    if(!blanketToolsValue)
    {
      if(this.OptionalCoveragesForm.get([contrToolsEqpmntCov]).value == 'Y')
      {
       this.OptionalCoveragesForm.get(contractorConstants.blanketLimit).setErrors({ 'blanketLimit': true });
       this.contractorInstallationLimitBlanketToolsRequiredFailedFlag=true;
      }
    }
  }
}

