import { Injectable } from "@angular/core";
import { ContractorRequest } from "../../interfaces/contractor-request.modal";
import { QuotePolicy } from "../../interfaces/quotepolicy.modal";
import { Insured } from "../../interfaces/insured.modal";
import { Producer } from "../../interfaces/producer.modal";
import { BusinessOwners } from "../../interfaces/bpowners.modal";
import { BPLocations } from "../../interfaces/bplocations.modal";
import { BPBuilding } from "../../interfaces/bpbuilding.modal";
import { BPInlineOBJ } from "../../interfaces/bpinlineobj.modal";
import { BPBuildingCoverage } from "../../interfaces/bpbuildingcoverage.modal";
import { BPClassifications } from "../../interfaces/bpclassifications.modal";
import { BPOptionalRTCCoverages } from "../../interfaces/bpoptionalrtccoverage.modal";
import { BPAdditionalInsureds } from "../../interfaces/bpadditionalinsureds.modal";
import { BPContractorsEndorsement } from "../../interfaces/bpcontractorsendorsement.modal";
import { BPGeneralQuestionsMU } from "../../interfaces/bpgeneralquestionsmu.modal";
import { DropdownService } from "./dropdown.service";
import { ContractorDataService } from "./contractor-data.service";
import { DataMappingService } from "./data-mapping.service";
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';
import { HabitationalDataService } from "./habitational-data.service";
import { HabitationalRequest } from "@app/interfaces/habitational/habitational-request.modal";
import { HabitationalQuotePolicy } from "@app/interfaces/habitational/habitational-quotepolicy.modal";
import { HabitationalBusinessOwners } from "@app/interfaces/habitational/habitational-bpowners.modal";
import { HabitationalBPBuilding } from "@app/interfaces/habitational/habitational-bpbuilding.modal";
import { HabitationalBPClassifications } from "@app/interfaces/habitational/habitational-bpclassifications.modal";
import { BPAdditionalInsured, BPContractorsEndorsementConst, BPOptionalRTCCoveragesConst } from "@app/contractor.constant";
import { HabitationalBPInlineOBJ } from "@app/interfaces/habitational/habitational-bpinlineobj.modal";
import { HabitationalBPGeneralQuestionsMU } from "@app/interfaces/habitational/habitational-bpgeneralquestionsmu.modal";

import { ConvenienceRequest } from "@app/interfaces/others/convenience.modal";
import { ProcessingRequest } from "@app/interfaces/others/processing.modal";
import { SelfRequest } from "@app/interfaces/others/self.modal";
import { MercantileRequest } from "@app/interfaces/others/mercantile.modal";
import { OfficeRequest } from "@app/interfaces/others/office.modal";
import { RestaurantRequest } from "@app/interfaces/others/restaurant.modal";
import { WholeSaleRequest } from "@app/interfaces/wholesale/wholesale-request.modal";

import { ConvenienceDataService } from "./convenience-data.service";
import { ProcessingDataService } from "./processing-data.service";
import { SelfDataService } from "./self-data.service";
import { RestaurantDataService } from "./restaurant-data.service";
import { MercantileDataService } from "./mercantile-data.service";
import { OfficeDataService } from "./office-data.service";
import { WholeSaleDataService } from "./wholesale-data.service";

export type BusinessRequest = ContractorRequest | HabitationalRequest | ConvenienceRequest | ProcessingRequest | SelfRequest | RestaurantRequest | MercantileRequest | OfficeRequest | WholeSaleRequest;

export type QuotePolicyRequest = QuotePolicy | HabitationalQuotePolicy | ConvenienceRequest["quotePolicy"] | ProcessingRequest["quotePolicy"] | SelfRequest["quotePolicy"] | RestaurantRequest["quotePolicy"] | MercantileRequest["quotePolicy"] | OfficeRequest["quotePolicy"] | WholeSaleRequest["quotePolicy"];

export type InsuredRequest = Insured | HabitationalRequest["quotePolicy"]["Insured"] | ConvenienceRequest["quotePolicy"]["Insured"] | ProcessingRequest["quotePolicy"]["Insured"] | SelfRequest["quotePolicy"]["Insured"] | RestaurantRequest["quotePolicy"]["Insured"] | MercantileRequest["quotePolicy"]["Insured"] | OfficeRequest["quotePolicy"]["Insured"] | WholeSaleRequest["quotePolicy"]["Insured"];

export type BusinessOwnersRequest = BusinessOwners | HabitationalBusinessOwners | ConvenienceRequest["quotePolicy"]["BusinessOwners"] | ProcessingRequest["quotePolicy"]["BusinessOwners"] | SelfRequest["quotePolicy"]["BusinessOwners"] | RestaurantRequest["quotePolicy"]["BusinessOwners"] | MercantileRequest["quotePolicy"]["BusinessOwners"] | OfficeRequest["quotePolicy"]["BusinessOwners"] | WholeSaleRequest["quotePolicy"]["BusinessOwners"];

export type BPBuildingRequest = BPBuilding | HabitationalBPBuilding | ConvenienceRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0] | ProcessingRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"] | SelfRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"] | RestaurantRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"] | MercantileRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"] | OfficeRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"] | WholeSaleRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"];

export type BPClassificationsRequest = BPClassifications | HabitationalBPClassifications | ConvenienceRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | ProcessingRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | SelfRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | RestaurantRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | MercantileRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | OfficeRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"] | WholeSaleRequest["quotePolicy"]["BusinessOwners"]["BPBuilding"][0]["BPClassifications"];

export type BPInlineOBJRequest = BPInlineOBJ | HabitationalBPInlineOBJ | ConvenienceRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | ProcessingRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | SelfRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | RestaurantRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | MercantileRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | OfficeRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"] | WholeSaleRequest["quotePolicy"]["BusinessOwners"]["BPLocations"][0]["BPInlineOBJ"];

export type BPGeneralQuestionsMURequest = BPGeneralQuestionsMU | HabitationalBPGeneralQuestionsMU | ConvenienceRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | ProcessingRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | SelfRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | RestaurantRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | MercantileRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | OfficeRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"] | WholeSaleRequest["quotePolicy"]["BusinessOwners"]["BPGeneralQuestionsMU"];

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public dropDownService: DropdownService, public contractorDataService: ContractorDataService, public dataMappingService: DataMappingService, public habitationalDataService: HabitationalDataService, public convenienceDataService: ConvenienceDataService, public processingDataService: ProcessingDataService, public selfDataService: SelfDataService, public restaurantDataService: RestaurantDataService, public mercantileDataService: MercantileDataService, public officeDataService: OfficeDataService, public wholeSaleDataService: WholeSaleDataService) {
    this.current_business_request = this.instantiateRequest();
  }

  private _current_business_request: BusinessRequest;
  private _current_business_response: any;

  private _marketplace_prefill_response: any;

  get current_business_response(): any {
    return this._current_business_response;
  }

  set current_business_response(value: any) {
    this._current_business_response = value;
  }

  get marketplace_prefill_response(): any {
    return this._marketplace_prefill_response;
  }

  set marketplace_prefill_response(value: any) {
    this._marketplace_prefill_response = value;
  }

  instantiateRequest(): BusinessRequest {
    switch (this.dataMappingService.getBusinessFlag()) {
      case BusinessAttribute.CONTRACTORS:
        return this.deepCopy(this.contractorDataService.contractorData);
      case BusinessAttribute.APARTMENTS:
        return this.deepCopy(this.habitationalDataService.habitationalData);
      case BusinessAttribute.CONVENIENCE:
          return this.deepCopy(this.convenienceDataService.convenienceData);
      case BusinessAttribute.PROCESSING_AND_SERVICE_RISKS:
          return this.deepCopy(this.processingDataService.processingData);
      case BusinessAttribute.SELF_STORAGE_FACILITIES:
          return this.deepCopy(this.selfDataService.selfData);
      case BusinessAttribute.RESTAURANTS:
          return this.deepCopy(this.restaurantDataService.restaurantData);
      case BusinessAttribute.MERCANTILE_RISKS:
          return this.deepCopy(this.mercantileDataService.mercantileData);
      case BusinessAttribute.OFFICE:
          return this.deepCopy(this.officeDataService.officeData);
      case BusinessAttribute.WHOLESALE_RISKS:
          return this.deepCopy(this.wholeSaleDataService.wholeSaleData);
      // add cases for other business flags here
      default:
        throw new Error("Unsupported business flag.");
    }
  }

  deepCopy(obj, visited = new WeakMap()) {
    // If obj is null or not an object, return it as is
    if (typeof obj !== 'object' || obj === null) {
      return obj;
    }
  
    // If obj is already visited, return the copied version
    if (visited.has(obj)) {
      return visited.get(obj);
    }
  
    let copy;
  
    // Handle arrays
    if (Array.isArray(obj)) {
      copy = [];
      visited.set(obj, copy);
  
      obj.forEach((item) => {
        copy.push(this.deepCopy(item, visited));
      });
    } else {
      // Handle objects
      copy = {};
      visited.set(obj, copy);
  
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = this.deepCopy(obj[key], visited);
        }
      }
    }
  
    return copy;
  }
  

  private saveToSessionStorage() {
    sessionStorage.setItem(
      "current_business_request",
      JSON.stringify(this._current_business_request)
    );
  }

  get current_business_request(): BusinessRequest {
    if (!this._current_business_request) {
      const storedValue = sessionStorage.getItem("current_business_request");
      this._current_business_request = storedValue ? JSON.parse(storedValue) : this.instantiateRequest();
    }
    return this._current_business_request;
  }

  set current_business_request(value: BusinessRequest) {
    this._current_business_request = value;
    this.saveToSessionStorage();
  }

  get quote_policy(): QuotePolicyRequest {
    return this._current_business_request.quotePolicy;
  }

  set quote_policy(value: QuotePolicyRequest) {
    this._current_business_request.quotePolicy = value;
    this.saveToSessionStorage();
  }

  get insured(): InsuredRequest {
    return this._current_business_request.quotePolicy.Insured;
  }

  set insured(value: InsuredRequest) {
    this._current_business_request.quotePolicy.Insured = value;
    this.saveToSessionStorage();
  }

  get producer(): Producer {
    return this._current_business_request.quotePolicy.Producer;
  }

  set producer(value: Producer) {
    this._current_business_request.quotePolicy.Producer = value;
    this.saveToSessionStorage();
  }

  get businessOwners(): BusinessOwnersRequest {
    return this._current_business_request.quotePolicy.BusinessOwners;
  }

  set businessOwners(value: BusinessOwnersRequest) {
    this._current_business_request.quotePolicy.BusinessOwners = value;
    this.saveToSessionStorage();
  }

  get BPLocations(): any {
    return this._current_business_request.quotePolicy.BusinessOwners.BPLocations;
  }

  set BPLocations(value: BPLocations) {
    this._current_business_request.quotePolicy.BusinessOwners.BPLocations[0] = value;
    this.saveToSessionStorage();
  }

  get BPBuilding(): any {
    return this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0];
  }

  set BPBuilding(value: any) {
    this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0] = value;
    this.saveToSessionStorage();
  }

  get BPInlineOBJ(): BPInlineOBJRequest {
    return this._current_business_request.quotePolicy.BusinessOwners.BPLocations[0]
      .BPInlineOBJ;
  }

  set BPInlineOBJ(value: BPInlineOBJRequest) {
    this._current_business_request.quotePolicy.BusinessOwners.BPLocations[0].BPInlineOBJ =
      value;
    this.saveToSessionStorage();
  }

  get BPBuildingCoverage(): BPBuildingCoverage {
    return this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0]
      .BPBuildingCoverage;
  }

  set BPBuildingCoverage(value: BPBuildingCoverage) {
    this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].BPBuildingCoverage =
      value;
    this.saveToSessionStorage();
  }

  get BPClassifications(): BPClassificationsRequest {
    return this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0]
      .BPClassifications;
  }

  set BPClassifications(value: BPClassificationsRequest) {
    this._current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].BPClassifications =
      value;
    this.saveToSessionStorage();
  }

  get BPOptionalRTCCoverages(): BPOptionalRTCCoverages {
    return this._current_business_request.quotePolicy.BusinessOwners
      [BPOptionalRTCCoveragesConst];
  }

  set BPOptionalRTCCoverages(value: BPOptionalRTCCoverages) {
    this._current_business_request.quotePolicy.BusinessOwners[BPOptionalRTCCoveragesConst] =
      value;
    this.saveToSessionStorage();
  }

  get BPAdditionalInsured(): BPAdditionalInsureds {
    if(BPAdditionalInsured in this._current_business_request.quotePolicy.BusinessOwners){
      return this._current_business_request.quotePolicy.BusinessOwners.BPAdditionalInsured;
    }else{
      return undefined;
    }
  }

  set BPAdditionalInsured(value: BPAdditionalInsureds) {
    if(BPAdditionalInsured in this._current_business_request.quotePolicy.BusinessOwners){
      this._current_business_request.quotePolicy.BusinessOwners.BPAdditionalInsured =
      value;
      this.saveToSessionStorage();
    }
  }

  get BPContractorsEndorsement(): BPContractorsEndorsement {
    return this._current_business_request.quotePolicy.BusinessOwners
      [BPContractorsEndorsementConst];
  }

  set BPContractorsEndorsement(value: BPContractorsEndorsement) {
    this._current_business_request.quotePolicy.BusinessOwners[BPContractorsEndorsementConst] =
      value;
    this.saveToSessionStorage();
  }

  get BPGeneralQuestionsMU(): BPGeneralQuestionsMURequest {
    return this._current_business_request.quotePolicy.BusinessOwners
      .BPGeneralQuestionsMU;
  }

  set BPGeneralQuestionsMU(value: BPGeneralQuestionsMURequest) {
    this._current_business_request.quotePolicy.BusinessOwners.BPGeneralQuestionsMU =
      value;
    this.saveToSessionStorage();
  }
}
