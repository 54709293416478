import { Injectable } from '@angular/core';
import { ProducerCode } from '@app/contractor.constant';
import { LexisNexisRequest } from '@app/interfaces/lexisnexis-request.modal';
import { AgentDataMappingService } from './agent-data-mapping.service';
import { DataMappingService } from './data-mapping.service';
import { DataService } from './data.service';
import { LexisNexisService } from './lexisnexis.service';
import { PrefillService } from './prefill.service';
import { UiService } from './ui.service';

@Injectable({
    providedIn: 'root'
})
export class AgentConfirmationService {
    confirAgentInfoModalFlag: boolean = false;

    constructor(public dataMappingService: DataMappingService, public dataService: DataService, public agentDataMappingService: AgentDataMappingService, public prefillService: PrefillService, public lexisNexisService: LexisNexisService, public uiService:UiService) {}

    confirmAgentInfo() {
        this.confirAgentInfoModalFlag = false;
        console.log(this.dataMappingService.currentForm,"current form");
        
        this.agentDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
        this.prefillService.prefillData('2', 'property-details', this.dataService);
        this.getNFCRAScore();
        console.log(this.dataService.current_business_request,"current business request")
    }

    getNFCRAScore() {
        let natureOfBusiness = this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding[0].PropertyType;
        let producerId = this.dataMappingService.currentForm.get("Producer." + ProducerCode).value
        if("Apartments" != natureOfBusiness && this.dataMappingService.firstName != "" && this.dataMappingService.lastName != "") {
            let payload: LexisNexisRequest = this.prepareLexisNexisRequest();
            this.lexisNexisService.orderScore(payload, producerId).subscribe(
                resp => {
                    if(resp.apiOutput.statusCode == "200") {
                        this.dataMappingService.nfcraScore = resp.apiOutput.result.score;
                    }
                },
                error => {
                    console.log(error);
                    this.dataMappingService.nfcraScore = "";
                }
            );
        }
    }

    prepareLexisNexisRequest(): LexisNexisRequest {
        let addressLine1 = this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[0].Address.Line1;
        let index = addressLine1.indexOf(" ");
        let house = "";
        let line1 = addressLine1;
        if(index > 0) {
            house = addressLine1.substring(0, index);
            line1 = addressLine1.substring(index).trim();
        }
        let adrs=[{
            type: "residence",
            apartment: "",
            city: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[0].Address.City,
            country: "",
            county: "",
            house: house,
            province: "",
            state: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[0].Address.StateCode,
            line1: line1,
            line2: "",
            unit: "",
            zip: {
                code: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[0].Address.ZipCode
            }
        }]
        if(this.uiService.addedLocations.length>1){
            let addressLine2 = this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[1].Address.Line1;
            let index2 = addressLine2.indexOf(" ");
            let house2 = "";
            let line2 = addressLine2;
            if(index2 > 0) {
                house2 = addressLine2.substring(0, index2);
                line2 = addressLine2.substring(index2).trim();
            }
            let x={
                type: "residence",
                apartment: "",
                city: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[1].Address.City,
                country: "",
                county: "",
                house: house2,
                province: "",
                state: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[1].Address.StateCode,
                line1: line2,
                line2: "",
                unit: "",
                zip: {
                    code: this.dataService.current_business_request.quotePolicy.BusinessOwners.BPLocations[1].Address.ZipCode
                }
            }
            adrs.push(x);
        }

        let payload: LexisNexisRequest = {
            subjects: [
                {
                    addresses: adrs,
                    contactDetails: [
                        {
                            description: "",
                            number: ""
                        }
                    ],
                    currentlyInsured: "",
                    dateOfBirth: null,
                    education: "",
                    email: "",
                    first: this.dataMappingService.firstName,
                    gender: "Unknown",
                    incidentInformation: "",
                    last: this.dataMappingService.lastName,
                    licenses: [],
                    middle: "",
                    occupation: "",
                    prefix: "",
                    ssn: "",
                    suffix: "",
                    type: "primary",
                    yearsContinuousInsured: "",
                    yearsLicensed: ""
                }
            ]
        };

        return payload;
    }
}