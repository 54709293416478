import { Component, OnInit } from '@angular/core';
import {PAGE_TITLES_AND_CONTENT} from '../constants'
import { Router } from '@angular/router';
@Component({
  selector: 'verti-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number;
  staticData;
  
  constructor(public router: Router) { 
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.staticData = PAGE_TITLES_AND_CONTENT;
  }

}
