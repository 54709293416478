import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from "rxjs";
import { of } from "rxjs";
import { catchError } from "rxjs";
import { AuthService } from '../../core/services/auth.service';
import { login } from '../../interfaces/login.modal';
import { ActivatedRoute } from '@angular/router';
import { SecurityService } from '@app/core/services/security.service';

@Component({
    templateUrl: './sso-login.component.html',
})
export class SSOLoginComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private securityService: SecurityService) { }

    ngOnInit(): void {
        let encryptedToken: string;
        let coverallToken: string;
        this.route.queryParams.subscribe(params => {
            encryptedToken = params.newToken;
            coverallToken = params.oldToken;
        });
        sessionStorage.setItem("coverallToken", coverallToken);

        if(encryptedToken) {
            this.securityService.decrypt(encryptedToken).pipe(
                map(resp => {
                    if(resp.apiOutput.statusCode == "200") {
                        const result = resp.apiOutput.result;
                        const username = result.substring(result.indexOf("=") + 1, result.indexOf("&"));
                        const password = result.substring(result.lastIndexOf("=") + 1);
                
                        const loginInput: login = { username, password };
                        this.authService.initiateLogin(loginInput).subscribe(
                            isLoggedIn => {
                                if(isLoggedIn) {
                                    // Navigate to the agent screen.
                                    this.router.navigate([`agent`]);
                                  } else {
                                    sessionStorage.clear();
                                    this.router.navigate([`login`]);
                                  }
                            },
                            error => {
                                console.log(error);
                                sessionStorage.clear();
                                this.router.navigate([`login`]);
                            }
                        );
                    } else {
                        sessionStorage.clear();
                        this.router.navigate([`login`]);
                    }
                }),
                catchError(error => {
                    console.log(error);
                    sessionStorage.clear();
                    this.router.navigate([`login`]);
                    return of();
                })
            ).subscribe();
        }
    }
}