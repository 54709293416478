import { Injectable } from '@angular/core';
import { Address } from "@app/interfaces/address.modal";
import { DataMappingService } from "@app/core/services/data-mapping.service";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { DataService } from "./data.service";
import { BusinessName, InsuredType, City, Line1, Phone1, StateCode, StateDesc, ZipCode, EMail, BusinessOwnersConst, BPBuildingConst, ClassCode, classificationDesc, AddressConst, BPLocationsConst, InsuredConst, ContactConst, yearsOfOperation, applicantLawsuit, cReconstrConstr, resAuRepMnufcProcOcup, recentPolCancld, otherLocations, cCompPolicy, RiseToClaim, SwimmingPoolExists, RecFacilities, PlayGroundExists, foreignProducts, CRecQues, numberOfVeterinarian, Nfpa96, isMailingLocationAddressSame, isInsuredSmallContractor, applicantSubcontract, snowPlowing } from "../../contractor.constant";
import { TrilliumService } from "../../core/services/address.service";
import CoastalZipcodes from '../../../assets/json/CoastalZipcodes.json';
import ClassCodesDescriptions from "../../../assets/json/ClassCodesDescription.json";
import { MarketplaceService } from './marketplace.service';
import { MarketplaceRequest } from '@app/interfaces/marketplace-request.modal';
import { LoaderService } from '@app/loader.service';
import { BusinessAttribute } from '../enums/business-attributes.enum';
import { ClassCodeModal } from '@app/interfaces/class-code-modal';
import jp from 'jsonpath';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

type Addresses = {
  city?: string,
  county?: string,
  state?: string,
  zipcode?: string
}

@Injectable({
  providedIn: 'root'
})
export class UiService {
  isZipLoading: boolean = false;
  stateWrongFlag: boolean = false;
  mailZipWrongFlag: boolean = false;
  isZipMailLoading: boolean = false;
  declineFlag: boolean = false;
  SqftDeclineFlag:boolean = false;
  SqftFlag:boolean = false;
  SqftDeclineBuildings = [];
  SqftAllBuildings = [];
  exitQuoteFlag: boolean = false;
  quotedWithReferrals: boolean = false;
  quotedWithoutReferrals: boolean = false;
  hasDataPrefilled: boolean = false;
  private _hasRatingErrors: boolean = false;
  selectedAddress: Addresses = {};
  selectedMailAddress: Addresses = {};
  filteredAddressDatas: Array<Addresses> = [];
  filteredMailAddressDatas: Array<Addresses> = [];
  selectedClassCodeDescription: ClassCodeModal;
  multiClassCodesDescriptions: Array<ClassCodeModal> = [];
  hasMultipleLocations: boolean = false;
  waiverOfSubFlag = '';
  onPrimNonContributoryFlag = '';
  onBlanketOngoingFlag = '';
  onBlanketCompletedFlag = '';
  condoDOSelectedFlag = '';
  veterinarianEndorsementSelectedFlag = '';
  liquorLiabilityCoverageSelectedFlag = '';
  liquorLiabilityMercantileCoverageSelectedFlag = '';
  processingServiceBarBeautSelectedFlag = '';
  processingServicePhotoSelectedFlag = '';
  processingServiceFuneralSelectedFlag = '';
  coastalZip: boolean;
  public referralIndicator: string;
  public referralMessages: Array<string> = [];
  public sicCode: string;
  public naicsCode: string;
  public salesRevenue: number;
  public habScore: string;
  public dbScore: string;
  public declineReason: string;
  public quoteNumber: string;
  public ratingErrorMessage: string;
  public smallContractorInd: string;
  public blancketFields:boolean = false;
  public agentFirstVisit:boolean=true;
  public uniqueIdCreated:boolean=false;
  public uniqueId="";
  declinationTitle='Thank you for this opportunity however your submission does not meet our eligibility guidelines for at least the following reason';


  selectedNormalizedAddress: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  selectedNormalizedMailAddress: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  public CopyLocationModalFlage: boolean = false;
  public ClassCodeAlertModal: boolean = false;
  public BlanketInfoAlert: boolean = false;


  public bpLocationNormalizedAddressFlag: boolean = false;
  public bpLocationReEnterNormalizedAddressFlag: boolean = false;
  public agentScreenReEnteredFlag: boolean = false;
  public bpLocationNormalizedAddressNotMatchFlag: boolean = false;
  public selectedNormalizedAddressFlag: boolean = false;
  public addressNormalizedButtonFlag: boolean = false;
  public totalNumberOfEmployeeFailFlag: boolean = false;
  public multiClassCodesModalFlag: boolean = false;
  public numberOfVeterinarianFailFlag: boolean = false;
  public convenience09331Flag: boolean = false;
  public mercantileRiskBewargeGorceryStoreFlag: boolean = false;
  public noHitModalFlag: boolean = false;

  public isDateAfter: boolean = false;

  public isLiabilityPayrollNotValid: boolean = false;

  public builidingCoverageFlag: boolean = false;

  public bpLocationNormalizedMailAddressFlag: boolean = false;
  public bpLocationReEnterNormalizedMailAddressFlag: boolean = false;
  public bpLocationNormalizedMailAddressNotMatchFlag: boolean = false;
  public selectedNormalizedMailAddressFlag: boolean = false;
  public mailAddressNormalizedButtonFlag: boolean = false;

  //uw required boolean flag
  public uwBuildUnderConstructionReConstructionRequiredFlag = false;
  public uwBusinessPremisesRequiredFlag = false;
  public uwRecentPolicyCancelledOrRenewedRequiredFlag = false;
  public uwApplicantOthersCoverageNotRequestRequiredFlag = false;
  public uwApplicantLawSuitRequiredFlag = false;
  public uwMapfreCompanionPolicyRequiredFlag = false;
  public uwBoardMemberKnowledgeAboutClaimRequiredFlag = false;
  public uwPremiseSwimmingPoolRequiredFlag = false;
  public uwPremiseRecreationFacilityRequiredFlag = false;
  public uwPremisePlaygroundRequiredFlag = false;
  public uwForeignProductImportRequiredFlag = false;
  public uwInsuredComplyNFPA96RequiredFlag = false;
  public uwReenteredRequiredFlag = false;
  public natureOfBusines: string;

  public condo69145Flag: boolean = false;
  public condoOffice60999Flag: boolean = false;
  public classcodeOffice64181Flag: boolean = false;
  public processingProcessService7195271332Flag: boolean = false;
  public processingBarberService71332Flag: boolean = false;
  public processingBeauticianService71952Flag: boolean = false;
  public processingFuneralService71865Flag: boolean = false;
  public processingPhotoService71899Flag: boolean = false;

  public totalNumberOfUnitsValidFlag: boolean = false;

  public basicBuildingCoverLimitFailedFlag: boolean = false;
  public basicbusinessPersonalPropertyLimitFailedFlag: boolean = false;

  public agentMailingAddressSameRequiredFlag: boolean = false;

  public isInsuredSmallContractorSelected: string = '';
  public isInsuredSmallContractorRequiredFlag: boolean = false;
  public isInsuredSmallContractorSelectedVal: string = '';

  public applicantSubcontractRequiredFlag: boolean = false;

  public snowPlowingSelected: boolean = false;
  public snowPlowingRequiredFlag: boolean = false;

  public yearofConstructionCheck: boolean = false;

  public LocationOneyearofConstructionCheck: boolean = false;
  public LocationTwoyearofConstructionCheck: boolean = false;
  public LocationThreeyearofConstructionCheck: boolean = false;

  public LocationOneYearofConstruction=[{buildingCheck:false},{buildingCheck:false},{buildingCheck:false}];
  public LocationTwoYearofConstruction=[{buildingCheck:false},{buildingCheck:false},{buildingCheck:false}];
  public LocationThreeYearofConstruction=[{buildingCheck:false},{buildingCheck:false},{buildingCheck:false}];

  public contractorToolsEquipmentBPP5000RequiredFlag: boolean = false;

  public InteractionType:number= 1;


  //for multi locations
  workingLocation: any;
  addedLocations: any = [    {
    LocationAddress: "Line1",
    LocationCity: "City",
    LocationState: "StateDesc",
    LocationZipCode: "ZipCode",
    LocationCoastal: "coastal1",
    classificationDesc: "classificationDesc",
    BusinessDesc: "BusinessDesc",
    ClassCode: "ClassCode",
    smallContractor:"cSmallContractor",
    ErrorMessage: false
  }];

  addedCoastals = [];
  mailingAddressPopupList = [];
  confirmedMailingLocation;
  cMailingAddress=new Subject();
  LocationTabs=new Subject();
  RemoveDeclinedBuildings = new Subject();
  MoveNextTab = new Subject();
  removeLocation=new Subject();
  removeDeclinedLocationOnSquareFootageDeclination = new Subject();
  idToDeleteLocation;
  mailingLocationSelected;
  moreThan3LocationsRequiredFlag: boolean = false;
  moreThan3LocationselectedVal="";
  declinedLocations=[];
  backupBPBuilding;
  

  // Property screen location tabs
  LocationOneFlag: boolean = true;
  LocationTwoFlag: boolean = false;
  LocationThreeFlag: boolean = false;

  PropertyScreenFlag:boolean = false;

  LocationOneTab: boolean = true;
  LocationTwoTab: boolean = false;
  LocationThreeTab: boolean = false;

  LocationOneTabVisited: boolean = true;
  LocationTwoTabVisited: boolean = false;
  LocationThreeTabVisited: boolean = false;

  PropertyDetailsLocationOneForm: FormGroup;
  PropertyDetailsLocationTwoForm: FormGroup;
  PropertyDetailsLocationThreeForm: FormGroup;

  // LocationOneBuildings = [];
  // LocationTwoBuildings = [];
  // LocationThreeBuildings = [];
  //style height calculation
  select_city_modal_height: number;
  cities_group_height: number;
  select_city_modal_count: number;

  //style mail-address height calculation
  select_mail_city_modal_height: number;
  cities_mail_group_height: number;
  select_mail_city_modal_count: number;

  //style multiClassCode height calculation
  multiClassCode_modal_height: number;
  classCodes_group_height: number;
  multiClassCode_modal_count: number;

  bpLocationAddressObj: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  bpLocationNormalizedAddressObj: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  normalizedMailingAddressObj: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  get hasRatingErrors(): boolean {
    return this._hasRatingErrors;
  }

  set hasRatingErrors(value: boolean) {
    this._hasRatingErrors = value;
  }

  constructor(private router: Router, public dataService: DataService, private addressTrilliumService: TrilliumService, public dataMappingService: DataMappingService, private marketplaceService: MarketplaceService, private loaderService: LoaderService) { }

  setAddressByZipCode() {
    this.isZipLoading = false;
    this.stateWrongFlag = false;
    if (Object.keys(this.selectedAddress).length != 0) {
      this.dataMappingService.currentForm.get("BusinessOwners").get("BPLocations").get("Address").get(this.workingLocation.LocationCity).setValue(this.selectedAddress.city);
      this.isCoastalZipcode(this.dataMappingService.currentForm.get("BusinessOwners").get("BPLocations").get("Address").get([this.workingLocation.LocationZipCode]).value, this.selectedAddress.city);
      this.selectedAddress = {};
    }
    this.processBpLocationAddressNormalization(this.dataMappingService.currentForm);
  }

  setMailAddressByZipCode() {
    if (Object.keys(this.selectedMailAddress).length != 0) {
      this.dataMappingService.currentForm.get("Insured").get("Address").get("City").setValue(this.selectedMailAddress.city);
      this.dataMappingService.currentForm.get("Insured").get("Address").get("StateCode").setValue(this.selectedMailAddress.state);
      this.selectedMailAddress = {};
    }
    this.processBpLocationMailAddressNormalization(this.dataMappingService.currentForm);
  }

  isCoastalZipcode(zip, city) {

    if (CoastalZipcodes.filter(coastalZipcode => coastalZipcode.zip == zip && coastalZipcode.location?.toLowerCase() == city.toLowerCase()).length > 0) {
      this.coastalZip = true;
      this.dataMappingService.currentForm.get("BusinessOwners").get("BPLocations").get("Address").get([this.workingLocation.LocationCoastal]).setValue(true);
    } else {
      this.dataMappingService.currentForm.get("BusinessOwners").get("BPLocations").get("Address").get([this.workingLocation.LocationCoastal]).setValue(false);
      this.checkForCoastals();
      if (this.addedCoastals.length == 0) {
        this.coastalZip = false;
        this.dataMappingService.currentForm.get("isInsuredSmallContractor").setValue("");
      }

    }

    this.updateCostalzipMandatoryRequired(this.coastalZip)
  }

  updateCostalzipMandatoryRequired(condition: boolean) {
    this.updateFormControlValidatorBasedOnCondition(this.dataMappingService.currentForm.get("isInsuredSmallContractor"), condition);
  }

  updateFormControlValidatorBasedOnCondition = (formControl: AbstractControl, condition: boolean) => {
    if (condition) {
      formControl.setValidators([Validators.required]);
    } else {
      formControl.clearValidators();
    }
    formControl.updateValueAndValidity();
  }

  processBpLocationAddressNormalization = (myformGroup: FormGroup) => {
    // setting false for every address normalized modal popup
    this.bpLocationReEnterNormalizedAddressFlag = false;
    this.addressNormalizedButtonFlag = false;

    let BpLocationZipCode = myformGroup.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationZipCode).value;
    let BpLocationStateCode = myformGroup.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + StateCode).value;
    let BpLocationCity = myformGroup.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationCity).value;
    let BpLocationAddressLine1 = myformGroup.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationAddress).value;
    let BpLocationStateDesc = myformGroup.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationState).value;

    this.bpLocationAddressObj = {
      City: BpLocationCity,
      Line1: BpLocationAddressLine1,
      StateCode: BpLocationStateCode,
      ZipCode: BpLocationZipCode,
      StateDesc: BpLocationStateDesc
    }

    this.addressTrilliumService.normalizeAddress(this.bpLocationAddressObj).subscribe(resp => {
      if (resp.apiOutput.statusCode == "200") {
        if (resp.apiOutput.result.matchLevel == "0") {
          this.bpLocationNormalizedAddressObj.City = resp.apiOutput.result.cityName;
          this.bpLocationNormalizedAddressObj.Line1 = resp.apiOutput.result.address;
          this.bpLocationNormalizedAddressObj.StateCode = resp.apiOutput.result.regionName;
          this.bpLocationNormalizedAddressObj.ZipCode = resp.apiOutput.result.postalCode;
          this.bpLocationNormalizedAddressFlag = true;
        } else {
          this.bpLocationNormalizedAddressFlag = true;
          this.bpLocationNormalizedAddressNotMatchFlag = true;
        }
      }
    });
  }

  setNormalizedAddress = (myformGroup: FormGroup) => {
    if (!this.bpLocationReEnterNormalizedAddressFlag && (this.selectedNormalizedAddressFlag)) {

      if (this.selectedNormalizedAddress.ZipCode.indexOf("-") >= 0) {
        this.selectedNormalizedAddress.ZipCode = this.selectedNormalizedAddress.ZipCode.substring(0, this.selectedNormalizedAddress.ZipCode.indexOf("-"));
      }

      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationZipCode).setValue(this.selectedNormalizedAddress.ZipCode);
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationCity).setValue(this.selectedNormalizedAddress.City);
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + this.workingLocation.LocationAddress).setValue(this.selectedNormalizedAddress.Line1);
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + StateCode).setValue(this.selectedNormalizedAddress.StateCode);

      this.isCoastalZipcode(this.selectedNormalizedAddress.ZipCode, this.selectedNormalizedAddress.City);

      this.bpLocationNormalizedAddressFlag = false;
      this.selectedNormalizedAddressFlag = false;
      this.addressNormalizedButtonFlag = false;
    } else {
      //Clearing the form values for re-entering the data
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + ZipCode).setValue("");
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + City).setValue("");
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + Line1).setValue("");
    }
  }

  processBpLocationMailAddressNormalization = (myformGroup: FormGroup) => {
    // setting false for every address normalized modal popup
    this.bpLocationReEnterNormalizedMailAddressFlag = false;
    this.mailAddressNormalizedButtonFlag = false;

    console.log(myformGroup.get("Insured.Address." + ZipCode).value);
    let mailingZipCode = myformGroup.get("Insured.Address." + ZipCode).value;
    let mailingStateCode = myformGroup.get("Insured.Address." + StateCode).value;
    let mailingCity = myformGroup.get("Insured.Address." + City).value;
    let mailingLine1 = myformGroup.get("Insured.Address." + Line1).value;
    let mailingStateDesc = this.dataMappingService.getStateDescByCode(mailingStateCode);;

    this.bpLocationAddressObj = {
      City: mailingCity,
      Line1: mailingLine1,
      StateCode: mailingStateCode,
      ZipCode: mailingZipCode,
      StateDesc: mailingStateDesc
    }

    this.addressTrilliumService.normalizeAddress(this.bpLocationAddressObj).subscribe(resp => {
      if (resp.apiOutput.statusCode == "200") {
        if (resp.apiOutput.result.matchLevel == "0") {
          this.normalizedMailingAddressObj.City = resp.apiOutput.result.cityName;
          this.normalizedMailingAddressObj.Line1 = resp.apiOutput.result.address;
          this.normalizedMailingAddressObj.StateCode = resp.apiOutput.result.regionName;
          this.normalizedMailingAddressObj.ZipCode = resp.apiOutput.result.postalCode;
          this.bpLocationNormalizedMailAddressFlag = true;
        } else {
          this.bpLocationNormalizedMailAddressFlag = true;
          this.bpLocationNormalizedMailAddressNotMatchFlag = true;
        }
      }
    });
  }

  setNormalizedMailAddress = (myformGroup: FormGroup) => {
    if (!this.bpLocationReEnterNormalizedMailAddressFlag && (this.selectedNormalizedMailAddressFlag)) {

      if (this.selectedNormalizedMailAddress.ZipCode.indexOf("-") >= 0) {
        this.selectedNormalizedMailAddress.ZipCode = this.selectedNormalizedMailAddress.ZipCode.substring(0, this.selectedNormalizedMailAddress.ZipCode.indexOf("-"));
      }

      this.dataMappingService.currentForm.get("Insured.Address." + ZipCode).setValue(this.selectedNormalizedMailAddress.ZipCode);
      this.dataMappingService.currentForm.get("Insured.Address." + City).setValue(this.selectedNormalizedMailAddress.City);
      this.dataMappingService.currentForm.get("Insured.Address." + Line1).setValue(this.selectedNormalizedMailAddress.Line1);
      this.dataMappingService.currentForm.get("Insured.Address." + StateCode).setValue(this.selectedNormalizedMailAddress.StateCode);

      // Invoke Marketplace Prefill Interaction 1 service
      this.callMarketplace('1B', this.smallContractorInd, false);

      this.bpLocationNormalizedMailAddressFlag = false;
      this.selectedNormalizedMailAddressFlag = false;
      this.mailAddressNormalizedButtonFlag = false;
    } else {
      //Clearing the form values for re-entering the data
      this.dataMappingService.currentForm.get("Insured.Address." + ZipCode).setValue("");
      this.dataMappingService.currentForm.get("Insured.Address." + City).setValue("");
      this.dataMappingService.currentForm.get("Insured.Address." + Line1).setValue("");
      this.dataMappingService.currentForm.get("Insured.Address." + StateCode).setValue("");
    }
  }

  callMarketplace(interaction, smallContractor, isMailingAddressSameAsLocation: boolean) {
    let payload: MarketplaceRequest = this.prepareMarketplaceRequest(smallContractor, isMailingAddressSameAsLocation, interaction);
    this.loaderService.isLoadingFromStorage = true;
    let loaderText = "Please wait...";
    if ("1B" == interaction) {
      loaderText = "Just a few seconds, we’re gathering data to help you complete the application";
    }
    this.loaderService.setLoaderText(loaderText);
    this.marketplaceService.prefill(payload, interaction).subscribe(
      resp => {
        if(resp.apiOutput.statusCode == "200") {
          if("1A" == interaction && resp.apiOutput.result.response && resp.apiOutput.result.response.eventResponses) {
            
            let declineInd=this.checkForDeclineInd(resp);
            // let declineInd = jp.query(resp, "$.apiOutput.result.response.eventResponses.eventResponse.attributes.attribute[?(@.name == 'DECLINE_IND')].value")[0];
            if("Y" == declineInd) {
              this.loaderService.isLoadingFromStorage = false;
              this.declineFlag = true;
              this.declinationTitle='Thank you for this opportunity however the following location(s) do(es) not meet our eligibility guidelines for at least the following reasons'
              // this.declineReason = jp.query(resp, "$.apiOutput.result.response.eventResponses.eventResponse.attributes.attribute[?(@.name == 'DECLINE_MESSAGE')].value")[0];
            } else {
              this.loaderService.isLoadingFromStorage = false;
            }
          } else {
            if (resp.apiOutput.result.message && !resp.apiOutput.result.message.response) {
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.BoFirstName').setValue(jp.query(resp, "$.apiOutput.result.message.data.parties.party[?(@.partyIdentifier == 'PrincipalOwner')].person.person[0].firstName")[0]);
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.BoLastName').setValue(jp.query(resp, "$.apiOutput.result.message.data.parties.party[?(@.partyIdentifier == 'PrincipalOwner')].person.person[0].lastName")[0]);
              this.dataMappingService.currentForm.get(InsuredConst + '.' + InsuredType).setValue(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.businessFacts.businessType);

              let yearsInBusiness = String(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.businessFacts.yearsInBusiness).toLowerCase();
              yearsInBusiness = (yearsInBusiness == "null") ? "" : yearsInBusiness;
              this.dataMappingService.currentForm.get(InsuredConst + '.' + yearsOfOperation).setValue(yearsInBusiness);

              let insuredmail = jp.query(resp, "$.apiOutput.result.message.data.parties.party[0].partyCommunications.partyCommunication[?(@.communicationIdentity.communicationIdentifier == 'Email')].communicationIdentity.communicationValue");
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.' + EMail).setValue(insuredmail[0]);

              let phoneNumber = jp.query(resp, "$.apiOutput.result.message.data.parties.party[0].partyCommunications.partyCommunication[?(@.communicationIdentity.communicationIdentifier == 'Phone')].communicationIdentity.communicationValue");
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.' + Phone1).setValue(phoneNumber[0]);

              this.salesRevenue = Number(resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.annualSales.annualSales[0].salesAmt);
              this.sicCode = resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.sicCodes[0].code;
              this.naicsCode = resp.apiOutput.result.message.data.agreement.policy.policy[0].insurableObjects[0].insurableObject.business.naicsCodes[0].code;

              if (ClassCodesDescriptions.filter(classCodeDescription => classCodeDescription.siccode == this.sicCode && classCodeDescription.naicscode == this.naicsCode).length == 1) {
                this.selectedClassCodeDescription = ClassCodesDescriptions.filter(classCodeDescription => classCodeDescription.siccode == this.sicCode && classCodeDescription.naicscode == this.naicsCode)[0];
                this.populateClassCodeAndDescription();
              } else if (ClassCodesDescriptions.filter(classCodeDescription => classCodeDescription.siccode == this.sicCode && classCodeDescription.naicscode == this.naicsCode).length > 1) {
                this.multiClassCodesDescriptions = ClassCodesDescriptions.filter(classCodeDescription => classCodeDescription.siccode == this.sicCode && classCodeDescription.naicscode == this.naicsCode);
                this.loaderService.isLoadingFromStorage = false;
                this.multiClassCodesModalFlag = true;
              } else {
                this.multiClassCodesModalFlag = false;
                this.loaderService.isLoadingFromStorage = false;
              }
            } else {
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.BoFirstName').setValue("");
              this.dataMappingService.currentForm.get(InsuredConst + '.' + ContactConst + '.BoLastName').setValue("");
              this.dataMappingService.currentForm.get(InsuredConst + '.' + InsuredType).setValue("");
              this.dataMappingService.currentForm.get(InsuredConst + '.' + yearsOfOperation).setValue("");

              this.multiClassCodesModalFlag = false;
              this.loaderService.isLoadingFromStorage = false;
              this.noHitModalFlag = true;
            }
          }
        }
      },
      error => {
        console.log(error);
        this.loaderService.isLoadingFromStorage = false;
        this.multiClassCodesModalFlag = false;
      }
    );
  }

  populateClassCodeAndDescription() {

    if (Object.keys(this.selectedClassCodeDescription).length != 0) {
      this.dataMappingService.originalClassCodeObj.classcode = this.selectedClassCodeDescription.classcode;
      this.dataMappingService.originalClassCodeObj.description = this.selectedClassCodeDescription.description;
      this.dataMappingService.originalClassCodeObj.propertytype = this.selectedClassCodeDescription.propertytype;
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + ClassCode).setValue(this.selectedClassCodeDescription.classcode);
      this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + classificationDesc).setValue(this.selectedClassCodeDescription.description);
      if(this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'ClassCode2')){
        this.dataMappingService.originalClassCodeObj.classcode2 = this.selectedClassCodeDescription.classcode;
        this.dataMappingService.originalClassCodeObj.description2 = this.selectedClassCodeDescription.description;
        this.dataMappingService.originalClassCodeObj.propertytype2 = this.selectedClassCodeDescription.propertytype;
        this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'ClassCode2').setValue(this.selectedClassCodeDescription.classcode);
        this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'classificationDesc2').setValue(this.selectedClassCodeDescription.description);
      }
      if(this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'ClassCode3')){
        this.dataMappingService.originalClassCodeObj.classcode3 = this.selectedClassCodeDescription.classcode;
        this.dataMappingService.originalClassCodeObj.description3 = this.selectedClassCodeDescription.description;
        this.dataMappingService.originalClassCodeObj.propertytype3 = this.selectedClassCodeDescription.propertytype;
        this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'ClassCode3').setValue(this.selectedClassCodeDescription.classcode);
        this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPBuildingConst + '.' + 'classificationDesc3').setValue(this.selectedClassCodeDescription.description);
      }

      this.natureOfBusines = this.selectedClassCodeDescription.propertytype;
      this.dataMappingService.setBusinessFlag(this.selectedClassCodeDescription.propertytype);
      if(this.agentFirstVisit){
        this.dataService.current_business_request = this.dataService.instantiateRequest();

      }

      this.multiClassCodesModalFlag = false;
      this.loaderService.isLoadingFromStorage = false;
    } else {
      this.multiClassCodesModalFlag = false;
      this.loaderService.isLoadingFromStorage = false;
    }
  }

  prepareMarketplaceRequest(smallContractor, isMailingAddressSameAsLocation: boolean, interaction) {
    let mailingStreet = this.dataMappingService.currentForm.get(InsuredConst + '.' + AddressConst + '.' + Line1).value;
    let mailingCity = this.dataMappingService.currentForm.get(InsuredConst + '.' + AddressConst + '.' + City).value;
    let mailingState = this.dataMappingService.currentForm.get(InsuredConst + '.' + AddressConst + '.' + StateCode).value;
    let mailingZip = this.dataMappingService.currentForm.get(InsuredConst + '.' + AddressConst + '.' + ZipCode).value
    let geographicLocationArray = [];
    if ("1A" == interaction) {
      this.addedLocations.forEach((d, index) => {
        let x = {
          geographicLocationIdentifier: index + 1,
          locationAddress: {
            line1Address: this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + d.LocationAddress).value,
            cityName: this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + d.LocationCity).value,
            postalCode: this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + d.LocationZipCode).value,
            state: {
              stateCode: this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + StateCode).value,
              stateName: this.dataMappingService.currentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + d.LocationState).value
            }
          }

        }
        geographicLocationArray.push(x);
      })
    }

    let payload: MarketplaceRequest = {
      message: {
        data: {
          geographicLocations: {
            geographicLocation: geographicLocationArray
          },
          agreement: {
            policy: {
              policy: {
                insurableObjects: [
                  {
                    insurableObject: {
                      business: {
                        attributes: {
                          attribute: [
                            {
                              name: "smallContractorInd",
                              value: smallContractor
                            }
                          ]
                        },
                        businessHeader: {
                          bin: "",
                          businessName: this.dataMappingService.currentForm.get(InsuredConst + '.' + BusinessName).value,
                          address: {
                            street: mailingStreet,
                            city: mailingCity,
                            state: mailingState,
                            zip: mailingZip,
                            zipExtension: ""
                          },
                          dbaNames: ""
                        }
                      }
                    }
                  }
                ]
              }
            }
          }
        }
      }
    };
    return payload;
  }

  validateUWRequiredDisplay() {
    if (this.dataMappingService.currentForm.get(cReconstrConstr).value == '') {
      this.uwBuildUnderConstructionReConstructionRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get(resAuRepMnufcProcOcup).value == '') {
      this.uwBusinessPremisesRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get(recentPolCancld).value == '') {
      this.uwRecentPolicyCancelledOrRenewedRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get(recentPolCancld).value == '') {
      this.uwRecentPolicyCancelledOrRenewedRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get(otherLocations).value == '') {
      this.uwApplicantOthersCoverageNotRequestRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get(cCompPolicy).value == '') {
      this.uwMapfreCompanionPolicyRequiredFlag = true;
    }
    if (this.natureOfBusines == BusinessAttribute.CONTRACTORS) {
      if (this.dataMappingService.currentForm.get(applicantLawsuit).value == '') {
        this.uwApplicantLawSuitRequiredFlag = true;
      }
    }
    if ((this.dataMappingService.businessFlags[BusinessAttribute.APARTMENTS] && this.condo69145Flag) || (this.dataMappingService.businessFlags[BusinessAttribute.OFFICE] && this.condoOffice60999Flag)) {
      if (this.dataMappingService.currentForm.get(RiseToClaim).value == '') {
        this.uwBoardMemberKnowledgeAboutClaimRequiredFlag = true;
      }

      if (this.dataMappingService.currentForm.get(SwimmingPoolExists).value == '') {
        this.uwPremiseSwimmingPoolRequiredFlag = true;
      }

      if (this.dataMappingService.currentForm.get(CRecQues).value == '') {
        this.uwPremiseRecreationFacilityRequiredFlag = true;
      }

      if (this.dataMappingService.currentForm.get(PlayGroundExists).value == '') {
        this.uwPremisePlaygroundRequiredFlag = true;
      }
    }
    if (this.dataMappingService.businessFlags[BusinessAttribute.WHOLESALE_RISKS] || this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {

      if (this.dataMappingService.currentForm.get(foreignProducts).value == '') {
        this.uwForeignProductImportRequiredFlag = true;
      }
    }

    if (this.dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS]) {

      if (this.dataMappingService.currentForm.get(Nfpa96).value == '') {
        this.uwInsuredComplyNFPA96RequiredFlag = true;
      }
    }

  }

  validateAgentRequiredDisplay() {

    if (this.dataMappingService.currentForm.get(isMailingLocationAddressSame).value == '') {
      this.agentMailingAddressSameRequiredFlag = true;
    }

    if (this.coastalZip && this.dataMappingService.currentForm.get(isInsuredSmallContractor).value == '') {
      this.isInsuredSmallContractorRequiredFlag = true;
    }
    if (this.dataMappingService.currentForm.get("moreThan3Locations").value == '') {
      this.moreThan3LocationsRequiredFlag = true;
    }





  }

  validateLiabilityRequiredDisplay() {

    if (this.dataMappingService.currentForm.get(applicantSubcontract).value == '') {
      this.applicantSubcontractRequiredFlag = true;
    }

    if (this.dataMappingService.currentForm.get(snowPlowing).value == '') {
      this.snowPlowingRequiredFlag = true;
    }
  }

  checkBewarageStore() {
    let bewareClassCodes: number[] = [59215, 54136, 54321, 54341, 54221, 54241];

    if (bewareClassCodes.includes(Number(this.dataMappingService.originalClassCodeObj.classcode))) {
      this.mercantileRiskBewargeGorceryStoreFlag = true;
    }
  }

  checkForCoastals() {
    this.addedCoastals = [];
    this.addedLocations.forEach((data) => {
      if (this.dataMappingService.currentForm.get("BusinessOwners").get("BPLocations").get("Address").get([data.LocationCoastal]).value) {
        this.addedCoastals.push(data);
      }
    })
  }
  coastalFlagChange() {
    this.checkForCoastals();
    if (this.addedCoastals.length == 0) {
      this.coastalZip = false;
      this.dataMappingService.currentForm.get("isInsuredSmallContractor").setValue("");
    }
    this.updateCostalzipMandatoryRequired(this.coastalZip)
  }
  checkForDeclineInd(res){
    let x=res.apiOutput.result.response.eventResponses.eventResponse;
    let flag='N';
    this.declinedLocations=[]
    x.forEach((d)=>{
      if((jp.query(d, "$.attributes.attribute[?(@.name == 'DECLINE_IND')].value")[0])=='Y'){
        flag="Y";
        let obj={location:"",reason:""}
       
        this.declineReason = jp.query(d, "$.attributes.attribute[?(@.name == 'DECLINE_MESSAGE')].value")[0];
        if(this.declineReason!='Losses'){
          obj.location=d;
          obj.reason=this.declineReason;
          this.declinedLocations.push(obj);
        }
   
      }
    })
    console.log(flag,"declination flag")
    return flag;
  }

  checkForUniqueId(){
    if(this.uniqueId==""){
      let uniqueId=uuidv4();
      uniqueId = uniqueId.replace(/-/g, '');
      this.dataMappingService.uniqueId=uniqueId;
      this.uniqueId=uniqueId;
      console.log(uniqueId,"uniqueId");
    }
  }
}
