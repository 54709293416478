import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertDollar'
  })
  export class ConvertDollarPipe implements PipeTransform {
    transform(value: string): string {
        if(value!=''){
            let x=Number(value);
            const dollarFormat = x.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              });
    
          return  dollarFormat.replace(/\.00$/, "");
        } else{
            return '$0'
        }
        
        
   
    }
  }