export enum BusinessAttribute {
    CONTRACTORS = 'Contractors',
    APARTMENTS = 'Apartments',
    RESTAURANTS = 'Restaurants',
    SELF_STORAGE_FACILITIES = 'SelfStorageFacilities',
    WHOLESALE_RISKS = 'WholesaleRisks',
    PROCESSING_AND_SERVICE_RISKS = 'ProcessingAndServiceRisks',
    OFFICE = 'Office',
    MERCANTILE_RISKS = 'MercantileRisks',
    CONVENIENCE = 'Convenience',
}
  