import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { DropdownService } from "./dropdown.service";
import { BusinessAttribute } from "@app/core/enums/business-attributes.enum";
import { liabMedicalExpensLmt, hiredAutoLiabLimit, nonownedAutoLiabLmt, nonOwnedAutoLiabCov, prodCompleteOperAggLmt, liabMedicalExpAggLmt, medicalExpensPerPerson, totalPayroll, liabilityDeduApp, liabilityDeductible, applicantSubcontract, snowIceRemoval, privateDriveways, parkingLots, roadHighways, cNoOfPlows, enhancerEndorsement, cEnhancerPlusEndt, condDirctLiabEndt, condLiabEndtLmt, condOffLiabEndtDeduc, liabilityExposure, veterinarProfLiab, numberOfVeterinarian, AnualGrossSales, LiquorSales, brbBeutPrsEnhPrfLb, professionalClass, noOfFullTimeBarbers, noOfPartTimeBarbers, noOfFlTmBuPrEnPf, noOfPtTmBuPrEnPf, photography, liabilityDeductibleType,BlanketType,BuildingBppLimit,BusinessOwnersConst,leadPoisoning,LeadPoisonYrConst } from "@app/contractor.constant";
import { DataMappingService } from "./data-mapping.service";
import { UiService } from '@app/core/services/ui.service';

@Injectable({
  providedIn: "root",
})
export class LiabilityDataMappingService {
  constructor(
    public dataService: DataService,
    public dropDownService: DropdownService,
    public dataMappingService: DataMappingService,
    public uiService: UiService
  ) {}

  functionsMap = {
    [liabMedicalExpensLmt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.liabMedicalExpensLmt = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(liabMedicalExpensLmt).value);
        }
      }
    ],
    [hiredAutoLiabLimit]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.hiredAutoLiabLimit = dataService.businessOwners.liabMedicalExpensLmt;
        }
      }
    ],
    [nonownedAutoLiabLmt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.nonownedAutoLiabLmt = dataService.businessOwners.liabMedicalExpensLmt;
        }
      }
    ],
    [nonOwnedAutoLiabCov]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.nonOwnedAutoLiabCov = "Without Delivery Service"; // default values
        }
      }
    ],
    [prodCompleteOperAggLmt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.prodCompleteOperAggLmt = dataMappingService.amountMultiplyBy2(dataService.businessOwners.liabMedicalExpensLmt); // calculated value may change ?
        }
      }
    ],
    [liabMedicalExpAggLmt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.liabMedicalExpAggLmt = dataMappingService.amountMultiplyBy2(dataService.businessOwners.liabMedicalExpensLmt); // calculated value may change ?
        }
      }
    ],
    [medicalExpensPerPerson]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.medicalExpensPerPerson = dataMappingService.currentForm.get(medicalExpensPerPerson).value;
        }
      }
    ],
    [totalPayroll]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(totalPayroll in dataService.BPGeneralQuestionsMU){
            let totalPayroll:any = dataMappingService.calculateTotalPayrollAllBuildings(dataService);
            dataService.BPGeneralQuestionsMU.totalPayroll = totalPayroll;
          }
        }
      }
    ],
    [liabilityDeduApp]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          dataService.businessOwners.liabilityDeduApp =dataMappingService.currentForm.get(liabilityDeductible).value?'Y':'N'; // default value may change ?
          if(liabilityDeductibleType in dataService.businessOwners)
          {
            dataService.businessOwners.liabilityDeductibleType=dataMappingService.currentForm.get(liabilityDeductible).value?"Per occurrence":"";
          } 
        }
      }
    ],
    [liabilityDeductible]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(liabilityDeductible in dataService.businessOwners){
             dataService.businessOwners.liabilityDeductible =dataMappingService.currentForm.get(liabilityDeductible).value
          }
        }
      }
    ],
    [applicantSubcontract]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(applicantSubcontract in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.applicantSubcontract = dataMappingService.currentForm.get(applicantSubcontract).value;
          }
        }
      }
    ],
    [snowIceRemoval]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(snowIceRemoval in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.snowIceRemoval = dataMappingService.currentForm.get(snowIceRemoval).value;
          }
        }
      }
    ],
    [privateDriveways]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(privateDriveways in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.privateDriveways = dataMappingService.currentForm.get(privateDriveways).value;
          }
        }
      }
    ],
    [parkingLots]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(parkingLots in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.parkingLots = dataMappingService.currentForm.get(parkingLots).value;
          }
        }
      }
    ],
    [roadHighways]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(roadHighways in dataService.BPGeneralQuestionsMU){
            dataService.BPGeneralQuestionsMU.roadHighways = dataMappingService.currentForm.get(roadHighways).value;
          }
        }
      }
    ],
    [cNoOfPlows]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Snow Plow Coverage (Mass only)').length != 0){
            dataService.BPOptionalRTCCoverages.filter(option => option.Endorsement === 'Snow Plow Coverage (Mass only)')[0].cNoOfPlows = dataMappingService.currentForm.get(cNoOfPlows).value; 
          }
        }
      }
    ],
    [condDirctLiabEndt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPClassifications.ClassCode=='69145' || dataService.BPClassifications.ClassCode=='60999' || dataMappingService.originalClassCodeObj.classcode2=='69145' || dataMappingService.originalClassCodeObj.classcode2=='60999' || dataMappingService.originalClassCodeObj.classcode3=='69145' || dataMappingService.originalClassCodeObj.classcode3=='60999' )
           {
            
          if(condDirctLiabEndt in dataService.BPClassifications){
            
              dataService.BPClassifications.condDirctLiabEndt = dataMappingService.currentForm.get(condDirctLiabEndt).value;
            }
          }
        }
      }
    ],
    [condLiabEndtLmt]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPClassifications.ClassCode=='69145' || dataService.BPClassifications.ClassCode=='60999' || dataMappingService.originalClassCodeObj.classcode2=='69145' || dataMappingService.originalClassCodeObj.classcode2=='60999' || dataMappingService.originalClassCodeObj.classcode3=='69145' || dataMappingService.originalClassCodeObj.classcode3=='60999')
           {
          if(condDirctLiabEndt in dataService.BPClassifications && condLiabEndtLmt in dataService.BPClassifications){
            if(dataService.BPClassifications.condDirctLiabEndt == 'Y'){
              dataService.BPClassifications.condLiabEndtLmt = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(condLiabEndtLmt).value);
            }else{
              dataService.BPClassifications.condLiabEndtLmt = '';
            }
          }
        }
        }
      }
    ],
    [condOffLiabEndtDeduc]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.BPClassifications.ClassCode=='69145' || dataService.BPClassifications.ClassCode=='60999')
           {
          if(condDirctLiabEndt in dataService.BPClassifications && condOffLiabEndtDeduc in dataService.BPClassifications){
            if(dataService.BPClassifications.condDirctLiabEndt == 'Y'){
              dataService.BPClassifications.condOffLiabEndtDeduc = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(condOffLiabEndtDeduc).value);
            }else{
              dataService.BPClassifications.condOffLiabEndtDeduc = '';
            }
          }
        }
        }
      }
    ],
    [liabilityExposure]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
         if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.CONTRACTORS])
          {
          if(liabilityExposure in dataService.BPBuilding.BPClassifications){
          dataService.BPBuilding.BPClassifications.liabilityExposure = dataMappingService.removeCommasAndDollar(dataMappingService.currentForm.get(totalPayroll).value);
          }
        }
         if(dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && dataService.BPClassifications.ClassCode=='09331'))
          {
             if(liabilityExposure in dataService.BPBuilding.BPClassifications){
             // dataService.BPBuilding.BPClassifications.liabilityExposure = dataMappingService.currentForm.get(AnualGrossSales).value;
          }
        }
        
        }
      }
    ],
    [veterinarProfLiab]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.OFFICE] && dataService.BPClassifications.ClassCode=='64181')
          {
          if(veterinarProfLiab in dataService.BPBuilding.BPClassifications){
         // dataService.BPBuilding.BPClassifications.veterinarProfLiab = dataMappingService.currentForm.get(veterinarProfLiab).value;
          //dataService.BPBuilding.BPClassifications.numberOfVeterinarian = dataMappingService.currentForm.get(numberOfVeterinarian).value;
          }
        }
        }
      }
    ],
    [AnualGrossSales]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && dataService.BPClassifications.ClassCode=='09331'))
          {
           if(AnualGrossSales in dataService.BPBuilding.BPClassifications){
             // dataService.BPBuilding.BPClassifications.AnualGrossSales = dataMappingService.currentForm.get(AnualGrossSales).value;
          }
        }
        }
      }
    ],
    [LiquorSales]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.RESTAURANTS] || (dataMappingService.businessFlags[BusinessAttribute.CONVENIENCE] && dataService.BPClassifications.ClassCode=='09331') || dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS])
          {
             if(LiquorSales in dataService.BPGeneralQuestionsMU ){
              dataService.BPGeneralQuestionsMU.LiquorSales = dataMappingService.currentForm.get(LiquorSales).value?dataMappingService.currentForm.get(LiquorSales).value:"0";
            
          }
        }
        }
      }
    ],

    [brbBeutPrsEnhPrfLb]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS] )
          {
             if(brbBeutPrsEnhPrfLb in dataService.BPClassifications){
            //  dataService.BPClassifications.brbBeutPrsEnhPrfLb = dataMappingService.currentForm.get(brbBeutPrsEnhPrfLb).value?dataMappingService.currentForm.get(brbBeutPrsEnhPrfLb).value:'';
            //   dataService.BPClassifications.professionalClass=dataMappingService.currentForm.get(professionalClass).value;
            //   dataService.BPClassifications.noOfFullTimeBarbers=dataMappingService.currentForm.get(noOfFullTimeBarbers).value;
            //   dataService.BPClassifications.noOfPartTimeBarbers=dataMappingService.currentForm.get(noOfPartTimeBarbers).value;
            //   dataService.BPClassifications.noOfFlTmBuPrEnPf=dataMappingService.currentForm.get(noOfFlTmBuPrEnPf).value;
            //   dataService.BPClassifications.noOfPtTmBuPrEnPf=dataMappingService.currentForm.get(noOfPtTmBuPrEnPf).value;
             }
             //Need to do
            //dataService.BPOptionalRTCCoverages
          }
        
        }
      }
    ],
    [photography]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataMappingService.businessFlags[BusinessAttribute.PROCESSING_AND_SERVICE_RISKS])
          {
          if(photography in dataService.BPClassifications){
         // dataService.BPClassifications.photography = dataMappingService.currentForm.get(photography).value;
          }
        }
        }
      }
    ],
    [leadPoisoning]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          let value=false;
          dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding.forEach((d,index)=>{
            if (LeadPoisonYrConst in d) {
              if (Number(d.YearBuilt) < 1978 && Number(d.NumberLeasedToOthers)>=1) {
                d.LeadPoisonYrConst = 'Before 1978';
                d.leadPoisoningAppl = 'Y';
              } else {
                d.LeadPoisonYrConst = 'After 1978';
                d.leadPoisoningAppl = 'N';
              }
            }
            if (Number(d.YearBuilt) < 1978 && Number(d.NumberLeasedToOthers)>=1){
              value=true;
            }
          })

          if(value){
            dataService.current_business_request.quotePolicy.BusinessOwners.leadPoisoning = 'Y';
          }else{
            dataService.current_business_request.quotePolicy.BusinessOwners.leadPoisoning = 'N';
          }
        }
      }
    ],
    [BlanketType]: [
      (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
        if (flag) {
          if(dataService.current_business_request.quotePolicy.BusinessOwners.blanketRating=='Y')
          {
            dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"].BlanketType=dataMappingService.currentForm.get('blanketType').value;
            dataService.current_business_request.quotePolicy.BusinessOwners["BPBlanketRating"].BuildingBppLimit=dataMappingService.currentForm.get('blanketLimit').value;
            dataMappingService.blanketType=dataMappingService.currentForm.get('blanketType').value;
           
            dataService.businessOwners.BPBuilding.forEach((d)=>{
              if(dataMappingService.currentForm.get('blanketType').value=="Building" || dataMappingService.currentForm.get('blanketType').value=="Building and Business Personal Property Combined")
              {
                if(d.BPBuildingCoverage){
                  d.BPBuildingCoverage.includedBlanketRating='Y';
                  d.BPBuildingCoverage["BlanketId"]="1";
                }
              }else{
                if(d.BPBuildingCoverage){
                  d.BPBuildingCoverage.includedBlanketRating='N';
                  delete d.BPBuildingCoverage["BlanketId"];
                }
              }
              if(dataMappingService.currentForm.get('blanketType').value=="Business Personal Property" || dataMappingService.currentForm.get('blanketType').value=="Building and Business Personal Property Combined"){
                d.BPClassifications.includedBlanketRating='Y';
                d.BPClassifications["BlanketId"]="1"
              }else{
                d.BPClassifications.includedBlanketRating='N';
                delete d.BPClassifications["BlanketId"];
              }
            })
        }
        }
      }
    ],
    
    // []: [
    //   (flag:boolean, dataMappingService: DataMappingService, dataService: DataService) => {
    //     if (flag) {
          
    //     }
    //   }
    // ],
    // add more mappings as necessary
  };


  businessFlagFunctionsMap = {
    [BusinessAttribute.CONTRACTORS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.liabilityDeduApp,
      this.functionsMap.liabilityDeductible,
      this.functionsMap.applicantSubcontract,
      this.functionsMap.snowIceRemoval,
      this.functionsMap.privateDriveways,
      this.functionsMap.parkingLots,
      this.functionsMap.roadHighways,
      this.functionsMap.cNoOfPlows,
      this.functionsMap.BlanketType,
      this.functionsMap.totalPayroll,
      this.functionsMap.leadPoisoning,
      // add more functions as necessary
    ],
    [BusinessAttribute.APARTMENTS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.condDirctLiabEndt,
      this.functionsMap.condLiabEndtLmt,
      this.functionsMap.condOffLiabEndtDeduc,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add functions for this flag
    ],
    [BusinessAttribute.WHOLESALE_RISKS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add more functions as necessary
    ],
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add more functions as necessary
    ],
    [BusinessAttribute.OFFICE]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.condDirctLiabEndt,
      this.functionsMap.condLiabEndtLmt,
      this.functionsMap.condOffLiabEndtDeduc,
      this.functionsMap.veterinarProfLiab,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      
      // add functions for this flag
    ],
    [BusinessAttribute.CONVENIENCE]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.AnualGrossSales,
      this.functionsMap.LiquorSales,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add functions for this flag
    ],
    [BusinessAttribute.RESTAURANTS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.AnualGrossSales,
      this.functionsMap.LiquorSales,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add functions for this flag
    ],
    [BusinessAttribute.MERCANTILE_RISKS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.LiquorSales,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      
      // add functions for this flag
    ],
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: [
      this.functionsMap.liabMedicalExpensLmt,
      this.functionsMap.hiredAutoLiabLimit,
      this.functionsMap.nonownedAutoLiabLmt,
      this.functionsMap.nonOwnedAutoLiabCov,
      this.functionsMap.prodCompleteOperAggLmt,
      this.functionsMap.liabMedicalExpAggLmt,
      this.functionsMap.medicalExpensPerPerson,
      this.functionsMap.brbBeutPrsEnhPrfLb,
      this.functionsMap.photography,
      this.functionsMap.BlanketType,
      this.functionsMap.leadPoisoning,
      // add functions for this flag
    ],
      // add more mappings as necessary
  };


  // call this function from data mapping service for mapping data
  mapDataBasedOnFlagName = (dataMappingService: DataMappingService, dataService: DataService) => {
    const functionsToExecute = this.businessFlagFunctionsMap[dataMappingService.getBusinessFlag()];
    if (functionsToExecute) {
      functionsToExecute.forEach((functions) => {
        functions.forEach((fn) => {
          fn(true, dataMappingService, dataService);
        });
      });
    }
  }
}
