import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { DataService } from "./data.service";
import { FormGroup } from "@angular/forms";
import { DropdownService } from "./dropdown.service";
import { ApiService } from "./api.service";
import { DeveloperService } from "./developer.service";
import { UiService } from "./ui.service";
import { LoaderService } from "@app/loader.service";
import { ClassCodeModal } from "@app/interfaces/class-code-modal";
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';
import { AgentDataMappingService } from "./agent-data-mapping.service";
import { PropertyDataMappingService } from "./property-data-mapping.service";
import { LiabilityDataMappingService } from "./liability-data-mapping.service";
import { OptionalDataMappingService } from "./optional-data-mapping.service";
import { UWDataMappingService } from "./uw-data-mapping.service";
import { BPContractorsEndorsementConst } from "@app/contractor.constant";


@Injectable({
  providedIn: 'root'
})
export class DataMappingService {
  constructor(public dataService: DataService, private dropDownService: DropdownService, private apiService: ApiService, private router: Router, private developerService: DeveloperService, public LoaderService: LoaderService, public agentDataMappingService: AgentDataMappingService,
    public propertyDataMappingService: PropertyDataMappingService,
    public liabilityDataMappingService: LiabilityDataMappingService,
    public optionalDataMappingService: OptionalDataMappingService,
    public uWDataMappingService: UWDataMappingService,
  ) { }

  currentForm: FormGroup;

  public originalClassCodeObj: ClassCodeModal = {
    classcode: "",
    description: "",
    classcode2: "",
    description2: "",
    classcode3: "",
    description3: "",
    propertytype: "",
    propertytype2: "",
    propertytype3: "",
    businessDesc:"",
    businessDesc2:"",
    businessDesc3:"",
  };
  
  public actualProducerCode: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public payroll: string = "";
  public selectedInterestDropdownValue: string = "";
  public isBuildingRoofValueSelected: boolean = false;
  public bppLimitChoosedFlag: boolean = false;
  public isBuildingRoofSelectedVal: string = "N";
  public isMailAddressSameAsLocationSelectedVal: string;
  public coastalZone: string;
  public ppc: string;
  public ppc1: string;
  public ppc2: string;
  public overallIRPMFactor: string;
  public generalManagementIRPMJustification: string;
  public generalManagementIRPMFactor: string;
  public buildingFeaturesIRPMJustification: string;
  public buildingFeaturesIRPMFactor: string;
  public locationIRPMJustification: string;
  public locationIRPMFactor: string;
  public protectionIRPMJustification: string;
  public protectionIRPMFactor: string;
  public premisesAndEquipmentIRPMJustification: string;
  public premisesAndEquipmentIRPMFactor: string;
  public safetyManagementIRPMJustification: string;
  public safetyManagementIRPMFactor: string;
  public employeesIRPMJustification: string;
  public employeesIRPMFactor: string;
  public nfcraScore: string;

  public isEffectiveDateAfterMargin: boolean = false;
  public blanketType="Building";
  public factorType="Both Combined";

  isLocationInsuredAddressSame: boolean = true;
  l1ApartmentBuildingEndorsementSelectedVal;
  l2ApartmentBuildingEndorsementSelectedVal;
  l3ApartmentBuildingEndorsementSelectedVal;

  apartmentLocationOneEndorsementSelectedVal:string;
  apartmentLocationTwoEndorsementSelectedVal:string;
  apartmentLocationThreeEndorsementSelectedVal:string;

  public restaurantLocationOneEndorsementSelectedVal: string;
  public restaurantLocationTwoEndorsementSelectedVal: string;
  public restaurantLocationThreeEndorsementSelectedVal: string;
  public propertyL1FirstVisit:boolean=true;
  public propertyL2FirstVisit:boolean=true;
  public propertyL3FirstVisit:boolean=true;
  public uniqueId;

  LocationOneBuildings = [];
  LocationTwoBuildings = [];
  LocationThreeBuildings = [];

  convertToDollarFormat(input: number, showCurrencySymbol: boolean = true) {
    const dollarFormat = input.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    if (showCurrencySymbol) {
      return dollarFormat.replace(/\.00$/, "");
    } else {
      return dollarFormat.replace(/\.00$/, "").replace(/^\$/, "");
    }
  }

  get10DigitString(str) {
    this.actualProducerCode = str;
    // Remove all non-digit characters from the input string
    const digitsOnly = str.replace(/\D/g, "");

    // Pad the resulting string with leading zeros until it is at least 8 digits long
    const paddedString = digitsOnly.padStart(8, "0");

    // Add two leading zeros to the padded string
    const finalString = "00" + paddedString;

    return finalString;
  }

  businessFlags = {
    [BusinessAttribute.CONTRACTORS]: true,
    [BusinessAttribute.APARTMENTS]: false,
    [BusinessAttribute.RESTAURANTS]: false,
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: false,
    [BusinessAttribute.WHOLESALE_RISKS]: false,
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]: false,
    [BusinessAttribute.OFFICE]: false,
    [BusinessAttribute.MERCANTILE_RISKS]: false,
    [BusinessAttribute.CONVENIENCE]: false,
  };

  businessConstants = {
    [BusinessAttribute.CONTRACTORS]: "Contractors",
    [BusinessAttribute.APARTMENTS]: "Apartments",
    [BusinessAttribute.RESTAURANTS]: "Restaurants",
    [BusinessAttribute.SELF_STORAGE_FACILITIES]: "Self-Storage Facilities",
    [BusinessAttribute.WHOLESALE_RISKS]: "Wholesale Risks",
    [BusinessAttribute.PROCESSING_AND_SERVICE_RISKS]:
      "Processing and Service Risks",
    [BusinessAttribute.OFFICE]:
      "Office (including Office & Commercial Condominiums).",
    [BusinessAttribute.MERCANTILE_RISKS]: "Mercantile Risks",
    [BusinessAttribute.CONVENIENCE]: "Convenience Food/Grocery/Supermarkets",
  };

  setBusinessFlag(propertyType) {
    Object.keys(this.businessFlags).forEach((business) => {
      this.businessFlags[business] =
        this.businessConstants[business] === propertyType;
    });
  }

  getBusinessFlag = () => {
    let businessFlagName;
    for (let key in this.businessFlags) {
      if (this.businessFlags[key]) {
        businessFlagName = key;
        break;
      }
    }
    console.log("Business flag name:", businessFlagName);
    return businessFlagName;
  }

  formatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, "");

    // Format the phone number using regex
    phoneNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");

    return phoneNumber;
  }

  reverseFormatPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the phone number
    phoneNumber = phoneNumber.replace(/\D/g, "");

    return phoneNumber;
  }

  removeCommasAndDollar(string) {
    return string.replace(/[$,]/g, "");
  }

  boolToYN(boolValue) {
    return boolValue ? "Y" : "N";
  }

  YNToBool(charValue) {
    return charValue == "Y" ? true : false;
  }

  amountMultiplyBy2(value) {
    const number = parseFloat(value);
    const result = number * 2;
    const resultString = result.toString();
    return resultString;
  }

  formatDate(inputDate, isISOFormat) {
    let dateObj;
    let outputDate;

    if (isISOFormat) {
      // Try parsing date string as ISO 8601 format
      dateObj = new Date(Date.parse(inputDate));
      if (!isNaN(dateObj.getMonth())) {
        console.log("ISOformat");
        outputDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`;
        console.log(outputDate);
        return outputDate;
      }
    } else {
      // Try parsing date string as MM/DD/YYYY format
      dateObj = new Date(inputDate);
      if (!isNaN(dateObj.getMonth())) {
        console.log("non ISOformat");
        outputDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`;
        console.log(outputDate);
        return outputDate;
      }
    }
    return inputDate;
  }

  calculateTotalPayrollAllBuildings(dataService) {
   
    let building =dataService.businessOwners.BPBuilding;
    let totalPayrollValue = 0;
    building.forEach((element)=>{
      totalPayrollValue = totalPayrollValue+ Number(element.BPClassifications.totalPayroll)
    })
 
    const subcontractorPayValue = Number(
      dataService.BPGeneralQuestionsMU.UiSubcontractorPay
    );
    const ownerPartnerValue = Number(
      dataService.BPGeneralQuestionsMU.OwnerPartner
    );
    const employeePayrollValue = totalPayrollValue - ownerPartnerValue;
    dataService.BPGeneralQuestionsMU.EmployeePayroll =
      employeePayrollValue < 0
        ? Math.abs(employeePayrollValue).toString()
        : employeePayrollValue.toString();
    const total:any =
      totalPayrollValue +
      employeePayrollValue +
      subcontractorPayValue +
      ownerPartnerValue;
    return total.toString();
  }
  
  calculateTotalPayroll(totalPayrollValueInput, dataService) {
    const totalPayrollValue = Number(totalPayrollValueInput);
    const subcontractorPayValue = Number(
      dataService.BPGeneralQuestionsMU.UiSubcontractorPay
    );
    const ownerPartnerValue = Number(
      dataService.BPGeneralQuestionsMU.OwnerPartner
    );
    const employeePayrollValue = totalPayrollValue - ownerPartnerValue;
    dataService.BPGeneralQuestionsMU.EmployeePayroll =
      employeePayrollValue < 0
        ? Math.abs(employeePayrollValue).toString()
        : employeePayrollValue.toString();
    const total =
      totalPayrollValue +
      employeePayrollValue +
      subcontractorPayValue +
      ownerPartnerValue;
    return total.toString();
  }

  PPCCodeMapping(ppc, location, dataService){
    let buildings = dataService.quote_policy.BusinessOwners.BPBuilding;
    buildings.forEach((ele)=>{
      if(location == ele.locationId){
        ele.PublicProtFireClas = ppc;
      }
    })
  }

  getStateDescByCode(code) {
    const foundState = this.dropDownService.mailingState.find(
      (state) => state.stateCode === code
    );
    return foundState ? foundState.stateDesc : null;
  }

  mapAgentFormDatatoFinalJson(myformGroup: FormGroup) {
    // this.agentDataMappingService.mapDataBasedOnFlagName(this.getBusinessFlag());
  }


  mapPropertyDetailsFormDatatoFinalJson = (myformGroup: FormGroup) => {
  }


  mapLiabilityFormDatatoFinalJson = (myformGroup: FormGroup) => {
  }

  mapOptionalCoveragesFormDatatoFinalJson = (myformGroup: FormGroup) => {
  }

  mapUWQuestionsformDatatoFinalJson = (myformGroup: FormGroup) => {
  }

  coverallMethod(developerService: DeveloperService, dataService: DataService, uiService: UiService) {
    if (developerService.isDeveloperMode) {
      this.LoaderService.isLoadingFromStorage = true;
      this.LoaderService.setLoaderText() // set default example
      setTimeout(() => {
        this.LoaderService.setLoaderText(); // reset back to original text
        this.LoaderService.isLoadingFromStorage = false;
        developerService.localCoverAllDataForTesting('quote-summary', dataService);
      }, 3000)
    } else {
      console.log(developerService.findEmptyAttributes(dataService.current_business_request));
      this.sendCoverAllData('quote-summary', dataService, uiService);
    }
  }

  sendCoverAllData(pageName: string, dataService: DataService, uiService: UiService) {
    uiService.checkForUniqueId();
    dataService.current_business_request.quotePolicy.BusinessOwners.BPIRPMRating[0].BPIRPMRatingLevel[0]["OverrideReason"]=uiService.uniqueId;
    const data = dataService.current_business_request;
    if (this.businessFlags[BusinessAttribute.CONTRACTORS] && dataService.businessOwners.contractorsEndorsement == 'N') {
      delete data.quotePolicy.BusinessOwners[BPContractorsEndorsementConst];
    }

    data.quotePolicy.BusinessOwners.BPBuilding.forEach((ele)=>{
      if(ele.BPBuildingCoverage){
        if(ele.BPBuildingCoverage.BuildingLimitInsurance == ''){
          delete ele.BPBuildingCoverage;
        }
      }
    
    })

    this.LoaderService.isLoadingFromStorage = true;
    this.LoaderService.setLoaderText(); // reset back to original text
    sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
    this.apiService.sendData(data).subscribe(response => {
      dataService.current_business_response = response;
      uiService.hasRatingErrors = false;
      this.LoaderService.isLoadingFromStorage = false;
      sessionStorage.setItem("isQuoteRated", JSON.stringify(true));
      this.router.navigate([`${pageName}`]);
    }, error => {
      console.log("Error Response : ", error);
      const apiErrorMessages = error.error.apiOutput.result.message;
      uiService.quoteNumber = error.error.apiOutput.result.id;
      if (apiErrorMessages.length != 0) {
        uiService.ratingErrorMessage = "";
        for (let i = 0; i < apiErrorMessages.length; i++) {
          uiService.ratingErrorMessage = `${uiService.ratingErrorMessage}${apiErrorMessages[i].moreinfo}`;
        }
        uiService.hasRatingErrors = true;
        uiService.referralIndicator = "N";
        sessionStorage.setItem("isQuoteRated", JSON.stringify(false));
        this.router.navigate([`${pageName}`]);
      } else {
        alert(error.message);
      }
      this.LoaderService.isLoadingFromStorage = false;
    });
  }
}
