import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url:string = environment.nodeserver;

  constructor(private httpClient: HttpClient) { }

  sendData(data: any): Observable<any> {
    return this.httpClient.post(`${this.url}coverall/quote`, data);
  }
}
