<header>
  <app-header></app-header>
  <app-stepper></app-stepper>
</header>
<form>
  <router-outlet></router-outlet>
</form>
<footer>
  <verti-footer></verti-footer>
  <app-next-back></app-next-back>
</footer>

<!-- Loader before rating -->
<div class="loader-container" *ngIf="LoaderService.isLoadingFromStorage">
  <div class="loader"></div>
  <div class="loader-text">{{ LoaderService.loaderText }}</div>
</div>

<!-- Select Cities Modal -->
<div class="loader-container" *ngIf="uiService.isZipLoading && (!uiService.stateWrongFlag)">
  <div class="select-city-modal">
    <!-- [ngStyle]="{'height.px': [uiService.select_city_modal_height]}" -->
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.isZipLoading=false;">
    <div class="select-city-modal-content">
      <div class="select-city-modal-title">
        <div class="select-city-modal-title">
          <div class="select-city-modal-subtitle">Please select city</div>
          <div class="cities-group">
            <!-- [ngStyle]="(uiService.select_city_modal_count>4)?{'height.px': [uiService.cities_group_height],'overflow': 'auto'}:{'height.px': [uiService.cities_group_height]}" -->
            <div *ngFor="let data of uiService.filteredAddressDatas;">
              <div class="city-row">
                <div class="city-radio">
                  <input class="city-radio-input" type="radio" [id]="data.id" name="addressRadio" [value]="data"
                    (click)="uiService.selectedAddress = data" />
                  <div class="city-radio-input-text">{{data.city}}</div>
                </div>
                <div class="county-state">
                  <div class="county-state-text">{{data.county}}, {{data.stateDesc}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-row">
            <button class="cities-modal-confirm-button"
              (click)="uiService.setAddressByZipCode();uiService.isZipLoading=false;">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Select Cities Error Modal -->
<div class="loader-container" *ngIf="uiService.stateWrongFlag">
  <div class="select-city-modal">
    <img class="close-icon" src="./assets/img/icon-close.png"
      (click)="uiService.isZipLoading=false;uiService.stateWrongFlag=false;">
    <div class="select-city-modal-content">
      <div class="select-city-modal-title">
        <div class="select-city-modal-title">
          <div class="select-city-modal-subtitle">Please select city</div>
          <div class="cities-group">
            <div>
              <p>Entered Zipcode does not belong to Massachusetts</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Select Cities Modal for Mailing Address -->
<div class="loader-container" *ngIf="uiService.isZipMailLoading">
  <div class="select-city-modal" [ngStyle]="{'height.px': [uiService.select_mail_city_modal_height]}">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.isZipMailLoading=false;">
    <div class="select-city-modal-content">
      <div class="select-city-modal-title">
        <div class="select-city-modal-title">
          <div class="select-city-modal-subtitle">Please select city</div>
          <div class="cities-group" [ngStyle]="(uiService.select_mail_city_modal_count>4)?{'height.px': [uiService.cities_mail_group_height],'overflow': 'auto'}:{'height.px': [uiService.cities_mail_group_height]}">
            <div *ngFor="let data of uiService.filteredMailAddressDatas;">
              <div class="city-row">
                <div class="city-radio">
                  <input class="city-radio-input" type="radio" [id]="data.id" name="addressRadio" [value]="data"
                    (click)="uiService.selectedMailAddress = data" />
                  <div class="city-radio-input-text">{{data.city}}</div>
                </div>
                <div class="county-state">
                  <div class="county-state-text">{{data.county}}, {{data.state}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-row">
            <button class="cities-modal-confirm-button"
              (click)="uiService.setMailAddressByZipCode();uiService.isZipMailLoading=false;">Confirm</button>
          </div>
        </div>
      </div>
      </div>
  </div>
</div>

<!-- Select Cities Error Modal for Mailing Address -->
<div class="loader-container" *ngIf="uiService.mailZipWrongFlag">
  <div class="select-city-modal">
    <img class="close-icon" src="./assets/img/icon-close.png"
      (click)="uiService.isZipMailLoading=false;uiService.mailZipWrongFlag=false;">
    <div class="select-city-modal-content">
      <div class="select-city-modal-title">
        <div class="select-city-modal-title">
          <div class="select-city-modal-subtitle">Please select city</div>
          <div class="cities-group">
            <div>
              <p>No Data found for entered Zipcode </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Multiple Locations Modal -->
<div class="loader-container" *ngIf="uiService.hasMultipleLocations">
  <div class="multi-locations-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.hasMultipleLocations=false;onHasMultiLocationClose()">
    <div class="multi-locations-modal-content">
      <div class="multi-locations-modal-title">
        <div class="multi-locations-modal-subtitle">
          <p class="multi-locations-modal-subtitle-text">Please navigate to MAPFRE Connect for Risks that exceed 3 Locations and/or 3 Buildings Per Location
          </p>
          <div class="multi-locations-modal-button-row">
            <button class="multi-locations-modal-button" (click)="navigateToCoverall();">Open MAPFRE Connect</button>
          </div>
          <div class="multi-locations-modal-contactus">
            <p class="multi-locations-modal-contactus-text">If you need assistance, please contact your underwriter.</p>
            <div class="multi-locations-modal-contactus-phone-link">
              <img class="phone-icon" src="./assets/img/icon-phone.png">
              <div class="multi-locations-modal-contactus-phone-number">1-800-922-8276</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Location Address Normalization-->
<div class="loader-container"
  *ngIf="uiService.bpLocationNormalizedAddressFlag && (!uiService.bpLocationNormalizedAddressNotMatchFlag)">
  <div class="confirm-addres-modal">
    <img class="close-icon" src="./assets/img/icon-close.png"
      (click)="uiService.bpLocationNormalizedAddressFlag=false;">
    <div class="confirm-addres-modal-content">
      <div class="confirm-addres-modal-title">
        <div class="confirm-addres-modal-subtitle">Please confirm your address</div>
        <div class="confirm-addres-modal-addresses">
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio" [value]="uiService.bpLocationAddressObj"
                (click)="uiService.selectedNormalizedAddress = uiService.bpLocationAddressObj; uiService.selectedNormalizedAddressFlag=true;uiService.addressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Business Address Entered
              </div>
            </div>
            <div class="confirm-addres-input-text">
              <div class="confirm-address-text">
                {{uiService.bpLocationAddressObj.Line1}} <br>
                {{uiService.bpLocationAddressObj.ZipCode}}, {{uiService.bpLocationAddressObj.City}}, {{uiService.bpLocationAddressObj.StateCode}}
              </div>
            </div>
          </div>
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio" [value]="uiService.bpLocationNormalizedAddressObj"
                (click)="uiService.selectedNormalizedAddress = uiService.bpLocationNormalizedAddressObj; uiService.selectedNormalizedAddressFlag=true;uiService.addressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Business Address Returned
              </div>
            </div>
            <div class="confirm-addres-input-text">
              <div class="confirm-address-text">
                {{uiService.bpLocationNormalizedAddressObj.Line1}} <br>
                {{uiService.bpLocationNormalizedAddressObj.ZipCode}}, {{uiService.bpLocationNormalizedAddressObj.City}}, {{uiService.bpLocationNormalizedAddressObj.StateCode}}
              </div>
            </div>
          </div>
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio"
                (click)="uiService.bpLocationReEnterNormalizedAddressFlag = true;uiService.addressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Re-enter Address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-row">
        <button class="cities-modal-confirm-button" [disabled]="!uiService.addressNormalizedButtonFlag"
          (click)="uiService.setNormalizedAddress();uiService.bpLocationNormalizedAddressFlag=false;">Confirm</button>
      </div>
    </div>
  </div>
</div>

<!-- Mailing Address Normalization-->
<div class="loader-container"
  *ngIf="uiService.bpLocationNormalizedMailAddressFlag && (!uiService.bpLocationNormalizedMailAddressNotMatchFlag)">
  <div class="confirm-addres-modal">
    <img class="close-icon" src="./assets/img/icon-close.png"
      (click)="uiService.bpLocationNormalizedMailAddressFlag=false;">
    <div class="confirm-addres-modal-content">
      <div class="confirm-addres-modal-title">
        <div class="confirm-addres-modal-subtitle">Please confirm your address</div>
        <div class="cities-group">
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio" [value]="uiService.bpLocationAddressObj"
                (click)="uiService.selectedNormalizedMailAddress = uiService.bpLocationAddressObj; uiService.selectedNormalizedMailAddressFlag=true;uiService.mailAddressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Business Address Entered
              </div>
            </div>
            <div class="confirm-addres-input-text">
              {{uiService.bpLocationAddressObj.Line1}} <br>
              {{uiService.bpLocationAddressObj.ZipCode}}, {{uiService.bpLocationAddressObj.City}}, {{uiService.bpLocationAddressObj.StateCode}}
            </div>
          </div>
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio" [value]="uiService.normalizedMailingAddressObj"
                (click)="uiService.selectedNormalizedMailAddress = uiService.normalizedMailingAddressObj; uiService.selectedNormalizedMailAddressFlag=true;uiService.mailAddressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Business Address Returned
              </div>
            </div>
            <div class="confirm-addres-input-text">
              {{uiService.normalizedMailingAddressObj.Line1}} <br>
              {{uiService.normalizedMailingAddressObj.ZipCode}}, {{uiService.normalizedMailingAddressObj.City}}, {{uiService.normalizedMailingAddressObj.StateCode}}
            </div>
          </div>
          <div class="city-row">
            <div class="city-radio">
              <input class="city-radio-input" type="radio" name="addressOriginalRadio"
                (click)="uiService.bpLocationReEnterNormalizedMailAddressFlag = true;uiService.mailAddressNormalizedButtonFlag=true">
              <div class="confirm-addres-input-label">
                Re-enter Address
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons-row">
        <button class="cities-modal-confirm-button" [disabled]="!uiService.mailAddressNormalizedButtonFlag"
          (click)="uiService.setNormalizedMailAddress();uiService.bpLocationNormalizedMailAddressFlag=false;">Confirm</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-container" *ngIf=" uiService.bpLocationNormalizedAddressFlag && uiService.bpLocationNormalizedAddressNotMatchFlag">
  <div class="confirm-addres-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.bpLocationNormalizedAddressFlag=false;uiService.bpLocationNormalizedAddressNotMatchFlag=false;">
    <div class="confirm-addres-modal-content">
      <div class="confirm-addres-modal-title">
        <div class="confirm-addres-modal-subtitle">Please confirm your address</div>
        <div class="cities-group">
          <div class="city-row">
          </div>
          <div class="city-row">
            <div class="city-radio">
              <div class="confirm-addres-input-label">
                Input address does not have matching address.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-container" *ngIf=" uiService.bpLocationNormalizedMailAddressFlag && uiService.bpLocationNormalizedMailAddressNotMatchFlag">
  <div class="confirm-addres-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="invokeMarketplace();">
    <div class="confirm-addres-modal-content">
      <div class="confirm-addres-modal-title">
        <div class="confirm-addres-modal-subtitle">Please confirm your address</div>
        <div class="cities-group">
          <div class="city-row">
          </div>
          <div class="city-row">
            <div class="city-radio">
              <div class="confirm-addres-input-label">
                Input address does not have matching address.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Multiple Class Codes Modal -->
<div class="loader-container" *ngIf="uiService.multiClassCodesModalFlag">
  <div class="multiClassCodesModal"> <!-- [ngStyle]="{'height.px': [uiService.multiClassCode_modal_height]}"-->
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.multiClassCodesModalFlag=false;">
    <div class="multiClassCodeModal-content">
      <div class="multiClassCodeModal-content-title">
        <div class="multiClassCodeModal-content-subtitle">
          <p class="multiClassCodeModal-content-subtitle-text">Please select class code</p>
          <div class="multiClassCodeModal-content-area">
            <!-- [ngStyle]="(uiService.multiClassCode_modal_count>4)?{'height.px': [uiService.classCodes_group_height],'overflow': 'auto'}:{'height.px': [uiService.classCodes_group_height]}" -->
            <div *ngFor="let data of uiService.multiClassCodesDescriptions;">
              <div class="multiClassCodeModal-row">
                <div class="multiClassCodeModal-radio">
                  <input class="multiClassCodeModal-radio-input" type="radio" [id]="data.id" name="classCodeRadio"
                    [value]="data" (click)="uiService.selectedClassCodeDescription = data" />
                  <div class="multiClassCodeModal-radio-input-text">{{data.classcode}} - {{data.propertytype}}</div>
                </div>
                <div class="multiClassCodeModal-class-desc">
                  <div class="multiClassCodeModal-class-desc-text">{{data.description}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-row">
            <button class="cities-modal-confirm-button"
              (click)="uiService.populateClassCodeAndDescription();uiService.multiClassCodesModalFlag=false;">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Declination Modal -->
<div class="loader-container no-default-height" *ngIf="uiService.declineFlag">
  <div class="marketplace-decline-modal no-default-height">
    <img *ngIf="uiService.declinedLocations.length==uiService.addedLocations.length || uiService.declineReason!='Coastal'" class="close-icon" src="./assets/img/icon-close.png" (click)="declineClose();">
    <div class="marketplace-decline-modal-content no-default-height">
      <div class="marketplace-decline-modal-title no-default-height">
        <div class="marketplace-decline-modal-subtitle no-default-height">
          <div class="marketplace-decline-modal-title-text">
            {{uiService.declinationTitle}}
          </div>
          <div *ngIf="uiService.declinedLocations.length!=0">
            <div *ngFor="let x of uiService.declinedLocations">
          <div class="marketplace-decline-modal-subtitle-text">{{ x.reason }} Location {{ x.location.geographicLocationIdentifier }}</div>
        </div>
        </div>
        <div *ngIf="uiService.declinedLocations.length==0">
          <div class="marketplace-decline-modal-subtitle-text">{{ uiService.declineReason }}</div>
        </div>
          <div class="marketplace-decline-modal-contactus">
            <div class="marketplace-decline-modal-contactus-text">If you think the information returned is incorrect, please contact your underwriter.</div>
            <div class="marketplace-decline-modal-contactus-phone">
              <img class="phone-icon" src="./assets/img/icon-phone.png">
              <div class="marketplace-decline-modal-contactus-phone-text">1-800-922-8276</div>
            </div>
          </div>
          <div *ngIf="uiService.declinedLocations.length!=uiService.addedLocations.length && uiService.declineReason=='Coastal'">
          <div class="marketplace-decline-modal-title-text height-60">
            Would you like to proceed with quote for remaining location(s)?
          </div>
          <div class="exit-quote-modal-buttons-row">
            <button class="exit-quote-modal-no-button" (click)="declineClose();">NO</button>
            <button class="exit-quote-modal-yes-button" (click)="removeDeclinedLocation();">YES</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Exit Quote Modal -->
<div class="loader-container" *ngIf="uiService.exitQuoteFlag">
  <div class="exit-quote-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.exitQuoteFlag=false;">
    <div class="exit-quote-modal-content">
      <div class="exit-quote-modal-content-title">
        <img class="exit-quote-error-icon" src="./assets/img/icon-warning-big.png" alt="Error">
        <div class="exit-quote-modal-content-subtitle">
          <div class="exit-quote-modal-content-title-text">
            All information entered will be lost if quote is exited prior to calculation.
          </div>
          <div class="exit-quote-modal-content-subtitle-text">
            Are you sure you want to exit this quote?
          </div>
        </div>
      </div>
      <div class="exit-quote-modal-buttons-row">
        <button class="exit-quote-modal-no-button" (click)="uiService.exitQuoteFlag=false;">NO</button>
        <button class="exit-quote-modal-yes-button" (click)="exitQuoteFromPopup();">YES, exit quote</button>
      </div>
    </div>
  </div>
</div>

<!-- Quoted without referrals Modal -->
<div class="loader-container" *ngIf="uiService.quotedWithoutReferrals">
  <div class="quote-without-referrals-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="closeAction();">
    <div class="quote-without-referrals-modal-content">
      <div class="quote-without-referrals-modal-title">
        <img class="check-icon" src="./assets/img/icon-check.png">
        <div class="quote-without-referrals-modal-subtitle">
          <div class="quote-without-referrals-modal-title-text">Almost there!</div>
          <div class="quote-without-referrals-modal-subtitle-text">Quote has been sent to the Policy Administration System and is ready to be customized or purchased.</div>
          <div class="quote-without-referrals-modal-content-area">
            <div class="quote-without-referrals-modal-content-area-left">
              <div class="quote-without-referrals-modal-content-area-left-title">
                <div class="quote-without-referrals-modal-content-area-left-title-text">What's next?</div>
              </div>
              <div style="padding-left: 2%;">
                <div class="quote-without-referrals-modal-content-area-left-instruction">&bull;&nbsp;&nbsp;<b>Copy Quote number [ {{uiService.quoteNumber}} ]</b></div>
                <div class="quote-without-referrals-modal-content-area-left-instruction" style="order: 2;">&bull;&nbsp;&nbsp;Open Policy Administration System using the button below.</div>
                <div class="quote-without-referrals-modal-content-area-left-instruction" style="order: 3;">&bull;&nbsp;&nbsp;Paste quote # (CTRL+V) into the search box in Policy Administration System.</div>
                <div class="quote-without-referrals-modal-content-area-left-instructions-frame">
                  <div class="quote-without-referrals-modal-content-area-left-instructions-frame-text">As shown in the image on the right</div>
                </div>
                <div class="quote-without-referrals-modal-content-area-left-instruction" style="order: 5;">&bull;&nbsp;&nbsp;Click GO and continue with the quote!</div>
              </div>
            </div>
            <div class="quote-without-referrals-modal-content-area-right">
              <div class="quote-without-referrals-modal-content-area-right-frame">
                <div class="quote-without-referrals-modal-content-area-right-frame-search-image"></div>
                <div class="quote-without-referrals-modal-content-area-right-frame-rectangle"></div>
              </div>
              <div class="quote-without-referrals-modal-content-area-right-frame-text">Search box located in Policy Administration System</div>
            </div>
          </div>
          <div class="quote-without-referrals-modal-button-row">
            <button class="quote-without-referrals-modal-button" (click)="navigateToCoverall();">Open Policy Administration System</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Quoted with referrals Modal-->
<div class="loader-container" *ngIf="uiService.quotedWithReferrals">
  <div class="quote-with-referrals-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="closeAction();">
    <div class="quote-with-referrals-modal-content">
      <div class="quote-with-referrals-modal-title">
        <img class="check-icon" src="./assets/img/icon-check.png">
        <div class="quote-with-referrals-modal-subtitle">
          <div class="quote-with-referrals-modal-title-text">Thanks for your submission, we need to take a closer look. <br/>Your quote has been sent to the Policy Administration System for additional review.</div>
          <div class="quote-with-referrals-modal-content">
            <div class="quote-with-referrals-modal-content-left">
              <div class="quote-with-referrals-modal-content-left-text">An Underwriter will contact you once the review is complete.</div>
            </div>
          </div>
          <div class="quote-with-referrals-modal-button-row">
            <button class="quote-with-referrals-modal-button" (click)="closeAction();">OK, I understand</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Agent Info Confirmation Modal-->
<div class="loader-container" *ngIf="agentConfirmationService.confirAgentInfoModalFlag">
  <div class="agent-info-confirmation-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="agentConfirmationService.confirAgentInfoModalFlag=false;">
    <div class="agent-info-confirmation-modal-content">
      <div class="agent-info-confirmation-modal-content-title">
          <div class="agent-info-confirmation-modal-content-subtitle">
            <div class="agent-info-confirmation-modal-content-title-text">
            Please ensure all entries are correct before proceeding to the next screen
          </div>
          </div>
       </div>
      <div class="agent-info-confirmation-modal-buttons-row">
        <button class="agent-info-confirmation-modal-ok-button" (click)="agentConfirmationService.confirmAgentInfo()">OK</button>
      </div>
    </div>
  </div>
</div>

<!-- Interaction 1B No Hit Modal -->
<div class="loader-container" *ngIf="uiService.noHitModalFlag">
  <div class="agent-info-confirmation-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.noHitModalFlag=false;">
    <div class="agent-info-confirmation-modal-content">
      <div class="agent-info-confirmation-modal-content-title">
          <div class="agent-info-confirmation-modal-content-subtitle">
            <div class="agent-info-confirmation-modal-content-title-text">
            Our search did not yield results. Please type in the required information
          </div>
          </div>
       </div>
      <div class="agent-info-confirmation-modal-buttons-row">
        <button class="agent-info-confirmation-modal-ok-button" (click)="uiService.noHitModalFlag=false;">OK</button>
      </div>
    </div>
  </div>
</div>

<!-- Copy Location Modal -->
<div class="loader-container" *ngIf=" uiService.CopyLocationModalFlage">
  <div class="confirm-addres-modal">
     <img class="close-icon" src="./assets/img/icon-close.png"
        (click)="onClose();uiService.CopyLocationModalFlage=false;">
     <div class="copy-locations-content">
        <h3 class="copy-locations-modal-title">Copy Location</h3>
        <table class="copy-location-table">
           <thead>
              <tr>
                 <th>Select</th>
                 <th>Street</th>
                 <th>City</th>
                 <th>State</th>
                 <th>Zip</th>
              </tr>
           </thead>
           <tbody>
            <tr *ngFor="let location of uiService.mailingAddressPopupList">
              <td>
              <div class="check-group-field">
              <input class="checkboxes" [(ngModel)]="location.IsSelected" [disabled]="location.IsDisabled" (change)="_doSelectLocation(location)" type="checkbox" />
              </div>
              </td>
              <td>{{location.Street}}</td>
              <td>{{location.City}}</td>
              <td>{{location.State}}</td>
              <td>{{location.Zip}}</td>
              </tr>
           </tbody>
        </table>
        <div class="copy-locations-btns">
           <button type="button" [ngClass]="uiService.mailingLocationSelected?.length>0 ? 'copy-locations-btn' : 'copy-locations-disabled-btn'" [disabled]="!(uiService.mailingLocationSelected?.length>0)" (click)="onConfirmLocation();uiService.CopyLocationModalFlage=false;">Confirm</button>
        </div>
     </div>
  </div>
</div>


<!-- Class Code Alert Modal -->
<div class="loader-container" *ngIf="uiService.ClassCodeAlertModal">
  <div class="agent-info-confirmation-modal class-code-alert">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.ClassCodeAlertModal=false;">
    <div class="agent-info-confirmation-modal-content">
      <img class="exit-quote-error-icon" src="./assets/img/icon-warning-big.png" alt="Error">
      <div class="agent-info-confirmation-modal-content-title">
          <div class="agent-info-confirmation-modal-content-subtitle">
            <div class="agent-info-confirmation-modal-content-title-text">
              All classifications on the policy must fall within the same Nature of Business
          </div>
          </div>
       </div>
      <div class="agent-info-confirmation-modal-buttons-row">
        <button class="agent-info-confirmation-modal-ok-button" (click)="uiService.ClassCodeAlertModal=false">OK</button>
      </div>
    </div>
  </div>
</div>



<!-- Blanket Alert Modal -->
<div class="loader-container" *ngIf="uiService.BlanketInfoAlert">
  <div class="agent-info-confirmation-modal">
    <img class="close-icon" src="./assets/img/icon-close.png" (click)="uiService.BlanketInfoAlert=false;">
    <div class="agent-info-confirmation-modal-content">
      <div class="agent-info-confirmation-modal-content-title">
          <div class="agent-info-confirmation-modal-content-subtitle">
            <div class="agent-info-confirmation-modal-content-title-text">
              Blanket Information completed on the Liability/Policy Screen
          </div>
          </div>
       </div>
      <div class="agent-info-confirmation-modal-buttons-row">
        <button class="agent-info-confirmation-modal-ok-button" (click)="uiService.BlanketInfoAlert=false">OK</button>
      </div>
    </div>
  </div>
</div>



<!--Square footage Declination Modal -->
<div class="loader-container no-default-height" *ngIf="uiService.SqftDeclineFlag">
  <div class="marketplace-decline-modal no-default-height">
    <img class="close-icon" *ngIf="uiService.SqftDeclineBuildings.length==uiService.SqftAllBuildings.length" src="./assets/img/icon-close.png" (click)="declineSqFootageClose()">
    <div class="marketplace-decline-modal-content no-default-height">
      <div class="marketplace-decline-modal-title no-default-height">
        <div class="marketplace-decline-modal-subtitle no-default-height">
          <div class="marketplace-decline-modal-title-text" *ngIf="uiService.SqftDeclineBuildings.length!=uiService.SqftAllBuildings.length">
            Thank you for this opportunity however the following building(s) do(es) not meet our eligibility guidelines for at least the following reasons:
          </div>
          <div class="marketplace-decline-modal-title-text" *ngIf="uiService.SqftDeclineBuildings.length==uiService.SqftAllBuildings.length">
            Thank you for this opportunity however the following location do(es) not meet our eligibility guidelines for at least the following reasons:
          </div>
          <div>
            <div *ngFor="let building of uiService.SqftDeclineBuildings">
          <div class="marketplace-decline-modal-subtitle-text">{{ building.reason }} Building {{ building.buildingNo }}</div>
        </div>
        </div>
          <div class="marketplace-decline-modal-contactus">
            <div class="marketplace-decline-modal-contactus-text">If you think the information returned is incorrect, please contact your underwriter.</div>
            <div class="marketplace-decline-modal-contactus-phone">
              <img class="phone-icon" src="./assets/img/icon-phone.png">
              <div class="marketplace-decline-modal-contactus-phone-text">1-800-922-8276</div>
            </div>
          </div>
          <div *ngIf="uiService.SqftDeclineBuildings.length!=uiService.SqftAllBuildings.length">
          <div class="marketplace-decline-modal-title-text height-60" *ngIf="uiService.SqftDeclineBuildings.length!=uiService.SqftAllBuildings.length">
            Would you like to proceed with quote for remaining building(s)?
          </div>
          <div class="exit-quote-modal-buttons-row">
            <button class="exit-quote-modal-no-button" (click)="uiService.SqftDeclineFlag = false;">NO</button>
            <button class="exit-quote-modal-yes-button" (click)="_doRemoveDeclinedBuildings();">YES</button>
          </div>
        </div>
        <div *ngIf="(uiService.SqftDeclineBuildings.length==uiService.SqftAllBuildings.length) && (dataService.businessOwners.BPLocations.length!=1)">
          <div class="marketplace-decline-modal-title-text height-60" >
            Would you like to proceed with quote for remaining location(s)?
          </div>
          <div class="exit-quote-modal-buttons-row">
            <button class="exit-quote-modal-no-button" (click)="uiService.SqftDeclineFlag = false;">NO</button>
            <button class="exit-quote-modal-yes-button" (click)="_doRemoveDeclinedLocation();">YES</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>