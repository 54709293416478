<div class="footer-wrapper">
    <!-- <div class="rectangle" *ngIf="router.url !== '/login'"></div> -->
    <div class="footer-content">
        <!-- <div class="padding"></div> -->
        <p class="copyright">&#169; {{ currentYear }} {{ staticData.copy_right_text }}</p>
        <ul>
            <li>
                <a [attr.href]="staticData.terms_link" target="_blank" rel="noreferrer">{{ staticData.termsName }}</a>
            </li>
            <li class="seperator">|</li>
            <li>
                <a [attr.href]="staticData.P_S_link" target="_blank" rel="noreferrer">{{ staticData.P_S_name }}</a>
            </li>
        </ul>
    </div>
</div>
