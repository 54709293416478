import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DataMappingService } from '../services/data-mapping.service';
import { UiService } from '../services/ui.service'
import { AgentDataMappingService } from '../services/agent-data-mapping.service';
import { PropertyDataMappingService } from '../services/property-data-mapping.service';
import { LiabilityDataMappingService } from '../services/liability-data-mapping.service';
import { OptionalDataMappingService } from '../services/optional-data-mapping.service';
import { DataService } from '../services/data.service';
import { PrefillService } from '../services/prefill.service';
import { AgentConfirmationService } from '../services/agent-confirmation.service';

@Component({
  selector: 'app-next-back',
  templateUrl: './next-back.component.html',
  styleUrls: ['./next-back.component.scss']
})
export class NextBackComponent implements OnInit {

  routes: any[] = [
    { currentPage: '/agent', nextPage: '/property-details', serviceName: this.dataMappingService.mapAgentFormDatatoFinalJson },
    { currentPage: '/property-details', nextPage: '/liability-details', serviceName: this.dataMappingService.mapPropertyDetailsFormDatatoFinalJson },
    { currentPage: '/liability-details', nextPage: '/optional-coverages', serviceName: this.dataMappingService.mapLiabilityFormDatatoFinalJson },
    { currentPage: '/optional-coverages', nextPage: '/uw-questions', serviceName: this.dataMappingService.mapOptionalCoveragesFormDatatoFinalJson },
    { currentPage: '/uw-questions', nextPage: '/quote-summary', serviceName: this.dataMappingService.mapUWQuestionsformDatatoFinalJson }
  ];

  currentRoute: string;

  constructor(public router: Router, public dataService: DataService, public dataMappingService: DataMappingService, public uiService: UiService, public agentDataMappingService: AgentDataMappingService,
    public propertyDataMappingService: PropertyDataMappingService, public liabilityDataMappingService: LiabilityDataMappingService, public optionalDataMappingService: OptionalDataMappingService,
    public prefillService: PrefillService, public agentConfirmationService: AgentConfirmationService) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRoute = val.url;
        console.log(this.currentRoute);
      }
    });
  }

  ngOnInit(): void {
  }

  // goToNextPage () {
  //   let currentPair = this.routes.find(pair => pair.currentPage == this.currentRoute);
  //   console.log(this.dataMappingService.currentForm)
  //   if(this.dataMappingService.currentForm.invalid) {
  //     console.log("Form invalid");
  //     //Custome validation(only after dataMappingService) for custom toggles - only required message - no formcontrol valiation
  //     if(currentPair.currentPage=='/uw-questions') {
  //       this.uiService.validateUWRequiredDisplay();
  //     }
  //     if(currentPair.currentPage=='/agent') {
  //       this.uiService.validateAgentRequiredDisplay();
  //     }
  //     if(currentPair.currentPage=='/liability-details') {
  //       this.uiService.validateLiabilityRequiredDisplay();
  //     }
  //     this.dataMappingService.currentForm.markAllAsTouched();
  //   } else {
  //     console.log("Form valid");
  //     switch (currentPair.currentPage) {
  //       case '/agent':
  //         this.agentConfirmationService.confirAgentInfoModalFlag = true;
  //         break;
  //      case '/property-details':

  //      if (this.uiService.addedLocations.length < 2 && this.uiService.LocationOneTab ||
  //       this.uiService.addedLocations.length < 3 && this.uiService.LocationOneTab && this.uiService.LocationTwoTab ||
  //       this.uiService.LocationOneTab && this.uiService.LocationTwoTab && this.uiService.LocationThreeTab) {
  //       this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
  //       this.prefillService.prefillData('3', 'liability-details', this.dataService);
  //     }
  //     else if (this.uiService.LocationOneTab && this.uiService.LocationTwoTab) {
  //       this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
  //       this.uiService.LocationThreeTab = true;
  //       this.uiService.LocationOneFlag = false;
  //       this.uiService.LocationTwoFlag = false;
  //       this.uiService.LocationThreeFlag = true;
  //       this.uiService.LocationTabs.next("3");

  //     }
  //     else if (this.uiService.LocationOneTab) {
  //       this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
  //       this.uiService.LocationTwoTab = true;
  //       this.uiService.LocationOneFlag = false;
  //       this.uiService.LocationTwoFlag = true;
  //       this.uiService.LocationThreeFlag = false;
  //       this.uiService.LocationTabs.next("2");

  //     }
  //     else {
  //       this.uiService.LocationOneTab = true;
  //       this.uiService.LocationOneFlag = true;
  //       this.uiService.LocationTwoFlag = false;
  //       this.uiService.LocationThreeFlag = false;
  //     }

  //   break;
  //       case '/liability-details':
  //         this.liabilityDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
  //         break;
  //       case '/optional-coverages':
  //         this.optionalDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
  //         break;
  //       case '/uw-questions':
  //         this.prefillService.prefillData('4', 'quote-summary', this.dataService);
  //         break;
  //       default:
  //         currentPair.serviceName(this.dataMappingService.currentForm);
  //     }

  //     if (currentPair.currentPage == '/liability-details' || currentPair.currentPage == '/optional-coverages') {
  //       // Default condition for all other pairs
  //       this.router.navigate([currentPair.nextPage]);
  //       // Separate condition for the pair with currentPage '/uw-questions' and nextPage '/quote-summary'
  //       // Write your code here for this pair, if needed
  //     }
  //   }
  // }

  //For prototype

  goToNextPage() {
    let currentPair = this.routes.find(pair => pair.currentPage == this.currentRoute);
    if (this.dataMappingService.currentForm.invalid) {
      console.log("Form invalid");
      console.log(this.dataMappingService.currentForm, "current form")
      //Custome validation(only after dataMappingService) for custom toggles - only required message - no formcontrol valiation
      if (currentPair.currentPage == '/uw-questions') {
        this.uiService.validateUWRequiredDisplay();
      }
      if (currentPair.currentPage == '/agent') {
        this.uiService.validateAgentRequiredDisplay();
      }
      if (currentPair.currentPage == '/liability-details') {
        this.uiService.validateLiabilityRequiredDisplay();
      }
      this.dataMappingService.currentForm.markAllAsTouched();
    } else {
      console.log("Form valid");
      switch (currentPair.currentPage) {
        case '/agent':
          this.agentConfirmationService.confirAgentInfoModalFlag = true;
          break;
        case '/property-details':
          this.uiService.PropertyScreenFlag = true;
          if (this.uiService.addedLocations.length < 2 && this.uiService.LocationOneTabVisited ||
            this.uiService.addedLocations.length < 3 && this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited ||
            this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited && this.uiService.LocationThreeTabVisited) {
            this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
            this.prefillService.prefillData('3', "liability-details", this.dataService);
            
          }
          else if (this.uiService.LocationOneTabVisited && this.uiService.LocationTwoTabVisited ) {
            this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
            this.prefillService.prefillData('3', "property-details", this.dataService);
          }
          else if (this.uiService.LocationOneTabVisited) {
            this.propertyDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
            this.prefillService.prefillData('3', "property-details", this.dataService);
          }
          else {
            this.uiService.LocationOneTab = true;
            this.uiService.LocationOneFlag = true;
            this.uiService.LocationTwoFlag = false;
            this.uiService.LocationThreeFlag = false;
          }

          break;
        case '/liability-details':
          this.liabilityDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
          break;
        case '/optional-coverages':
          this.optionalDataMappingService.mapDataBasedOnFlagName(this.dataMappingService, this.dataService);
          break;
        case '/uw-questions':
          this.prefillService.prefillData('4', 'quote-summary', this.dataService);
          break;
        default:
          currentPair.serviceName(this.dataMappingService.currentForm);
      }
      if (currentPair.currentPage == '/liability-details' || currentPair.currentPage == '/optional-coverages') {
        // Default condition for all other pairs
        console.log("navigate to next page")
        this.router.navigate([currentPair.nextPage]);
        // Separate condition for the pair with currentPage '/uw-questions' and nextPage '/quote-summary'
        // Write your code here for this pair, if needed
      }
    }




  }

  goToPreviousPage() {
    let currentPair = this.routes.find(pair => pair.nextPage == this.currentRoute);
    if (currentPair) {
      this.router.navigate([currentPair.currentPage]);
    }
  }
}
