import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { StepperComponent } from './stepper/stepper.component';
import { NextBackComponent } from './next-back/next-back.component';
import { CustomDatepickerComponent } from './custom-datepicker/custom-datepicker.component';



@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    StepperComponent,
    NextBackComponent,
    CustomDatepickerComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[FooterComponent,HeaderComponent,StepperComponent,NextBackComponent]
})
export class CoreModule { }
