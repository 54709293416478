import { HttpClient, HttpParameterCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { login } from "../../interfaces/login.modal";
import { authResp } from "../../interfaces/security.auth.response.modal";
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {
    constructor(private httpClient: HttpClient) {}

    authenticateUser(loginInput: login) {
        return this.httpClient.post<authResp>(environment.nodeserver + 'security/authenticate', loginInput);
    }

    decrypt(encryptedToken: string): Observable<any> {
        return this.httpClient.get(environment.nodeserver + 'security/decAuthenticate?encryptedToken=' + encodeURIComponent(encryptedToken));
    }
}