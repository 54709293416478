import { Injectable } from '@angular/core';
import { ContractorRequest } from '@app/interfaces/contractor-request.modal';
import { DropdownService } from '@app/core/services/dropdown.service';

@Injectable({
  providedIn: 'root'
})
export class ContractorDataService {

  constructor(public dropDownService: DropdownService) { }

  contractorData: ContractorRequest = {
    rate: "Y",
    draft: "N",
    fullModel: "Y",
    quickQuote: "N",
    quotePolicy: {
      CompanyCode: "80",
      CompanyName: "MAPFRE Insurance Company",
      effectiveDate: "",
      expirationDate: "",
      auditFrequency: "Non-Auditable",
      underwriterCode: "", // blank
      Insured: {
        BusinessName: "",
        InsuredType: "",
        yearsOfOperation: "",
        Address: {
          City: "",
          Line1: "",
          StateCode: "",
          StateDesc: "", // hard coded based on Gopal Comments
          ZipCode: "",
        },
        Contact: {
          ContactName: "",
          Phone1: "",
          EMail: ""
        },
        Extendednamedinsured1: {
          CExtendednamedinsured1: ""
        }
      },
      Producer: {
        ProducerCode: "", // hard coded based on Gopal Comments
        producerOfRecord: "Y",
        Contact: {
          ContactName: "",
          EMail: "",
          Phone1: "",
        },
      },
      BusinessOwners: {
        primaryState: "Massachusetts", // hard coded based on Gopal Comments
        liabMedicalExpensLmt: "1000000", // default values
        prodCompleteOperAggLmt: "2000000", // default values
        liabMedicalExpAggLmt: "2000000", // default values
        medicalExpensPerPerson: "5000", // default for true
        liabilityDeduApp: "",
        liabilityDeductibleType: "Per occurrence", // hard coded based on Gopal Comments
        liabilityDeductible: "",
        blanketRating: "N", // hard coded based on Gopal Comments
        IndRiskPremiumMod: "Y",
        equipmentBreakdown: "N",
        cEmpPracLiab: "Y",
        cEpliLimDed: "25000", // initialize value
        cDoesLocMtf: "N", // hardcoded based on gopal comments 
        CNoEmpMore: "",
        CNoEmpLess: "",
        cIsCyberCov: "",
        cCyberOption: "",
        cCyberCovLimit: "",
        CBlgExtRepCc: "",
        CExtRpCcOptions: "10",
        fuelTank: "N",
        cContraEnhaEndorsement: "Y",
        selfStorgFacilEnd: "N",
        residCleanServCovAp: "N",
        employeeDishonesty: "N",
        electronicData: "N",
        forgeryOrAlteration: "N",
        interruptionCompOper: "N",
        businessIncome: "N",
        hireNonownAutoLiab: "",
        hireNonownAutoLiabCov: "",
        hireAutoNonownLiabType: "Both",
        hiredAutoLiabLimit: "",
        nonownedAutoLiabLmt: "",
        nonOwnedAutoLiabCov: "",
        compAndFundTranFrd: "N",
        moldFungiWetDryRot: "N",
        apartLosDamCusAuto: "N",
        resLossDamCustAuto: "N",
        contractorsEndorsement: "Y",
        terrorismCoverage: "Y",
        leadPoisoning: "N",
        wantGenQstn: "Y",
        BPLocations: [{
          referenceObjectId: "1", // hardcoded based on gopal comments 
          copyMailingAddress: "Y", // hardcoded based on gopal comments 
          name: "", // hardcoded based on gopal comments 
          description: "", // hardcoded based on gopal comments 
          Address: {
            City: "", // hardcoded because i dont know where to map this
            Line1: "", // hardcoded because i dont know where to map this
            StateCode: "MA", // hardcoded because i dont know where to map this
            StateDesc: "Massachusetts", // hard coded based on Gopal Comments
            ZipCode: "", // hardcoded because i dont know where to map this
            CCoastalZone: "",
            cSmallContractor: ""
          },
          BPInlineOBJ: {
            incLmtFireDeptSc: "N",
            outdoorSigns: "N",
            moneyAndSecurity: "N",
            empDishonestyApp: "N",
            moldFungWetDryRtAp: "N", // hardcoded based on gopal comments 
            wtrBckupSmpOvrfl: "N",
            apartBuildCov: "N",
            restaurantsCov: "N",
          }
        }],
        BPBuilding: [{
          locationId: "1",
          building: "Y", // default value may change ?
          businessPersonalProp: "Y", // default value may change ?
          cMortgageInfo: "N", // hardcoded based on gopal comments 
          BuildingDescription: "", // hardcoded based on gopal comments 
          PropertyType: "", // hardcoded based on gopal comments 
          BuildingSquareFootage: "", // hardcoded based on gopal comments 
          ConstructionType: this.dropDownService.constructionOptions[1], // field default - updated based on tester comments
          BldOwnrAndSoleOccupant: "N", // hardcoded based on gopal comments 
          NumberOfEmployees: "0",
          DistanceFromFireStation: "", // hardcoded based on gopal comments 
          DistToNearestHydrant: "",
          PublicProtFireClas: "",
          autoSprinkleredSystem: "",
          Interest: "Tenant",
          RentalProperty: '',
          OwnerOccupiedSqFtge: "1500", // hardcoded based on gopal comments 
          cRatingBasis: "Replacement Cost",
          YearBuilt: "",
          cEffectiveAgeOfBldg: "", // hard coded based on Gopal Comments
          cRoof: "",
          cElectrical: "",
          cWaterHeater: "",
          cHeating: "",
          cPlumbing: "",
          NumberOfUnits: "",
          NumberOccupiedByOwners: "",
          NumberLeasedToOthers: "",
          NumberVacant: "",
          IsThereABasement: "N",
          PercentFinished: "0", // hard coded based on Gopal Comments
          BldReplacementCostEst: "", // hard coded based on Gopal Comments
          cReconstrConstr: "",
          resAuRepMnufcProcOcup: "",
          fireAlarmALocalAlarm: "",
          fireAlarmACenStnAlarm: "",
          fireAlarmAHrdwrSmkDet: "",
          fireAlarmABatopSmkDet: "",
          CrmProtAlmALocalAlm: "",
          CrmProtAlmACenStAlm: "",
          BldDeadboltDoorLocks: "Y", // hard coded based on Gopal Comments
          bldAnyOtherProtection: "", // hard coded based on Gopal Comments
          leadPoisoningAppl: "N",
          NoOfUnitsLetterCntrl:"0", // new - HARCODED CHECK WITH GOPAL
          supplLeadPoisonAppl: "N",
          fuelTankApplies: "N",
          dmgPremisesRentYou: "Y",
          dmgPremRentYouSelCov: "Basic",
          dmgPremRentYouLimit: "50000", // field default
          swimmingPool: "N",
          cBusinessIncExcl: "N",
          offPrmBsnsInter: "N",
          windstormOrHailExcl: "N",
          optionalBldgDedu: "Y",
          OptionalPropertyDedu: "500",
          windstrmHailPrcntDed: "", // hard coded based on Gopal Comments
          autoIncInInsurance: "", // hard coded based on Gopal Comments
          spoilage: "N",
          comCondUntOwner: "N",
          condominComUntOwnr: "N",
          foodContamination: "N",
          timeElement: "N", // hard coded based on Gopal Comments
          directDamage: "N", // hard coded based on Gopal Comments
          vacancyPermitEndors: "N",
          lossPayableClauses: "N",
          earthquakeCoverage: "N",
          BPBuildingCoverage: {
            BuildingLimitInsurance: "",
            includedBlanketRating: "N", // hard coded based on Gopal Comments
            incRebudExpFollDis: "N",
            greenUpgrades: "N",
            ordinanceLaw: "N",
            whRoofSurfaceLoss: "N", // hard coded based on Gopal Comments
            ltCovAcvBldRc: "N",
            debrisRemAddInsEndt: "N",
          },
          BPClassifications: {
            ClassCode: "",
            classificationDesc: "",
            BusinessDesc: "",
            BusinssPersonalPropLmt: "",
            liabilityExposure: "150000", // hard coded based on Gopal Comments
            AreaOccupied: "Percentage",
            ClassificationPercen: "100",
            cTenanatRelExp: "N",
            includedBlanketRating: "N",
            greenUpgrades: "N",
            utlSrvcDirectDmgApp: "N", // hard coded based on Gopal Comments
            electronicDataCovApp: "N", // hard coded based on Gopal Comments
            intOfCompOprCovApp: "N", // hard coded based on Gopal Comments
            incLmtAccountsRecv: "N",
            incLmtDpndntProp: "N", // hard coded based on Gopal Comments
            incLmtPaperRecord: "N", // hard coded based on Gopal Comments
            incLmtOutdoorProp: "N", // hard coded based on Gopal Comments
            specBppTmpAwayFrmPrm: "N", // hard coded based on Gopal Comments
            theftLimitations: "N", // hard coded based on Gopal Comments
            brandsAndLabels: "N",
            permYardsMaintainStr: "N", // hard coded based on Gopal Comments
            seasonalIncPercentage: "25", // hard coded based on Gopal Comments
            spoilageCovApp: "N",
            earthquakeCovApplies: "N",
            eqSprinklerLeakage: "N", // hard coded based on Gopal Comments
            medExpensExclusion: "N", // hard coded based on Gopal Comments
            amdAggLmtInsrnPrOb: "Y", // default value - may change ?
          },
        }],
        BPOptionalRTCCoverages: [],
        BPAdditionalInsured: [],
        BPContractorsEndorsement: {
          contrInstalCoverage: "Y", // hard coded based on Gopal Comments
          contrInstalLimit: "3000", // hard coded based on Gopal Comments
          contrToolsEqpmntCov: "Y",
          sublimitOption: "", // using data from the sample
          sublimit: "500", // field default
          blanketCov: "Y", // hard coded based on Gopal Comments
          blanketLimit: "3000",
          acvOption: "N",
          scheduledCov: "N",
          nonownedToolsOptCov: "N",
          empToolsOptionalCov: "N",
        },
        BPIRPMRating: [
          {
            ProvideBreak: "Y", // backend default
            StateName: "Massachusetts", // backend default
            BPIRPMRatingLevel: [
              {
                FactorType: "Both Combined", // backend default
                IrpmCovUk: "MA-Both Combined-Both Combined", // backend default
                OverrideModifier: "",
                TotalModifier: "",
              },
            ],
            BPIRPMOverridefactors: [
              {
                FactorType: "", //backend default
                UnderwriterRiskConcern: "",
                RiskFactor: "",
                OverrideReason: "",
                OverrideValue: "",
                TotalModifier: "",
              }
            ],
          },
        ],
        BPGeneralQuestionsMU: {
          BusinessDesc: "",
          otherLocations: "",
          CSelectSubmission: "New Business Policy to MAPFRE",
          cCompPolicy: "N",
          namePriorInsurer: "Other",
          PriorInsPremium: "0",
          ListOfPolicies: "None", 
          recentPolCancld: "",
          YrsExpClass: "5", // hard coded based on Gopal's comments
          LosseHistory: "",
          BPLossHistoryGeneralQuestions: [],
          BPCompanionPolicy: {
            CCompPolicyType: "",
            CCompPolicyNumberRef: ""
          },
          cReasons: "",
          // lossDetail: "",
          // lossDate: "",
          // lossPropReserv: "",
          // lossLiabReserv: "",
          ContractorResg: "123456",
          snowIceRemoval: "Y",
          privateDriveways: "Y",
          parkingLots: "Y",
          roadHighways: "Y",
          OwnerPartner: "28600",
          EmployeePayroll: "", // hard coded based on Gopal Comments
          TemporaryWorker: "0",
          totalPayroll: "",
          UiSubcontractorPay: "0", // hard coded based on Gopal Comments
          GlCertSubcntrsPay: "0",
          applicantSubcontract: "",
          applicantLawsuit: "",
          remarkTradeContractors: "Null",
        },
      },
    },
  };
}
