import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Renderer2,
  Input // import Input decorator
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";

@Directive({
  selector: "[onlyNumber]"
})
export class OnlyNumberDirective {
  constructor(
    private elmRef: ElementRef,
    public ngControl: NgControl
  ) {}

  @Input() allowDecimal: boolean = false; // declare allowDecimal as an input property

  @HostListener("input", ["$event"]) onInputChange(event) {
    const initalValue = this.elmRef.nativeElement.value;
    let newValue = initalValue.replace(/[^0-9\.]*/g, ""); // allow digits and decimal point

    if (!this.allowDecimal) {
      newValue = newValue.replace(/\./g, ""); // remove decimal point if not allowed
    }

    if (initalValue !== newValue) {
      this.elmRef.nativeElement.value = newValue;
      this.ngControl.control.setValue(newValue);
    }
  }
}