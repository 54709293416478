import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentInsurerDetailsComponent } from './lead/agent-insurer-details/agent-insurer-details.component';
import { PropertyDetailsComponent } from './lead/property-details/property-details.component';
import { LiabilityDetailsComponent } from './lead/liability-details/liability-details.component';
import { OptionalCoveragesComponent } from './lead/optional-coverages/optional-coverages.component';
import { UwQuestionsComponent } from './lead/uw-questions/uw-questions.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { CoreModule } from './core/core.module';
import { QuoteSummaryComponent } from './lead/quote-summary/quote-summary.component';
import { PhoneMaskDirective } from './shared/phone-mask.directive';
import { OnlyNumberDirective } from './shared/only-number.directive';
import { CurrencyInputDirective } from './shared/currency-input.directive';
import { CurrencyNumericInputDirective } from './shared/currenyNumeric-input.directive'
import { LoginComponent } from './lead/login/login.component';
import { AuthService } from './core/services/auth.service';
import { AuthGuardService } from './core/services/authguard.service';
import { ComponentService } from './core/services/component.service';
import { UiService } from './core/services/ui.service';
import { AgentConfirmationService } from './core/services/agent-confirmation.service';
import { DeveloperJsonViewComponent } from './developer-json-view/developer-json-view.component';
import { HttpClientModule } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { RequiredFieldValidationComponent } from './utils/RequiredFieldValidationComponent';
import { CUSTOM_FORMATS } from './core/custom-datepicker/custom-datepicker.component';
import { ConvertDollarPipe } from './utils/convert-dollar.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AgentInsurerDetailsComponent,
    PropertyDetailsComponent,
    LiabilityDetailsComponent,
    OptionalCoveragesComponent,
    UwQuestionsComponent,
    QuoteSummaryComponent,
    PhoneMaskDirective,
    OnlyNumberDirective,
    CurrencyInputDirective,
    CurrencyNumericInputDirective,
    LoginComponent,
    DeveloperJsonViewComponent,
    RequiredFieldValidationComponent,
    ConvertDollarPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCardModule,
    MatTooltipModule,
    HttpClientModule,
    NgSelectModule,
    AutocompleteLibModule,
    MatMomentDateModule,
    NoopAnimationsModule
  ],
  exports: [PhoneMaskDirective, MatDatepickerModule],
  providers: [DatePipe, CurrencyPipe, AuthService, AuthGuardService, ComponentService, UiService, AgentConfirmationService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
