import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators, FormGroupDirective, NgForm, FormGroup, FormBuilder } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { DataService } from "@app/core/services/data.service";
import { DataMappingService } from "@app/core/services/data-mapping.service";
import { CompanyCode, CompanyName, effectiveDate, expirationDate, auditFrequency, underwriterCode, ProducerCode, producerOfRecord, yearsOfOperation, City, Line1, StateCode, StateDesc, ZipCode, ContactName, Phone1, referenceObjectId, copyMailingAddress, description, ClassCode, classificationDesc, LosseHistory, ContactConst, EMail, InsuredConst, BusinessName, InsuredType, AddressConst, ProducerConst, BusinessOwnersConst, BPLocationsConst, name, BPBuildingConst, BPClassificationsConst, BPGeneralQuestionsMUConst, BusinessDesc, lossDate, lossDetail, lossLiabReserv, lossPropReserv, AddlossDate, AddlossDetail, AddlossLiabReserv, AddlossPropReserv, CExtendednamedinsured1, cSmallContractor, isMailingLocationAddressSame, isInsuredSmallContractor } from "../../contractor.constant";
import * as contractorConstants from '../../contractor.constant';
import { ComponentService } from '@app/core/services/component.service';
import { DeveloperService } from '@app/core/services/developer.service';
import { ProducerOneService } from "@app/core/services/producerone.service";
import { DropdownService } from "@app/core/services/dropdown.service";
import { Address } from "@app/interfaces/address.modal";
import { normalizedAddress } from '@app/interfaces/trillium.address.response.modal';
import classcodesdescription from '../../../assets/json/ClassCodesDescription.json';
import { TrilliumService } from "../../core/services/address.service";
import { Subscription } from "rxjs";
import { UiService } from '@app/core/services/ui.service';
import { BusinessAttribute } from '@app/core/enums/business-attributes.enum';
import { CustomDatepickerComponent } from "@app/core/custom-datepicker/custom-datepicker.component";
import { formatDate } from "@angular/common";
import { v4 as uuidv4 } from 'uuid';

type Addresses = {
  city?: string,
  county?: string,
  state?: string,
  zipcode?: string
}

@Component({
  selector: "app-agent-insurer-details",
  templateUrl: "./agent-insurer-details.component.html",
  styleUrls: ["./agent-insurer-details.component.scss"]
})

export class AgentInsurerDetailsComponent implements OnInit,OnDestroy {
  public startDate: Date;
  public smallContractorselectedVal: string;
  public mailingAddressselectedVal: string;
  public secondLocationselectedVal: string;
  public agencyCodes: Array<string> = [];
  public agencyAddress: string;
  public agencyName: string;
  contractorConstants = contractorConstants;
  BusinessAttribute = BusinessAttribute;
  emailFormControl = new FormControl("", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]);
  endDate: any;
  year: any;
  agentForm: FormGroup;
  showLoss: boolean = false;
  todayDate: Date = new Date();

  datepicker = CustomDatepickerComponent;

  keyword = "classcode";
  descriptionKeyword = "description";
  public classcodes = classcodesdescription;
  myScriptElement: HTMLScriptElement;
  selectedAddress: Addresses = {};
  selectedMailAddress: Addresses = {};
  addressData: Array<Addresses> = []
  addressObj: Addresses = {
    city: "",
    county: "",
    state: ""
  };

  filteredAddressDatas: Array<Addresses> = [];
  filteredMailAddressDatas: Array<Addresses> = [];
  customAddressMailModalFlag: boolean = false;
  stateWrongFlag: boolean = false;
  bpLocationAddressObj: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  bpLocationNormalizedAddressObj: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  selectedNormalizedAddress: Address = {
    City: "",
    Line1: "",
    StateCode: "",
    ZipCode: "",
    StateDesc: ""
  }

  InsuredLocations = [
    {
      LocationAddress: "Line1",
      LocationCity: "City",
      LocationState: "StateDesc",
      LocationZipCode: "ZipCode",
      LocationCoastal: "coastal1",
      classificationDesc: "classificationDesc",
      BusinessDesc: "BusinessDesc",
      ClassCode: "ClassCode",
      smallContractor:"cSmallContractor",
      ErrorMessage: false
    }
  ]
  removeLocSubscription:Subscription;

  subscription: Subscription;
  bpLocationNormalizedAddressFlag: boolean = false;
  addLoss: boolean = false;
  public addressBpLocation: string = '10 E Main St, Dorchester, 02124';
  public blanketRatingselectedVal: string;

  constructor(private datePipe: DatePipe, private router: Router, private fb: FormBuilder, public dataService: DataService, public developerService: DeveloperService, public dataMappingService: DataMappingService,
    private componentService: ComponentService, private producerOneService: ProducerOneService, public dropDownService: DropdownService, private addressTrilliumService: TrilliumService,
    public uiService: UiService) {
    this.componentService.currentComponent = 'insured';
    this.componentService.currentComponentChanged.emit('insured');
  }

  initializeForm() {
    const quotePolicy = this.dataService.quote_policy;
    const producer = this.dataService.producer;
    const insured = this.dataService.insured;
    const businessOwners = this.dataService.businessOwners;
    const bPClassifications = businessOwners.BPBuilding[0].BPClassifications;

    this.agentForm = new FormGroup({
      productName: new FormControl(""),
      [CompanyCode]: new FormControl(quotePolicy.CompanyCode),
      [CompanyName]: new FormControl(quotePolicy.CompanyName),
      [effectiveDate]: new FormControl(quotePolicy.effectiveDate, Validators.required),
      [expirationDate]: new FormControl(quotePolicy.expirationDate),
      [auditFrequency]: new FormControl(quotePolicy.auditFrequency),
      [underwriterCode]: new FormControl(quotePolicy.underwriterCode),

      // producer
      [ProducerConst]: new FormGroup({
        [ProducerCode]: new FormControl(this.dataMappingService.actualProducerCode, Validators.required),
        [producerOfRecord]: new FormControl(producer.producerOfRecord),
        [ContactConst]: new FormGroup({
          [ContactName]: new FormControl(producer.Contact.ContactName, Validators.required),
          [EMail]: new FormControl(producer.Contact.EMail, [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
          [Phone1]: new FormControl(producer.Contact.Phone1, Validators.required)
        })
      }),
      //insured
      [InsuredConst]: new FormGroup({
        nameType: new FormControl(""), //not present in json
        [BusinessName]: new FormControl(insured.BusinessName, Validators.required),
        [CExtendednamedinsured1]: new FormControl(insured.Extendednamedinsured1.CExtendednamedinsured1),
        [InsuredType]: new FormControl(insured.InsuredType, Validators.required),
        [yearsOfOperation]: new FormControl(insured.yearsOfOperation, Validators.required),
        [AddressConst]: new FormGroup({
          [City]: new FormControl(insured.Address.City),
          [Line1]: new FormControl(insured.Address.Line1),
          [StateCode]: new FormControl(insured.Address.StateCode),
          [StateDesc]: new FormControl(insured.Address.StateDesc),
          [ZipCode]: new FormControl(insured.Address.ZipCode),
          cCoastalZone: new FormControl("") //not present in Json
        }),
        [ContactConst]: new FormGroup({
          BoFirstName: new FormControl(this.dataMappingService.firstName),
          BoLastName: new FormControl(this.dataMappingService.lastName),
          [ContactName]: new FormControl(insured.Contact.ContactName),
          [EMail]: new FormControl(insured.Contact.EMail, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]), //field not given for Insured Email?
          [Phone1]: new FormControl(insured.Contact.Phone1,Validators.required)
        })
      }),

      [BusinessOwnersConst]: new FormGroup({
        blanketRating:new FormControl(""),
        [BPLocationsConst]: new FormGroup({
          [copyMailingAddress]: new FormControl(businessOwners.BPLocations[0].copyMailingAddress), //Is Mailing address different than Location address?
          [name]: new FormControl(businessOwners.BPLocations[0].name),
          [description]: new FormControl(businessOwners.BPLocations[0].description),
          [AddressConst]: new FormGroup({
            [City]: new FormControl(businessOwners.BPLocations[0].Address.City, Validators.required),
            [Line1]: new FormControl(businessOwners.BPLocations[0].Address.Line1, Validators.required),
            [StateCode]: new FormControl(businessOwners.BPLocations[0].Address.StateCode, Validators.required),
            [StateDesc]: new FormControl(businessOwners.BPLocations[0].Address.StateDesc),
            [ZipCode]: new FormControl(businessOwners.BPLocations[0].Address.ZipCode, Validators.required),
            [cSmallContractor]: new FormControl(businessOwners.BPLocations[0].Address.cSmallContractor),
            coastal1: new FormControl(businessOwners.BPLocations[0].Address.cSmallContractor?true:false)
          })
        }),
        [BPBuildingConst]: new FormGroup({
          [ClassCode]: new FormControl(this.dataMappingService.originalClassCodeObj.classcode, Validators.required),
          [classificationDesc]: new FormControl(this.dataMappingService.originalClassCodeObj.description, Validators.required),
          [BusinessDesc]: new FormControl(this.dataMappingService.originalClassCodeObj.businessDesc),
        })
      }),

      [LosseHistory]: new FormControl(businessOwners.BPGeneralQuestionsMU.LosseHistory, Validators.required),
      [lossDate]: new FormControl(''),
      [lossDetail]: new FormControl(''),
      [lossPropReserv]: new FormControl(''),
      [lossLiabReserv]: new FormControl(''),
      [AddlossDate]: new FormControl(""),
      [AddlossDetail]: new FormControl(""),
      [AddlossPropReserv]: new FormControl(""),
      [AddlossLiabReserv]: new FormControl(""),
      [isMailingLocationAddressSame]: new FormControl("", Validators.required),
      [isInsuredSmallContractor]: new FormControl(""),
      moreThan3Locations: new FormControl(this.uiService.moreThan3LocationselectedVal, Validators.required)
    });
  }

  get agent() {
    return this.agentForm.controls;
  }

  get contact() {
    return this.agentForm.get("Producer").get("Contact") as FormGroup;
  }

  get insured() {
    return this.agentForm.get("Insured") as FormGroup;
  }

  get insuredAddress() {
    return this.agentForm.get("Insured").get("Address") as FormGroup;
  }

  get insuredContact() {
    return this.agentForm.get("Insured").get("Contact") as FormGroup;
  }

  get boBpLocationAddress() {
    return this.agentForm.get("BusinessOwners").get("BPLocations").get("Address") as FormGroup;
  }

  get boBPClassifications() {
    return this.agentForm.get("BusinessOwners").get("BPBuilding") as FormGroup;
  }

  get boBPGeneralQuestionsMu() {
    return this.agentForm.get("BusinessOwners").get("BPGeneralQuestionsMU") as FormGroup;
  }

  goToPage(pageName: string, next?: boolean): void {
    if (next) {
      this.dataMappingService.mapAgentFormDatatoFinalJson(this.agentForm);
    }
    this.router.navigate([`${pageName}`]);
  }

  ngOnInit() {
   this.uiService.checkForUniqueId();
  
    this.initializeForm();
    this.dataMappingService.currentForm = this.agentForm;
    // this is to retain the value previously selected.
    this.prefillData()
    this.mailingAddressselectedVal = this.dataMappingService.isMailAddressSameAsLocationSelectedVal;
    if (this.mailingAddressselectedVal) {
      this.uiService.agentMailingAddressSameRequiredFlag = false;
      this.agentForm.get(isMailingLocationAddressSame).setValue(this.mailingAddressselectedVal);
    }
    if(this.mailingAddressselectedVal=='Y'){
      this.disableMailingAddressFields();
    }
    if (this.uiService.isInsuredSmallContractorSelected && this.uiService.coastalZip) {
      this.onSmallContractorValChange(this.dataMappingService.YNToBool(this.uiService.isInsuredSmallContractorSelectedVal));
    }
    // this.secondLocationValChange(false);
    this.fetchAgencyCodes(sessionStorage.getItem("username"));
    if (this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length >= 1) {
      this.setLossData();
      if (this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length == 2) {
        this.setAddLossData();
      }
    }
    this.fetchAgencyDetails(null);

    this.onBlanketRatingValChange(this.uiService.blancketFields);
    this.updateMailingAddressValues();
    this.removeDeclinedLocations();
    this.secondLocationselectedVal=this.uiService.moreThan3LocationselectedVal;

  }

  fetchAgencyCodes(username) {
    this.producerOneService.getAgencyCodes(username).subscribe(
      agencyCodesResp => {
        if (agencyCodesResp && agencyCodesResp.apiOutput && agencyCodesResp.apiOutput.result) {
          for (const code of agencyCodesResp.apiOutput.result) {
            this.agencyCodes.push(code.agencyCode);
          }
        }
      }
    );
  }

  onSmallContractorValChange(smallContractorCheck) {

    if (smallContractorCheck == true) {
      this.smallContractorselectedVal = "Y";
      this.uiService.smallContractorInd = "1";
      this.setSmallContractorValue(this.smallContractorselectedVal);
      this.uiService.callMarketplace('1A', '1', true);
    } else {
      this.smallContractorselectedVal = "N";
      this.uiService.smallContractorInd = "0";
      this.setSmallContractorValue(this.smallContractorselectedVal);
      this.uiService.callMarketplace('1A', '0', true);
    }
    this.uiService.isInsuredSmallContractorRequiredFlag = false;
    this.uiService.isInsuredSmallContractorSelectedVal = this.smallContractorselectedVal;
    this.uiService.isInsuredSmallContractorSelected = 'Y';
    this.agentForm.get(isInsuredSmallContractor).setValue(this.smallContractorselectedVal);
  }
  setSmallContractorValue(x){
   
    this.InsuredLocations.forEach((d)=>{
      let lc=d.LocationCoastal;
      if(this.agentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + lc).value){
        let s=d.smallContractor;
        this.agentForm.get(BusinessOwnersConst + '.' + BPLocationsConst + '.' + AddressConst + '.' + s).setValue(x);
      }
    })
  }
  setInsuredAddressValue(address: Address) {
    this.agentForm.get("Insured.Address." + City).setValue(address.City);
    this.agentForm.get("Insured.Address." + Line1).setValue(address.Line1);
    this.agentForm.get("Insured.Address." + ZipCode).setValue(address.ZipCode);
    this.agentForm.get("Insured.Address." + StateCode).setValue(address.StateCode);
    this.agentForm.get("Insured.Address." + StateDesc).setValue(address.StateDesc);
  }

  onMailingAddressValChange(check1) {
    if (check1) {
      this.mailingAddressselectedVal = 'Y'
      this._doOpenCopyLocationModal();
    } else {
      this.mailingAddressselectedVal = 'N'
      this.clearMailingAddressFields();
      this.enableMailingAddressFields();
      this.makeUntouched()
    
      this.uiService.confirmedMailingLocation = '';
      this.uiService.mailingLocationSelected = '';
    }
    this.dataMappingService.isMailAddressSameAsLocationSelectedVal = this.mailingAddressselectedVal;

    this.uiService.agentMailingAddressSameRequiredFlag = false;
    this.agentForm.get(isMailingLocationAddressSame).setValue(this.mailingAddressselectedVal);
    this.updateInsuredMailiingAddress(this.mailingAddressselectedVal == 'N');


  }

  updateInsuredMailiingAddress(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get("Insured.Address." + City), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get("Insured.Address." + Line1), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get("Insured.Address." + ZipCode), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get("Insured.Address." + StateCode), condition);
  }

  secondLocationValChange(secondLocationCheck) {
    if (secondLocationCheck == true) {
      this.secondLocationselectedVal = "Y";
    } else {
      this.secondLocationselectedVal = "N";
    }
    this.uiService.moreThan3LocationsRequiredFlag = false;
    this.uiService.moreThan3LocationselectedVal=this.secondLocationselectedVal;
    this.agentForm.get("moreThan3Locations").setValue(this.secondLocationselectedVal);
  }

  calculateEndDate(event: any) {

    const startDate = this.agentForm.controls[contractorConstants.effectiveDate].value;
    let effectiveStartDate = new Date(startDate);
    this.year = effectiveStartDate.getFullYear();

    let endDate = new Date;

    //Policy end date calculation to take leap years into account
    if (effectiveStartDate.getMonth() == 1 && effectiveStartDate.getDate() == 29) {
      endDate = new Date(
        this.year + 1,
        effectiveStartDate.getMonth(),
        effectiveStartDate.getDate() - 1
      );
    } else {
      endDate = new Date(
        this.year + 1,
        effectiveStartDate.getMonth(),
        effectiveStartDate.getDate()
      );
    }

    //Policies before 12/28/2023 will show info set A, policies on or after will show set B
    let policyMarginDate = new Date(2023, 11, 28);

    this.uiService.isDateAfter = (effectiveStartDate.getTime() >= policyMarginDate.getTime()) ? true : false;
    this.dataMappingService.isEffectiveDateAfterMargin = this.uiService.isDateAfter;

    this.agentForm.controls[contractorConstants.expirationDate].setValue(this.dataMappingService.formatDate(endDate, false));
    this.agentForm.controls[contractorConstants.effectiveDate].setValue(this.dataMappingService.formatDate(startDate, true), { onlyself: true });

  }

  getFormattedDate() {
    if (this.agentForm.controls[contractorConstants.expirationDate].value)
      return this.datePipe.transform(this.agentForm.controls[contractorConstants.expirationDate].value, 'MM/dd/yyyy');
  }

  formatLossDate(event: any) {
    const lossDate = this.agentForm.controls[contractorConstants.lossDate].value;
    this.agentForm.controls[contractorConstants.lossDate].setValue(this.dataMappingService.formatDate(lossDate, true), { onlyself: true })
  }
  formatAddLossDate(event: any) {
    const addLossDate = this.agentForm.controls[contractorConstants.AddlossDate].value;
    this.agentForm.controls[contractorConstants.AddlossDate].setValue(this.dataMappingService.formatDate(addLossDate, true), { onlyself: true })
  }


  fetchAgencyDetails(event: any) {
    const ProducerCodeValue = this.agentForm.get("Producer." + ProducerCode).value;
    if (ProducerCodeValue != '') {
      this.producerOneService.getAgencyDetails(ProducerCodeValue).subscribe(
        agencyDetailsResp => {
          if (agencyDetailsResp && agencyDetailsResp.apiOutput && agencyDetailsResp.apiOutput.result) {
            this.agencyName = agencyDetailsResp.apiOutput.result.agencyName;
            this.agencyAddress = agencyDetailsResp.apiOutput.result.agencyAddress;
          }
        },
        error => {
          console.log(error);
          this.agencyName = '';
          this.agencyAddress = '';
        }
      );
    } else {
      this.agencyName = '';
      this.agencyAddress = '';
    }
  }

  selectEvent(item, ClassCodeObj, index) {
    this.boBPClassifications.get(ClassCodeObj.ClassCode).setValue(item.classcode);
    this.boBPClassifications.get(ClassCodeObj.classificationDesc).setValue(item.description);
    this.ClassCodeObjPreapre(index, item);
    this.dataMappingService.setBusinessFlag(this.dataMappingService.originalClassCodeObj.propertytype);
    if(this.uiService.agentFirstVisit){
      this.dataService.current_business_request = this.dataService.instantiateRequest();
    }
   
    console.log(`Business Flag : ${JSON.stringify(this.dataMappingService.businessFlags)}`);
    this.uiService.natureOfBusines = this.dataMappingService.originalClassCodeObj.propertytype;

    this.ValidateClassCodes(ClassCodeObj, item);
  }

  selectDescriptionEvent(item, ClassCodeObj, index) {
    this.boBPClassifications.get(ClassCodeObj.ClassCode).setValue(item.classcode);
    this.boBPClassifications.get(ClassCodeObj.classificationDesc).setValue(item.description);
    console.log(item, ClassCodeObj)
    this.ClassCodeObjPreapre(index, item);
    console.log(this.dataMappingService.originalClassCodeObj)
    this.dataMappingService.setBusinessFlag(this.dataMappingService.originalClassCodeObj.propertytype);
    if(this.uiService.agentFirstVisit){
      this.dataService.current_business_request = this.dataService.instantiateRequest();
    }
    
    console.log(`Business Flag : ${JSON.stringify(this.dataMappingService.businessFlags)}`);

    this.uiService.natureOfBusines = this.dataMappingService.originalClassCodeObj.propertytype;
    if (this.dataMappingService.businessFlags[BusinessAttribute.MERCANTILE_RISKS]) {
      this.uiService.checkBewarageStore();
    }
    this.ValidateClassCodes(ClassCodeObj, item);
  }

  clearClassCode(item, ClassCodeObj, index) {
    this.boBPClassifications.get(ClassCodeObj.ClassCode).setValue("");
    this.ClassCodeObjPreapre(index, item);
    this.uiService.natureOfBusines = "";
  }
  clearDescription(item, ClassCodeObj, index) {
    this.boBPClassifications.get(ClassCodeObj.classificationDesc).setValue("");
    this.ClassCodeObjPreapre(index, item);
    this.uiService.natureOfBusines = "";
  }

  ClassCodeObjPreapre(index, item) {
   if(item != undefined){
    if (index === 0) {
      this.dataMappingService.originalClassCodeObj.classcode = item.classcode;
      this.dataMappingService.originalClassCodeObj.description = item.description;
      this.dataMappingService.originalClassCodeObj.propertytype = item.propertytype;
    }
    else if (index === 1) {
      this.dataMappingService.originalClassCodeObj.classcode2 = item.classcode;
      this.dataMappingService.originalClassCodeObj.description2 = item.description;
      this.dataMappingService.originalClassCodeObj.propertytype2 = item.propertytype;
    }
    else {
      this.dataMappingService.originalClassCodeObj.classcode3 = item.classcode;
      this.dataMappingService.originalClassCodeObj.description3 = item.description;
      this.dataMappingService.originalClassCodeObj.propertytype3 = item.propertytype;
    }
   }
   else {
    if (index === 0) {
      this.dataMappingService.originalClassCodeObj.classcode = "";
      this.dataMappingService.originalClassCodeObj.description = "";
      this.dataMappingService.originalClassCodeObj.propertytype = "";
    }
    else if (index === 1) {
      this.dataMappingService.originalClassCodeObj.classcode2 = "";
      this.dataMappingService.originalClassCodeObj.description2 = "";
      this.dataMappingService.originalClassCodeObj.propertytype2 = "";
    }
    else {
      this.dataMappingService.originalClassCodeObj.classcode3 = "";
      this.dataMappingService.originalClassCodeObj.description3 = "";
      this.dataMappingService.originalClassCodeObj.propertytype3 = "";
    }
   }
  }

  ValidateClassCodes(ClassCodeObj, item) {
    if (this.InsuredLocations.length > 1) {
      
      for (const [index, [key, value]] of Object.entries(Object.entries(this.dataMappingService.originalClassCodeObj))) {
        if (key.includes('propertytype') && value != "") {
          if (value != item.propertytype) {
            this.boBPClassifications.get(ClassCodeObj.classificationDesc).setErrors({ 'Not Same NOB': true });
            this.boBPClassifications.get(ClassCodeObj.ClassCode).setErrors({ 'Not Same NOB': true });
            this.boBPClassifications.get(ClassCodeObj.classificationDesc).setValue("");
            this.dataMappingService.originalClassCodeObj[ClassCodeObj.classificationDesc] = "";
            this.dataMappingService.originalClassCodeObj[ClassCodeObj.ClassCode] = "";
            this.boBPClassifications.get(ClassCodeObj.ClassCode).setValue("");
            this.uiService.ClassCodeAlertModal = true;
          }
        }
      }
    }
  }

  customFilter = function (passedData: any[], query: string): any[] {
    let cusFilterArray: any = [];
    passedData.filter(x => {
      if (JSON.stringify(x.classcode).toLowerCase().includes(query.toLowerCase())) {
        cusFilterArray.push(x);
      }
    });

    return cusFilterArray;
  };

  customFilterDescription = function (passedData: any[], query: string): any[] {
    let cusFilterArray: any = [];
    passedData.filter(x => {
      if (JSON.stringify(x).toLowerCase().includes(query.toLowerCase())) {
        cusFilterArray.push(x);
      }
    });

    return cusFilterArray;
  };

  fetchByZipCode(location) {
    this.uiService.workingLocation = location;
    //smallContractorselectedVal default to empty when ever changes in zipcode to avoid default selection while changing between zipcodes
    this.smallContractorselectedVal = "";
    let zip = this.boBpLocationAddress.get(location.LocationZipCode).value;
    this.addressTrilliumService.getCities(zip).subscribe(resp => {
      this.addressData = resp.apiOutput.result;
      if (this.addressData.length > 1) {
        let tempStateCode = this.boBpLocationAddress.get(contractorConstants.StateCode).value.toLowerCase();
        //city modal height calculation starts
        let cityCount: number = this.addressData.length > 4 ? 4 : this.addressData.length;

        this.uiService.select_city_modal_height = cityCount * 188;
        this.uiService.cities_group_height = cityCount * 84;
        this.uiService.select_city_modal_count = this.addressData.length;
        //city modal height calculation end
        if (this.addressData.filter(address => address.state?.toLowerCase() == tempStateCode).length > 0) {
          this.uiService.filteredAddressDatas = this.addressData;

          this.uiService.isZipLoading = true;
        } else {
          this.boBpLocationAddress.get(location.LocationCity).setValue("");
          this.uiService.stateWrongFlag = true;
          this.uiService.isZipLoading = false;
        }
      } else {
        if (this.boBpLocationAddress.get(contractorConstants.StateCode).value.toLowerCase() == this.addressData[0].state?.toLowerCase()) {
          this.boBpLocationAddress.get(location.LocationCity).setValue(this.addressData[0].city);
          this.uiService.selectedAddress = {};
          //calling address normalization
          this.uiService.processBpLocationAddressNormalization(this.dataMappingService.currentForm);
          this.uiService.isCoastalZipcode(zip, this.addressData[0].city);
        } else {
          this.boBpLocationAddress.get(location.LocationCity).setValue("");
          this.uiService.stateWrongFlag = true;
        }
      }
    });
  }

  fetchMailByZipCode() {
    let zip = this.insuredAddress.get(contractorConstants.ZipCode).value;
    this.addressTrilliumService.getCities(zip).subscribe(resp => {
      this.addressData = resp.apiOutput.result;
      if (this.addressData.length != 0) {
        if (this.addressData.length > 1) {
          //city modal height calculation starts
          let cityCount: number = this.addressData.length > 4 ? 4 : this.addressData.length;

          this.uiService.select_mail_city_modal_height = cityCount * 188;
          this.uiService.cities_mail_group_height = cityCount * 84;
          this.uiService.select_mail_city_modal_count = this.addressData.length;
          //city modal height calculation end

          this.filteredMailAddressDatas = this.addressData;
          this.uiService.filteredMailAddressDatas = this.addressData;
          this.uiService.isZipMailLoading = true;
        } else {
          this.insuredAddress.get(contractorConstants.City).setValue(this.addressData[0].city);
          this.insuredAddress.get(contractorConstants.StateCode).setValue(this.addressData[0].state);

          //calling address normalization
          this.uiService.processBpLocationMailAddressNormalization(this.dataMappingService.currentForm);
        }
      } else {
        this.uiService.mailZipWrongFlag = true;
        this.uiService.selectedMailAddress = {};
      }
    });
  }

  toggleAddLossForm() {
    this.addLoss = this.showLoss ? !this.addLoss : this.showLoss;
    this.updateAddLossMandatoryRequired(this.addLoss);
    const businessOwners = this.dataService.businessOwners;
    if (!this.addLoss) {
      this.agentForm.get(AddlossDate).setValue('');
      this.agentForm.get(AddlossDetail).setValue('');
      this.agentForm.get(AddlossPropReserv).setValue('');
      this.agentForm.get(AddlossLiabReserv).setValue('');
      while (this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length > 1) {
        this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.pop();
      }
      return;
    }
    businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.push({
      lossDate: '',
      lossDetail: '',
      lossLiabReserv: '',
      lossPropReserv: '',
      LossClosed: 'N'
    })
    this.agentForm.get(AddlossDate).setValue('');
    this.agentForm.get(AddlossDetail).setValue('');
    this.agentForm.get(AddlossPropReserv).setValue('');
    this.agentForm.get(AddlossLiabReserv).setValue('');
  }

  updateAddLossMandatoryRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(AddlossDate), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(AddlossDetail), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(AddlossPropReserv), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(AddlossLiabReserv), condition);
  }

  toggleLossForm() {
    const businessOwners = this.dataService.businessOwners;
    if (this.agentForm.get(contractorConstants.LosseHistory).value == this.dropDownService.lossOptions[3].value) {
      this.showLoss = true;
      this.updateLossMandatoryRequired(this.showLoss);
      this.agentForm.get(lossDate).setValue('');
      this.agentForm.get(lossDetail).setValue('');
      this.agentForm.get(lossPropReserv).setValue('');
      this.agentForm.get(lossLiabReserv).setValue('');
    } else {
      this.showLoss = false;
      this.updateLossMandatoryRequired(this.showLoss);
      this.toggleAddLossForm();
      this.agentForm.get(lossDate).setValue('');
      this.agentForm.get(lossDetail).setValue('');
      this.agentForm.get(lossPropReserv).setValue('');
      this.agentForm.get(lossLiabReserv).setValue('');
      while (this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.length > 0) {
        this.dataService.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.pop();
      }
      return;
    }
    //this.updateLossMandatoryRequired(this.showLoss);
    businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions.push({
      lossDate: '',
      lossDetail: '',
      lossLiabReserv: '',
      lossPropReserv: '',
      LossClosed: 'N'
    })
  }

  updateLossMandatoryRequired(condition: boolean) {
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(lossDate), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(lossDetail), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(lossPropReserv), condition);
    this.uiService.updateFormControlValidatorBasedOnCondition(this.agentForm.get(lossLiabReserv), condition);
  }

  setLossData() {
    const businessOwners = this.dataService.businessOwners;
    this.showLoss = true;
    this.agentForm.get(lossDate).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossDate);
    this.agentForm.get(lossDetail).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossDetail);
    this.agentForm.get(lossPropReserv).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossPropReserv);
    this.agentForm.get(lossLiabReserv).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[0].lossLiabReserv);
  }

  setAddLossData() {
    const businessOwners = this.dataService.businessOwners;
    this.addLoss = true;
    this.agentForm.get(AddlossDate).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossDate);
    this.agentForm.get(AddlossDetail).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossDetail);
    this.agentForm.get(AddlossPropReserv).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossPropReserv);
    this.agentForm.get(AddlossLiabReserv).setValue(businessOwners.BPGeneralQuestionsMU.BPLossHistoryGeneralQuestions[1].lossLiabReserv);
  }

  showDiv = {
    loss: false
  };

  searchIconState: boolean = true;

  classSearchIcon() {
    if (this.searchIconState) {
      this.searchIconState = false;
    } else {
      this.searchIconState = true;
    }
  }



  _doAddMultiLocation() {
    if (this.InsuredLocations.length == 1) {
      let x = {
        LocationAddress: "Line2",
        LocationCity: "City2",
        LocationState: "StateDesc2",
        LocationZipCode: "ZipCode2",
        LocationCoastal: "coastal2",
        classificationDesc: "classificationDesc2",
        BusinessDesc: "BusinessDesc2",
        ClassCode: "ClassCode2",
        smallContractor:"cSmallContractor2",
        ErrorMessage: false,
      }
      this.addControls(x,true)
      this.InsuredLocations.push(x);
      this.uiService.addedLocations = this.InsuredLocations;
    }
    else if (this.InsuredLocations.length == 2) {
      let x = {
        LocationAddress: "Line3",
        LocationCity: "City3",
        LocationState: "StateDesc3",
        LocationZipCode: "ZipCode3",
        LocationCoastal: "coastal3",
        classificationDesc: "classificationDesc3",
        BusinessDesc: "BusinessDesc3",
        ClassCode: "ClassCode3",
        smallContractor:"cSmallContractor3",
        ErrorMessage: false,
      }
      this.addControls(x,true)
      this.InsuredLocations.push(x);
      this.uiService.addedLocations = this.InsuredLocations;
    }
    
  }

  addControls(x,addButton) {
    const businessOwners = this.dataService.businessOwners;
    if(addButton){
      this.clearIsMailingAddressSame()
    }
    
    this.boBpLocationAddress.addControl(x.LocationAddress, new FormControl('', Validators.required));
    this.boBpLocationAddress.addControl(x.LocationCity, new FormControl('', Validators.required));
    this.boBpLocationAddress.addControl(x.LocationState, new FormControl(businessOwners.BPLocations[0].Address.StateDesc, Validators.required));
    this.boBpLocationAddress.addControl(x.LocationZipCode, new FormControl('', Validators.required));
    this.boBpLocationAddress.addControl(x.LocationCoastal, new FormControl(false));
    this.boBpLocationAddress.addControl(x.smallContractor, new FormControl(''));
    this.boBPClassifications.addControl(x.classificationDesc, new FormControl(this.boBPClassifications.get("classificationDesc").value, Validators.required));
    this.boBPClassifications.addControl(x.BusinessDesc, new FormControl(''));
    this.boBPClassifications.addControl(x.ClassCode, new FormControl(this.boBPClassifications.get("ClassCode").value, Validators.required));
    
    if(x.ClassCode == "ClassCode2" && this.dataMappingService.originalClassCodeObj.classcode2==""){
      this.dataMappingService.originalClassCodeObj.classcode2 = this.boBPClassifications.get(x.ClassCode).value;
      this.dataMappingService.originalClassCodeObj.description2 = this.boBPClassifications.get(x.classificationDesc).value;
      this.dataMappingService.originalClassCodeObj.propertytype2 = this.dataMappingService.originalClassCodeObj.propertytype;
    }

    if(x.ClassCode == "ClassCode3" && this.dataMappingService.originalClassCodeObj.classcode3==""){
      this.dataMappingService.originalClassCodeObj.classcode3 = this.boBPClassifications.get(x.ClassCode).value;
      this.dataMappingService.originalClassCodeObj.description3 = this.boBPClassifications.get(x.classificationDesc).value;
      this.dataMappingService.originalClassCodeObj.propertytype3 = this.dataMappingService.originalClassCodeObj.propertytype
    }
   
  }
  deleteControls(x) {
    this.clearIsMailingAddressSame()
    this.boBpLocationAddress.removeControl(x.LocationAddress);
    this.boBpLocationAddress.removeControl(x.LocationCity);
    this.boBpLocationAddress.removeControl(x.LocationState);
    this.boBpLocationAddress.removeControl(x.LocationZipCode);
    this.boBpLocationAddress.removeControl(x.LocationCoastal);
    this.boBpLocationAddress.removeControl(x.smallContractor);
    this.boBPClassifications.removeControl(x.classificationDesc);
    this.boBPClassifications.removeControl(x.BusinessDesc);
    this.boBPClassifications.removeControl(x.ClassCode);
    
  }

  _doDeleteLocation(index: number) {

    if (this.InsuredLocations.length > 1) {
     
      if (index == 0) {
        this.dataMappingService.originalClassCodeObj.classcode = this.dataMappingService.originalClassCodeObj.classcode2;
        this.dataMappingService.originalClassCodeObj.description = this.dataMappingService.originalClassCodeObj.description2;
        this.dataMappingService.originalClassCodeObj.propertytype = this.dataMappingService.originalClassCodeObj.propertytype2;

        this.dataMappingService.originalClassCodeObj.classcode2 = this.dataMappingService.originalClassCodeObj.classcode3;
        this.dataMappingService.originalClassCodeObj.description2 = this.dataMappingService.originalClassCodeObj.description3;
        this.dataMappingService.originalClassCodeObj.propertytype2 = this.dataMappingService.originalClassCodeObj.propertytype3;
        
        this.dataMappingService.originalClassCodeObj.classcode3 = "";
        this.dataMappingService.originalClassCodeObj.description3 = "";
        this.dataMappingService.originalClassCodeObj.propertytype3 = "";


        this.boBPClassifications.get([this.InsuredLocations[0].classificationDesc]).setValue(this.boBPClassifications.get([this.InsuredLocations[1].classificationDesc]).value);
        this.boBPClassifications.get([this.InsuredLocations[0].BusinessDesc]).setValue(this.boBPClassifications.get([this.InsuredLocations[1].BusinessDesc]).value);
        this.boBPClassifications.get([this.InsuredLocations[0].ClassCode]).setValue(this.boBPClassifications.get([this.InsuredLocations[1].ClassCode]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].smallContractor]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].smallContractor]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].LocationAddress]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].LocationAddress]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].LocationCity]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCity]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].LocationState]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].LocationState]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].LocationZipCode]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].LocationZipCode]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[0].LocationCoastal]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCoastal]).value);
        if (this.InsuredLocations.length == 3) {
          this.boBpLocationAddress.get([this.InsuredLocations[1].LocationAddress]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationAddress]).value);
          this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCity]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationCity]).value);
          this.boBpLocationAddress.get([this.InsuredLocations[1].LocationState]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationState]).value);
          this.boBpLocationAddress.get([this.InsuredLocations[1].LocationZipCode]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationZipCode]).value);
          this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCoastal]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationCoastal]).value);
          this.boBpLocationAddress.get([this.InsuredLocations[1].smallContractor]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].smallContractor]).value);
        }
      
      }

      if (index == 2) {
        this.dataMappingService.originalClassCodeObj.classcode3 = "";
        this.dataMappingService.originalClassCodeObj.description3 = "";
        this.dataMappingService.originalClassCodeObj.propertytype3 = "";
      
      }

      if (index == 1 && this.InsuredLocations.length == 3) {
        this.boBpLocationAddress.get([this.InsuredLocations[1].LocationAddress]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationAddress]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCity]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationCity]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[1].LocationState]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationState]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[1].LocationZipCode]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationZipCode]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[1].LocationCoastal]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].LocationCoastal]).value);
        this.boBpLocationAddress.get([this.InsuredLocations[1].smallContractor]).setValue(this.boBpLocationAddress.get([this.InsuredLocations[2].smallContractor]).value);
        this.boBPClassifications.get([this.InsuredLocations[1].classificationDesc]).setValue(this.boBPClassifications.get([this.InsuredLocations[2].classificationDesc]).value);
        this.boBPClassifications.get([this.InsuredLocations[1].BusinessDesc]).setValue(this.boBPClassifications.get([this.InsuredLocations[2].BusinessDesc]).value);
        this.boBPClassifications.get([this.InsuredLocations[1].ClassCode]).setValue(this.boBPClassifications.get([this.InsuredLocations[2].ClassCode]).value);

        this.dataMappingService.originalClassCodeObj.classcode2 = this.dataMappingService.originalClassCodeObj.classcode3;
        this.dataMappingService.originalClassCodeObj.description2 = this.dataMappingService.originalClassCodeObj.description3;
        this.dataMappingService.originalClassCodeObj.propertytype2 = this.dataMappingService.originalClassCodeObj.propertytype3;
        this.dataMappingService.originalClassCodeObj.classcode3 = "";
        this.dataMappingService.originalClassCodeObj.description3 = "";
        this.dataMappingService.originalClassCodeObj.propertytype3 = "";
       
      }

      if (this.InsuredLocations.length == 2) {
        let x = this.InsuredLocations[1];
        this.deleteControls(x);
        this.InsuredLocations.splice(1, 1)
        this.uiService.addedLocations = this.InsuredLocations;
        this.uiService.coastalFlagChange();
      } else if (this.InsuredLocations.length == 3) {
        let x = this.InsuredLocations[2];
        this.deleteControls(x);
        this.InsuredLocations.splice(2, 1)
        this.uiService.addedLocations = this.InsuredLocations;
        this.uiService.coastalFlagChange();
      }
    }
    this.deleteAssociatedBuildings(index);
  }

  deleteAssociatedBuildings(index){
    let res=[];
 
    this.dataService.businessOwners.BPBuilding.forEach((d)=>{
      if(d.locationId!=index+1){
        res.push(d);
      }
    })

    let locationLength=this.dataService.businessOwners.BPLocations.length;
    if(index==0){
      this.dataMappingService.LocationOneBuildings=[];
      this.dataMappingService.LocationTwoBuildings.forEach((d)=>{
        this.dataMappingService.LocationOneBuildings.push(d);
      })
      this.dataMappingService.LocationTwoBuildings=[];
      this.dataMappingService.LocationThreeBuildings.forEach((d)=>{
        this.dataMappingService.LocationTwoBuildings.push(d);
      })
      this.dataMappingService.LocationThreeBuildings=[];
      res.forEach((d,i)=>{
        if(d.locationId=="2"){
          res[i].locationId="1";
        }
        if(d.locationId=="3"){
          res[i].locationId="2";
        }
       
       console.log(res,i);
      })
    }
    if(index==1){
      this.dataMappingService.LocationTwoBuildings=[];
      this.dataMappingService.LocationThreeBuildings.forEach((d)=>{
        this.dataMappingService.LocationTwoBuildings.push(d);
      })
      this.dataMappingService.LocationThreeBuildings=[];
      res.forEach((d,i)=>{
        if(d.locationId=="3"){
          res[i].locationId="2";
        }
      })
    }
    console.log(res,"filtered response")
    if(res.length==0){
      res.push(this.dataService.BPBuilding);
    }
      this.dataService.current_business_request.quotePolicy.BusinessOwners.BPBuilding=res;
  
  }

  disableMailingAddressFields() {
    this.agentForm.get("Insured.Address." + City).disable()
    this.agentForm.get("Insured.Address." + Line1).disable()
    this.agentForm.get("Insured.Address." + ZipCode).disable()
    this.agentForm.get("Insured.Address." + StateDesc).disable()
    this.agentForm.get("Insured.Address." + StateCode).disable()
  }

  enableMailingAddressFields() {
    this.agentForm.get("Insured.Address." + City).enable()
    this.agentForm.get("Insured.Address." + Line1).enable()
    this.agentForm.get("Insured.Address." + ZipCode).enable()
    this.agentForm.get("Insured.Address." + StateDesc).enable()
    this.agentForm.get("Insured.Address." + StateCode).enable()
  }
  makeUntouched(){
    this.agentForm.get("Insured.Address." + City).markAsPristine();
    this.agentForm.get("Insured.Address." + Line1).markAsPristine();
    this.agentForm.get("Insured.Address." + ZipCode).markAsPristine();
    this.agentForm.get("Insured.Address." + StateDesc).markAsPristine();
    this.agentForm.get("Insured.Address." + StateCode).markAsPristine();
    this.agentForm.get("Insured.Address." + City).markAsUntouched();
    this.agentForm.get("Insured.Address." + Line1).markAsUntouched();
    this.agentForm.get("Insured.Address." + ZipCode).markAsUntouched();
    this.agentForm.get("Insured.Address." + StateDesc).markAsUntouched();
    this.agentForm.get("Insured.Address." + StateCode).markAsUntouched();
  }

  clearMailingAddressFields() {
    this.agentForm.get("Insured.Address." + City).setValue('');
    this.agentForm.get("Insured.Address." + Line1).setValue('');
    this.agentForm.get("Insured.Address." + ZipCode).setValue('');
    this.agentForm.get("Insured.Address." + StateDesc).setValue('');
    this.agentForm.get("Insured.Address." + StateCode).setValue('');
   
  }

  _doOpenCopyLocationModal() {
    if (this.boBpLocationAddress.get([this.InsuredLocations[0].LocationZipCode]).value) {
      let list = [];
      this.InsuredLocations.forEach((data, index) => {
        if(this.boBpLocationAddress.get(data.LocationZipCode).value){
        let x = {};
        x = {
          id: index + 1,
          Street: this.boBpLocationAddress.get(data.LocationAddress).value,
          City: this.boBpLocationAddress.get(data.LocationCity).value,
          State: this.boBpLocationAddress.get(data.LocationState).value,
          Zip: this.boBpLocationAddress.get(data.LocationZipCode).value,
          IsSelected: false,
          IsDisabled: false
        };
        list.push(x);
      }
      })
      if (list.length > 1) {
        if (!this.uiService.confirmedMailingLocation) {
          this.uiService.mailingAddressPopupList = list;
          this.uiService.mailingLocationSelected = '';
        }
        this.uiService.CopyLocationModalFlage = true;

      }
      else {
        this.uiService.confirmedMailingLocation = list[0];
        this.setMailingAddreesFieds()
        this.disableMailingAddressFields();
        this.callInteraction1B();
      }

    } else {
      this.clearIsMailingAddressSame()
    }
  }

  callInteraction1B() {
    if (this.mailingAddressselectedVal == 'Y') {
      this.uiService.callMarketplace("1B", this.smallContractorselectedVal, true);
    }
  }

  clearIsMailingAddressSame() {
    this.mailingAddressselectedVal = '';
    this.agentForm.get(isMailingLocationAddressSame).setValue(this.mailingAddressselectedVal);
    this.uiService.confirmedMailingLocation = '';
  }

  onBlanketRatingValChange(value) {
    if (value == true) {
      this.blanketRatingselectedVal = "Y";
      this.uiService.blancketFields = true;
    } else {
      this.blanketRatingselectedVal = "N";
      this.uiService.blancketFields = false;
    }
    this.agentForm.get("BusinessOwners").get("blanketRating").setValue(this.blanketRatingselectedVal);
  }
  onBlanketAlert(data){
    if(data){
      this.uiService.BlanketInfoAlert=true;
    }
  }

  updateMailingAddressValues() {
    this.uiService.cMailingAddress.subscribe((d) => {
      if (this.uiService.confirmedMailingLocation) {
        this.setMailingAddreesFieds()
        this.disableMailingAddressFields();
        this.callInteraction1B();
      }
      else {
        this.mailingAddressselectedVal = '';
        this.agentForm.get(isMailingLocationAddressSame).setValue(this.mailingAddressselectedVal);
      }

    })
  }

  removeDeclinedLocations(){
   
    this.removeLocSubscription=this.uiService.removeLocation.subscribe((d)=>{
      this.uiService.declinedLocations.forEach((data,index)=>{
        if(index==1){
          this._doDeleteLocation(data.location.geographicLocationIdentifier-2)
        }else
        {
          this._doDeleteLocation(data.location.geographicLocationIdentifier-1)
        }
      })
    })
  }
  setMailingAddreesFieds(){
    this.agentForm.get("Insured.Address." + City).setValue(this.uiService.confirmedMailingLocation.City);
    this.agentForm.get("Insured.Address." + Line1).setValue(this.uiService.confirmedMailingLocation.Street);
    this.agentForm.get("Insured.Address." + ZipCode).setValue(this.uiService.confirmedMailingLocation.Zip);
    this.agentForm.get("Insured.Address." + StateDesc).setValue(this.uiService.confirmedMailingLocation.State);
    this.agentForm.get("Insured.Address." + StateCode).setValue(this.dataService.businessOwners.BPLocations[0].Address.StateCode);
  }

  prefillData(){
    this.InsuredLocations=this.uiService.addedLocations;
   if(this.InsuredLocations.length>1){
    this.InsuredLocations.forEach((d)=>{
      if(d.LocationAddress!='Line1'){
        this.addControls(d,false)
      }
    })
    this.setTheValues()
   }
  }
  setTheValues(){
    this.dataService.businessOwners.BPLocations.forEach((d)=>{
      if(d.referenceObjectId=="2"){
        this.boBpLocationAddress.get('Line2').setValue(d.Address.Line1);
        this.boBpLocationAddress.get('City2').setValue(d.Address.City);
        this.boBpLocationAddress.get('StateDesc2').setValue(d.Address.StateDesc);
        this.boBpLocationAddress.get('ZipCode2').setValue(d.Address.ZipCode);
        this.boBpLocationAddress.get('coastal2').setValue(d.Address.cSmallContractor?true:false);
        this.boBpLocationAddress.get('cSmallContractor2').setValue(d.Address.cSmallContractor);
        this.boBPClassifications.get('ClassCode2').setValue(this.dataMappingService.originalClassCodeObj.classcode2);
        this.boBPClassifications.get('classificationDesc2').setValue(this.dataMappingService.originalClassCodeObj.description2);
        this.boBPClassifications.get('BusinessDesc2').setValue(this.dataMappingService.originalClassCodeObj.businessDesc2);

       
      }else if(d.referenceObjectId=="3"){
        this.boBpLocationAddress.get('Line3').setValue(d.Address.Line1);
        this.boBpLocationAddress.get('City3').setValue(d.Address.City);
        this.boBpLocationAddress.get('StateDesc3').setValue(d.Address.StateDesc);
        this.boBpLocationAddress.get('ZipCode3').setValue(d.Address.ZipCode);
        this.boBpLocationAddress.get('coastal3').setValue(d.Address.cSmallContractor?true:false);
        this.boBpLocationAddress.get('cSmallContractor3').setValue(d.Address.cSmallContractor);
        this.boBPClassifications.get('ClassCode3').setValue(this.dataMappingService.originalClassCodeObj.classcode3);
        this.boBPClassifications.get('classificationDesc3').setValue(this.dataMappingService.originalClassCodeObj.description3);
        this.boBPClassifications.get('BusinessDesc3').setValue(this.dataMappingService.originalClassCodeObj.businessDesc3);
      }

    })

  }
  ngOnDestroy(){
    this.removeLocSubscription.unsubscribe();
  }
}
