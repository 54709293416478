import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { login } from '../../interfaces/login.modal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup
  hasErrors: boolean = false;

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    if(this.authService.checkLogin()){
      this.router.navigate([`agent`]);
    }
    // Initialize the login form
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    // Check if the form is valid
    if (this.loginForm.invalid) {
      return;
    }
    // Get the form values
    const username = this.loginForm.get('username').value;
    const password = this.loginForm.get('password').value;

    // Call the initiateLogin method with the form values
    const loginInput: login = { username, password };
    this.authService.initiateLogin(loginInput).subscribe(
      isLoggedIn => {
        if(isLoggedIn) {
          // Navigate to the agent screen.
          this.router.navigate([`agent`]);    
        } else {
          // Display login error message.
          this.hasErrors = true;
        }
      },
      error => {
        console.log(error);
      }
    )
  }
}
