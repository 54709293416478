import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { LexisNexisRequest } from '@app/interfaces/lexisnexis-request.modal';

@Injectable({
    providedIn: 'root'
})
export class LexisNexisService {
    url:string = environment.nodeserver;

    constructor(private httpClient: HttpClient) { }

    orderScore(payload: LexisNexisRequest, producerId: string): Observable<any> {
        return this.httpClient.post(`${this.url}lexisnexis/score/${producerId}`, payload);
    }
}